import React from "react";
import { Button } from "react-bulma-components";
import T from "../../../../../components/i18n";
import ProductStore from "../../../products-store";
import AppStore from "../../../../../app-store";
import AddSalesModal from "../../../../sales/add-sales-modal";
import EditProductsModal from "../../../edit-products-modal";
import {useHistory} from "react-router-dom";
import UserStore from "../../../../../user/user-store";
import EditVariableModal from "../../add-product/edit-variable-modal";


const ActionButtons = ({ isActiveTab, pid, parentId, mid, onClose }) => {
  const history = useHistory()

  const handleAddProduct = () => {
    AppStore.openModal(<AddSalesModal modal='sales' select_product={{
      value: ProductStore.getIdent(pid, mid),
    }}/>)
    onClose && onClose()
  }

  const handleEditProduct = () => {
    if(parentId) {
        AppStore.openModal(<EditVariableModal pid={pid} parent_id={parentId}/>)
    } else {
      AppStore.openModal(<EditProductsModal modal='edit' pid={pid}/>)
    }
    onClose && onClose()
  }

  const handleNewAddProduct = () => {
    AppStore.openModal(<EditProductsModal modal='edit' pid={parentId}/>)
    onClose && onClose()

  }

  const handleAddShipment = () => {
    history.push('/shipments/add-shipment', {
      step: 0,
      received: false,
      edit: false,
      group_select: {select_product: [{value: `${pid}_${mid}`, label: 123}]}
    });
    onClose && onClose()
  }

  const handleAddMoving = () => {
    history.push('moving/add-moving', {
      step: 0,
      received: false,
      edit: false,
      group_select: {select_product: [{value: `${pid}_${mid}`, label: 123}]}
    });
    onClose && onClose()
  }

  const handleAddWriteOff = () => {
    history.push('/write-off/add-write-off', {
      data: {items: [{product_id: pid}], from_marketplace_id: mid},
    })
    onClose && onClose()
  }

  return (
    <div className="display-flex-row-gap flex-wrap margin-top-10" >
      {parentId && <Button
        onClick={handleNewAddProduct} color='warning'>{T('add-new-product-btn')}</Button>}
      <div>
        {isActiveTab === 'Shipments' && <Button
          color="warning"
          onClick={handleAddShipment}
        >
          {T("add-to-shipment-btn")}
        </Button>}
        {isActiveTab === 'moving' && <Button
          color="warning"
          onClick={handleAddMoving}
        >
          {T("add-to-moving")}
        </Button>}
        {isActiveTab === 'write-off-label' && <Button
          color="warning"
          onClick={handleAddWriteOff}
        >
          {T("write-off-product")}
        </Button>}
      </div>
      <div className='display-flex-row flex-wrap'>
        <Button
          color="success"
          onClick={handleAddProduct}
        >
          {T("add-sale-btn")}
        </Button>
        {UserStore.getPermissionEnable('editproducts') && <Button onClick={handleEditProduct} color="info">
          {T("btn-edit")}
        </Button>}
        <Button onClick={onClose} color="light">
          {T("close")}
        </Button>
      </div>
    </div>
  );
};

export default ActionButtons;
