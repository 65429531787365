import React, {useEffect, useState} from 'react'
import T from "../../../components/i18n";
import formatDate from "../../../tools/format-date";
import DOM from "react-dom-factories";
import Money from "../../../components/money";
import ClientsStore from "../../clients/clients-store";
import User from "../../../user/user-store";
import Columns from "react-bulma-components/lib/components/columns";
import SaleStatusButton from "../common/sale-status-button";
import SalesProductRowMobile from "./sales-product-row-mobile";
import List from "react-bulma-components/lib/components/list";
import SalesDetailInfo from "./sales-detail-info";
import NumberBlock from "../../../components/number-block";
import Content from "react-bulma-components/lib/components/content";
import ActionsButtons from "./actions-buttons";
import Button from "react-bulma-components/lib/components/button";
import {
	Checkbox,
	Control,
	Field
} from "react-bulma-components/lib/components/form";
import SelectStore from "../select-store";
import UserStore from "../../../user/user-store";
import DpkTag from "../../../components/dpk-tag/dpk-tag";
import ClientInfoRow from "../../../components/client-info-row/client-info-row";
import AppStore from "../../../app-store";
import SaleViewModal from "../modal/sale-view/sale-view-modal";

const SalesRowMobile = ({columnLength, data, isCheckbox, triggerIsOpen, isRefund}) => {
	const [isOpen, setIsOpen] = useState(false)
	const [isOpenProducts, setIsOpenProducts] = useState(true)

	const itemsCount = T("items-count", {"count": data['items'].length})
	const createDate = formatDate(data.purchase_date)
	const total = data.amount_sale

	const name = <span className='product-name' onClick={(evt) => {
		evt.stopPropagation()
		data?.oid && AppStore.openModal(<SaleViewModal saleID={data?.oid}/>)}
	}>
		{T('main_sale')} #{data["order_number_text"] || data?.order_number || data?.oid}
	</span>

	const clientInfo = {}

	const productSaleInfo = data.items.reduce((acc,cur) => {
		acc.quantity += +cur.quantity?.toFixed(2)
		acc.profit += cur.profit

		return acc
	}, {
		quantity: 0,
		profit: 0,
	})

	if(data.client_id) {
		clientInfo.client = ClientsStore.getClientById(data.client_id);
		clientInfo.delivery_address = ClientsStore.getClientDeliveryById(data.client_id, data.clients_delivery_id);
	}

	const isRefundButton = data.items.filter(({is_refund}) => is_refund === 0).length === 1

	useEffect(() => {
		setIsOpen(triggerIsOpen)
	}, [triggerIsOpen]);

	const handleChangeCheckbox = (id) => {
		SelectStore.onSelectGroup(id);
	}

	const handleClickCheckbox = (e) => {
		e.stopPropagation();
	}

	const isNotDps = UserStore.isButtonDps({data: data['dps'], orderID: data['oid']});
	const isNotDpsPre = User.isButtonPrepaymentDps({data: data['dps'], prepaid: data.prepaid_amount, accountPrepaid: data.account_prepaid})

	return (
		<tr>
			<td colSpan={columnLength} align='left' style={{position: "relative", paddingTop: 5}}>
						{!isOpen && (
							<div align='right' style={{position: "absolute", bottom: 0, right: 5}}>
								<small>{createDate}</small>
							</div>
						)}
						<Columns align='left' onClick={() => setIsOpen(prevState => !prevState)}>
							<Columns.Column className='display-flex-row-gap'>
								<div>
									<div className='display-flex-row'>
										{isCheckbox && <Field style={{margin: 0}}>
											<Control>
												<Checkbox
													name="is_products"
													onChange={() => handleChangeCheckbox(data.oid)}
													onClick={handleClickCheckbox}
													checked={SelectStore.getIsSelectById(data.oid)}
												/>
											</Control>
										</Field>}
										<b className='product-name'>
											{name} <span
											className='silver nowrap'>{itemsCount} ({productSaleInfo.quantity} {T('items-e')})</span>
										</b>
									</div>
									<div>
										<DpkTag orderID={data.oid} status={data.order_status}/>
									</div>
									{!isOpen && clientInfo?.client && User.getModuleEnable("clients") && (
										<ClientInfoRow clientID={data.client_id}/>)}
									{/*{clientInfo?.delivery_address && !!Object.keys(clientInfo?.delivery_address).length && <>{ClientsStore.getClientDeliveryNane(clientInfo?.delivery_address, User.getLang())}</>}*/}
								</div>
								<div align='center'>
									<SaleStatusButton status={data.order_status} oid={data.oid} isRefund={isRefund}/>
									<div align="center" style={{width: "145px", marginLeft: 0}}>
										{(!data['dps'] || data['dps']?.length) && data.prepaid_amount > 0 && (
											<small className="has-text-danger">
												{!data['dps'] && isNotDps && isNotDpsPre && T("not-dps")}
												{!data['dps'] && !isNotDps && !isNotDpsPre && UserStore.getPRROList(false, true) &&  T("not-dps")}
												{isNotDps && data.prepaid_amount > 0 && !isNotDpsPre && T("partial-dps")}
											</small>
										)}
										{(!data['dps'] || data['dps']?.length) && data.prepaid_amount <= 0 && (
											<small className="has-text-danger">
												{!data['dps'] && !isNotDps && UserStore.getPRROList(false, true) &&  T("not-dps")}
												{!data['dps'] && isNotDps && T("not-dps")}
											</small>
										)}
									</div>
									{!isOpen && (
										<div align="left">
												<span className='font-size-120 bold'>
													<Money amount={total || 0} wrapper={DOM.span}/><br/>
												</span>
											{User.getPermissionEnable("incomeinformation") && (
												<>{T('sales-profit')}: <b><Money signClassname={true}
																												 aid={data?.account_id}
																												 wrapper={DOM.span}
																												 amount={productSaleInfo?.profit}/></b> </>)}
										</div>)
									}
								</div>
							</Columns.Column>
						</Columns>
				{isOpen && (
					<>
						<SalesDetailInfo data={data} quantity={productSaleInfo.quantity}
														 total={total} clientInfo={clientInfo} isRefund={isRefund}/>
						{data?.items?.length > 1 && <div align='right'>
							<Button
								onClick={() => setIsOpenProducts(prevState => !prevState)}
								className='button-link-without-style'
							>
								{isOpenProducts ? T('hide-sale-products') : T('show-sale-products')}
							</Button>
						</div>}
						<List>
							{!!data?.items?.length && isOpenProducts && data.items.map((item, index) =>
								<SalesProductRowMobile
									key={`${item.product_id}+${index}`}
								data={item}
								accountID={data?.account_id}
								status={data?.order_status}
								productsLength={data?.items?.length}
								orderID={data.oid}
								isRefundButton={isRefundButton}
								/>)}
						</List>
						<Columns className="is-mobile product-mobile-block">
							{UserStore.getPermissionEnable("salesprice") && <Columns.Column size="half">
								<NumberBlock top="amount-of-sale" number={total || 0}
														 bottom={User.getCurrency()} money={true}
														 className="small-number-box"/>
							</Columns.Column>}
							{!User.getPermissionEnable("incomeinformation") ? null :
								<Columns.Column>
									<Content>
										<NumberBlock top="sales-profit" number={productSaleInfo.profit || 0} bottom={User.getCurrency()} money={true} className="small-number-box" />
									</Content>
								</Columns.Column>
							}
						</Columns>
						{<ActionsButtons data={data} status={data.order_status} isRefund={isRefund}/>}
						</>)}
			</td>
		</tr>
	);
};

export default SalesRowMobile