import React, { Component, useEffect, useState } from "react";
import T from "components/i18n";
import { connectToStores } from "tools/reflux-tools";
import { redirectTo } from "tools/redirect-to";
import SelectComponent from "tools/select-component";
import OpenInsideModal from "components/modal/open-inside-modal";
import DOM from "react-dom-factories";
import {
  Field,
  Textarea,
  Radio,
  Control,
  Input,
  Label,
} from "react-bulma-components/lib/components/form";
import { Link } from "react-router-dom";
import Money from "components/money";
import Columns from "react-bulma-components/lib/components/columns";
import Heading from "react-bulma-components/lib/components/heading";
import Box from "react-bulma-components/lib/components/box";
import Button from "react-bulma-components/lib/components/button";
import Actions from "../../products-actions";
import ProductsStore from "../../products-store";
import AddVariableModal from "./add-variable-modal";
import AddProductBundle from "./add-product-bundle";
import AddCategoryModal from "../../add-category-modal";
import RenderWarehouseBlock from "../../desktop/add-product-warehouse-block";
import WarehouseStore from "modules/warehouse/warehouse-store";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
  getDefaultWarehouseValue,
  buildStock,
  prepareWarehouse,
} from "../../common/function";
import AppStore from "app-store";
import SubmitButton from "components/submit-button";
import Messages from "components/messages";
import User from "user/user-store";
import UserActions from "../../../../user/user-actions";
import UserStore from "user/user-store";
import { errorHandler, parseJSON } from "../../../../tools/error-handler";
import { LocalIcon } from "whitelables/wl-name/icons";
import { Icon } from "react-bulma-components";
import LoadImagesWithList from "../load-images-with-list";
import ProductsActions from "../../products-actions";
import Loader from "react-loader-spinner";
import AdditionalFieldsInfo from "./common/additional-fields-info";
import {
  withIsMobileSize
} from "../../../../components/is-mobile-context/is-mobile-context";
import SearchString
  from "../../../../components/base-filter-layout/search-string";
import {filterSearch} from "../../../../tools/filters-helper";
import AddBrandModal from "../../add-brand-modal";
import SalesStore from "../../../sales/sales-store";
import ChannelBlock from "../../../../components/channel-block/channel-block";
import BindingIntegrationProductsModal
  from "../../binding-integration-products-modal";

class RenderAddProductsModal extends Component {
  constructor(props) {
    super(props);
    let attr = null;
    if (props.attr) {
      const raw_attr = JSON.parse(props.attr);
      attr = [];
      for (let i in raw_attr) {
        attr.push({
          value: raw_attr[i],
        });
      }
    }

    this.DEFAULT_WAREHOUSE = getDefaultWarehouseValue({});
    let stock = buildStock(props);

    if (props.copy) {
      stock = stock.map((obj) => {
        delete obj.wid;
        return obj;
      });
    }

    let af = {};
    let custom_price = {};
    if (props.af) {
      const parseAf = JSON.parse(props.af || "{}");

      const keysAf = Object.keys(parseAf);
      keysAf.forEach((key) => {
        if (key === "custom_price") {
          custom_price = parseAf[key];
        } else {
          af[key] = parseAf[key];
        }
      });
    }
    let additionalInfo = {}
    let expirated_date = null

    if(!props.is_variable && props.pid) {
      const getProduct = ProductsStore.getProduct(props.pid)
      additionalInfo = ProductsStore.getAdditionalInfoByPid(props.pid)
      expirated_date = getProduct.expirated_date
    }



    let units = props.units || "units";
    this.state = {
      category: { value: props.category_id || 0 },
      brand: props.brand_id
        ? { value: props.brand_id }
        : { value: 0, label: T("all-brands") },
      attr: attr,
      copy: props.copy || false,
      product_data: {},
      fields: UserStore.getAdditionalFieldsList("product"),
      af: af,
      custom_price: custom_price,
      warehouses: stock || [this.DEFAULT_WAREHOUSE],
      product_name: {
        error: "",
        value: props.name || "",
      },
      type_product: {
        error: "",
        value: props.type_product ? props.type_product.toString() : "1",
      },
      desc: {
        error: "",
        value: props.desc || "",
      },
      barcode: {
        error: "",
        value: props.copy ? "" : props.barcode || "",
      },
      vendorCode: {
        error: "",
        value: props.vendorCode || stock[0]["sku"] || "",
      },
      units: {
        label: T(units),
        value: units,
      },
      is_variable: props.is_variable || false,
      close: false,
      message: null,
      waiting: null,
      edit: props.copy ? false : props.edit || false,
      source: props.source || false,
      pid: props.copy ? null : props.pid || null,
      step: props.step || 0,
      variable: props.variable || false,
      expirated_date: expirated_date || null,
      ...additionalInfo,
    };

    this.setVariable = false;

    this.onChange = this.onChange.bind(this);
    this.onChangeRadio = this.onChangeRadio.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.onChangeWarehouse = this.onChangeWarehouse.bind(this);
    this.onChangeSelectProduct = this.onChangeSelectProduct.bind(this);
    this.onChangeCustomPrice = this.onChangeCustomPrice.bind(this);
    this.submit = this.submit.bind(this);
    this.submitImages = this.submitImages.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.backStep = this.backStep.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.renderSingleProduct = this.renderSingleProduct.bind(this);
    this.renderVariableProduct = this.renderVariableProduct.bind(this);
    this.renderBundleProduct = this.renderBundleProduct.bind(this);
    this.renderStepOne = this.renderStepOne.bind(this);
    this.renderStepSecond = this.renderStepSecond.bind(this);
    this.renderServices = this.renderServices.bind(this);
    this.submitVariable = this.submitVariable.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeAdditional = this.onChangeAdditional.bind(this);
    this.onChangeSearchVariable = this.onChangeSearchVariable.bind(this);
  }

  nextStep() {
    this.setState({ step: 1 });
  }

  backStep() {
    this.setState({ step: 0 });
  }

  onChangeCheckbox(evt) {
    const value = evt.target.checked,
      name = evt.target.name;

    this.setState({
      [name]: value,
    });
  }

  closeModal(simple) {
    const { onClose, source } = this.props;
    if (!this.state.edit && !source) {
      if (onClose) {
        onClose();
      }
      this.setState({
        close: true,
      });
    } else {
      if (ProductsStore.get("openModal")) {
        ProductsStore.closeModal();
      } else if (typeof onClose == "function") {
        onClose();
      }
    }
  }

  componentWillUnmount() {
    if (typeof this.listenAdd == "function") {
      this.listenAdd();
    }
    if (typeof this.listenAddFaild == "function") {
      this.listenAddFaild();
    }
    if (typeof this.listenLoad == "function") {
      this.listenLoad();
    }
    if (typeof this.listenFromScanner == "function") {
      this.listenFromScanner();
    }
    if (typeof this.listenAddFieldCompleted == "function") {
      this.listenAddFieldCompleted();
    }
    if (typeof this.listenLoadAdditionalInfo == "function") {
      this.listenLoadAdditionalInfo();
    }
  }

  componentDidMount() {
    if (!UserStore.getPermissionEnable("editproducts") && this.props.step !== 1) {
      this.closeModal(true);
    }

    this.listenFromScanner = Actions.setProductFromScanner.completed.listen(
      (barcode) => {
        const _scan_product = ProductsStore.get("fromScanner");
        console.log("listen from scanner ", _scan_product);
        this.setState({
          barcode: { value: barcode },
        });
      }
    );
    this.listenAdd = Actions.addProduct.completed.listen((res) => {
      if (res["success"] && !this.setVariable) {
        if (this.state.edit) {
          this.closeModal();
        } else {
          this.setState({
            step: 1,
            waiting: false,
            pid: res["pid"],
          });
        }
      }

      if (this.setVariable) {
        if (!this.state.pid) {
          this.setState({
            pid: res["pid"],
          });
        }
      }

      this.setVariable = false;
    });

    this.listenLoad = Actions.loadByPid.completed.listen((res) => {
      setTimeout(() => {
        let products = ProductsStore.getProductByIdInList(this.state.pid);
        this.setState({
          variable: products,
        });
      }, 100);
    });

    this.listenAddFaild = Actions.addProduct.failed.listen((res) => {
      const data = parseJSON(res["response"]);
      const message = errorHandler(res);

      this.setState({
        waiting: false,
        message: {
          msg: data?.error?.error ? T(data["error"]["error"]) : message,
          pid: data?.error?.pid,
          product_name: data?.error?.name,
        },
      });
    });

    this.listenAddFieldCompleted = UserActions.loadProfile.completed.listen(
      () => {
        const fieldsList = UserStore.getAdditionalFieldsList("product");

        this.updateFields(fieldsList);
      }
    );

    this.listenLoadAdditionalInfo = ProductsActions.loadAdditionalInfoProduct.completed.listen(() => {
      const getAdditionalInfo = ProductsStore.getAdditionalInfoByPid(this.props.pid)
      this.setState({
        ...getAdditionalInfo
      })
    })
  }

  submitImages(images) {
    if (images && this.state.pid) {
      Actions.uploadImages(images, this.state.pid);
    }
  }

  prepareAttr() {
    let prepare_attr = {};

    if (!Array.isArray(this.state.attr) && this.state.attr?.value) {
      prepare_attr[0] = this.state.attr?.value;
    } else {
      for (let i in this.state.attr) {
        let a = this.state.attr[i];
        prepare_attr[i] = a["value"];
      }
    }

    return JSON.stringify(prepare_attr);
  }

  checkField(data) {
    let result = true,
      message = "";
    if (data["category_id"] === 0) {
      message = "HTTP 400: Bad Request (no arguments)";
      result = false;
    } else if (data["name"].length < 2) {
      result = false;
      message = "toshort-name";
    } else if (
      parseInt(data["type_product"]) === 3 &&
      Object.keys(this.state.product_data["product_data"]).length < 1
    ) {
      result = false;
      message = "select-one-products";
    }

    if (message !== "") {
      this.setState({
        message: {
          msg: T(message),
        },
      });
    }

    return result;
  }

  submit() {
    let warehouse = this.state.warehouses;

    if (this.state.copy) {
      warehouse = warehouse.map((obj) => {
        delete obj.wid;
        return obj;
      });
    }

    const {size_height, size_width, size_length, weight, ukzt, storage_place, expirated_date} = this.state


    let variableAdditionalInfo = {}

    if(this.props.is_variable && this.props.pid) {
      const getProduct = ProductsStore.getProduct(this.props.pid);
      variableAdditionalInfo = ProductsStore.getAdditionalInfoByPid(this.props.pid)
      if(getProduct.expirated_date) {
        variableAdditionalInfo['expirated_date'] = new Date(getProduct.expirated_date).getTime() / 1000;
      }
    }

    const additionalInfo = this.state.is_variable ? variableAdditionalInfo : {
      size_height: size_height ? size_height : null,
      size_width: size_width ? size_width : null,
      size_length: size_length ? size_length : null,
      weight: weight ? weight : null,
      ukzt: ukzt ? ukzt : null,
      storage_place: storage_place ? storage_place : null,
      expirated_date: expirated_date ? new Date(this.state.expirated_date).getTime() / 1000 : null
    }

    let data = {
      warehouses: prepareWarehouse(warehouse),
      category_id: this.state.category.value,
      name: this.state.product_name.value,
      desc: this.state.desc.value,
      barcode: this.state.barcode.value,
      brand: this.state.brand.value || 0,
      vendorCode: this.state.vendorCode.value,
      units: this.state.units.value,
      type_product: this.state.type_product.value,
      is_variable: this.state.is_variable ? 1 : 0,
      ...additionalInfo
    };

    if (!this.checkField(data)) {
      return;
    }

    if (data["type_product"] === "3") {
      data["bundle"] = JSON.stringify(this.state.product_data);
    } else if (data["type_product"] === "1") {
      const afData = {
        ...this.state.af,
      };
      if (!!Object.keys(this.state.custom_price).length) {
        afData.custom_price = this.state.custom_price;
      }
      data["af"] = JSON.stringify(afData || {});
    }

    if (this.state.is_variable) {
      data["parent_id"] = this.state.pid;
      data["attr"] = this.prepareAttr();
    }

    if (this.state.pid && !this.state.copy) {
      data["pid"] = this.state.pid;
    }

    let error = false,
      name = "";
    if (data["name"].length < 2) {
      error = true;
      name = "name";
    }

    if (error) {
      this.setState({
        [name]: {
          value: data[name],
          error: true,
        },
      });
      return;
    }
    this.setState({ waiting: true });

    Actions.addProduct(data);
  }

  submitVariable(data_variable, attr) {
    let product_name = this.state.product_name.value;

    // let product_name = [];
    // product_name.push(this.state.product_name.value);

    // if (Array.isArray(this.state.attr)) {
    //   for (let i in this.state.attr) {
    //     const attr_id = this.state.attr[i]['value'];
    //     if (this.state.pid && this.state.pid !== data_variable['pid']) {
    //       product_name.push(this.state.attr[i]['label']+": "+attr[attr_id]);
    //     }
    //   }
    // } else {
    //   product_name.push(this.state.attr['label']+": "+attr[this.state.attr['value']]);
    // }

    let data = {
      warehouses: prepareWarehouse(data_variable["warehouses"]),
      barcode: data_variable["barcode"],
      vendorCode: data_variable["vendorCode"],
      units: this.state.units.value,
      // "af": JSON.stringify(data_variable['af']),
      // "custom_price": JSON.stringify(data_variable.custom_price),
      category_id: this.state.category.value,
      // "name": product_name.join(". "),
      name: product_name,
      desc: this.state.desc.value,
      brand: this.state.brand.value,
      type_product: 2,
      attr: this.prepareAttr(),
      is_variable: this.state.is_variable ? 1 : 0,
      size_height: data_variable.size_height,
      size_width: data_variable.size_width,
      size_length: data_variable.size_length,
      weight: data_variable.weight,
      ukzt: data_variable.ukzt,
      storage_place: data_variable.storage_place,
      expirated_date: data_variable.expirated_date
    };

    const afData = { ...data_variable.af };

    if (!!Object.keys(data_variable.custom_price).length) {
      afData.custom_price = data_variable.custom_price;
    }

    data["af"] = JSON.stringify(afData || {});

    if (!this.checkField(data)) {
      return;
    }

    let error = false,
      name = "";
    if (data["name"].length < 2) {
      error = true;
      name = "name";
    }

    if (error) {
      this.setState({
        [name]: {
          value: data[name],
          error: true,
        },
      });
      return;
    }

    // let data  = {...main_data, ...data_variable};

    if (this.state.pid) {
      data["parent_id"] = this.state.pid;
    }

    if (data_variable["pid"]) {
      data["pid"] = data_variable["pid"];
    }

    if (!this.state.pid || data_variable["pid"] === this.state.pid) {
      let pr_data = {};
      for (let i in data_variable) {
        if (i === "pid" || i === "warehouses") {
          pr_data[i] = data_variable[i];
        } else {
          pr_data[i] = { value: data_variable[i] };
        }
      }

      this.setState(pr_data);
    }

    if (attr) {
      data["attr_value"] = JSON.stringify(attr);
    }

    this.setVariable = true;
    // this.setState({"waiting": true});

    Actions.addProduct(data);
  }

  getLinkEditProduct(pid, name) {
    return (
      <Link
        to={{
          pathname: "/products/edit-products",
          state: {
            pid: this.state.message["pid"],
          },
        }}
      >
        {this.state.message["product_name"]}
      </Link>
    );
  }

  onChangeWarehouse(warehouses) {
    this.setState({
      warehouses: warehouses,
    });
  }

  onChangeSelectProduct(product_data, mid) {
    let warehouse = getDefaultWarehouseValue({
      quantity: product_data.count,
      price: product_data.price,
      mid: mid.value,
      netprice: product_data.amount,
    });

    this.setState({
      warehouses: [warehouse],
      product_data: product_data,
    });
  }

  onChange(evt) {
    const value = evt.target.value,
      name = evt.target.name;

    this.setState({
      [name]: {
        value: value,
        error: "",
      },
      message: null,
    });
  }
  onChangeInput({ target: { name, value } }) {
    this.setState({
      [name]: value,
    });
  }

  onChangeAdditional(evt) {
    const value = evt.target.value,
      name = evt.target.name;

    let field = this.state.af;
    field[name] = value;

    this.setState({
      af: field,
      message: null,
    });
  }

  onChangeCustomPrice(evt) {
    const value = evt.target.value,
      name = evt.target.name;

    let field = this.state.custom_price;

    field[name] = value;

    this.setState({
      custom_price: field,
    });
  }

  onChangeRadio(evt) {
    const value = evt.target.value,
      name = evt.target.name;

    this.setState({
      [name]: {
        value: value,
        error: "",
      },
      is_variable: value === "2" ? true : false,
      message: null,
    });
  }

  onChangeSelect(selectedOption, actions) {
    this.setState({
      [actions.name]: selectedOption,
      message: null,
    });
  }

  updateFields(fields) {
    this.setState({
      fields: fields,
    });
  }

  deleteProduct(pid) {
    confirmAlert({
      title: T("confirm-delete"),
      message: T("are-you-sure-to-delete-this-product"),
      buttons: [
        {
          label: T("delete"),
          onClick: () => Actions.deleteProduct(pid),
        },
        {
          label: T("no-delete"),
          onClick: () => {},
        },
      ],
    });
  }

  generateSku() {
    var result = [];
    var characters = "abci0123456789";
    var charactersLength = characters.length;
    for (let j = 0; j < 2; j++) {
      let r = "";
      for (let i = 0; i < 3; i++) {
        r += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      result.push(r);
    }
    this.setState({
      vendorCode: { value: result.join("-") },
    });
  }

  generateBarcode() {
    this.setState({
      barcode: { value: Date.now().toString().substring(1, 13) },
    });
  }

  renderCustomPrice() {
    const getCustomPriceField = UserStore.getCustomPrice();

    const chunkArray = (array, size) => {
      const chunked_arr = [];
      let index = 0;
      while (index < array.length) {
        chunked_arr.push(array.slice(index, size + index));
        index += size;
      }
      return chunked_arr;
    };

    const chunkedPriceFields = chunkArray(getCustomPriceField, 4);

    return (
      !!chunkedPriceFields.length && (
        <>
          <Label size="large">{T("custom-prices")}</Label>
          {chunkedPriceFields.map((chunk, chunkIndex) => (
            <Columns key={chunkIndex}>
              {chunk.map((name) => (
                <Columns.Column key={name}>
                  <Field>
                    <Label>{name}</Label>
                    <Input
                      size="large"
                      name={name}
                      type="number"
                      autoComplete="nope"
                      onChange={this.onChangeCustomPrice}
                      value={this.state.custom_price[name]}
                    />
                  </Field>
                </Columns.Column>
              ))}
            </Columns>
          ))}
        </>
      )
    );
  }

  renderBarcodeAndVendor() {
    const handleBarcodeIconClick = (event) => {
      event.stopPropagation();
      User.postMessage("init-scan");
    };

    return (
      <Columns className={this.props.isMobileSize ? "is-mobile" : ""}>
        <Columns.Column>
          <Field style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Control>
              <Label>{T("product-barcode")}</Label>
              <Control
                iconRight={!!User.isMobileApp()}
                style={{ width: "100%" }}
              >
                <Input
                  size="large"
                  name="barcode"
                  type="text"
                  autoComplete="nope"
                  onChange={this.onChange}
                  color={this.state.barcode.error ? "danger" : "primary"}
                  value={this.state.barcode.value}
                />
                {User.isMobileApp() && (
                  <Icon
                    align="right"
                    style={{ pointerEvents: "auto" }}
                    onClick={handleBarcodeIconClick}
                  >
                    <span style={{ marginTop: "5px" }}>
                      <LocalIcon icon="barcode-scan" width={14} />
                    </span>
                  </Icon>
                )}
              </Control>
              <div align="right">
                <Button
                  onClick={() => this.generateBarcode()}
                  className="button_link sml"
                  renderAs="a"
                >
                  {T("gen-barcode")}
                </Button>
              </div>
            </Control>
          </Field>
        </Columns.Column>
        <Columns.Column>
          <Field>
            <Control>
              <Label>{T("product-vendor-code")}</Label>
              <Input
                size="large"
                name="vendorCode"
                type="text"
                autoComplete="nope"
                onChange={this.onChange}
                color={this.state.vendorCode.error ? "danger" : "primary"}
                value={this.state.vendorCode.value}
              />
            </Control>
            <div align="right">
              <Button
                onClick={() => this.generateSku()}
                className="button_link sml"
              >
                {T("gen-sku")}
              </Button>
            </div>
          </Field>
        </Columns.Column>
      </Columns>
    );
  }

  renderSingleProduct(type) {
    return (
      <div>
        {this.renderBarcodeAndVendor()}

        {this.state.source !== "shipment" ? (
          <RenderWarehouseBlock
            {...this.props}
            typeProduct={this.state.type_product.value}
            mid={this.props.mid}
            onClose={this.closeModal}
            onChange={this.onChangeWarehouse}
          />
        ) : null}
        <AdditionalFieldsInfo
          source={this.state.source}
          state={this.state}
          type={type}
          onChangeCustomPrice={this.onChangeCustomPrice}
          onChangeAdditional={this.onChangeAdditional}
          onChangeInput={this.onChangeInput}
          onChangeTextArea={this.onChange}
        />
      </div>
    );
  }

  renderServices() {
    return this.renderSingleProduct('services');
  }

  getIntegrationItem(mp, pid, mid) {
    let integraions = [];
    for (let i in mp) {
      let iid = mp[i];
      let channel_id = SalesStore.foundChannelsByIntegrationId(iid);


      const isMidInIntegration = UserStore.isIntegrationWithWarehouse({mid,iid})
      if(!isMidInIntegration) continue


      integraions.push(
          <ChannelBlock key={`'ii-${pid}_${iid}_${mid}`} channel_id={channel_id} onClick={() => {
            User.isAdmin() ?  AppStore.openModal(<BindingIntegrationProductsModal modal='binding-modal' product_id={parseFloat(pid)} product_mid={parseFloat(mid)} integration_id={parseFloat(iid)}/>) : console.log("false")}}/>
      )
    }
    return integraions
  }

  getIntegrationButton(pid, mid) {
    return (
        <Link
          key={`binding-${pid}`}
          to='#'
          onClick={() => AppStore.openModal(<BindingIntegrationProductsModal modal='binding-modal' product_id={parseFloat(pid)} product_mid={parseFloat(mid)} isCreate={true}/>)}
        >
          <LocalIcon icon='integration' size='small'/>
        </Link>
      )
  }

  renderVariable() {
    let response = [];

    for (let i in this.state.variable) {
      const variable = this.state.variable[i];

      let sku = [];
      for (let i in variable['stock']) {
        let stock = variable['stock'][i];
        sku.push(stock['sku'])
      }

      let result = true
      if(this.state.searchVariable) {
        const searchData = {
          ...variable,
          sku: sku.join(" ")
        }

        result = filterSearch(this.state.searchVariable, ['id', 'name', 'brand', 'asin', 'asin2', 'sku', 'af', 'attr_dict'], searchData);
      }

      if(!result) {
        continue
      }


      // console.log("!variable ", variable);
      for (let j in variable["stock"]) {
        const stock = variable["stock"][j];

        let attr_desc = {};
        try {
          attr_desc = JSON.parse(variable["attr_desc"]);
        } catch {
          attr_desc = {};
        }

        if (attr_desc === null) {
          attr_desc = {};
        }

        const integrationButton = this.getIntegrationItem(variable.mp, variable.id, j)

        response.push(
          <Box key={`variable-${variable.id}-${j}`}>
            <div className='display-flex-row-gap' style={{alignItems: 'flex-start'}}>
              <Heading size={6}>
                ID: {variable["id"]}.{" "}
                {Object.keys(attr_desc).length > 0
                  ? ProductsStore.buildAttr(attr_desc)
                  : variable["name"]}
              </Heading>
              <div className='display-flex-row'>
                {integrationButton}
                {UserStore.getProductIntegrationModuleEnable(variable.mp.length) && this.getIntegrationButton(variable.id, j)}
              </div>
            </div>
            <Columns className={this.props.isMobileSize ? "is-mobile" : ""}>
              <Columns.Column>
                {!User.getPermissionEnable("incomeinformation") ? null : (
                  <>
                    <b>{T("products-netcost")}</b>:{" "}
                    <Money
                      signClassname={true}
                      amount={stock["netprice"]}
                      wrapper={DOM.span}
                    />{" "}
                    <br />
                  </>
                )}
                <b>{T("products-price")}</b>:{" "}
                <Money
                  signClassname={true}
                  amount={stock["price"]}
                  wrapper={DOM.span}
                />{" "}
                <br />
                {WarehouseStore.isDropshipping(stock["marketplace_id"]) ? null : (
                  <span>
                    <b>{T("product-info-warehouse-one")}</b>: {stock["instock"]}{" "}
                    {T("items-e")} <br />
                  </span>
                )}
              </Columns.Column>
              <Columns.Column>
                {WarehouseStore.isNeedName() ? (
                  <span>
                    <b>{T("product-marketplace")}</b>:{" "}
                    {T(WarehouseStore.getWarehouseName(stock["marketplace_id"]))} <br />
                  </span>
                ) : null}
                {variable["asin"] ? (
                  <span>
                    <b>{T("product-barcode")}</b>: {variable["asin"]} <br />
                  </span>
                ) : null}
                {stock["sku"] ? (
                  <span>
                    <b>{T("product-vendor-code")}</b>: {stock["sku"]} <br />
                    <br />
                  </span>
                ) : null}
                <Button.Group>
                  <OpenInsideModal size="small" text={T("edit-btn")}>
                    <AddVariableModal
                      onCloseProduct={this.props?.onClose}
                      attr={this.state.attr}
                      edit={true}
                      submit={this.submitVariable}
                      {...variable}
                    />
                  </OpenInsideModal>

                  {(this.state.pid !== variable["id"] ||
                  this.state?.variable?.length === 1) && User.getPermissionEnable("deleteproducts") ? (
                    <Button
                      size="small"
                      rounded
                      color="light"
                      onClick={() =>
                        this.deleteProduct(
                          ProductsStore.getIdent(variable["id"], stock["marketplace_id"])
                        )
                      }
                    >
                      {T("delete")}
                    </Button>
                  ) : null}
                </Button.Group>
              </Columns.Column>
            </Columns>
          </Box>
        );
      }
    }

    return response;
  }

  onChangeSearchVariable({name, value}) {
    if(name) {
      this.setState({
        searchVariable: value
      })
    }
  }

  renderVariableProduct() {
    let variable = {},
      for_new = {};

    if (!this.state.variable && this.state.edit) {
      variable = {
        stock: {},
        asin: this.state.barcode.value || "",
        id: this.state.pid || 0,
        edit: true,
      };
    }

    if (this.state.variable) {
      // for_new = {...this.state.variable[0]};  //it's not working.. I broke my brain..
      // for_new = Object.assign({}, this.state.variable[0]); //it's not working too!!!!!
      const findProductWithStock = this.state.variable.find(item => !!Object.keys(item.stock).length)
      for_new = JSON.parse(JSON.stringify(findProductWithStock)); //it's work.....
      for_new["id"] = null;
      for_new["edit"] = false;
      let s = Object.keys(for_new["stock"]);
      let s_n = for_new["stock"][s[0]];
      if(s_n) {
        s_n["quantity"] = 0;
        s_n["wid"] = false;
        s_n["is_calc"] = false;
        for_new["stock"] = [s_n];
      }
    }

    return (
      <div key={"p-" + variable["id"]}>
        <Field>
          <Control>
            <SelectComponent
              name="attr"
              label="add-attr"
              onChange={this.onChangeSelect}
              value={this.state.attr}
              addItem={Actions.addAttr}
              list={ProductsStore.getAttrList}
              load={Actions.loadAttr}
              isMulti={true}
              creatable={true}
            />
          </Control>
        </Field>
        <Field>
          <Control>
            <Label>{T("product-description")}</Label>
            <Textarea
              size="large"
              name="desc"
              type="text"
              rows={2}
              placeholder={T("product-description-placeholder")}
              onChange={this.onChange}
              color={this.state.desc.error ? "danger" : "primary"}
              value={this.state.desc.value}
            />
          </Control>
        </Field>

        {this.state.variable && this.state.variable.length > 2 && <div className='display-flex-row' style={{justifyContent: 'flex-end', margin: '10px 0'}}><SearchString onChange={this.onChangeSearchVariable} defaultValue={this.state.searchVariable} /></div>}
        {this.state.variable ? this.renderVariable() : null}

        <div align="right">
          {this.state.attr && this.state.product_name.value.length > 0 ? (
            <OpenInsideModal
              size={this.props.isMobileSize ? "small" : "medium"}
              text={T("add-variable")}
            >
              <AddVariableModal
                onCloseProduct={this.props?.onClose}
                source={this.state.source}
                attr={this.state.attr}
                submit={this.submitVariable}
                mid={this.props.mid}
                {...for_new}
              />
            </OpenInsideModal>
          ) : null}
        </div>
      </div>
    );
  }

  renderBundleProduct() {
    return (
      <>
        {this.renderBarcodeAndVendor()}

        <AddProductBundle
          bid={this.state.pid}
          warehouse={this.state.warehouses}
          product_data={this.state.product_data}
          onChange={this.onChangeSelectProduct}
        />
      </>
    );
  }

  getAdditionalList() {
    let response = [];

    for (let name of this.state.fields) {
      response.push(
        <Field key={"ad-f-" + name}>
          <Control>
            <Label>{name}</Label>
            <Input
              size="large"
              name={name}
              type="text"
              autoComplete="nope"
              onChange={(e) => this.onChangeAdditional(e)}
              value={this.state.af[name]}
            />
          </Control>
        </Field>
      );
    }

    // if (response.length > 1) {
    //     let size = 2;
    //     let subarray = [];
    //     for (let i = 0; i <Math.ceil(response.length/size); i++){
    //         subarray[i] = response.slice((i*size), (i*size) + size);
    //     }

    //     return (
    //         <Columns>

    //         </Columns>

    //       )
    //     console.log(subarray);
    // }
    return response;
  }

  renderStepOne() {
    const isDisabledType =
      this.state.edit && ["2", "3"].indexOf(this.state.type_product.value) > -1;
    // const isDisabledType = (this.state.edit)
    let button = this.state.edit
        ? T("edit-product-btn")
        : T("add-new-product-btn"),
      title = this.state.edit ? T("product-name") : T("new-product-name");

    if (this.state.variable) {
      button = T("btn-save");
    }

    const renderSub = [
      this.renderSingleProduct,
      this.renderVariableProduct,
      this.renderBundleProduct,
      this.renderServices,
    ][parseInt(this.state.type_product.value) - 1];
    return (
      <Box>
        <Field>
          <Control>
            <Label>{title}*</Label>
            <Input
              size="large"
              name="product_name"
              type="text"
              autoComplete="nope"
              onChange={this.onChange}
              color={this.state.product_name.error ? "danger" : "primary"}
              value={this.state.product_name.value}
            />
          </Control>
        </Field>
        <Columns className={this.props.isMobileSize ? "is-mobile" : ""}>
          <Columns.Column>
            <SelectComponent
              name="category"
              label="category"
              // empty="select-category"
              onChange={this.onChangeSelect}
              value={this.state.category}
              addItem={({name}) => {
                AppStore.openModal(<AddCategoryModal modal="add-category" name={name}/>);
              }}
              addItemModal={() => {
                AppStore.openModal(<AddCategoryModal modal="add-category" />);
              }}
              list={ProductsStore.getFormattingCategoryList}
              load={Actions.loadCategory}
              creatable={true}
            />
          </Columns.Column>
          {parseInt(this.state.type_product.value) !== 4 && (
            <Columns.Column>
              <SelectComponent
                name="brand"
                label="product-brand"
                onChange={this.onChangeSelect}
                value={this.state.brand}
                empty={T("select-brand")}
                addItem={({name}) => AppStore.openModal(<AddBrandModal name={name}/>)}
                addItemModal={() => AppStore.openModal(<AddBrandModal/>)}
                list={ProductsStore.getBrandsList}
                load={Actions.loadBrand}
                creatable={true}
              />
            </Columns.Column>
          )}
          {parseInt(this.state.type_product.value) !== 4 && (
            <Columns.Column>
              <Field>
                <Control>
                  <SelectComponent
                    name={"units"}
                    label="units-of-measurement"
                    onChange={this.onChangeSelect}
                    value={this.state.units}
                    list={ProductsStore.getUnitsList}
                    creatable={false}
                  />
                </Control>
              </Field>
            </Columns.Column>
          )}
        </Columns>
        <Field>
          <Control>
            <Radio
              onChange={this.onChangeRadio}
              checked={this.state.type_product.value === "1"}
              value="1"
              size="large"
              disabled={isDisabledType}
              name="type_product"
            >
              {T("is-simple")}
            </Radio>

            <Radio
              onChange={this.onChangeRadio}
              checked={this.state.type_product.value === "2"}
              value="2"
              size="large"
              disabled={isDisabledType}
              name="type_product"
            >
              {T("is-variable-product")}
            </Radio>
            <Radio
              onChange={this.onChangeRadio}
              checked={this.state.type_product.value === "3"}
              value="3"
              size="large"
              disabled={isDisabledType}
              name="type_product"
            >
              {T("is-complect")}
            </Radio>
            <Radio
              onChange={this.onChangeRadio}
              checked={this.state.type_product.value === "4"}
              value="4"
              size="large"
              disabled={isDisabledType}
              name="type_product"
            >
              {T("services")}
            </Radio>
          </Control>
        </Field>

        {renderSub()}

        <Button.Group className="margin-top-10" position="right">
          {!this.state.is_variable || this.state.variable ? (
            <SubmitButton
              size={this.props.isMobileSize ? "medium" : "large"}
              text={button}
              fullwidth={false}
              waiting={this.state.waiting}
              submit={this.submit}
            />
          ) : null}

          <Button
            size={this.props.isMobileSize ? "medium" : "large"}
            rounded
            color="light"
            onClick={() => {
              this.closeModal(true);
            }}
          >
            {T("close")}
          </Button>
        </Button.Group>
      </Box>
    );
  }

  renderStepSecond() {
    return (
      <LoadImagesWithList
        pid={this.state.pid}
        submit={this.submitImages}
        back={this.backStep}
        done={this.closeModal}
      />
    );
  }

  getMessage() {
    if (!this.state.message) return null;

    if (!this.state.message["pid"]) {
      return this.state.message;
    }

    return (
      <span>
        {this.state.message.msg} ID: {this.state.message["pid"]}.{" "}
        {this.state.message["product_name"]}
      </span>
    );
  }

  render() {
    const stepNaviganor = [this.renderStepOne, this.renderStepSecond],
      currentStep = stepNaviganor[this.state.step];
    return (
      <div
        className={`add-product ${
          this.state.type_product.value === "2" ? "is-variable" : ""
        }`}
      >
        <Messages
          message={this.getMessage()}
          close={() => this.setState({ message: null })}
        />
        {currentStep()}
        {this.state.close ? redirectTo("/products") : ""}
      </div>
    );
  }
}

const AddProductsModal = (props) => {
  const [isWaiting, setIsWaiting] = useState(props.copy ? false : !!props.pid);

  useEffect(() => {
    if (props.pid && !props.copy) {
      if (props.is_variable) {
        const getIdsVariable = ProductsStore.getIdsProductVariable(props.pid);
        ProductsActions.loadByPid(getIdsVariable);
        ProductsActions.loadAdditionalInfoProduct(getIdsVariable)
      } else {
        ProductsActions.loadAdditionalInfoProduct([props.pid])
        ProductsActions.loadByPid([props.pid]);
      }
    }

    const loadByPid = ProductsActions.loadByPid.completed.listen(() => {
      setIsWaiting(false);
    });

    return () => {
      loadByPid();
    };
  }, [props.copy, props.is_variable, props.pid]);

  return isWaiting ? (
    <div className="display-flex-center">
      <Loader type="Rings" color="#00BFFF" height={80} width={80} />
    </div>
  ) : (
    <RenderAddProductsModal {...props} />
  );
};

export default connectToStores(withIsMobileSize(AddProductsModal), {
  products: ProductsStore,
});
