import AddShipmentBase from "../../modules/shipments/shipments-component/add-shipment-base";
import AddMoving from "../../modules/moving/add-moving";
import AddProduction from "../../modules/production/add-production";
import AddInventory from "../../modules/inventory/add-inventory";
import Sales from "../../modules/sales/index-nw";
import Settings from "../../modules/settings";
import Products from "../../modules/products";
import History from "../../modules/history";
// import Reports from "../../modules/reports";
import Reports from "../../modules/reports/index-nw";
import AddExpensesModalLink from "../../modules/expenses/add-expenses-modal-link";
import AddWriteOff from "../../modules/write-off/add-write-off/add-write-off";
import Chats from "../../modules/chats/index-nw";
import UserChat from "../../modules/chats/users-chat/users-chat";
import React from "react";
import MassBinding from "../../modules/settings/mass-binding";

const routes = [
  {
    path: "/expenses/add-expenses",
    auth: true,
    component: AddExpensesModalLink,
  },
  {
    path: "/shipments/add-shipment",
    auth: true,
    component: AddShipmentBase,
  },
  {
    path: "/shipments/add-shipment-base",
    auth: true,
    component: AddShipmentBase,
  },
  {
    path: "/shipments/edit-shipment",
    auth: true,
    component: AddShipmentBase,
  },
  {
    path: "/moving/add-moving",
    auth: true,
    component: AddMoving,
  },
  {
    path: "/moving/edit",
    auth: true,
    component: AddMoving,
  },
  {
    path: "/write-off/edit",
    auth: true,
    component: AddWriteOff,
  },
  {
    path: "/write-off/add-write-off",
    auth: true,
    component: AddWriteOff,
  },
  {
    path: "/production/add-production",
    auth: true,
    component: AddProduction,
  },
  {
    path: "/production/edit",
    auth: true,
    component: AddProduction,
  },
  {
    path: "/inventory/add-inventory",
    auth: true,
    component: AddInventory,
  },
  {
    path: "/inventory/edit",
    auth: true,
    component: AddInventory,
  },
  {
    path: "/sales",
    auth: true,
    component: Sales,
  },
  {
    path: "/products",
    auth: true,
    component: Products,
  },
  {
    path: "/products/:id",
    auth: true,
    component: Products,
  },
  {
    path: "/history",
    auth: true,
    component: History,
  },
  {
    path: "/sales/:status",
    auth: true,
    component: Sales,
  },
  {
    path: "/settings",
    auth: true,
    component: Settings,
  },
  {
    path: "/settings/permission",
    auth: true,
    component: Settings,
  },
  {
    path: "/settings/subscribe",
    auth: true,
    component: Settings,
  },
  {
    path: "/settings/catalog",
    auth: true,
    component: Settings,
  },
  {
    path: "/settings/catalog/:catalogID",
    auth: true,
    component: Settings,
  },
  {
    path: "/settings/settings",
    auth: true,
    component: Settings,
  },
  {
    path: "/settings/integrations",
    auth: true,
    component: Settings,
  },
  {
    path: "/settings/integrations/mass-binding",
    auth: true,
    component: () => <MassBinding/>
  },
  {
    path: "/settings/partners",
    auth: true,
    component: Settings,
  },
  {
    path: "/settings/terminal",
    auth: true,
    component: Settings,
  },
  {
    path: "/settings/print",
    auth: true,
    component: Settings
  },
  {
    path: "/settings/products",
    auth: true,
    component: Settings,
  },
  {
    path: "/reports",
    auth: true,
    component: Reports,
  },
  {
    path: "/reports/:tabs",
    auth: true,
    component: Reports,
  },
  {
    path: "/chats",
    auth: true,
    component: () => <Chats />,
  },
  {
    path: "/chats/:id",
    auth: true,
    component: () => <UserChat />,
  },
];

export default routes;
