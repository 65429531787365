import React from "react";
import List from "react-bulma-components/lib/components/list";
import Columns from "react-bulma-components/lib/components/columns";
import User from "../../../../user/user-store";
import T from "../../../../components/i18n";
import { createMobileAppLink } from "../../../../tools/create-mobile-app-link";
import { Link } from "react-router-dom";
import ProductsActions from "../../products-actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmDelete } from "../../../../tools/confirm-delete";
import ShowFilters from "./show-filters";
import {
  useIsMobile
} from "../../../../components/is-mobile-context/is-mobile-context";
import ProductsStore from "../../products-store";

const PriceItem = ({ filters, line }) => {
  const {isMobileSize} = useIsMobile()
  return (
    <List.Item>
      <Columns className={isMobileSize ? "is-mobile" : ""}>
        <Columns.Column size="one-fifth">{line["name"]}</Columns.Column>
        <Columns.Column size="one-fifth">
          <a
            rel="noopener noreferrer"
            href={
              `${ProductsStore._prefix_remote_csv}prl?lang=` +
              User.getLang() +
              "&pl=" +
              line["ident"] +
              "&format=html"
            }
            target="_blank"
          >
            {T("HTML")}
          </a>{" "}
          |{" "}
          <a
            rel="noopener noreferrer"
            onClick={() =>
              createMobileAppLink(
                `${ProductsStore._prefix_remote_csv}prl?pl=` + line["ident"] + "&format=pdf"
              )
            }
            href={`${ProductsStore._prefix_remote_csv}prl?pl=${line["ident"]}&format=pdf&lang=${User.getLang()}`}
            target="_blank"
          >
            {T("PDF")}
          </a>
        </Columns.Column>
        <Columns.Column>
          <ShowFilters filters={filters} />
        </Columns.Column>
        <Columns.Column
          align="center"
          size="one-fifth"
          className="display-flex-center"
          style={{ height: "auto" }}
        >
          <Link
            to="#"
            onClick={() =>
              confirmDelete(() => ProductsActions.deletePriceList(line["id"]))
            }
            title={T("delete")}
          >
            <FontAwesomeIcon icon="trash-alt" size="1x" />
          </Link>
        </Columns.Column>
      </Columns>
    </List.Item>
  );
};

export default PriceItem;
