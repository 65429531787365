import yajax from "yajax";

import { createStore } from "../../tools/reflux-tools";
import Actions from './shipments-actions';
import ProductActions from '../products/products-actions';
import ExpensesActions from '../expenses/expenses-actions';
import User from '../../user/user-store';

import { filterSearch } from 'tools/filters-helper';
import {getDatePeriod} from "../../tools/date-range";
import UserStore from "../../user/user-store";
import T from '../../components/i18n'

const CLEAR_FILTER = () => ({
    status: "all",
    mid: {'value': 0, label: T('all')},
    date_start: getDatePeriod(30),
    date_end: new Date(),
    account: {'value': 0, label: T('all')},
    search: "",
    supplier: {value: 0, label: T('all')},
    currency: {value: 0, label: T("all")},
    searchInSupplier: '',
    product: null,
})

const ShipmentsStore = createStore({
	listenables: Actions,
    _load_url: '/bexpenses/shipment',
    _prefix: '/bproducts/',
    _prefix_files: '/bproducts-loads/',
    _prefix_expenses: '/bexpenses/',
    default_status: ['complete', 'pending', 'saled'],
    state: {
        rows: null,
        dictByPid: {},
        reports: null,
        delivery: null,
        suppliers: null,
        lastAdd: 0,
        isLoaded: false,
        isLoadedDelivery: false,
        _filter: CLEAR_FILTER()
    },

    get(field) {
        return this.state[field];
    },

    getFilter(field) {
        return this.state['_filter'][field];
    },

    clearFilter() {
      this.setState({
          _filter: CLEAR_FILTER()
      })
    },

    onFilter(name, value) {
        let filters = {};

        for (let i in this.state['_filter']) {
            filters[i] = this.state['_filter'][i];
        }
        let date_start = filters['date_start'];

        filters[name] = value;

        this.setState({
            _filter: filters
        });

        if (name === "date_start" && value < date_start) {
            Actions.load();
        }
    },

    prepareStatus(data) {
        let result = [];

        for (let i in data) {
            let d = data[i];
            let name = typeof(d['name']) !== "undefined" ? d['name'] : d;
            let val = typeof(d['name']) !== "undefined" ? d['name'] : d;
            result.push({'id': val, 'name': name})
        }

        return result;
    },

    getStatusList() {
        return this.prepareStatus(this.default_status);
    },

    onAdd(data) {
        console.log("start add shipment", data)
        yajax.post(this._prefix_expenses+"shipment", JSON.stringify(data)).then(
            Actions.add.completed, Actions.add.failed
        );
    },

    onAddCompleted(data) {
        console.log("result add shipment: ", data)
        //Actions.load();
        if (data['finish']) {
            setTimeout(() => {
                // ProductActions.load(true);
                ExpensesActions.loadAccounts();
                ExpensesActions.load();
                Actions.load(true);
            }, 500)
        }

        if(data.step === 4) {
            const shipment = this.getShipmentById(data.sid)
            if(shipment) {
                const pids = shipment.items.map(item => item.product_id)
                if(pids.length > 100){
                    ProductActions.loadByPid(pids)
                } else {
                    ProductActions.load(true)
                }
            }
        }
    },
    
    onAddFailed(){
        console.log("failed add shipment")
    },

    onDelete(data) {
        console.log("start delete shipment product", data);
        
        const params = {
          method: "DELETE",
          url: this._prefix_expenses+"shipment",
          data: data
        };

        let shipment = null

        if(data.sid) {
            shipment = this.getShipmentById(data.sid)
        }

        yajax(params).then(
          (res) => Actions.delete.completed(res, shipment), Actions.delete.failed
        );
    },
    onDeleteCompleted(data, shipment) {
        if (["shipment", "undo"].indexOf(data['type']) > -1) {
            setTimeout(() => {
                ExpensesActions.loadAccounts();
                Actions.load();
                if(shipment && shipment?.items?.length) {
                    const getItemsId = shipment.items.map(item => item.product_id)
                    ProductActions.loadByPid(getItemsId)
                }
            }, 1000);
        }
        console.log("result delete shhipment product: ", data)
    },

    onDeleteFailed(){
        console.log("failed delete shipment product")
    },

    onAddSupplier(data) {
        console.log("start add suppliers", data)
        yajax.post(this._prefix+"suppliers", data).then(
            Actions.addSupplier.completed, Actions.addSupplier.failed
        );
    },
    onAddSupplierCompleted(data) {
        console.log("result add suppliers: ", data)
        if (data['success']) {
            this.setState({
                'suppliers': data['data'],
                'lastAdd': data['sid']
            });
        }
    },
    onAddSupplierFailed(){
        console.log("failed add suppliers")
    },

    onAddDeliveryService(data) {
        console.log("start add delivery service", data)
        yajax.post(this._prefix+"delivery", data).then(
            Actions.addDeliveryService.completed, Actions.addDeliveryService.failed
        );
    },
    onAddDeliveryServiceCompleted(data) {
        console.log("result add delivery service: ", data)
        if (data['success']) {
            this.setState({
                'delivery': data['data']
            });
        }
    },
    onAddDeliveryServiceFailed(){
        console.log("failed add delivery service")
    },

    getDeliveryName(id) {
        const deliveries = this.getDeliveryList();

        for (let i in deliveries) {
            const delivery = deliveries[i];

            if (parseInt(id) === parseInt(delivery['id'])) {
                return delivery['name'];
            }
        }
    },

    getSupplier(id) {
        const suppliers = this.getSuppliersList();
        const supplier = suppliers.find(supplier => parseInt(supplier.id) === parseInt(id));

        return supplier;
    },

    getSupplierName(id) {
        const suppliers = this.getSuppliersList();

        for (let i in suppliers) {
            const supplier = suppliers[i];

            if (parseInt(id) === parseInt(supplier['id'])) {
                return supplier['name'];
            }
        }
    },

    getDeliveryList() {
        const delivery = this.get("delivery");
        if (delivery === null) {
            Actions.loadDelivery();
            return [];
        }

        return delivery;
    },

    getSuppliersList() {
        const suppliers = this.get("suppliers");
        if (suppliers === null) {
            Actions.loadSuppliers();
            return [];
        }

        return suppliers;
    },

    getSupplierById(supplierId) {
      const list = this.getSuppliersList()

      return list.find(item => parseInt(item.id) === parseInt(supplierId))
    },

    onLoadDelivery(data) {
        if (this.loadDelivery) return;

        this.loadDelivery = true;
        console.log("start load delivery...", data)
        yajax.get(this._prefix+"delivery", JSON.stringify(data)).then(
            Actions.loadDelivery.completed, Actions.loadDelivery.failed
        );
    },
    
    onLoadDeliveryCompleted(data) {
        if (data['success']) {
            this.setState({
                'delivery': data['data']
            });
        }

        this.loadDelivery = false;
        console.log("result load delivery: ", data)
    },
    onLoadDeliveryFailed(){
        this.loadDelivery = false;
        console.log("failed add delivery")
    },

    applyFilter(shipment) {
        let s_date = shipment['delivered_date'] || shipment['created_at']
        // const [dateValues, timeValues] = s_date.split(' ');
        // const [day, month, year] = dateValues.split('-');
        // const [hours, minutes] = timeValues.split(':');
        // const date = new Date(+year, +month - 1, +day, +hours, +minutes);
        const date = new Date(s_date*1000);
        const filter = this.state._filter;
        let result = true;
        filter['date_start'].setHours(0,0,0,0);
        filter['date_end'].setHours(23,59,59,999);

        if (date < filter['date_start'] || date > filter['date_end']) {
            return false;
        }

        if (shipment['supplier_id']) {
            shipment['supplier'] = this.getSupplierName(shipment['supplier_id']);
        } else {
            shipment['supplier']  = "";
        }

        result = filterSearch(filter['search'], ['id', 'name', 'comment', 'supplier'], shipment);
        if (result) {
            if (filter['mid'] && filter['mid']['value'] !== 0) {
                result = result && (parseInt(filter['mid']['value']) === parseInt(shipment['marketplace_id'])) ;
            }

            if (filter['status'] && filter['status'] !== "all" && filter['status']['value'] !== 0) {
                result = result && (filter['status']['value'] === shipment['shipment_status']) ;
            }

            if(filter['supplier'] && filter['supplier']['value'] !== 0) {
                result = result && (filter['supplier']['value'] === shipment['supplier_id']) ;
            }
            if(filter['currency'] && filter['currency']['value'] !== 0) {
                result = result && (parseInt(filter['currency']['value']) === parseInt(shipment['currency'])) ;
            }

            if (filter['product'] && filter['product'].length > 0 && filter['product'][0]['value'] !== 0) {

                const isSomeProduct = filter['product'].some(product => {
                    const [pid] = product['value'].split("_")

                    return shipment.items.some(item => parseInt(item.product_id) === parseInt(pid))
                })

                result = result && isSomeProduct;
            }

        }


        return result;
    },

    getShipments() {
        const shipments = this.getList();
        return shipments.filter(this.applyFilter);
    },

    getShipmentById(sid) {
        for (let i in this.state.rows) {
            const ship = this.state.rows[i];
            if (parseInt(ship['id']) === parseInt(sid)) {
                return ship;
            }
        }
    }, 

    getShipmentByProductId(pid) {
        let shipment_by_product = [];
        for (let i in this.state.rows) {
            const ship = this.state.rows[i];
            for (let j in ship['items']) {
                const item = ship['items'][j];
                if (parseInt(item['product_id']) === parseInt(pid)) {
                    shipment_by_product.push({
                        'id': ship['id'],
                        'name': ship['name'],
                        'marketplace_id': ship['marketplace_id'],
                        'supplier_id': ship['supplier_id'],
                        'currency_id': ship['currency'],
                        'currency_rate': ship['currency_rate'],
                        'amount': ship['amount'],
                        'created_at': ship['created_at'],
                        'delivered_date': ship['delivered_date'],
                        'item_amount': item['amount'],
                        'purchase_price': item['purchase_price'],
                        'quantity_received': item['quantity_received'],
                        'quantity_shipped': item['quantity_shipped'],
                        'quantity_sold': item['quantity_sold'],
                        "pid": item['product_id'],
                        "fee_total": item.fee_per_unit || 0
                    });
                    break;
                }
            }
        }

        return shipment_by_product;
    },

    getShipmentBySupplierId(id) {
      const shipment = this.getList()

      return shipment.filter(item => {
          if(parseInt(item.supplier_id) === parseInt(id)) {
              const filter = this.state._filter
              const s_date = item['delivered_date'] || item['created_at']
              const date = new Date(s_date*1000);

              filter['date_start'].setHours(0,0,0,0);
              filter['date_end'].setHours(23,59,59,999);

              return !(date < filter["date_start"] || date > filter["date_end"])
          }

          return false
      })
    },

    getCalcStatisticsBySupplier(shipments) {
        const calcExpenses = (expenses, currencyId) => {
            if(expenses) {
                return expenses.reduce((acc, cur) => {
                    if(currencyId) {
                        if(acc[currencyId]) {
                            acc[currencyId] += cur.amount;
                        } else {
                            acc[currencyId] = cur.amount;
                        }
                    }
                    return acc
                }, {})
            }

            return {
                [currencyId]: 0
            }
        }

        return shipments.reduce((acc, cur) => {
            if(acc.sumAmountByCurrency[cur.currency]) {
                acc.sumAmountByCurrency[cur.currency] += cur.amount;
            } else {
                acc.sumAmountByCurrency[cur.currency] = cur.amount;
            }

            const calcExpensesData = calcExpenses(cur.expenses, cur.currency)

            if(!!Object.keys(calcExpensesData).length) {
                Object.entries(calcExpensesData).forEach(([key, value]) => {
                    if(acc.sumExpensesAmountByCurrency[key]) {
                        acc.sumExpensesAmountByCurrency[key] += value;
                    } else {
                        acc.sumExpensesAmountByCurrency[key] = value
                    }
                })
            }
            if(cur.shipment_status === 'pending') {
                acc.pendingCount += 1
            } else {
                acc.completeCount += 1
            }

            return acc
        },{
            sumAmountByCurrency: {},
            sumExpensesAmountByCurrency: {},
            pendingCount: 0,
            completeCount: 0
        })
    },

    getShipmentStatisticBySupplier() {
        const getSupplier = this.getSuppliersList()

        return getSupplier.reduce((acc,cur) => {
            const supplierId = cur.id
            const getShipmentBySupplier = this.getShipmentBySupplierId(supplierId)
            acc[supplierId] = this.getCalcStatisticsBySupplier(getShipmentBySupplier)
            return acc
        }, {})
    },

    getShipmentsStatisticsBySupplierId(supplierId) {
      const getStatistics = this.getShipmentStatisticBySupplier()

      return getStatistics[supplierId] || {}
    },

    onLoadSuppliers(data) {
        if (this.loadSuppliers) return;

        this.loadSuppliers = true;

        console.log("start load suppliers...", data)
        yajax.get(this._prefix+"suppliers", JSON.stringify(data)).then(
            Actions.loadSuppliers.completed, Actions.loadSuppliers.failed
        );
    },
    
    onLoadSuppliersCompleted(data) {
        this.loadSuppliers = false;
        if (data['success']) {
            this.setState({
                'suppliers': data['data']
            });
        }
        console.log("result load suppliers: ", data)
    },
    onLoadSuppliersFailed(){
        this.loadSuppliers = false;
        console.log("failed add sale")
    },

    onLoadExcel(sid, mid, file) {
        const data = new FormData();
        data.append('sid', sid);
        data.append('mid', mid);
        data.append('file', file);
        // data.append('template', template);


        let headers = {
            "X-Source-Type": "desktop"
        }

        if (!User.isGuest()) {
            headers["X-Auth-Token"] = encodeURIComponent(User.getToken())
        }
        
        console.log("start load files, ", data)
        return yajax.post(this._prefix_files+"shipment/excel", data, {
            raw: true,
            headers: headers
        }).then(
            Actions.loadExcel.completed, Actions.loadExcel.failed
        );
    },
    onLoadExcelCompleted() {
        setTimeout(() => {
            ProductActions.load(true);
            setTimeout(()=>{
                Actions.load(true)
            }, 500);
        }, 2000);
    },
    onLoadExcelFailed() {
    },

    onLoad(force) {
        if (this.loading) return;
        this.setState({
            loadingShipment: true
        })

        this.loading = this._load_url;

        let url = this._load_url;

        if (force) {
            url += "?t="+Date.now();
        }

        let filter = this.state._filter;

        filter['date_start'].setHours(0,0,0,0);
        filter['date_end'].setHours(23,59,59,999);

        const data = {
            "start": parseInt(filter['date_start'].getTime()/1000),
            "end": parseInt(filter['date_end'].getTime()/1000)
        }

        if (force) {
            data.t = "?t="+Date.now();
        }

        yajax.get(url, data).then(this.listenables.load.completed, this.listenables.load.failed);
    },

    onLoadCompleted(data) {
        if (data['success']) {

            const permissionWarehouse = UserStore.getPermissionWarehouse()


            const filterWithWarehouse = data['data'].filter(item => {
                let result = true
                if(permissionWarehouse?.length) {
                    result = result && permissionWarehouse.some(permissionID => permissionID === item?.marketplace_id)
                }

                return result
            })

            const s = {
                'rows': filterWithWarehouse,
            }

            if ('reports' in data) {
                s['reports'] = data['reports'];
            }
            this.setState(s);
        }
        this.setState({
            loadingShipment: false
        })
        console.log("!!!! result load: ", this.loading)
        this.loading = false;

    },
    onLoadFailed(){
        console.log("!!!! failed loading", this.loading)
        this.setState({
            loadingShipment: false
        })
        this.loading = false;
    },

    getShipmentDeliveryDate(pid, sid) {
      const dictByPid = this.get('dictByPid')
      if(dictByPid[pid]) {
        const data = dictByPid[pid]
        const findShipmentById = data.find(item => item.id === sid)

        if(findShipmentById) {
            return new Date(findShipmentById.created_at * 1000)
        }

      } else {
          Actions.loadByPid(pid)
      }
      return null
    },

    onLoadByPid(pid) {
      if (this.loadingByPid) return;

      this.loadingByPid = this._load_url;

      yajax.get(this._load_url, {pid}).then((data) => Actions.loadByPid.completed(data, pid), Actions.loadByPid.failed)
    },
    onLoadByPidCompleted(data, pid) {
      if(data['success']) {
          const dictByPid = JSON.parse(JSON.stringify(this.state.dictByPid))

          dictByPid[pid] = data['data']
          this.setState({
              dictByPid: dictByPid
          })
      }
      this.loadingByPid = false
      console.log("load by pid completed")
    },
    onLoadByPidFailed() {
        this.loadingByPid = false
      console.log('load by pid failed')
    },

    printShipmentURL(type) {
      const filter = this.state._filter;
      const start = filter['date_start'].getTime()/1000;
      const end = filter['date_end'].getTime()/1000;

      const data = []
      if(filter.mid.value) {
          data.push(`&warehouse=${filter.mid.value}`)
      }
      if(filter.status.value) {
          data.push(`&status=${filter.status.value}`)
      }
      if(filter.search) {
          data.push(`&search=${filter.search}`)
      }
      if(filter.supplier.value) {
          data.push(`&supplier_id=${filter.supplier.value}`)
      }
      if(filter.currency.value) {
          data.push(`&currency=${filter.currency.value}`)
      }

      return `/bprint/shipments?format=${type || 'pdf'}&date_start=${Math.round(start)}&date_end=${Math.round(end)}${data.join('')}&token=${encodeURIComponent(User.getToken())}&lang=${UserStore.getLang()}`
    },

    getReport() {
        const report = this.get('reports')
        if(report === null) {
            this.setState({
                reports: {}
            })
            Actions.load(true)
            return {}
        }

        return report
    }
});

export default ShipmentsStore;