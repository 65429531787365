import React from 'react'
import SelectComponent from "../../../../tools/select-component";
import WarehouseStore from "../../../warehouse/warehouse-store";
import WarehouseActions from "../../../warehouse/warehouse-actions";
import {
	Checkbox,
	Field,
	Label
} from "react-bulma-components/lib/components/form";
import T from '../../../../components/i18n'

const VirtualWarehouse = ({onChange,onChangeCheckbox, value, vmidValue, withoutMidId}) => {

	const filterWarehouseList = () => {
		const list = WarehouseStore.getWarehouseList();

		return list.filter(item => {
			if(WarehouseStore.isDropshipping(item.id)) return false

			if(withoutMidId && withoutMidId === item.id) return false

			return item
		})
	}

	return (
		<>
			<Label>
				<Field>
					<Checkbox onChange={onChangeCheckbox} checked={vmidValue} name='vmid' style={{display: 'flex'}}>
						{T('virtual-warehouse')}
					</Checkbox>
				</Field>
			</Label>
			{vmidValue && <SelectComponent
				name="vmids"
				placeholder={'virtual-warehouse'}
				onChange={onChange}
				value={value}
				list={filterWarehouseList}
				className='full-size-select'
				load={WarehouseActions.load}
				isMulti
				creatable={false}
			/>}
		</>
	);
};

export default VirtualWarehouse;