import React, {useEffect, useState} from "react";
import Box from "react-bulma-components/lib/components/box";
import { Label, Textarea } from "react-bulma-components/lib/components/form";
import T from "../../../../../components/i18n";
import Button from "react-bulma-components/lib/components/button";
import s from "./styles.module.scss";
import PreviewImage from "../../../../../components/load-file-image/preview-image";
import PreviewDocument from "../../../../../components/load-file-image/preview-document";
import ErrorMessageControlHook from "../../../../../components/error-message-block/error-message-control.hook";

const allowedTypes = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "text/csv",
  ".csv",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/webp",
  "image/svg+xml",
  "image/tiff",
  "image/bmp",
  "text/plain",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.oasis.opendocument.text",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.oasis.opendocument.presentation",
  "application/vnd.apple.pages",
  "application/vnd.apple.numbers",
  "application/vnd.apple.keynote",
];

const LoadImages = ({ blobFile, messageData, onSubmit, onClose, onDisabledSendParent, onChangeMessage  }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [message, setMessage] = useState(messageData || "");
  const {
    setErrorMessage,
    ErrorMessageBlock,
    errorMessage,
    handleAutoRemoveError,
  } = ErrorMessageControlHook();

  const isFileValid = (file) => {
    const maxSize = 10 * 1024 * 1024; // 10 MB
    if (file.size > maxSize) {
      handleAutoRemoveError("size-file-not-valid");
    }
    return allowedTypes.includes(file.type) && file.size <= maxSize;
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    const newFiles = Array.from(files).filter((file) => {
      const isValid = isFileValid(file);
      if (!isValid) {
        console.error(`Invalid file: ${file.name}`);
      }
      return isValid;
    });
    setSelectedFiles([...selectedFiles, ...newFiles]);
  };

  const handleAddFileBlob = (file) => {
    const isValidBlob = isFileValid(file)
    if(!isValidBlob) {
      console.error(`Invalid file: ${file.name}`);
      return isValidBlob;
    }
    setSelectedFiles([...selectedFiles, file])
  }

  useEffect(() => {
    if(blobFile) {
      handleAddFileBlob(blobFile)
    }

    return () => {
      onDisabledSendParent && onDisabledSendParent(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleFiles = (files) => {
    const newFiles = Array.from(files).filter((file) => {
      const isValid = isFileValid(file);
      if (!isValid) {
        console.error(`Invalid file: ${file.name}`);
      }
      return isValid;
    });
    setSelectedFiles([...selectedFiles, ...newFiles]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleFiles(files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleRemoveFile = (fileToRemove) => {
    const updatedFiles = selectedFiles.filter((file) => file !== fileToRemove);
    setSelectedFiles(updatedFiles);
  };

  const handleChangeMessage = ({ target: { value } }) => {
    setMessage(value);
  };

  const renderPreviews = () => {
    return selectedFiles.map((file, index) => (
      <div key={index}>
        {file.type.includes("image") ? (
          <PreviewImage file={file} onRemove={handleRemoveFile} />
        ) : (
          <PreviewDocument file={file} onRemove={handleRemoveFile} />
        )}
      </div>
    ));
  };
// eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = () => {
    onSubmit && onSubmit({ files: selectedFiles, message });
    if (selectedFiles.length || message) {
      onClose();
    }
  };

  useEffect(() => {
    const handlePressKeyEnter = (event) => {
      if (!event) return;
      const ENTER_KEY = 13;
      if (event.keyCode === ENTER_KEY && !event.shiftKey) {
        handleSubmit();
        event.preventDefault();
        event.stopPropagation();
      }
    };

    document.addEventListener("keydown", handlePressKeyEnter);

    return () => {
      document.removeEventListener("keydown", handlePressKeyEnter);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit]);


  const handlePaste = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;

    for (const item of items) {
      if (item.type.indexOf('image') !== -1) {
        const blob = item.getAsFile();
        handleAddFileBlob(blob)
      }
    }
  }


  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
      <div className={s.file_wrapper}>
        <Box>
          <Label>{T("select-files", { count: selectedFiles.length })}</Label>
          {selectedFiles.length > 0 && (
            <div className={s.wrapper_preview} style={{}}>
              {renderPreviews()}
            </div>
          )}
          <input
            type="file"
            id="file"
            accept={allowedTypes.join(",")}
            className={s.file_input}
            onChange={handleFileChange}
            multiple
          />
          <label
            htmlFor="file"
            className={s.label_file}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            {T("choose-a-file-10mb")}
          </label>
          <div style={{ marginBottom: "10px" }}>
            <Textarea
              className="textarea-chat"
              placeholder={T("type-message-here")}
              name="message"
              rows={2}
              onChange={handleChangeMessage}
              value={message}
              onPaste={handlePaste}
              autoFocus
            />
          </div>
          <Button.Group position="centered">
            <Button size="medium" rounded color="light" onClick={onClose}>
              {T("undo")}
            </Button>
            <Button
              size="medium"
              rounded
              color="success"
              onClick={handleSubmit}
            >
              {T("send-btn-label")}
            </Button>
          </Button.Group>
        </Box>
      </div>
    </>
  );
};

export default LoadImages;
