import React from 'react'
import BaseFilterLayout from "../../../components/base-filter-layout";
import ClientsStore from "../clients-store";
import ClientsFilter from "../clients-filter";
import ClientsActions from "../clients-actions";

const DATA_SORT = [
	{label: "clients-date", value: "created_at"},
	{label: "clients-name", value: "name"},
	{label: "clients-discount", value: "discount"},
	{label: "clients-total_amount", value: "total_amount"},
	{label: "clients-debt", value: "debt"},
	{label: "clients-balance", value: 'balance'}
]

const FilterPanel = () => {
	const view = ['city', "tags", "total_amount_from",
		"total_amount_to", "debt_from",'debt_to']


	const handleChangeFilter = ({name, value}) => {
		if(name) {
			ClientsActions.filter(name, value);
		}
	}

	const handleChangeDate = (value) => {
		const daysKey = Object.keys(value).reverse()
		daysKey.forEach(item => {
			ClientsActions.filter(item, value[item]);
		})
	}

	const handleRefreshFilter = () => {
		ClientsStore.clearFilter()
		ClientsActions.load(true)
	}

	return (
		<BaseFilterLayout
			searchStringSetting={{
				onChange: handleChangeFilter,
				defaultValue: ClientsStore.getFilter("search")
			}}
			refresh={{
				onChange: handleRefreshFilter
			}}
			dateRangeSetting={{
				onChange: handleChangeDate,
				date_start: ClientsStore.getFilter("date_start"),
				date_end: ClientsStore.getFilter("date_end"),
				range: ['today', 'yesterday', 'week', 'this-month', 'last-month', '3-month', 'all-period']
			}}
			sortSetting={{
				data:DATA_SORT,
				defaultActive: ClientsStore.getFilter('sort'),
				onChange:(value) => ClientsStore.onFilter('sort', value)
			}}
			filterView={{
				list: ClientsStore.get('_filter'),
				view,
				onDelete: ClientsStore.filterClearField
			}}
			hiddenFilter={<ClientsFilter/>}
		/>)
};

export default FilterPanel;