import React from 'react'
import T from '../i18n'
import s from './styles.module.scss'

const ServicesProductTag = ({short, typeProduct}) => {
	return  typeProduct === 4 && (
		<span className={s.wrapper}>
			{short ? T('services-tag-short') : T('services-tag')}
		</span>)
};

export default ServicesProductTag;