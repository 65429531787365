import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';

// import { Provider } from 'react-redux'
// import store from './store'

import * as serviceWorker from './serviceWorker';
import {
	IsMobileContext
} from "./components/is-mobile-context/is-mobile-context";

const rootElement = document.getElementById('root')

ReactDOM.render(
	<IsMobileContext>
		<App />
	</IsMobileContext>,
  rootElement
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
