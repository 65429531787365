import React from 'react'
import User from "../../../../user/user-store";
import ExpensesStore from "../../../expenses/expenses-store";
import Level from "react-bulma-components/lib/components/level";
import OpenInsideModal from "../../../../components/modal/open-inside-modal";
import RefundModal from "../../refund-modal";

const RefundButton = ({accountID, prepaidAccountID, amount, productName, orderID}) => {
	if (!User.getPermissionEnable("refund-products")) {
		return null
	}
	if (ExpensesStore.isDebt(accountID) || ExpensesStore.isDebt(prepaidAccountID)) {
		return null;
	}

	return (
		<Level.Item>
			<OpenInsideModal size={"small"} color="warning" text='refund' icon="undo">
				<RefundModal
					fromAccount={accountID}
					amount={amount}
					product_name={productName}
					oid={orderID}
				/>
			</OpenInsideModal>
		</Level.Item>
	);
};

export default RefundButton;