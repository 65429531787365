import React from "react";

import Dashboard from "../../modules/dashboard";
import AccessDenied from "../../modules/dashboard/access_denied";
import Expenses from "../../modules/expenses";
import Investors from "../../modules/investors";
import Orders from "../../modules/orders";
import Ppc from "../../modules/ppc";
import Shipments from "../../modules/shipments";
import Moving from "../../modules/moving";
import Inventory from "../../modules/inventory";
import Production from "../../modules/production";
import HistoryProduction from "../../modules/history";
import Start from "whitelables/wl-name/start";
import Transactions from "../../modules/transactions/index-nw";
import Notification from "../../modules/notification";
import Clients from "../../modules/clients";
import PaymentSuccess from "../../modules/settings/payment_success";
import PaymentFailed from "../../modules/settings/payment_failed";
import Info from "../../public/info/wl-name";
import { notAuthRoutes } from "whitelables/wl-name/common/not-auth-routes";
import RemoteLogin from "user/login/remote-login";

import RecoveryPassForm from "../../user/login/recovery-pass-form";
import RegFormModal from "modules/registration";
import WriteOff from "../../modules/write-off";
import PublicContacts from "../../public/contacts";
import Contacts from "../../modules/contacts";
import LoginForm from "../../user/login/login-form";

const routes = [
  {
    path: "/:lang/app/login",
    auth: false,
    component: LoginForm,
    main: () => <LoginForm />,
  },
  {
    path: "/app/login",
    auth: false,
    component: LoginForm,
    main: () => <LoginForm />,
  },
  {
    path: "/:lang/rlogin",
    auth: false,
    component: RemoteLogin,
    main: () => <RemoteLogin />,
  },
  {
    path: "/:lang/app/recovery-pass",
    auth: false,
    component: RecoveryPassForm,
    main: () => <RecoveryPassForm />,
  },
  {
    path: "/:lang/app/registration",
    auth: false,
    component: RegFormModal,
    main: () => <RegFormModal />,
  },
  {
    path: "/:lang/access-denied",
    auth: true,
    component: AccessDenied,
    main: () => <AccessDenied />,
  },
  {
    path: "/dashboard",
    auth: true,
    main: () => <Dashboard />,
  },
  {
    path: "/expenses",
    auth: true,
    main: () => <Expenses />,
  },
  {
    path: "/production",
    auth: true,
    main: () => <Production />,
  },
  {
    path: "/production-history",
    auth: true,
    main: () => <HistoryProduction />,
  },
  {
    path: "/orders",
    auth: true,
    main: () => <Orders />,
  },

  {
    path: "/investors",
    auth: true,
    main: () => <Investors />,
  },
  {
    path: "/shipments",
    auth: true,
    main: () => <Shipments />,
  },
  {
    path: "/moving",
    auth: true,
    main: () => <Moving />,
  },
  {
    path: "/write-off",
    auth: true,
    main: () => <WriteOff />,
  },

  {
    path: "/inventory",
    auth: true,
    main: () => <Inventory />,
  },
  {
    path: "/ppc",
    auth: true,
    main: () => <Ppc />,
  },
  {
    path: "/transactions",
    auth: true,
    main: () => <Transactions />,
  },
  {
    path: "/notification",
    auth: true,
    main: () => <Notification />,
  },
  {
    path: "/clients",
    auth: true,
    main: () => <Clients />,
  },
  {
    path: "/start",
    auth: true,
    main: () => <Start />,
  },
  {
    path: "/:lang/help",
    auth: false,
    main: () => <Info />,
  },
  {
    path: "/settings/payment_success",
    auth: true,
    main: () => <PaymentSuccess />,
  },
  {
    path: "/settings/payment_failed",
    auth: false,
    main: () => <PaymentFailed />,
  },
  {
    path: "/:lang/public_contacts",
    auth: false,
    main: () => <PublicContacts />,
    component: PublicContacts,
  },
  {
    path: "/:lang/app/public_contacts",
    auth: false,
    main: () => <PublicContacts />,
    component: PublicContacts,
  },
  {
    path: "/contacts",
    auth: false,
    main: () => <Contacts />,
    component: Contacts,
  },
  ...notAuthRoutes,
];

export default routes;
