import React from 'react';

import T from 'components/i18n';
// import Dropdown from 'react-bulma-components/lib/components/dropdown';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Navbar from 'react-bulma-components/lib/components/navbar';

import UserStore from 'user/user-store';


import {
  NavLink
} from "react-router-dom";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {LocalIcon} from 'whitelables/wl-name/icons'


export const keyMapNavbar = {
  dashboard: {
    label: 'dashboard',
    link: '/dashboard',
    icon: <FontAwesomeIcon icon="home" size="2x" />,
  },
  sales: {
    label: 'sales',
    link: '/sales',
    icon: <FontAwesomeIcon icon="cart-arrow-down" size="2x" />,
  },
  orders: {
    label: 'orders',
    link: '/orders',
    icon: <FontAwesomeIcon icon="cart-arrow-down" size="2x" />,
  },
  chats: {
    label: 'chats',
    link: '/chats',
    icon: <LocalIcon icon="chats" fill='currentColor' size="large" style={{width: '24px'}}/>,
  },
  clients: {
    label: 'clients',
    link: '/clients',
    icon: <LocalIcon icon='clientsIcon' size="large"/>,
  },
  products: {
    label: 'products',
    link: '/products',
    icon: <FontAwesomeIcon icon="th-list" size="2x" />
  },
  shipments: {
    label: "shipments",
    link: '/shipments',
    icon: <LocalIcon icon='shipmentsIcon' size='large'/>
  },
  moving: {
    label: 'moving',
    link: '/moving',
    icon: <LocalIcon icon='movingIcon' size='large' />,
  },
  'write-off': {
    label: 'write-off',
    link: '/write-off',
    icon: <LocalIcon icon='writeOffIcon' size='large' />,
  },
  inventory: {
    label: 'inventory',
    link: '/inventory',
    icon: <LocalIcon icon='inventory' size='large' fill='currentColor'/>,
  },
  production: {
    label: 'production',
    link: '/production',
    icon: <LocalIcon icon='productionIcon' size='large' />,
  },
  transactions: {
    label: 'cash-flow',
    link: '/transactions',
    icon: <FontAwesomeIcon icon="money-check-alt" size="2x" />
  },
  reports: {
    label: 'reports',
    link: '/reports',
    icon: <LocalIcon icon='reportsIcon' size='large' />,
  },
  history: {
    label: 'history',
    link: '/history',
    icon: <LocalIcon icon='historyIcon' size='large'/>
  }
}


const MobileBottomBar = ({preView, closeMenu, previewList}) => {
  let classString = "is-expanded is-block has-text-centered";

  const defaultListNavbar = ['sales', 'transactions', 'products']

  if(UserStore.isCashier()) {
    defaultListNavbar.unshift('clients');
  } else  {
    defaultListNavbar.unshift('dashboard');
  }

  const getListNavbar = UserStore.getMoreSetting('navbar')

  const list  = previewList ? previewList : (getListNavbar && getListNavbar.length) ? getListNavbar : defaultListNavbar

  return (
    <Navbar
      fixed={preView ? null :"bottom"}
      active={true}
      transparent={false}
      onClick={closeMenu}

    >
      <Navbar.Brand style={{width: '100%'}}>
        {list.map(item => UserStore.getModuleEnable(item) && (
              <Navbar.Item key={item} renderAs="span" className={classString}>
                {preView ? (
                  <>
                    {keyMapNavbar[item].icon}
                    <div>{T(keyMapNavbar[item].label)}</div>
                  </>
                ) : (
                  <NavLink activeClassName="bottom-active" strict to={keyMapNavbar[item].link} style={{height: '100%', width: '100%', display: 'block'}}>
                    {keyMapNavbar[item].icon}
                    <div>{T(keyMapNavbar[item].label)}</div>
                  </NavLink>
                )}
              </Navbar.Item>
        ))}
      </Navbar.Brand>
    </Navbar>
  )
}

export default MobileBottomBar;