import React, {useEffect} from 'react'
import BaseFilterLayout from "../../../components/base-filter-layout";
import OrdersStore from "../orders-store";
import OrdersFilters from "../orders-filters";
import OrdersActions from "../orders-actions";
import {useLocation} from "react-router-dom";

const FilterPanel = ({tab}) => {
	const handleChange = ({name, value}) => {
		if(name) {
			OrdersActions.filter(name, value);
		}
	}

	const handleChangeDate = (value) => {
		const daysKey = Object.keys(value).reverse()
		daysKey.forEach(item => {
			OrdersActions.filter(item, value[item]);
		})
	}

	const handleRefreshFilter = () => {
		OrdersStore.clearFilter()
		OrdersActions.filter("status", tab)
		OrdersActions.refreshOrders()
	}
	const location = useLocation()

	useEffect(() => {
		if(location?.state?.date_start) {
			OrdersActions.filter('date_start', location?.state?.date_start)
		}

		if(location?.state?.search) {
			OrdersActions.filter('search', location?.state?.search)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

		return (
			<BaseFilterLayout
				searchStringSetting={{
					onChange: handleChange,
					defaultValue: OrdersStore.getFilter('search')
				}}
				refresh={{
					onChange: handleRefreshFilter,
					isLoading: OrdersStore.get("waiting")
				}}
				dateRangeSetting={{
					onChange: handleChangeDate,
					date_start: location?.state?.date_start || OrdersStore.getFilter('date_start'),
					date_end: OrdersStore.getFilter('date_end'),
				}}
				hiddenFilter={<OrdersFilters/>}
			/>)
};

export default FilterPanel;