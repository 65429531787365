import React from "react";

const messages = {
  "roi-tip": {
    ru: "Рентабельность инвестиций. Рассчитывается согласно формуле: чистая прибыль / расходы * 100",
    en: "Return on investment. It is calculated according to the formula: net profit / expenses * 100",
    ua: "Рентабельність інвестицій. Розраховується за формулою: чистий прибуток / витрати * 100"
  },
  "operating-profit-tip": {
    ru: "Разница между всеми поступлениями и расходами",
    en: "The difference between all income and expenses",
    ua: "Різниця між усіма надходженнями та витратами"
  },
  "sales-profit-tip": {
    ru: "Общий доход от продаж без вычета расходов",
    en: "Total sales income without deducting expenses",
    ua: "Загальний дохід від продажів без вирахування витрат"
  },
  "total-profit-tip": {
    ru: "Cумма всех поступлений",
    en: "The sum of all income",
    ua: "Сума усіх надходжень"
  },
  "net-profit-tip": {
    ru: "Чистая прибыль от продаж. Цена продажи минус Цена закупки минус Расходы (тип Вычитать из прибыли с продаж)",
    en: "Net profit on sales. Sales price minus Purchase price minus Expenses (type Subtract from profit on sales)",
    ua: "Чистий прибуток від продажів. Ціна продажу мінус Ціна закупівлі мінус Витрати (тип Віднімати з прибутку з продажів)"
  },
  "average-margin-tip": {
    ru: "Средний показатель разницы между выручкой и затратами",
    en: "The average percentage difference between revenue and expenses",
    ua: "Середній показник різниці між виручкою та затратами"
  },
  "average-receipt-tip": {
    ru: "Исчисляется путем деления общей суммы средств, полученных от продажи товаров, на общую численность покупок",
    en: "The ratio of the total amount of money received from the sales to the total number of purchases",
    ua: "Обчислюється шляхом ділення загальної суми коштів, отриманих від продажу товарів, на загальну кількість покупок"
  },
  "all-expenses-tip": {
    ru: "Cумма расходов за исключением расходов на поставки",
    en: "The sum of the expenses excluding shipments expenses",
    ua: "Сума витрат за виключенням витрат на поставки"
  },
  "shipments-expenses-tip": {
    ru: "Cумма расходов на поставки",
    en: "The amount of shipments expenses",
    ua: "Сума витрат на поставки"
  },
  "account-tarif-info-tip": {
    ru: <span>
          1. Квота по количеству продаж обновляется первого числа каждого месяца;<br/>
          2. Оплата происходит на основе даты последней оплаты тарифа.<br/>
          3. <span className="text-danger">Смена тарифа не плюсует количество продаж к текущему тарифу, а меняет месячную квоту.</span><br/>
        </span>,
    en: <span>
          1. The sales quota is updated on the first day of each month;<br/>
           2. Payment is made based on the date of the last tariff payment.<br/>
           3. <span className="text-danger">Changing the tariff does not add the number of sales to the current tariff, but updates the monthly quota.</span><br/>
        </span>,
    ua: <span>
          1. Квота по кількісті продажів оновлюється першого числа кожного місяця;<br/>
          2. Оплата відбувається на основі дати останньої оплати тарифу.<br/>
          3. <span className="text-danger">Зміна тарифу не плюсує кількість продажів до поточного тарифу, а змінює місячну квоту.</span><br/>
        </span>
  },
  "mass-change-status-tip": {
    ru: "Статус можно изменить только для продаж, которые не имеют статуса \"продано, забрали и отказ\". Продажи со статусом \"продано,забрали и отказ\" будут проигнорированы.",
    en: "The status can only be changed for sales that do not have a \"sold, taken away and rejection\" status. Sales with \"sold, taken away and rejection\" status will be ignored.",
    ua: "Статус можна змінити тільки для продажів, які не мають статусу \"продано, забрали і відмова\". Продажі зі статусом \"продано, забрали і відмова\" будуть проігноровані."
  },
  "client-nickname-tip": {
    ru: <span>
            Код для быстрого доступа к переписке в Telegram с выбранным пользователем:
            <br/>
            @nickname
            <br/>
            Просто введите никнейм Telegram, и вы сможете моментально перейти к переписке с этим пользователем.
        </span>,
    en: <span>
            Code for quick access to Telegram chat with a selected user:
            <br/>
            @nickname
            <br/>
            Simply enter the Telegram username, and you can instantly navigate to the chat with that user.
        </span>,
    ua: <span>
            Код для швидкого доступу до чату в Telegram з обраним користувачем:
            <br/>
            @nickname
            <br/>
            Просто введіть ім'я користувача в Telegram, і ви миттєво перейдете до чату з цим користувачем.
        </span>
  },
  "name-accounts-check-tip": {
    ru: (
      <ul style={{listStyle: 'inside'}}>
        <li>Тип счета - вместо названий счетов будут в чек передаваться
          Безланичная оплата, Карта, Наличные.
        </li>
        <li>Личные - в качестве названий счетов будут использоваться названия
          как у вас они называются.
        </li>
        <li>Вместе - будет передаваться оъединенное название Карта - Ваше
          название.
        </li>
      </ul>
    ),
    en: (
      <ul style={{listStyle: 'inside'}}>
        <li>Account type - instead of account names will be passed to the check
          Unplanned payment, Card, Cash.
        </li>
        <li>Personal - the names of the accounts will be used as the names of
          the accounts as you have them.
        </li>
        <li>Together - the unified name Card - your name will be transmitted.
        </li>
      </ul>
    ),
    ua: (
      <ul style={{listStyle: 'inside'}}>
        <li>Тип рахунку - замість назв рахунків в чек передаватимуться
          Безготівкова оплата, Картка, Готівка.
        </li>
        <li>Особисті - в якості назв рахунків будуть використовуватися назви як
          у вас вони називаються.
        </li>
        <li>Разом - передаватиметься об'єднана назва Карта - Ваша назва.</li>
      </ul>
    ),
  },
  "sale-discount-tip": {
    ru: "Применяется ко всем товарам на которых нет скидки",
    en: "Applies to all products that are not discounted",
    ua: "Застосовується до всіх товарів на яких немає знижки"
  },
  "when-additional-price-tip": {
    ru: "Если в карточке товара не указана дополнительная цена, тогда будет напечатана основная цена товара.",
    en: "If no additional price is specified in the product card, then the main price of the product will be printed.",
    ua: "Якщо в картці товару не вказана додаткова ціна, тоді буде надрукована основна ціна товару."
  },
  "report-total-sales": {
    ru: "Если сумма продаж по типам начислений больше чем общая, то это означает, что товары в продажах были из разных типов начислений.",
    en: "If the sum of sales by accrual type is greater than the total, it means that the items in the sales were from different accrual types.",
    ua: "Якщо сума продажів по типам нарахувань більша ніж загальна, то це означає ,що товари в продажах були з різних типів нарахувань."
  },
  "these-expenses-are-deducted-tip": {
    ru: "Эти расходы вычитаются из прибыль продаж, они появляются, если вы добавили в категории расходов \"Вычитать из Прибыли с продаж\"",
    en: "These expenses are deducted from sales profit, they appear if you have added \"Deduct from Sales Profit\" to the expense category",
    ua: "Ці витрати віднімаються від прибутку продажів, вони з'являються, якщо ви додали в категорії витрат \"Віднімати від Прибутку з продажів\"",
  },
  "income-from-sales-other-expenses-without-shipments": {
    ru: "Доход с продаж + Другие поступления - Расходы (без поставок)",
    en: "Income from sales + Other income - Expenses (without shipments)",
    ua: "Дохід з продажів + Інші надходження - Витрати (без поставок)",
  },
  "expenses-cost-of-sales-tip": {
    ru: "Расходы уже учтенные в себестоимости (комиссии, доставка, производство), либо расходы не влияющие на доход (вывод прибыли)",
    en: "Expenses already recognized in the cost of sales (commissions, delivery, production) or expenses not affecting income (profit withdrawal)",
    ua: "Витрати вже враховані в собівартості (комісії, доставка, виробництво), або витрати, що не впливають на дохід (виведення прибутку)"
  },
  "expense-deducted-income-tip": {
    ru: "Чтобы расход не вычитался дважды с дохода, списывать комиссию лучше в Категории Платежей с типом (Не учитывать в доходах).",
    en: "To prevent the expense from being deducted twice in income, it is better to write off the commission in the Payments category with the type (Do not include in income).",
    ua: "Щоб витрата не віднімалася двічі від доходу, списувати комісію краще в Категорії Платежів з типом (Не враховувати в доходах)."
  },
  "change-price-type-bulk-tip": {
    ru: "\n" +
      "- При выборе массового типа цены, у всех товаров автоматически произойдёт замена цены в соответствии с выбранным типом. Если у товара нет установленной цены для данного типа, цена не изменится.\n" +
      "\n" +
      "- Если уже выбрано массовое изменение типа цены, все последующие добавленные товары будут менять свой тип цены автоматически, если для них задана соответствующая цена. Иначе будет оставлена базовая цена.",
    en: "\n" +
      "- If you select a bulk price type, all products will automatically have their prices changed according to the selected type. If the item does not have a set price for this type, the price will not change.\n" +
      "\n" +
      "- If you have already selected a bulk change of price type, all subsequent added products will change their price type automatically if they have the appropriate price set for them. Otherwise, the base price will remain.",
    ua:"\n" +
      "- При виборі масового типу ціни, у всіх товарів автоматично відбудеться заміна ціни відповідно до обраного типу. Якщо у товару немає встановленої ціни для даного типу, ціна не зміниться.\n" +
      "\n" +
      "- Якщо вже вибрано масову зміну типу ціни, усі наступні додані товари змінюватимуть свій тип ціни автоматично, якщо для них задано відповідну ціну. Інакше буде залишено базову ціну."
  },
  "not-select-channel-integration-tip": {
    ru: "Если вы не выберете канал продаж, он создастся автоматически!",
    en: "If you do not select a sales channel, it will be created automatically!",
    ua: "Якщо ви не оберете канал продажів, він створиться автоматично!"
  },
  "change-delivery-shipment-tip": {
    ru: "Вы можете вручную перераспределить стоимость доставки между товарами, но общая стоимость доставки на поставке не изменится",
    en: "You can manually redistribute the shipping cost among the items, but the total shipping cost for the shipment will not change.",
    ua: "Ви можете вручну перерозподілити вартість доставки між товарами, але загальна вартість доставки на поставці не зміниться."
}
}

export default messages;