import React from "react";
import Button from "react-bulma-components/lib/components/button";
import ProductsStore from "../../../products/products-store";
import Columns from "react-bulma-components/lib/components/columns";
import Content from "react-bulma-components/lib/components/content";
import UserStore from "../../../../user/user-store";
import T from "../../../../components/i18n";
import WarehouseStore from "../../../warehouse/warehouse-store";
import Money from "../../../../components/money";
import DOM from "react-dom-factories";
import calcMargin from "../../../../tools/calc-margin";
import AppStore from "../../../../app-store";
import ViewProductModal
  from "../../../products/modal/view-product/view-product-modal";

const SalesReportSubItem = ({ data }) => {
	const margin = calcMargin(data["net_price"], data["amount"]);

	let pr_d = ProductsStore.getProduct(data["product_id"]);
	data["product_name"] = pr_d["name"];

  return (
    <div className="margin-top-10">
      <Button
        className="button_link"
        onClick={() => AppStore.openModal(<ViewProductModal pid={data["product_id"]}/>)}
      >
        <b>
          ID: {data["product_id"]}. {data["product_name"]}. {data["quantity"]}{" "}
          {ProductsStore.getUnits(pr_d["units"])}{" "}
        </b>
        <br />
      </Button>
      <Columns>
        <Columns.Column>
          <Content>
            {!UserStore.getPermissionEnable("incomeinformation") ? null : (
              <span>
                <b>{T("margin")}:</b> {margin}% <br />
              </span>
            )}
            {WarehouseStore.isNeedName() ? (
              <span>
                <b>{T("history-data-marketplace_id")}:</b>{" "}
                {WarehouseStore.getWarehouseName(data["mid"])} <br />
              </span>
            ) : null}
          </Content>
        </Columns.Column>
        <Columns.Column>
          <Content>
            {!UserStore.getPermissionEnable("incomeinformation") ? null : (
              <span>
                <b>{T("products-netcost")}:</b>{" "}
                <Money
                  amount={data["net_price"]}
                  aid={data["account_id"]}
                  wrapper={DOM.span}
                />{" "}
                <br />
              </span>
            )}
            {!UserStore.getPermissionEnable("salesprice") ? null : (
              <span>
                <b>{T("products-price")}:</b>{" "}
                <Money
                  amount={data["amount"]}
                  aid={data["account_id"]}
                  wrapper={DOM.span}
                />{" "}
                <br />
              </span>
            )}
            {!UserStore.getPermissionEnable("incomeinformation") ? null : (
              <span>
                <b>{T("sales-profit")}:</b>{" "}
                <Money
                  amount={data["profit"]}
                  aid={data["account_id"]}
                  className="text-success"
                  wrapper={DOM.span}
                />
                <br />
              </span>
            )}
          </Content>
        </Columns.Column>
      </Columns>
    </div>
  );
};

export default SalesReportSubItem;
