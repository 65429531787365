import Reflux from 'reflux';

const UserActions = Reflux.createActions({
	 // "login": { children: ['completed', 'failed'] },
	 // "reg": { children: ['completed', 'failed'] },
	 //auth
    login: { asyncResult: true },
    logout: { asyncResult: true },
    changeToken: { asyncResult: true },
    getSubUsers: { asyncResult: true },
    checkIp: { asyncResult: true },
    changeTarif: { asyncResult: true },
    loadProfile: { asyncResult: true },
    getCurrentStateTarif: { asyncResult: true },
    loadTransactions: { asyncResult: true },
    cancelSubscribe: { asyncResult: true },
    initSession: { asyncResult: true },
    // changePassword: { asyncResult: true },
    renewToken: { asyncResult: true },
    updateSettings: { asyncResult: true },
    keepAlive: { sync: true },
    clearAccount: { asyncResult: true },
    changePasswd: { asyncResult: true },
    recoveryPass: { asyncResult: true },
    recoveryPassCode: { asyncResult: true },
    updateIntegrations: { asyncResult: true },
    deleteIntegration: { asyncResult: true },
    paymentSuccess: { asyncResult: true },
    loadMessages: { asyncResult: true },
    readMessage: { asyncResult: true },
    changeRates: { asyncResult: true },
    setCustomColumns: {asyncResult: true},
    confirmChangeTarif: {asyncResult: true},
    deleteCustomColumns: {asyncResult: true},
    downloadListCategories: {asyncResult: true},
    downloadListIntegrationStatus: {asyncResult: true}
});


export default UserActions;
