import React from 'react'
import Columns from "react-bulma-components/lib/components/columns";
import CheckboxGroupShopSupervision from "./checkbox-group-shop-supervision";

const DATA_CHECKBOX = [
	{
		name: "update_stock",
		title: "update-instock"
	},
	{
		name: "update_price",
		title: "update-price"
	},
	{
		name: "update_orders",
		title: "update-orders"
	}
]

const DATA_CHECKBOX_2 = [
	{
		name: "is_description",
		title: "integration-is-description",
	},
	{
		name: "is_price",
		title: "integration-is-price",
	},
	{
		name: "dont_orders",
		title: 'integration-is-dont-orders'
	}
]

const IntegrationCheckboxGroup = ({values,addFieldGroupOne = [], addFieldGroupSecond= [], onChange}) => {

	return (
		<Columns>
			<Columns.Column>
				<CheckboxGroupShopSupervision
					label="transfer-from-us-site"
					dataCheckbox={[...DATA_CHECKBOX, ...addFieldGroupOne]}
					checkboxValue={values}
					onChange={onChange}
				/>
			</Columns.Column>
			<Columns.Column>
				<CheckboxGroupShopSupervision
					label='transfer-from-site-us'
					dataCheckbox={[...DATA_CHECKBOX_2, ...addFieldGroupSecond]}
					checkboxValue={values}
					onChange={onChange}
				/>
			</Columns.Column>
		</Columns>
	);
};

export default IntegrationCheckboxGroup;