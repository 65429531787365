import yajax from "yajax";
import { createStore } from "../../tools/reflux-tools";
import Actions from './warehouse-actions';
// import T from "components/i18n";
// import User from 'user/user-store';

const WarehouseStore = createStore({
	listenables: Actions,
    _prefix: "/userprofile/",
    state: {
        rows: [],
        warehouses: null,
        default_warehouse: null,
        count: 1,
        isLoaded: false
    },

    w_type: {
        'STANDART': 1,
        'DROPSHIPPING': 2,
        'NEGATIVE_STOCK': 3
    },

    get(field) {
        return this.state[field];
    },

    getInitialState() {
        return this.state;
    },
 
    isNeedName() {
        return this.state.count > 1;
    },

    onAddWarehouse(data) {
        return yajax.put(
            this._prefix+'warehouse',
            {
                "name": data.name.value,
                "wid": data.wid || 0,
                "wtype": data.warehouseType
            },
        ).then(Actions.addWarehouse.completed, Actions.addWarehouse.failed);
    },

    onAddWarehouseCompleted(data) {
        console.log("completed add martket place", data);
        Actions.load();
    },

    onAddWarehouseFailed() {
        console.log("failed add expenses");
    },

    onDeleteWarehouse(_id) {
        const params = {
          method: "DELETE",
          url: this._prefix+"warehouse",
          data: { "id": _id }
        };

        yajax(params).then(
            Actions.deleteWarehouse.completed, Actions.deleteWarehouse.failed
        );
    },

    onDeleteWarehouseCompleted(data) {
        Actions.load(true);
    },

    onDeleteWarehouseFailed() {
        console.log("PROBLEM WITH DELETE WAREHOUSE");
    },

    getWarehouseName(id, isReal) {
        const warehouses = this.getWarehouseList();

        if (warehouses.length === 1 && !isReal){
            return warehouses[0]['name'];
        }

        if (typeof(id) === undefined) return "";

        let warehouse = warehouses.filter((w) => {return parseInt(id) === parseInt(w['id'])})

        if (warehouse.length > 0) {
            return warehouse[0]['name'];
        }

        return "";
    },

    isDropshipping(id) {
        const warehouses = this.getWarehouseList();
        for (let i in warehouses) {
            const warehouse = warehouses[i];
            if (parseInt(id) === parseInt(warehouse['id'])) {
                return parseInt(warehouse['w_type']) === this.w_type['DROPSHIPPING'];
            }
        }
        return false;
    },    

    isNegativeStock(id) {
        const warehouses = this.getWarehouseList();
        for (let i in warehouses) {
            const warehouse = warehouses[i];
            if (parseInt(id) === parseInt(warehouse['id'])) {
                return parseInt(warehouse['w_type']) === this.w_type['NEGATIVE_STOCK'];
            }
        }
        return false;
    },
    isStandardStock(id) {
        const warehouses = this.getWarehouseList();
        for (let i in warehouses) {
            const warehouse = warehouses[i];
            if (parseInt(id) === parseInt(warehouse['id'])) {
                return parseInt(warehouse['w_type']) === this.w_type['STANDART'];
            }
        }
        return false;
    },

    getDefaultWarehouse() {
        if (this.state.default_warehouse) {
            return this.state.default_warehouse;
        }

        const warehouse_list = this.getWarehouseList();
        if (warehouse_list.length > 0) {
            this.setState({
                'default_warehouse': warehouse_list[0].id
            });
        }

        return this.state.default_warehouse || 0;
    },

    getWarehouseList() {
        const warehouse = this.get("rows");
        if (warehouse === null) {

            Actions.load();
            return [];
        }

        return warehouse;
    },

    getTabList() {
      const list = this.getWarehouseList()
      const itemAll = { label: "all", value: 0, url: "", is_global: 1 }
        if(list && list.length) {
            const tabList = list.map(item => ({label: item.name, value: item.id, url: item.id, count: item.add_prod}))

            return [itemAll, ...tabList]
        }
    },

    getWarehouseById(id) {
        const list = this.getWarehouseList()

        return list.find(item => item.id === id)
    },

    getWarehouseShipmentList() {
        const warehouses = this.getWarehouseList(),
              result = warehouses.filter((row) => { return [this.w_type['STANDART'], this.w_type['NEGATIVE_STOCK']].indexOf(parseInt(row['w_type'])) > -1});

        return result;
    },

    onLoad(force) {
        if (this.state.isLoaded && !force) {
            return;
        }

        this.setState({
            isLoaded: true
        })
        
        console.log("start load warehouse...")

        return yajax.get('/bproducts/warehouse').then(
            Actions.load.completed, 
            Actions.load.failed);
    },

    onLoadCompleted(data) {
        console.log("completed load warehouses", data);

        this.setState({
            rows: (data.success) ? data.data : [],
            count: (data.success) ? data.data.length : 0,
            isLoaded: false
        })
    },

    onLoadFailed() {
        console.log("failed load warehouses");
        this.setState({
            isLoaded: false
        })
    },

});
	

export default WarehouseStore;