import React from 'react';
import T from "components/i18n";

import {connectToStores} from "tools/reflux-tools";
import {redirectTo} from 'tools/redirect-to'
import {filterClient} from 'tools/filters-helper'
import Money from "components/money";
import DOM from "react-dom-factories";
import {LocalIcon} from 'whitelables/wl-name/icons'

import {
  Control,
  Field,
  Input,
  Label,
  Radio,
  Textarea
} from 'react-bulma-components/lib/components/form';

import {
  Box,
  Button,
  Columns,
  Heading,
  Icon,
  List,
  Notification,
  Tabs
} from 'react-bulma-components';

import PreOrder from './preorder'
import SalesStore from './sales-store'
import Actions from './sales-actions'
import CreateNovaposhtaModal from './delivery/create-novaposhta'
import CreateUkrPoshtaModal from './delivery/create-ukrposhta'
import NotEnableNPModal from './delivery/not-enable-np'

// import AppStore from 'app-store'
import ClientsStore from '../clients/clients-store'
import ClientsActions from '../clients/clients-actions'

import ProductsStore from '../products/products-store'
import ProductsActions from '../products/products-actions';
import EditProductsModal from "../products/edit-products-modal";
// import TransactionsActions from 'modules/transactions/transactions-actions';
import WarehouseStore from '../warehouse/warehouse-store'
import ExpensesStore from '../expenses/expenses-store'
import ExpensesActions from '../expenses/expenses-actions'

import AddClientModal from '../clients/add-client-modal'
import OrdersActions from '../orders/orders-actions'

import SelectProducts from 'components/select-products';
import SelectComponent from 'tools/select-component';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Link} from "react-router-dom";

import User from 'user/user-store';
import UserStore from 'user/user-store';
import UserActions from '../../user/user-actions';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {isMobileOnly} from "react-device-detect";

import OpenModalFactory from 'components/modal/open-modal-factory';
import OpenInsideModal from "components/modal/open-inside-modal";

// import IconButton from 'components/modal/icon-button';
import AddDeliveryLink from 'modules/clients/delivery';
// import OrdersStore from 'modules/orders/orders-store';
import Messages from 'components/messages';
import BaseForm from "components/modules/base-form";

import SubmitButton from 'components/submit-button';
import trackEvent from 'tools/track-events';
import PrintElement from 'tools/print/elem';
import Tag from "../../components/tag";
import InfoWrapper from 'components/info-wrapper';
import CommissionBlock from "./common/commission-block/commission-block";
import CustomPriceDropdown from "./common/custom-price-dropdown";
import {errorHandler} from "../../tools/error-handler";
import TerminalStore from "../settings/terminal/terminal-store";
import AppStore from "../../app-store";
import PayOnTerminalModal
  from "../settings/terminal/modal/pay-on-terminal-modal";
import TerminalActions from "../settings/terminal/terminal-actions";
import {roundToNearest10Kopecks} from "../../tools/round-to-nearest-10-kopecks";
import Decimal from "decimal.js";
import InputWithCounter from "../../components/input-with-counter";
import PrepaymentFiscalization
  from "./modal/prepayment-fiscalization/prepayment-fiscalization";
import DpsCheckModal from "./modal/dps-check-modal/dps-check-modal";
import {extractNumber} from "../../tools/extract-number";
import AddStatusModal from "./add-status-modal";
import PrintActionButtons
  from "./common/print-action-buttons/print-action-buttons";
import FCheckButton from "./common/f-check-button/f-check-button";
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";
import {confirmAlert} from "react-confirm-alert";
import MassChangeTypePrice from "./common/mass-change-type-price";
import EditVariableModal
  from "../products/modal/add-product/edit-variable-modal";
import AddChannelModal from "../settings/add-channel-modal";
import ChangeWarehouseProductSaleModal
  from "./modal/change-warehouse-product-sale-modal/change-warehouse-product-sale-modal";

function list_to_options(value) {
  if (value.length === 1) {
    return {'value': value[0], 'label': T(SalesStore.getChannelsName(value[0]))}
  } else {
    let response = []
    for (let i in value) {
      let id = value[i];
      response.push({'value': id, 'label': T(SalesStore.getChannelsName(id))})
    }

    return response;
  }

}

const ACTIVE_TTN = [User.NP_ID, User.UP_ID, User.RZ_ID];

class SalesModal extends BaseForm {
    constructor(props) {
        super(props);
        let {product_data, type_sale} = this.preparingProductData();

        if (props.type_sale) {
            type_sale = props.type_sale;
        }


        let delivery_info = "", integration_id = null;
        if (props.delivery_info && typeof props.delivery_info === "string") {
           delivery_info = props.delivery_info.split(":")[1];
           integration_id = props.delivery_info.split(":")[0];
        } else if(props.delivery_info && typeof props.delivery_info === "number") {
          integration_id = props.delivery_info
        }

        let modification = true

        if(props.isEdit) {
          modification = props.modification
        }

        let terminal_trx = ''

        if(props.terminal_trx && props.terminal_trx?.length) {
          terminal_trx = props.terminal_trx[0]
        }


        this.state = {
          preorder: props.preorder || false,
          amount_sale: props.amount_sale || 0,
          product_data: product_data,
          save_product_data: JSON.parse(JSON.stringify(product_data)),
          comment: props.comment || "",
          amount: props.amount,
          prepaid_enable: props.prepaid_amount > 0 ? true : false,
          prepaid_amount: props.prepaid_amount || 0,
          account: props.account_id > 0 ? {'value': props.account_id} : null,
          account_prepaid: props.prapaid_account_id > 0 ? {'value': props.prapaid_account_id} : null,
          channel: props.channel_id ? list_to_options(props.channel_id) : null,
          client: props.client_id > 0 ? {'value': props.client_id} : false,
          tracking_number: props.tracking_number || "",
          type: type_sale,
          custom_status: {'value': props.order_status ||  'delivery'},
          discount: props.discount || 0,
          discount_type: {value:  (props.discount_type || "%"), label: (props.discount_type === "stable" ? T('fix') : "%")},
          date: props.purchase_date && !props.isCopy ? new Date(props.purchase_date * 1000) : new Date(),
          amount_from_buyer: "",
          amount_for_buyer: 0,
          products: [],
          client_data: null,
          terminal_trx,
          delivery_info: delivery_info,
          discount_enable: props.discount > 0,
          disable_button: false,
          hide_ttn: false,
          message: null,
          postoffice: null,
          integration_id: integration_id ? {'value': integration_id} : null,
          remote_order_id: props.remote_order_id,
          new_postoffice: null,
          step: 0,
          waiting: false,
          edit: props.oid ? props.oid : false,
          check_id: props.dps ? props.dps : 0,
          order_id: 0,
          showSeller: false,
          fields: UserStore.getAdditionalFieldsList('sale'),
          af: (typeof props.af === 'string' ? JSON.parse(props.af) : props.af) || {},
          user: {value: "", label: ""},
          modification: modification,
          fee: props.fee || '',
          default_fee: props.fee,
          delivery_load: [],
          transactionRRN: null,
          sum_discount: 0,
          globalTypePrice: '0',
        };

        this.onChange = this.onChange.bind(this);
        this.onChangeCount = this.onChangeCount.bind(this);
        this.onChangeHelper = this.onChangeHelper.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.onChangeSelectChannel = this.onChangeSelectChannel.bind(this);
        this.onChangeDiscount = this.onChangeDiscount.bind(this);
        this.onChangeDateSaled = this.onChangeDateSaled.bind(this);
        this.onChangeCostProduct = this.onChangeCostProduct.bind(this);
        this.onChangeSelectClient = this.onChangeSelectClient.bind(this);
        this.onChangeSelectOffice = this.onChangeSelectOffice.bind(this);
        this.onChangeSelectProduct = this.onChangeSelectProduct.bind(this);
        this.onChangeSelectDelivery = this.onChangeSelectDelivery.bind(this);
        this.submit = this.submit.bind(this);
        this.sendToDps = this.sendToDps.bind(this);
        this.renderMain = this.renderMain.bind(this);
        this.renderCheck = this.renderCheck.bind(this);
        this.renderFinish = this.renderFinish.bind(this);
        this.disableSubmit = this.disableSubmit.bind(this);
        this.onChangeTypePriceOrDiscount = this.onChangeTypePriceOrDiscount.bind(this);
        this.getTerminalButton = this.getTerminalButton.bind(this);
        this.openTerminalModal = this.openTerminalModal.bind(this);
        this.changeTransactionsNumber = this.changeTransactionsNumber.bind(this);
        this.calcDiscountProduct = this.calcDiscountProduct.bind(this);
        this.calcSumProductWithCount = this.calcSumProductWithCount.bind(this);
        this.reservedAlert = this.reservedAlert.bind(this);
        this.onChangeTypePrice = this.onChangeTypePrice.bind(this);
        this.onChangeMidByProduct = this.onChangeMidByProduct.bind(this);
        this.changeMid = this.changeMid.bind(this);
    }

  componentDidMount() {
        const userGroup = User.get("group");
        const subusers = User.getSubUsersList();
        if (userGroup === 2 && subusers.length > 1) {
          this.setState({showSeller: true})
        } else {
          this.setState({showSeller: false})
        }

        const rid = this.props.responsible_user_id;
        if (rid) {
          const userdata = subusers.find(s => s.id === parseInt(rid));
          if (userdata) {
            this.setState({ user: { value: rid, name: userdata.name } })
          }
        } else {
          const currentUser_id = User.get('realId');
          const userdata = subusers.find(user => user.id === parseInt(currentUser_id));
          if (userdata) {
            this.setState({ user: { value: currentUser_id, name: userdata.name } })
          }
        }

        const { select_product } = this.props;

        const dataModal = SalesStore.get("dataModal")
        if (select_product || 'select_product' in dataModal) {
            this.onChangeSelectProduct(select_product || dataModal);
        }

        this.listenSetToDps = Actions.setToDps.completed.listen((data) => {
            this.setState({"waiting": false, "check_id": data['id']});
        });

        this.listenLoadProducts = ProductsActions.load.completed.listen((data) => {
            setTimeout(() => {
                let {product_data, type_sale} = this.preparingProductData(this.state.product_data);
                if (type_sale) {

                }
                this.updateData(product_data);
            }, 100);
        });

        this.listenLoadProductsByPid = ProductsActions.loadByPid.completed.listen(() => {
            setTimeout(() => {
              let {product_data, type_sale} = this.preparingProductData(this.state.product_data);
              if (type_sale) {

              }
              this.updateData(product_data);
            }, 100);
        })

        this.loadDeliveryClient = ClientsActions.loadDelivery.completed.listen(() => {
          const getClientDelivery = ClientsStore.getClientDeliveryById(this.state.client.value, this.props.clients_delivery_id)
          if(!!Object.values(getClientDelivery).length) {
            this.setState({'postoffice': {'value': this.props.clients_delivery_id}, 'addPostOffice': false})
          }
        })


        // this.listenSetToDpsFail = Actions.setToDps.failed.listen((data) => {
        //     const d = JSON.parse(data['response']);
        //     this.setState({
        //       'message':  T(d['error'])
        //     });
        // });

        this.listenAdd = Actions.add.completed.listen((data) => {
            this.setState({
              "waiting": false,
              "step": 1,
              "check_id": data['check_id'],
              "typeCheck": data['type'],
              "order_id": data['oid']
            });
         const productDataDict = this.state.product_data
         const productDataPids = Object.keys(productDataDict).map(item => item.split('_')[0])

          if(productDataPids.length >= 100) {
            ProductsActions.load(true)
          } else {
            ProductsActions.loadByPid(productDataPids)
          }

            if (this.state.preorder) {
              setTimeout(OrdersActions.load, 1000);
            }
        });

        this.listenAddFaild = Actions.add.failed.listen((res) => {
            const message = errorHandler(res)

            this.setState({
              'waiting': false,
              'message': {
                'msg': message
              }
            });
        });

        this.listenAddDelivery = ClientsActions.addDeliveryService.completed.listen((data) => {
          if (data['success']) {
            setTimeout(function(){
                this.setState({
                  "addPostOffice": false,
                  "postoffice": {'value': data['cdid'], 'label': data['dname']}
                });
            }.bind(this), 500);
          }
        });

        if(this.state.terminal_trx) {
          TerminalActions.loadReceiptById(this.state.terminal_trx)
        }

      this.loadReciept = TerminalActions.loadReceiptById.completed.listen((data) => {
        const result = data.data.find(item => item.id === this.state.terminal_trx)

        if(result) {
          this.setState({
            transactionRRN: result?.rrn
          })
        }
      });

        this.addNewClient = ClientsActions.add.completed.listen((data) => {
          if(data.id) {
            this.loadClient = ClientsActions.load.completed.listen(() => {

              this.setState({
                client: {value: data.id}
              })
            });
          }
        })

        ExpensesStore.getDebtAccounts();

        setTimeout(() => {
          const state = this.state
          const {postoffice, ...rest} = state
          this.setState({save_state: JSON.stringify(rest)})
        }, 1000)
    }

    componentWillUnmount() {
      if (typeof(this.listenAdd) == "function") {
          this.listenAdd();
      }
      if (typeof(this.listenLoadProducts) == "function") {
          this.listenLoadProducts();
      }
      if (typeof(this.listenAddFaild) == "function") {
          this.listenAddFaild();
      }
      if (typeof(this.listenAddDelivery) == "function") {
          this.listenAddDelivery();
      }
      if (typeof(this.listenSetToDps) == "function") {
          this.listenSetToDps();
      }
      if (typeof(this.addNewClient) == "function") {
          this.addNewClient();
      }
      if (typeof(this.loadClient) == "function") {
        this.loadClient();
      }
      if (typeof(this.loadReciept) == "function") {
          this.loadReciept();
      }
      if (typeof(this.listenLoadProductsByPid) == "function") {
          this.listenLoadProductsByPid();
      }
}

componentDidUpdate(prevProps, prevState, snapshot) {
      if(this.state.save_state) {
        const state = this.state
        const {save_state,postoffice,...rest} = state
        const stateNow = JSON.stringify(rest)
        if(this.props?.onChangeSale) {
          this.props.onChangeSale(stateNow !== this.state.save_state && this.state.step !== 1)
        }

      }
}

  submit() {
        const state = this.state;
                    // products: state.products.map(x => x['value']),

        if ((state.amount_sale - state.prepaid_amount) < 0) {
            this.setState({message: {'msg': T('bad-prapaid-amount')}});
            return;
        }

        if (!state.channel) {
          this.setState({message: {'msg': T('not all fields required')}});
            return;
        }
        if(!state.account?.value) {
          this.setState({message: {'msg': T('error-account')}});
          return;
        }
        if(state.prepaid_amount && !state.account_prepaid?.value) {
          this.setState({message: {'msg': T('choose-account')}});
          return
        }

        // if (state.type !== "reserve_sale") {
        //     for (let sp in state.product_data) {
        //       let d = state.product_data[sp];
        //       if (d['instock'] <= 0 && !WarehouseStore.isDropshipping(d['mid']) && !WarehouseStore.isNegativeStock(d['mid'])) {
        //           this.setState({
        //             "message": {'msg': T('not-have-stock')}
        //           });
        //           return;
        //       }
        //     }
        // }

        for (let __pid in state.product_data) {
          if (state.product_data[__pid]['product_deleted'] === 1) {
              this.setState({message: {'msg': T('remove-deleted-product')}});
              return;
          }
          if(UserStore.getCheckboxEnable() && !parseFloat(state.product_data[__pid]['finish_price'])) {
            this.setState({message: {'msg': T('error-price-product')}});
            return;
          }
        }

        let delivery_info = "";
        if (this.state.integration_id && this.state.integration_id.value) {
            delivery_info = this.state.integration_id.value;

            if (state.delivery_info && state.delivery_info !== "") {
                delivery_info += ": "+state.delivery_info;
            }
        }
        let data = {
            amount_sale: state.amount_sale,
            date: state.date,
            products: state.product_data,
            comment: state.comment,
            delivery_info: delivery_info,
            channel_id: state.channel,
            client_id: (state.client && state.client.value) ? state.client.value : false,
            amount: state.amount,
            prepaid_amount: state.prepaid_amount,
            account_id: state.account?.value,
            order_status: state.type,
            custom_status: state.custom_status ? state.custom_status.value : false,
            discount: state.discount,
            discount_type: state.discount_type.value,
            af: JSON.stringify(state.af),
        }


        // if (state.type !== "reserve_sale") {
        //     data['account_id'] = state.account.value
        // } else {
        //    data['account_prepaid'] = state.account.value
        // }

        if(this.props.isRefund) {
          data.recovery_refund = true
        }

        if (state.account_prepaid && state.prepaid_amount) {
            data['account_prepaid'] =  state.account_prepaid.value;
        }

        if (this.state.edit && !this.props.isCopy) {
          data['oid'] = this.state.edit;
        }


        const commission = this.getActiveCommission()

        if(this.state.fee) {
          data['fee'] = this.state.fee
        } else if (!this.state.fee && commission.fee > 0) {
          data['fee'] = ((this.state.amount_sale * commission.fee) / 100).toFixed(2)
        }

        if(this.state.terminal_trx) {
          data['terminal_trx'] = JSON.stringify([this.state.terminal_trx])
        }

        if (state.postoffice) {
            data['postoffice'] = state.postoffice.value;
        }


        if (state.tracking_number) {
            if (state.tracking_number.length !== 14 && state.tracking_number.length !== 13 && this.state.integration_id?.value !== User.RZ_ID) {
              this.setState({message: {'msg': T('not-correct-tracking-number')}});
              return;
            }

            data['tracking_number'] =  state.tracking_number;
        }

        if (this.state.preorder) {
            data['preorder'] = this.state.preorder['integration_id']+"||"+ this.state.preorder['order_id'];
        }

        if (this.state.remote_order_id && this.props.isCopy) {
            data['remote_order_id'] = this.state.remote_order_id;
        }

      if (state.user && state.showSeller) {
          data['rid'] = state.user.value;
        }

      if(this.state.preorder && this.props.client_id) {
        if(this.props.client_id !== data.client_id) {
          confirmAlert({
            title: T('customer-not-match'),
            buttons: [
              {
                label: T('confirm-order-shift'),
                onClick: () => {
                  this.setState({"waiting": true});
                  trackEvent('users', 'working', 'add-sales');
                  Actions.add(data);
                }
              },
              {
                label: T('cancel-label'),
                onClick: () => {}
              }
            ]
          });
          return;
        }
      }

      if (this.state.check_id && !this.props.isCopy && !this.props.isRefund) {
        confirmAlert({
          title: T( 'check-fiscalized-check-return'),
          buttons: [
            {
              label: T( 'to-return'),
              onClick: () => {
                this.setState({"waiting": true});
                trackEvent('users', 'working', 'add-sales');
                Actions.add(data);
              }
            },
            {
              label: T( 'no-refunds'),
              onClick: () => {
                data.cancel_fiscal = true
                this.setState({"waiting": true});
                trackEvent('users', 'working', 'add-sales');
                Actions.add(data);
              }
            },
            {
              label: T('close'),
              onClick: () => {}
            }
          ]
        });
      } else {
        this.setState({"waiting": true});
        trackEvent('users', 'working', 'add-sales');
        Actions.add(data);
      }
    }

    disableSubmit(status) {
      // console.log("disable_button: ", status)
      this.setState({'disable_button': status})
    }

  onChangeAdditional(evt) {
    const value = evt.target.value,
          name = evt.target.name;

    let field = this.state.af;
    field[name] = value;

    this.setState({
      af: field,
      message: null
    });
  }

  updateFields(fields) {
    this.setState({
      fields: fields
    })
  }

  getAdditionalList() {
    let response = [];

    for (let name of this.state.fields) {
        response.push(
          <Field key={'ad-f-' + name}>
            <Control>
              <Label>{name}</Label>
              <Input size="large"
                     name={name}
                     type="text"
                     autoComplete="nope"
                     onChange={(e) => this.onChangeAdditional(e)}
                     value={this.state.af[name]}
              />
            </Control>
          </Field>
        )
      }

      return response;
    }


    sortingProducts(pr_data) {
          this.product_data_list = [];

          for (let pid_ident in pr_data) {
              this.product_data_list.unshift(pid_ident);
          }

          this.product_data_list = [...new Set(this.product_data_list)];

    }

    preparingProductData(pr_data) {
        const props = this.props;
        let product_data = {}, type_sale = 'main_sale';
        if (pr_data) {
          for (let p in pr_data) {
            let product = pr_data[p];
            if (!product) {
              continue
            }
            let d = ProductsStore.getProductByMid(product.pid, product.mid),
              k = JSON.parse(JSON.stringify(d));
            k['count'] = product['count'] || 0;
            if ( product['finish_price']) {
              k['price'] = product['finish_price'];
            }

            product_data[product.ident] = this.prepareProductData(product.pid, product.mid, k);
          }
        } else if (props.oid) {
          for (let i in props.items) {
            let item = props.items[i],
                pid = item['product_id'],
                mid = item['mid'],
                pid_ident = ProductsStore.getIdent(pid, mid),
                data = ProductsStore.getProductByMid(pid, mid) || item,
                price = item?.discount ? item['price'] : item['base_price'];

            // let price = this.calcDiscount(item['price'], props.discount, "%", "+");

            // if (props.discount_type === "stable" &&  props.discount > 0) {
            //   price = data['price'];
            // }


            data['count'] = item['quantity'];
            product_data[pid_ident] = this.prepareProductData(pid, mid, data, price, item['discount'], item['type_price']);
          }
          if (props.order_status === "reserve_sale") {
            type_sale = props.order_status;
          } else if (props.order_status === "saled") {
            type_sale = 'main_sale';
          } else {
            type_sale = 'delivery'
          }
        } else if (props.preorder) {
          const integrator = User.getIntegrationNameById(props.preorder['integration_id']);
          let product_info = props.preorder['order_data'];
          for (let i in product_info) {
            let d = product_info[i],
              pid = d['local_product_id'],
              is_reserved = d['is_reserved'],
              mid = integrator['ident']['mid'],
              pid_ident = ProductsStore.getIdent(pid, mid);
              const data = ProductsStore.getProductByMid(pid, mid);
            if (data) {
                data['count'] = d['quantity'];

                const price = props?.preorder?.discount && d?.price ? null : extractNumber(d?.price)

                product_data[pid_ident] = this.prepareProductData(pid, mid, data, price);
                product_data[pid_ident]['is_reserved'] = is_reserved;
                if (is_reserved) {
                    product_data[pid_ident]['instock'] += d['quantity'];
                }
            }
          }

          type_sale = 'delivery'
        }

        this.sortingProducts(product_data)

        return {product_data, type_sale};
    }

    getCurrency() {
      const aid = this.state.account && this.state.account.value ? this.state.account.value : false;
      return ExpensesStore.getAccountCurrency(aid);
    }

    sendToDps(isWithPrepaid) {
      SalesStore.closeModal()

      if(isWithPrepaid) {
          AppStore.openModal(<PrepaymentFiscalization oid={this.state.order_id} amount={this.state.prepaid_amount} accountId={this.state.account_prepaid?.value}/>)
      } else {
          AppStore.openModal(<DpsCheckModal oid={this.state.order_id} />)
      }
    }

    calcSaleAmount(products) {
      let amount = 0;
      for (let i in products) {
        const prod = products[i];
        amount += parseFloat(this.getItemPriceMultCount(prod['finish_price'], prod['count']))
      }

      return amount
    }

    calcDiscountProduct() {
      const productData = this.state.product_data

      const valuesProductData = Object.values(productData)

      return valuesProductData.reduce((acc, cur) => {
          if (cur.discount) {
            acc += (cur.default_price - cur.finish_price) * cur.count
          }

          return acc
        }, 0)
    }

  calcDiscount(amount, discount, _type, operator) {
    if (!discount || discount === "") discount = 0;

      this.setState({
        sum_discount: 0
      })

      if (discount === 0) {
        return amount;
      }

      let types = _type || this.state.discount_type.value;

      const calcDiscount = (amountData, discountData, operatorData, typesData) => {
        if (operatorData === "+") {
          if (typesData === "%") {
            return parseFloat(amountData/(100-discountData)*100).toFixed(2)
          } else {
            return  parseFloat(amountData + discountData).toFixed(2);
          }
        } else {
          if (typesData === "%") {
            return parseFloat(amountData-((amountData/100)*discountData)).toFixed(2);
          } else {
            return parseFloat(amountData - discountData).toFixed(2);
          }
        }
      }

      let result = calcDiscount(amount, discount, operator, types);

      const product_data = this.state.product_data

      let amount_without_product_with_discount = amount

      const calcSumDiscountProduct = Object.keys(product_data || {}).reduce((acc, cur) => {
        if(product_data[cur]) {
          if(product_data[cur].discount) {
            const amountProduct = this.getItemPriceMultCount(product_data[cur].finish_price, product_data[cur].count)

            acc += ((amount - result) * amountProduct) / amount
            amount_without_product_with_discount -= amountProduct
          }
        }

        return acc
      },0)

      if(!!calcSumDiscountProduct) {
        result = calcDiscount(amount_without_product_with_discount, discount, operator, types)
        this.setState({
          sum_discount: amount_without_product_with_discount - result
        })
        return amount - (amount_without_product_with_discount - result)
      } else {
        this.setState({
          sum_discount: amount - result
        })
        return result
      }
    }

    onChangeDiscount(evt) {
      const val = evt.target.value;

      let amountSale = this.calcDiscount(this.state.amount, val)

      const getAccountNow = ExpensesStore.getAccountById(this.state.account?.value)

      if(getAccountNow.rounded) {
        amountSale = roundToNearest10Kopecks(amountSale, this.state.account?.value)
      }

      this.setState({
        "discount": this.checkVal(val),
        'amount_sale': amountSale
      });

    }

    onChangeSelectProduct(selectedOption, need_delete, isEdit) {
      let product_data = this.state.product_data;
      if (selectedOption && 'value' in selectedOption) {
        selectedOption = {'select_product': selectedOption}
      }

      for (let p in selectedOption) {
          const pid_ident = selectedOption[p]['value'],
              _ident = pid_ident.split("_"),
              pid = _ident[0],
              mid = _ident[1],
              data = ProductsStore.getProductByMid(pid, mid);
          if (data) {
            if (need_delete) {
                delete product_data[pid_ident];
            } else {
                if (typeof(product_data[pid_ident]) !== "undefined" && !isEdit) {
                  product_data[pid_ident]['count'] += 1;
                } else {
                  if(this.state.globalTypePrice && this.state.globalTypePrice !== "0") {
                    const priceWithCustomPrice = ProductsStore.getProductPriceByCustomPriceNameAndPid(this.state.globalTypePrice, pid)
                    if(priceWithCustomPrice)  {
                      product_data[pid_ident] = this.prepareProductData(pid, mid, data, priceWithCustomPrice, false, this.state.globalTypePrice)
                    } else {
                      product_data[pid_ident] = this.prepareProductData(pid, mid, data, false)
                    }
                  } else {
                    product_data[pid_ident] = this.prepareProductData(pid, mid, data, false)
                  }
                }
            }
          }
      }

      this.sortingProducts(product_data)

      this.updateData(product_data);
    }

    prepareProductData(pid, mid, data, pr, discount, type_price) {
      let sale_price = data['sale_price'],
          price = (sale_price) ? sale_price : data['price'] || 0;

      if (pr) {
        price = pr;
      }

      let count = data.count || 1;

      let defaultPrice = price

      if(discount !== 100) {
        defaultPrice = +price + ((+price * (discount || 0)) / (100 - (discount || 0)))

        if(discount < 0) {
          defaultPrice = +price - discount
        }
      } else if(discount === 100) {
        price = 0
      }

      const getProduct = ProductsStore.getProductByMid(pid, mid);

      const result = {
        'count': count,
        'mid': mid,
        'name': data['product_deleted'] === 1 ? T('product_deleted') : data['name'],
        'product_deleted': data['product_deleted'],
        'size': data['size'],
        'pid': pid,
        'parent_id': data['parent_id'],
        'instock': data['instock'] || 0,
        'units': data['units'],
        'ident': ProductsStore.getIdent(pid, mid),
        'finish_price': price,
        'finish_amount': this.getItemPriceMultCount(price, count),
        'discount': discount || 0,
        "type_price": type_price || '',
        'default_price': defaultPrice,
      }

      if(getProduct.ukzt) {
        result['ukzt'] = getProduct['ukzt']
      }

      return result
    }

  onChangeSelect(selectedOption, action) {
        this.setState({
          [action.name]: selectedOption
        });

        setTimeout(() => {
          this.updateData(this.state.product_data);
        },100);
    }

    onChangeSelectChannel(selectedOption, action) {
        if (Array.isArray(selectedOption) && selectedOption.length > 1 && selectedOption[0]['value'] === 1){
          selectedOption.splice(0, 1);
        }

        if(Array.isArray(selectedOption)) {
            const getCommissions = selectedOption.map(item => SalesStore.getChannelCommission(item.value))

            const positiveCommissions = getCommissions.filter(info => info.fee > 0);

            if (positiveCommissions.length > 1) {
              this.setState({
                'message': {'msg': T('error-sale-add-channels-commission')}
              })
              return;
            }
        }

        this.setState({
          [action.name]: selectedOption,
          fee: 0
        });

        setTimeout(() => {
          this.updateData(this.state.product_data);
        },100);
    }

    onChangeSelectDelivery(selectedOption, action) {
        this.setState({
          [action.name]: selectedOption,
          'postoffice': null,
          'hide_ttn': ACTIVE_TTN.indexOf(selectedOption.value) === -1 ? true : false,
        });

        // setTimeout(() => {
        //   this.updateData(this.state.product_data);
        // },100);
    }

    onChangeSelectClient(selectedOption, action) {

        const client = ClientsStore.getClientById(selectedOption.value),
              discount = client['discount'],
              current_discount = this.state.discount || 0,
              new_discount = current_discount === 0 ? discount : current_discount;

        let delivery_load = this.state.delivery_load;

        if (delivery_load.indexOf(selectedOption.value) === -1) {
          ClientsActions.loadDelivery(null, selectedOption.value);
          delivery_load.push(selectedOption.value)
        }

        this.setState({
          [action.name]: selectedOption,
          "discount": new_discount,
          "delivery_load": delivery_load,
          'discount_enable': new_discount > 0,
          "client_data": client,
          "postoffice": null,
          'amount_sale': this.calcDiscount(this.state.amount, new_discount)
      })
    }

    onChangeSelectOffice(selectedOption, action) {
        if (action.action === "select-option") {
          if (selectedOption.value === "_create" && (this.state.client && this.state.client.value !== 0)) {
            this.setState({
              "addPostOffice": true
            });
          } else {
            this.setState({
              [action.name]: selectedOption
            });
          }
        }
    }

    onChange(evt) {
        const value = evt.target.value,
            name = evt.target.name;

        this.setState({
            [name]: value
        });
    }

    onChangeDateSaled(date) {
        this.setState({
            "date": date
        });
    }

    onChangeHelper(evt) {
        const value = evt.target.value,
            name = evt.target.name,
            change = value - this.state.amount_sale;

        this.setState({
            [name]: value,
            'amount_for_buyer': change
        });
    }

    updateData(product_data) {
       let amount = this.calcSaleAmount(product_data),
           amount_sale = this.calcDiscount(amount, this.state.discount);

      const getAccountNow = ExpensesStore.getAccountById(this.state.account?.value)

      if(getAccountNow.rounded) {
        amount_sale = roundToNearest10Kopecks(amount_sale, this.state.account?.value)
      }

        this.setState({
            'product_data': product_data,
            'amount': amount,
            // 'message': null,
            'amount_sale': amount_sale
        });

        this.sortingProducts(product_data);
    }

    onChangeCostProduct(evt) {
        let value = evt.target.value,
            pid = evt.target.name.split("-")[1];

        let product_data = this.state.product_data;
        let val = this.checkVal(value);
        product_data[pid]['type_price'] = ''
        product_data[pid]['discount'] = 0
        product_data[pid]['default_price'] = 0
        product_data[pid]['finish_price'] = val;

        this.updateData(product_data);
    }

    onChangeCount(evt) {
        const value = evt.target.value,
            pid = evt.target.name.split("-")[2];

        let product_data = this.state.product_data;
        let val = this.checkVal(value);

        product_data[pid]['count'] = val;

        this.updateData(product_data);
    }

    onChangeTypePriceOrDiscount({type, pid, value}) {
      let product_data = this.state.product_data;

      if(type === 'discount') {
        product_data[pid]['discount'] = value
        const defaultPrice = product_data[pid]['default_price']
        const finishPrice = product_data[pid]['finish_price']
        const calcDiscount = (amount) => {
          if(value >= 0) {
            const discount_amount =  (+amount * value) / 100
            return (+amount - discount_amount).toFixed(2)
          }

          return +amount + value
        }

        if(value) {
          product_data[pid]['default_price'] = finishPrice
          product_data[pid]['finish_price'] = calcDiscount(product_data[pid]['finish_price'])
        } else {
          if(defaultPrice) {
            product_data[pid]['finish_price'] = calcDiscount(defaultPrice)
          }
        }
      }

      if(type === 'custom-price') {
        product_data[pid]['type_price'] = value
      }

      this.updateData(product_data);
    }

    calcSumProductWithCount({finishPrice, count, discount, defaultPrice}) {
      return (
        <div style={{position: 'relative'}} className='display-flex-column'>
          {!!discount && <span style={{textDecoration: 'line-through', opacity: 0.7}}>
            <Money
            amount={this.getItemPriceMultCount(defaultPrice, count)}/>
            </span>
          }
          <Money
            amount={this.getItemPriceMultCount(finishPrice, count)}
            wrapper={DOM.span}
          />
        </div>)
    }

    mobileProductLine(prod) {
        let name = prod.name || "";
        const pid = prod.ident;

        if (name?.length > 60) {
           name = name.substring(0,60)+"...";
        }

        const getProduct = ProductsStore.getProductByMid(prod.pid, prod.mid)

        if(getProduct && getProduct?.sku) {
          name += ` ${getProduct.sku}. `
        }

        let warehouseDrop = null
        if (!WarehouseStore.isDropshipping(prod['mid']) && getProduct.type_product !== 4) {
          warehouseDrop = " ("+T('available-small')+" "+ProductsStore.getStockUnits(prod['instock'], this.state.product_data[pid]['units'])+")";
        }

        let warehouse = null
        if (WarehouseStore.getWarehouseList().length > 1) {

          const changeMid = this.changeMid(prod)
          warehouse = (<span onClick={(evt) => this.onChangeMidByProduct(evt, prod, changeMid)} className={changeMid.isChange ? 'underline' : ''}>{T(WarehouseStore.getWarehouseName(prod['mid']))}</span>)
        }

        name = <span>{name} {warehouse} {warehouseDrop}</span>

        if (this.state.product_data[pid]['product_deleted'] === 1) {
          name = (<span className='has-text-danger'>{name}</span>);
        }

        let isNotDeleted = true

        if (getProduct && !getProduct.stock[prod.mid] && this.state.product_data[pid]['product_deleted'] !== 1 ) {
          isNotDeleted = false
          name = (<span className='has-text-danger'>{T('product_deleted')}{name}</span>);
        }

        if(!getProduct && this.state.product_data[pid]['product_deleted'] !== 1) {
          isNotDeleted = false
          name = (<span className='has-text-danger'>{T('product_deleted')}{name}</span>);
        }

        return (
          <List.Item key={pid}>
              <div style={{fontSize: '1.4em'}} className='display-flex-row-gap'>
                <OpenInsideModal size="small" text={name} renderAs="b">
                  {prod.parent_id ? (
                    <EditVariableModal pid={prod.pid} parent_id={prod.parent_id}/>
                  ) : (
                    <EditProductsModal pid={prod.parent_id || prod.pid} step={0}/>
                  )}
                 </OpenInsideModal>
                  {this.state.product_data[pid]['count'] > 1 && this.calcSumProductWithCount(
                    {
                      finishPrice: this.state.product_data[pid]['finish_price'],
                      count: this.state.product_data[pid]['count'],
                      discount: this.state.product_data[pid]['discount'],
                      defaultPrice: this.state.product_data[pid]['default_price']
                    })}
              </div>
              <Columns className="is-mobile">
                <Columns.Column style={{minWidth: '160px'}}>
                  <InputWithCounter
                    onChange={this.onChangeCount}
                    value={this.state.product_data[pid]['count']}
                    disabled={!this.state.modification || !isNotDeleted}
                    size="medium"
                    key={"count-pid-"+pid}
                    name={"count-pid-"+pid}
                    icon={
                      ProductsStore.getUnits(this.state.product_data[pid]['units'])}
                  />
                </Columns.Column>
                <Columns.Column style={{display: 'flex', gap: '5px', alignItems: 'center', minWidth: '160px'}}>
                  <Control iconRight style={{flex: 1}} className='input-with-icon-right'>
                    <Input  size="medium"
                        key={"pid-"+pid}
                        name={"pid-"+pid}
                        type="number"
                        readOnly={User.getPermissionEnable("editproducts") ? false : true}
                        min="0.01"
                        onChange={this.onChangeCostProduct}
                        value={this.state.product_data[pid]['finish_price']}
                        disabled={!this.state.modification || !isNotDeleted}
                        />
                      <Icon align="right">
                        <span>{this.getCurrency()}</span>
                      </Icon>
                    </Control>
                  {isNotDeleted && <CustomPriceDropdown
                    pid_mid={pid}
                    discount={this.state.product_data[pid]['discount']}
                    typePrice={this.state.product_data[pid]['type_price']}
                    onChangeCost={this.onChangeCostProduct}
                    onChangeTypeDiscount={this.onChangeTypePriceOrDiscount}
                  />}
                </Columns.Column>
                {/*<Columns.Column size={1} style={{flex: 0, paddingLeft: '5px', minWidth: '65px',display: 'flex', alignItems: 'center'}}  align='left'>*/}
                {/*  {this.calcSumProductWithCount(*/}
                {/*    {*/}
                {/*      finishPrice: this.state.product_data[pid]['finish_price'],*/}
                {/*      count: this.state.product_data[pid]['count'],*/}
                {/*      discount: this.state.product_data[pid]['discount'],*/}
                {/*      defaultPrice: this.state.product_data[pid]['default_price']*/}
                {/*    })}*/}
                {/*</Columns.Column>*/}
                {this.state.modification && <Columns.Column className="delete-on-sale">
                  <Link to="#" onClick={() => this.deleteItem(pid)}
                         title={T('delete')}>
                    <FontAwesomeIcon icon="trash-alt"
                                     size={this.props.isMobileSize ? "1x" : "1x"}/>
                  </Link>
                    </Columns.Column>}
              </Columns>
          </List.Item>
        )
    }

    onChangeMidByProduct(evt, product, changeMid) {
      evt.stopPropagation()

      const handleChangeMid = ({pid, mid, oldMid}) => {
        const productState = {...this.state.product_data}

        const oldIdent = `${pid}_${oldMid}`
        const newIdent = `${pid}_${mid}`

        const getProduct = ProductsStore.getProductByMid(pid, mid)

        if(productState[oldIdent]) {
          const newStateProduct = {...productState[oldIdent]}

          newStateProduct.ident = newIdent
          newStateProduct.mid = mid
          newStateProduct.type_price = ""
          newStateProduct.discount = 0
          newStateProduct.default_price = getProduct.sale_price || getProduct.price
          newStateProduct.finish_price = getProduct.sale_price || getProduct.price
          newStateProduct.instock = getProduct.instock

          productState[newIdent] = newStateProduct
          delete productState[oldIdent]
        }

        this.updateData(productState)

      }
      if(changeMid.isChange) {
        AppStore.openModal(<ChangeWarehouseProductSaleModal modal='change-warehouse-product' mids={changeMid.mids} product={product} onChange={handleChangeMid}/>)
      }
    }

  changeMid(product) {
      const productState = this.state.product_data

      const getProduct = ProductsStore.getProduct(product.pid)
      if(getProduct) {
        const isManyWarehouse = Object.values(getProduct.stock)

        const isChange = isManyWarehouse.filter(item => !productState[`${product.pid}_${item.mid}`])

        isChange.map(item => item.mid)

        return {isChange: !!isChange.length, mids: isChange}
      } else {
        return {}
      }

    }

    productLine(prod) {
        let name = prod.name || "";
        const pid = prod.ident;

        if (name.length > 75) {
           name = name.substring(0,75)+"...";
        } else {
           name += ". ";
        }
        const getProduct = ProductsStore.getProductByMid(prod.pid, prod.mid)

        if(getProduct && getProduct?.sku) {
          name += ` ${getProduct.sku}. `
        }

        let warehouseDrop = null
        if (!WarehouseStore.isDropshipping(prod['mid']) && getProduct.type_product !== 4) {
          warehouseDrop = " ("+T('available-small')+" "+ProductsStore.getStockUnits(prod['instock'], this.state.product_data[pid]['units'])+")";
        }

        let warehouse = null
        if (WarehouseStore.getWarehouseList().length > 1) {

          const changeMid = this.changeMid(prod)
          warehouse = (<span onClick={(evt) => this.onChangeMidByProduct(evt, prod, changeMid)} className={changeMid.isChange ? 'underline' : ''}>{T(WarehouseStore.getWarehouseName(prod['mid']))}</span>)
        }

        name = <span>{name} {warehouse} {warehouseDrop}</span>


        if (this.state.product_data[pid]['product_deleted'] === 1) {
          name = (<span className='has-text-danger'>{name}</span>);
        }

        let isNotDeleted = true

        if (getProduct && !getProduct.stock[prod.mid] && this.state.product_data[pid]['product_deleted'] !== 1 ) {
          isNotDeleted = false
          name = (<span className='has-text-danger'>{T('product_deleted')}{" "}{name}</span>);
        }


        if(!getProduct && this.state.product_data[pid]['product_deleted'] !== 1) {
          isNotDeleted = false
          name = (<span className='has-text-danger'>{T('product_deleted')}{name}</span>);
        }


        let reserved = null;
        if (typeof(prod['is_reserved']) !== "undefined") {
          reserved = (<span className="has-text-success"><br /><b>{T('status-reserve_sale')}</b></span>);
        }


        return (
          <List.Item key={pid} >
              <Columns vCentered>
                <Columns.Column size={6} style={{flex: 1}}>
                  {getProduct ? <OpenInsideModal size="small" text={name} renderAs="b">
										{prod.parent_id ? (
											<EditVariableModal pid={prod.pid} parent_id={prod.parent_id}/>
										) : (
											<EditProductsModal pid={prod.parent_id || prod.pid} step={0}/>
										)}
                  </OpenInsideModal> : <b>{name}</b>}
                    {reserved}
                </Columns.Column>
                <Columns.Column size={2}>
                  <InputWithCounter
                    onChange={this.onChangeCount}
                    value={this.state.product_data[pid]['count']}
                    disabled={!this.state.modification || !isNotDeleted}
                    size="small"
                    key={"count-pid-" + pid}
                    name={"count-pid-" + pid}
                    icon={
                      ProductsStore.getUnits(this.state.product_data[pid]['units'])}
                  />
                </Columns.Column>
                {UserStore.getPermissionEnable('salesprice') && <Columns.Column size={2} style={{
                  display: 'flex',
                  gap: '5px',
                  alignItems: 'center',
                  paddingRight: 0
                }}>
                  <Control iconRight style={{flex: 1}}>
                    <Input size="small"
                           key={"pid-" + pid}
                           name={"pid-" + pid}
                           readOnly={User.getPermissionEnable("editproducts") ? false : true}
                           type="text"
                           onChange={this.onChangeCostProduct}
                           value={this.state.product_data[pid]['finish_price']}
                           disabled={!this.state.modification || !isNotDeleted}
                    />
                    <Icon align="right">
                      <span>{this.getCurrency()}</span>
                    </Icon>
                  </Control>
                  {isNotDeleted && <CustomPriceDropdown
                    pid_mid={pid}
                    discount={this.state.product_data[pid]['discount']}
                    typePrice={this.state.product_data[pid]['type_price']}
                    onChangeCost={this.onChangeCostProduct}
                    onChangeTypeDiscount={this.onChangeTypePriceOrDiscount}
                  />}
                </Columns.Column>}
                {UserStore.getPermissionEnable('salesprice') && <Columns.Column size={1} style={{
                  flex: 0,
                  paddingLeft: '5px',
                  minWidth: '65px'
                }} align='left'>
                  {this.calcSumProductWithCount(
                    {
                      finishPrice: this.state.product_data[pid]['finish_price'],
                      count: this.state.product_data[pid]['count'],
                      discount: this.state.product_data[pid]['discount'],
                      defaultPrice: this.state.product_data[pid]['default_price']
                    })}
                </Columns.Column>}
                {this.state.modification && <Columns.Column style={{width: 'fit-content', flex: 0, minWidth: '33px'}} size={1} className="delete-on-sale">
                  <Link to="#" onClick={() => this.deleteItem(pid)}
                         title={T('delete')}>
                    <FontAwesomeIcon icon="trash-alt" size="1x"/>
                  </Link>
                </Columns.Column>}
              </Columns>
          </List.Item>
        )
    }

    getItemPriceMultCount(amount, count) {
      const decimalAmount = new Decimal(amount || 0)
      const decimalCount = new Decimal(count || 1)
      return decimalAmount.mul(decimalCount).toFixed(2)
      // return Math.round(parseFloat(amount*count) * 10) / 10;
    }

    deleteItem(pid) {
      let product_data = this.state.product_data;

      if (typeof(product_data[pid]) !== "undefined") {
        delete product_data[pid];
        this.updateData(product_data);
      }
    }


    getSalesList() {
      const sp = this.state.product_data;

      let sales_list = [];
      for (let p in this.product_data_list) {
          let product = sp[this.product_data_list[p]];
          if (product) {
            sales_list.push(this.props.isMobileSize ? this.mobileProductLine(product) : this.productLine(product));
          }
      }

      return (
          <List hoverable className="sales-order-list" style={{marginBottom: 5}}>
              {sales_list}
          </List>

        );
    }

    renderHelperForChange(type) {
      let content;

      if (type === "data") {
         content = (<div className="middle-text">{T('change-for-client')}: {this.state.amount_for_buyer} {this.getCurrency()}</div>);
      } else if (type === "form") {
        content = (
            <Field>
                <Control iconRight>
                  <Input  size="medium"
                      name="amount_from_buyer"
                      type="number"
                      min="0.01"
                      placeholder={T('amount-from-buyer')}
                      onChange={this.onChangeHelper}
                      value={this.state.amount_from_buyer}
                      />
                  <Icon align="right">
                    <span>{this.getCurrency()}</span>
                  </Icon>
              </Control>
            </Field>
          );
      }
      return content;
    }

    getLocalAccountList(not_system) {
      if (not_system) {
        return ExpensesStore.getAccountsList([1,4], User.get("currency_id"), true);
      }

      return ExpensesStore.getAccountsList(undefined, User.get("currency_id"));
    }

    renderPrepaid() {
      if (!this.state.prepaid_enable) return null;
      const commissionAccount = this.state.account_prepaid && ExpensesStore.getAccountCommissionToByOidInSale(this.state.account_prepaid?.value)

      let content = (
      <Box className="sale-prepaid">
        <Columns className={this.props.isMobileSize ? "is-mobile": ""}>
          <Columns.Column>
            <Field>
                <Label>{T('prepaid_amount')}</Label>
                <Control iconRight className={this.props.isMobileSize ? "" : "like-select"}>
                  <Input  size="medium"
                      name="prepaid_amount"
                      placeholder={T('amount-from-buyer')}
                      onChange={this.onChangeNumber}
                      value={this.state.prepaid_amount}
                      />
                  <Icon align="right">
                    <span>{this.getCurrency()}</span>
                  </Icon>
              </Control>
            </Field>
          </Columns.Column>
          <Columns.Column>
              <SelectComponent
                      name="account_prepaid"
                      label="to-account"
                      onChange={this.onChangeSelect}
                      value={this.state.account_prepaid}
                      list={() => this.getLocalAccountList(true)}
                      load={ExpensesActions.loadAccounts}
                      creatable={false}/>
              {commissionAccount && <div className='commission-label'><b>{T('commission-label')} ({this.state.account_prepaid && ExpensesStore.getAccountName(this.state.account_prepaid?.value)}): </b> {commissionAccount}%</div> }
          </Columns.Column>
        </Columns>
        <div className="prepaid-balance">{T('need-balance')}: <Money amount={this.state.amount_sale - this.state.prepaid_amount}  wrapper={DOM.span} /> {T('_to-account')} {ExpensesStore.getAccountName(this.state.account.value)}</div>
      </Box>
          );

      return content;
    }

    reservedAlert(){
      if(this.state.type === 'reserve_sale') {
        const save_product_data = this.state.save_product_data

        const result = Object.values(this.state.product_data || {}).filter(product => {
          const splitIdent = product.ident ? product.ident.split('_') : [];
          const pid = splitIdent[0]
          const mid = splitIdent[1]
          const isStandardStock = WarehouseStore.isStandardStock(mid);
          const getProduct = ProductsStore.getProductByMid(pid, mid);

          const saveCount = save_product_data[product.ident]?.count || 0

          return isStandardStock && getProduct && getProduct.type_product !== 4 && (product.instock + saveCount) - product.count < 0
        })

        if(!!result.length) {
          return <Notification color="danger">
            {T('reservation-will-added-minus')}
          </Notification>
        } else {
          return null
        }
      }
    }

    changeTab(tab) {
        let is_reser = (tab === "reserve_sale");
        let account = this.state.account;
        if (is_reser) {
          account = ExpensesStore.getDebtAccounts();
        } else if (tab === "delivery" && this.state.prepaid_enable && this.state.type !== "reserve_sale") {
          account = ExpensesStore.getDeliveryAccounts();
        } else if (tab === "main_sale") {
          account = ExpensesStore.getPersonalAccounts()[0];
        }

      this.setState(
            {
                "type": tab,
                "prepaid_enable": is_reser || this.state.prepaid_amount > 0,
                "account": account
            }
        );
    }

    getTabsRadio(sale_tab) {
        let response = [];
        for (const i in sale_tab) {
            const tab = sale_tab[i]
            if (!User.getModuleEnable(tab)) {
              continue;
            }

            response.push (
               <Radio
                      key={'t-'+tab}
                      onChange={() => this.changeTab(tab)}
                      checked={this.state.type === tab}
                      value={tab}
                      size="large"
                      name="type">
                    {T(tab)}
               </Radio>
            );
        }

        return response;
    }

    getTabs(sale_tab) {
        let response = [];
        for (const i in sale_tab) {
            const tab = sale_tab[i]
            if (!User.getModuleEnable(tab)) {
              continue;
            }

            response.push (
                <Tabs.Tab key={tab} active={this.state.type === tab} onClick={() => this.changeTab(tab)}>
                    {T(tab)}
                </Tabs.Tab>
            );
        }

        return response;
    }

    renderReserv() {
        return (
            this.renderSale()
          )
    }

    renderDelivery() {
        return (
            this.renderSale()
          )
    }

    discountBlockMobile() {
      const enable = !this.state.discount_enable,
            discount = !enable ? "" : this.state.discount;
      return (
        <>
           <span className="surrender" onClick={()=>{
              this.setState(
                {
                  "discount_enable": enable,
                  "discount": discount,
                  "amount_sale": this.calcDiscount(this.state.amount, discount)
                }
              );
            }}>{T('sales-discount')}
          </span> | &nbsp;
        </>
        );
    }

    discountBlock() {
      // if (!this.state.client || this.state.client.value === 0) return null;
      const columnClass = this.props.isMobileSize ? "is-mobile" : "";
      return (
          <Columns.Column>
            <InfoWrapper info={T('sale-discount-tip')}>
              <Label>{T('sales-discount')}</Label>
            </InfoWrapper>
            <Columns className={columnClass}>
                <Columns.Column>
                  <Control iconRight>
                    <Input  size="medium"
                          name="discount"
                          className={this.props.isMobileSize ? "" : "discount-field"}
                          placeholder="0"
                          disabled={!UserStore.getPermissionEnable('edit-discount')}
                          onChange={this.onChangeDiscount}
                          value={this.state.discount || 0}
                          />
                          <Icon align="right" className="discount_type_icon">
                              <span>{this.state.discount_type.value === "%" ? "%" : this.getCurrency()}</span>
                          </Icon>
                          </Control>
                  </Columns.Column>
                   <Columns.Column>
                        <SelectComponent
                            name="discount_type"
                            label=""
                            className="discount_type_select"
                            onChange={this.onChangeSelect}
                            value={this.state.discount_type}
                            readOnly={!UserStore.getPermissionEnable('edit-discount')}
                            list={() => [{'id': '%', 'name': '  %  '},{'id': 'stable', 'name': T('fix')}]}
                            creatable={false}/>
                 </Columns.Column>
            </Columns>
        </Columns.Column>
      )
    }

    getDateField() {
      return (
        <Columns.Column>
           <Field>
              <Label>{T("sale-date")}</Label>
              <Control className='full-width-date'>
                 <DatePicker
                    selected={this.state.date}
                    onChange={this.onChangeDateSaled}
                    className="input"
                    dateFormat="dd-MM-yyyy"
                    popperPlacement="top-left"
                    showTimeInput
                    locale={User.getLang()}
                    maxDate={new Date()}
                    timeFormat="HH:mm"
                  />
              </Control>
          </Field>
         </Columns.Column>
      );
    }

    getCreateTTNButton() {
      if (ACTIVE_TTN.indexOf(this.state.integration_id.value) === -1) return null;

      let buttons = {
          1: (<OpenInsideModal size="small" text={T('create-novaposhta-ttn')}>
                      {User.isNpCreateTTNEnable() ?
                        <CreateNovaposhtaModal
                          modal='create-np'
                          client={this.state.client}
                          amount={this.state.account && ExpensesStore.isCOD(this.state.account.value) ? (this.state.amount_sale - this.state.prepaid_amount) : this.state.amount_sale}
                          postoffice={this.state.postoffice}
                          money={this.state.account && ExpensesStore.isCOD(this.state.account.value) ? "2" : false}
                          product={this.state.product_data}
                          onCompleteCreate={(tracking_number) => {
                            this.setState({tracking_number: tracking_number});
                          }}
                          checkLocalStorage={this.state.account && ExpensesStore.isCOD(this.state.account.value)}
                        />
                      : <NotEnableNPModal /> }
                  </OpenInsideModal>
                  ),
          3: (
                  <OpenInsideModal size="small" text={T('create-ukrposhta-ttn')}>
                      {User.isUpCreateTTNEnable() ?
                        <CreateUkrPoshtaModal
                          modal='create-urk'
                          client={this.state.client}
                          amount={this.state.account && ExpensesStore.isCOD(this.state.account.value) ? (this.state.amount_sale - this.state.prepaid_amount) : this.state.amount_sale}
                          postoffice={this.state.postoffice}
                          money={this.state.account && ExpensesStore.isCOD(this.state.account.value) ? "2" : false}
                          product={this.state.product_data}
                          onCompleteCreate={(tracking_number) => {
                            this.setState({tracking_number: tracking_number});
                          }}
                          checkLocalStorage={this.state.account && ExpensesStore.isCOD(this.state.account.value)}
                        />
                      : <NotEnableNPModal delivery_id={3}/> }
                  </OpenInsideModal>
          )
      }

      return (
        <div className="create-ttn-btn-box">
          {buttons[this.state.integration_id.value]}
        </div>
      )
    }

    delChannel(i) {
        let channel =this.state.channel;
        // delete channel[i];
        channel.splice(i, 1);
        channel = channel.filter(n => n)
        this.onChangeSelect(channel, 'channel');
    }

    balanceClientWithLoyalCardIntegration () {
      // const isLoyalCard = UserStore.getIsIntegration(UserStore.LC_ID);
      // const isBonusEnable = ClientsStore.getEnabledDiscount() && ClientsStore.getDiscountTypes() === "bonus";
      const dataClient = ClientsStore.getClientById(this.state.client?.value)

      if(this.state.client?.value && !!dataClient?.balance) {
        return <List.Item key='balance'> <InfoWrapper info={T("balance-help")} defaultPosition="-20"><b>{T('balance')}:&nbsp;</b> <Money amount={dataClient.balance } wrapper={DOM.span}/></InfoWrapper></List.Item>
      }

      return null
    }

    getActiveChannel() {

      let _active = [],
        channels = this.state.channel;
      if (Array.isArray(channels)) {
        channels = channels.filter(n => n)
      }

      if ((channels && channels.value) ||
          (channels && channels.length === 1) ||
          (!Array.isArray(channels))) {
        return null;
      }
      for (let i in channels) {
          let fil = channels[i];
        _active.push(<Button key={"b-"+i} size="small" rounded className="sb iconright" onClick={() => this.delChannel(i)}>{fil.label} <LocalIcon icon="close" /></Button> )
      }
      return (
        <Button.Group className="active-select">
            {_active}
         </Button.Group>
      )
    }

    getActiveCommission() {
      let commission = {}
      if(this.state.channel && Array.isArray(this.state.channel)) {
        const getCommissions = this.state.channel.map(item => SalesStore.getChannelCommission(item.value))
        commission = getCommissions.find(info => info.fee > 0) || {}
      } else if(this.state.channel){
        commission = SalesStore.getChannelCommission(this.state.channel.value) || {}
      }

      if(commission?.id) {
        commission.name = T(SalesStore.getChannelsName(commission?.id))
      }

      return commission
    }

  getActiveCommissionByChannel(channels) {
    let commission = {}
    if(channels && Array.isArray(channels)) {
      const getCommissions = channels.map(item => SalesStore.getChannelCommission(item.value))
      commission = getCommissions.find(info => info.fee > 0) || {}
    } else if(channels){
      commission = SalesStore.getChannelCommission(channels) || {}
    }

    if(commission?.id) {
      commission.name = T(SalesStore.getChannelsName(commission?.id))
    }

    return commission
  }

    enablePrepaid() {
      const data = {
        "prepaid_amount": 0,
        "prepaid_enable": !this.state.prepaid_enable,
        "account": this.state.type === "delivery" ? ExpensesStore.getDeliveryAccounts() : ExpensesStore.getDebtAccounts()
      }

      if(this.state.prepaid_enable) {
        data.account_prepaid = null
      }

      this.setState(
          data
        );
    }

    getHelperActions() {
      return (
          <div>
            {this.props.isMobileSize ? this.discountBlockMobile() : null}
            {this.state.type !== "reserve_sale" ? <><span className="surrender" onClick={() => this.enablePrepaid()}>{T('prepaid')}</span> | </>: null}
            {this.state.type === "main_sale" ? <><span className="surrender" onClick={()=>{this.setState({"surrender": !this.state.surrender})}}>{T('surrender')}</span></>: null}
          </div>
        )
    }

    changeTransactionsNumber(transactionID, transactionRRN) {
      const data = {}
      if(transactionID) data.terminal_trx = transactionID
      if(transactionRRN) data.transactionRRN = transactionRRN
      if(!!Object.keys(data).length) {
        this.setState(data)
      }
    }

    openTerminalModal(terminalID) {
      if(!!this.state.amount_sale && !isNaN(this.state.amount_sale) && this.state.amount_sale > 0) {
        if(terminalID) {
          AppStore.openModal(<PayOnTerminalModal account_id={this.state?.account?.value}  terminalID={terminalID} amount={this.state.amount_sale} onResponseTerminal={this.changeTransactionsNumber}/>)
        }
      }else {
        this.setState({
          'message': {'msg': T('transfer-amount-incorrect')}
        })
      }

    }

    onChangeTypePrice({target: {value}}) {
      this.setState({
        globalTypePrice: value
      })

      const productData = {...this.state.product_data}

      Object.values(productData).forEach(item => {
        const sliceIdent = item.ident.split('_')
        const pid = sliceIdent[0]
        const mid = sliceIdent[1]
        const priceWithCustomPrice = ProductsStore.getProductPriceByCustomPriceNameAndPid(value, pid)

        if(priceWithCustomPrice && value !== "0") {
          let val = this.checkVal(priceWithCustomPrice);
          productData[item.ident]['type_price'] = value
          productData[item.ident]['discount'] = 0
          productData[item.ident]['default_price'] = 0
          productData[item.ident]['finish_price'] = val;
        } else if(value === "0") {
          const getProduct = ProductsStore.getProductByMid(pid, mid)
          productData[item.ident]['type_price'] = ''
          productData[item.ident]['discount'] = 0
          productData[item.ident]['default_price'] = 0
          productData[item.ident]['finish_price'] = getProduct.price;
        }


      })

      this.updateData(productData)
    }

    getTerminalButton() {
      const linkAccount = TerminalStore.getTerminalIdByAccountId(this.state?.account?.value)

      return (
        <div align='right'>
          <Button
            onClick={() => this.openTerminalModal(linkAccount.terminal_id || null)}
            color='info' size='small' rounded>
            {T('pay-by-terminal')}
          </Button>
        </div>)
    }

    renderSale() {
      const {oid} = this.props;
      let text_btn = 'add-sale-btn', main_sale = (this.state.type === "main_sale");
      const columnClass = this.props.isMobileSize ? "is-mobile" : "";
      if (this.state.type === "reserve_sale") {
        text_btn = 'reserv-button-btn';
      }

      if (oid && !this.props.isCopy) {
        text_btn = 'edit-btn';
      }

      if(this.props.isRefund) {
        text_btn = 'restore-label'
      }

      let tabs = this.getTabsRadio(['main_sale', 'delivery', 'reserve_sale'])
      let mobile_discount = (
        <Box>
          <Columns>
            {this.discountBlock()}
          </Columns>
         </Box>
        );

      const discount_product = this.calcDiscountProduct()
      let sum_discount = (
         <small>{T('sales-discount')}: <Money amount={this.state.sum_discount + discount_product} wrapper={DOM.span} /> <br /></small>
        );

      const tags = ClientsStore.getGroupClientTagByClientId(this.state.client?.value)
      const commission = this.getActiveCommission()

      const commissionAccount = this.state.account && ExpensesStore.getAccountCommissionToByOidInSale(this.state.account?.value)

      return (
        <div>
          <Columns className={columnClass}>
                {this.props.isMobileSize ? <Columns.Column className="padding-bottom0">{this.getHelperActions()}</Columns.Column>  : this.discountBlock()}
                <Columns.Column className="alignright padding-bottom0">
                  <div className="big-text">
                    {UserStore.getPermissionEnable("salesprice") && <>{T('total')}: <Money amount={this.state.amount_sale}
                                            wrapper={DOM.span}/> <br/></>}
                      {this.state.sum_discount > 0 || discount_product > 0 ? sum_discount : null}

                      <CommissionBlock
                        preAmount={this.state.prepaid_amount}
                        accountId={this.state.account && this.state.account?.value}
                        preAccountId={this.state.account_prepaid && this.state.account_prepaid?.value}
                        commission={commission} onChangeFee={this.onChange}
                        fee={this.state.fee}
                        defaultFee={this.state.default_fee}
                        price={this.state.amount_sale}
                        currency={this.getCurrency()}
                      />
                  </div>
                 {!this.props.isMobileSize ? this.getHelperActions() : null }
                </Columns.Column>

          </Columns>
          {this.state.discount_enable && this.props.isMobileSize ? mobile_discount : null}
            {this.state.surrender ?
              <Box>
                   {this.renderHelperForChange("form")}
                   {this.renderHelperForChange("data")}
              </Box>
            : null }


            {this.renderPrepaid()}

            <Field>
              <Control className="type-sales">
                  {tabs.length > 1 ? tabs : null}
              </Control>
            </Field>
            {this.state.type === "delivery" ?
            <Columns className={columnClass}>
                <Columns.Column>
                    <SelectComponent
                        name="custom_status"
                        label="sale-status"
                        onChange={this.onChangeSelect}
                        value={this.state.custom_status}
                        addItem={(value) => AppStore.openModal(<AddStatusModal name={value.name} defaultType='sales'/>)}
                        addItemModal={() => AppStore.openModal(<AddStatusModal defaultType='sales'/>)}
                        list={() => SalesStore.getAllStatusesList(['saled'])}
                        load={OrdersActions.loadStatus}
                        creatable={true}/>

                </Columns.Column>
                <Columns.Column>
                    <SelectComponent
                      name="integration_id"
                      label="delivery-service"
                      // empty='choose-delivery-service'
                      onChange={this.onChangeSelectDelivery}
                      value={this.state.integration_id}
                      list={User.getDeliveryServiceBase}
                    />
                </Columns.Column>
            </Columns> : null}

            {User.getModuleEnable("clients") ?
            <Columns className={columnClass}>
                <Columns.Column>
                  <Field kind="group">
                    <Control fullwidth>
                      <SelectComponent
                          name="client"
                          label="client"
                          onChange={this.onChangeSelectClient}
                          value={this.state.client}
                          filterOption={filterClient}
                          list={ClientsStore.getClientsList}
                          load={ClientsActions.load}
                          addItem={({name}) => AppStore.openModal(<AddClientModal phone={name}/>)}
                          addItemModal={() => AppStore.openModal(<AddClientModal/>)}
                          placeholder={"enter-client-phone"}
                          className='fixed-copy-select'
                          // empty={'enter-client-phone'}
                          creatable={true}/>
                    </Control>
                    {this.state.client && this.state.client.value !== 0 && (
                        <Control className="margin-top-10">
                        <Label>&nbsp;</Label>
                        <OpenInsideModal renderAs="icon" size="1x" icon="edit">
                          <AddClientModal
                            edit={true}
                            data={this.state.client_data}
                            client_id={this.state.client.value}
                          />
                        </OpenInsideModal>
                      </Control>
                    )}
                  </Field>
                </Columns.Column>
                {main_sale && isMobileOnly ? this.getDateField() : null}

                {this.state.type === "delivery" && this.state.integration_id?.value !== User.RZ_ID &&
                <Columns.Column>
                  {User.getEnableNovaPoshta() && this.state.integration_id && [User.NP_ID, User.UP_ID].indexOf(this.state.integration_id.value) > -1 ?
                  <SelectComponent
                      name="postoffice"
                      label="post-office-number"
                      readOnly={(!this.state.client || this.state.client.value === 0 ?  true : false)}
                      onChange={this.onChangeSelectOffice}
                      customCreate={true}
                      emptyValue={true}
                      // autoSelect={true}
                      value={this.state.postoffice}
                      list={() => {return ClientsStore.getClientDeliveryListSelect(
                        this.state.client ? this.state.client.value : false, User.getLang(), this.state.integration_id.value
                      )}}
                      load={ClientsActions.load}
                      addItem={ClientsActions.addClient}
                      empty={'add-delivery-address-btn'}
                      creatable={false}/>
                    :
                  <Field>
                      <Label>{T('delivery_info')}</Label>
                      <Input
                          name="delivery_info"
                          type="text"
                          size="medium"
                          onChange={this.onChange}
                          placeholder={T('delivery_info-desc')}
                          value={this.state.delivery_info}
                          />
                  </Field>
                  }
                </Columns.Column>}
            </Columns>
            : "" }
          {(!!tags?.length || !!this.balanceClientWithLoyalCardIntegration()) && (
            <List>
              {this.balanceClientWithLoyalCardIntegration()}
              {!!tags?.length && (
                <List.Item className='display-flex-row nowrap'>
                  <b>{T('client-tags')}: </b>
                  <div className='display-flex-row flex-wrap'>
                    {tags.map(item => <Tag key={`tag-${item.id}`}
                                           color={item.color} name={item.name}/>)}
                  </div>
                </List.Item>
              )}
            </List>)}


            {this.state.type === "delivery" && !this.state.hide_ttn?
            <Columns>
                <Columns.Column>
                  <Label>{T('tracking-number')}</Label>
                  <Input
                      name="tracking_number"
                      size="medium"
                      onChange={this.onChange}
                      placeholder={T('input-tracking-number')}
                      value={this.state.tracking_number}
                      />

              </Columns.Column>
              {this.state.integration_id && ACTIVE_TTN.indexOf(this.state.integration_id.value) > -1 && (User.isNpCreateTTNEnable() || User.isUpCreateTTNEnable()) ?
              <Columns.Column>
                 {this.getCreateTTNButton()}
              </Columns.Column>
              : null}
            </Columns>
            : null }

            <Columns  className={columnClass}>
                <Columns.Column>
                  <SelectComponent
                      name="account"
                      label="account"
                      onChange={this.onChangeSelect}
                      value={this.state.account}
                      // readOnly={this.state.prepaid_enable && this.state.type === "delivery"}
                      list={this.getLocalAccountList}
                      load={ExpensesActions.loadAccounts}
                      creatable={false}/>
                      {commissionAccount && <div className='commission-label'><b>{T('commission-label')} ({this.state.account && ExpensesStore.getAccountName(this.state.account.value)}): </b> {commissionAccount}%</div> }
                      {main_sale && !this.state.transactionRRN && TerminalStore.isCheckTheBindingAccountToTerminal(this.state?.account?.value) && this.getTerminalButton()}
                </Columns.Column>
                <Columns.Column>
                  <SelectComponent
                      name="channel"
                      label="channel-tag"
                      onChange={this.onChangeSelectChannel}
                      value={this.state.channel}
                      isMulti={this.state.channel}
                      autoSelect={true}
                      list={SalesStore.getChannelsList}
                      load={Actions.loadChannels}
                      className="channel-select mySelect"
                      addItem={({name}) => AppStore.openModal(<AddChannelModal name={name}/>)}
                      addItemModal={() => AppStore.openModal(<AddChannelModal />)}
                      creatable={true}/>
                      {commission?.fee > 0 && <div className='commission-label'><b>{T('commission-label')} ({commission?.name}): </b> {commission.fee}%</div> }
                      {this.getActiveChannel()}
                </Columns.Column>
                {main_sale && !isMobileOnly ? this.getDateField() : null}
            </Columns>

          {main_sale &&  TerminalStore.isCheckTheBindingAccountToTerminal(this.state?.account?.value) && (
            <Columns>
              <Columns.Column className='display-flex-row'>
                <span>
                  <span
                    className="silver">{T('rrn-transaction')}:</span> <b>{this.state.transactionRRN || T('undefined-rrn')}</b>
                  </span>
                {/*{!this.state.transactionRRN && <div>*/}
                {/*  <span className='text-success'>*/}
                {/*    <FontAwesomeIcon icon='plus-circle'/>*/}
                {/*  </span>*/}
                {/*</div>}*/}
              </Columns.Column>
            </Columns>)}
          {/*{commission.fee > 0 && <div className='margin-bottom-15'><b>{T('commission-label')}({commission.name}): </b> {commission.fee}%</div> }*/}

          <Columns>
            <Columns.Column
              className={this.state.showSeller ?"is-two-thirds" : ""}
>
              <Field>
              <Control>
                <Label>{T('comment')}</Label>
                <Textarea
                      name="comment"
                      type="text"
                      rows={2}
                      placeholder={T('sales-comment-placeholder')}
                      onChange={this.onChange}
                      value={this.state.comment}
                      />
              </Control>
          </Field>
            </Columns.Column>
            {!this.state.showSeller ? null : <Columns.Column>
              <SelectComponent
                      name="user"
                      label={T('who-add-sale')}
                      onChange={this.onChangeSelect}
                      value={this.state.user}
                      list={User.getSubUsersList}
                      load={UserActions.getSubUsers}
                      creatable={false}/>
            </Columns.Column>}
          </Columns>
          {this.getAdditionalList()}
          {!(this.props.oid && !UserStore.getPermissionEnable("edit-sales")) && <Button.Group>
            <SubmitButton disabled={this.state.disable_button} text={text_btn}
                          waiting={this.state.waiting} submit={this.submit}/>
          </Button.Group>}
        </div>
        );
    }

    // <Button  size="large" rounded color="light"
    //                   onClick={SalesStore.closeModal}>{T('close')}</Button>

    renderMain() {
      // const tabs = this.getTabs(['main_sale', 'delivery', 'reserve_sale']);

      let content;

      switch (this.state.type) {
        case 'delivery':
          content = this.renderDelivery()
          break;
        case 'reserve_sale':
          content = this.renderReserv()
          break;
        case 'main_sale':
        default:
          content = this.renderSale()
      }

      const productsList = Object.values(this.state.product_data)
      const counts = productsList.reduce((acc, cur) => {
        acc += (parseFloat(cur.count) || 0)
        return acc
      },0)
      return (
        <div className="sale_modal">
          <Box>
            {this.renderFiscalAlert()}
            {this.reservedAlert()}
            <SelectProducts
              onChange={this.onChangeSelectProduct}
              selected={() => {return this.product_data_list}}
              value={this.state.products}
              disableSubmit={(status) => this.disableSubmit(status)}
              source="sales"
              quantity={1}
              disabled={!this.state.modification}
            />
            {Object.keys(this.state.product_data || {}).length >= 2 && <MassChangeTypePrice value={this.state.globalTypePrice}
                                  onChangeValue={this.onChangeTypePrice}/>}
            {this.getSalesList()}
            {!!productsList.length && <div align='right' style={{marginBottom: 10}}>
                <b>{T('total')}: {productsList.length} {T('items-short')} ({counts} {T("items-e")})</b>
            </div>}
            {content}

          </Box>
        </div>
      );
    }

    getFCheckPrintButton() {
      if (!this.state.check_id || this.state.check_id === 0) {

        if (User.getPrintCheckboxEnable()) {
          return (
            <>
              {!ExpensesStore.isDebt(this.state.account?.value) && <SubmitButton
                fullwidth={false}
                text={T('send-to-dps')}
                size={this.props.isMobileSize ? "small" : "medium"}
                color="success"
                waiting={this.state.waiting}
                submit={() => this.sendToDps()}/>}
              {this.state.prepaid_amount > 0 && !ExpensesStore.isDebt(this.state.account_prepaid?.value) && <SubmitButton
                fullwidth={false}
                text={T('send-to-dps-prepaid')}
                size={this.props.isMobileSize ? "small" : "medium"}
                color="success"
                waiting={this.state.waiting}
                submit={() => this.sendToDps(true)}
              />}
            </>
          )
        } else {
          return null
        }
      }

      let url = SalesStore.getCheckboxPrintSlip(this.state.check_id);

      return (<PrintElement url={url} text='print-fiscal-slip' />)
    }

    renderFinish() {
      // let url_print_slip =SalesStore.getUrlPrintSlip(this.state.order_id);

      return (
        <Box align="center">
          <div className="modal-icon">
                  <img src="/img/icons/ok.png" alt="print-icon" width="88px" border="0"/>
          </div>

          <Heading>{T(this.state.edit ? 'sales-created-edit' : 'sales-created')}</Heading>

           <Button size={this.props.isMobileSize ? "large": "medium"} className="margin-bottom-10" fullwidth={true} rounded color="primary"
                onClick={() => {this.setState({close: true}); SalesStore.closeModal()}}
                >{T('continue-btn')}</Button>

          <Button.Group className="is-centered is-grouped">
              <FCheckButton
                waiting={this.state.waiting}
                account={this.state.account?.value}
                checkId={this.state.check_id}
                orderId={this.state.order_id}
                typeCheck={this.state?.typeCheck}
                prepaidAccount={this.state.account_prepaid?.value}
                prepaidAmount={this.state.prepaid_amount}
              />
              <PrintActionButtons orderID={this.state.order_id}/>
            </Button.Group>

        </Box>
      );
    }

    renderFiscalAlert() {
      if (!this.state.check_id || this.state.check_id === 0 || this.props.isCopy || this.props.isRefund) return null;

      return (
        <Notification color="danger">
          {T('this-check-dps')}
        </Notification>
        );
    }

    renderCheck() {
      if (!User.getPrintCheckboxEnable()) return null;

      return (
        <Box align="center">
          <h1 className="title">{T('dps-send-success')}</h1>
          <Button.Group className="is-centered is-grouped">
              {this.getFCheckPrintButton()}
              <Button size={this.props.isMobileSize ? "small": "medium"}  fullwidth={!User.getModuleEnable("slip") && !User.getPrintCheckboxEnable() ? true : false} rounded color="primary"
                onClick={() => {this.setState({close: true}); SalesStore.closeModal()}}
                >{T('close')}</Button>
            </Button.Group>
        </Box>
      );
    }

    render() {
        const stepNaviganor = [this.renderMain, this.renderFinish, this.renderCheck],
              currentStep = stepNaviganor[this.state.step];

        return (
          <div>
            {this.state.preorder ? <PreOrder data={this.state.preorder} /> : null}
            <Messages message={this.state.message} close={() => this.setState({message: null})} />
            {currentStep()}
            {this.state.close && ["/sales/add-sale", "/sales/edit-sale"].indexOf(window.location.pathname) > -1 ? redirectTo("/sales") : ""}
            {ClientsStore.get('openModal') ? OpenModalFactory(
              <AddClientModal preorder={this.state.preorder} data={{"phone": ClientsStore.get('phone')}}/>, ClientsStore.closeModal
            ) : null}
            {this.state.addPostOffice ? OpenModalFactory(
              <AddDeliveryLink modal='add-delivery' onClose={() => {this.setState({
                                      "addPostOffice": false
                                    })}} date={{  delivery: this.state.integration_id.value,
                                        phone: this.state.client_data['phone'],
                                        client_id: this.state.client_data["id"],
                                        name: this.state.client_data["name"],
                                        inner: true
                                    }}/>, () => {this.setState({
                                      "addPostOffice": false
                                    })}

              ) : null}
          </div>
        )
    }

}


export default connectToStores(withIsMobileSize(SalesModal), {
    products: ProductsStore,
    clients: ClientsStore,
    sales: SalesStore,
    terminal: TerminalStore
  });
