import React from 'react'
import SelectComponent from "../../../../tools/select-component";

const HiddenColumn = ({value, onChange}) => {
	const list = [
		{name: 'image', id: 'images'},
		{name: 'price-tag-sku', id: 'sku'},
		{name: 'history-data-asin', id: 'barcode'},
		{name: 'product-info-warehouse-one', id: 'instock'},
		{name: 'product-info-price', id: 'price'},
	]

	return (
		<SelectComponent
			onChange={onChange}
			placeholder='choose-column'
			isMulti
			label='hide-columns'
			className='full-size-select'
			value={value}
			list={() => list}
		/>
	);
};

export default HiddenColumn;