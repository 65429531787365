import React, {useEffect, useState} from 'react'
import ClientsStore from "../../modules/clients/clients-store";
import AppStore from "../../app-store";
import ClientViewModal from "../../modules/clients/modal/client-view/client-view-modal";
import ClientsActions from "../../modules/clients/clients-actions";
import T from '../i18n'

const ClientInfoRow = ({clientID, withoutPhone}) => {
		const client = ClientsStore.getClientById(clientID);

		const clientCount = ClientsStore.getClientCountById(clientID)
		const [countClient, setCountClient] = useState(clientCount)

		useEffect(() => {
			if(clientID) {
				ClientsStore.queueClientsCount(clientID)
			}

			const loadCount = ClientsActions.loadClientsCount.completed.listen(() => {
				const getClientCount = ClientsStore.getClientCountById(clientID)
				setCountClient(getClientCount)
			})

			return () => {
				loadCount()
			}
		}, [clientID]);

		if (!client) {
			return null
		}

		return (
			<div className='button-link-without-style display-inline' style={{userSelect: 'text'}} onClick={(e) =>{
				e.stopPropagation(); AppStore.openModal(<ClientViewModal clientID={clientID} />)}}
			>
          <span>
            {client.simple_name} {withoutPhone ? '' : client.phone && `(+${client.phone})`} {client && !!countClient.non_delete_orders_count && <b className='text-success' title={T('total-sales-report')}>({countClient.non_delete_orders_count})</b>}
          </span>
			</div>
		);
};

export default ClientInfoRow;