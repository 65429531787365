import React, {useEffect, useState} from 'react'
import {parsePhoneNumber} from "../../../../tools/parse-phone-number";
import {Box, Button, Heading} from "react-bulma-components";
import AppStore from "../../../../app-store";
import ChatsActions from "../../../chats/chats-actions";
import T from '../../../../components/i18n'
import SelectComponents from "../../../../tools/select-component";
import ChatsStore from "../../../chats/chats-store";
import {connectToStores} from "../../../../tools/reflux-tools";
import UserStore from "../../../../user/user-store";
import ErrorMessageControlHook
	from "../../../../components/error-message-block/error-message-control.hook";
import {errorHandler} from "../../../../tools/error-handler";
import SubmitButton from "../../../../components/submit-button";

const getPhoneNumberByZero = (phone) => {
	const reverse = phone.split("").reverse();

	const lastSevenDigits = reverse.slice(0, 10).reverse();

	return lastSevenDigits.join("");
}

const BinotelModal = ({phone, onClose}) => {
	const [iternalList, setIternalList] = useState([])
	const [waiting, setWaiting] = useState(false)
	const binotelList = UserStore.getBinotelList()

	const createListForSelect = binotelList.map((binotel) => ({
		id: binotel.id,
		name: binotel.name || `Binotel: ${binotel.id}`,
	}))

	const [activeIntegration, setActiveIntegration] = useState(createListForSelect[0])
	const [iternalNumber, setIternalNumber] = useState({value: UserStore.getMoreSetting('internalNumber') || UserStore.getAdminInternalNumberBinotel()})

	const handleSubmit = () => {
		if(iternalNumber && iternalNumber?.value && phone) {
			setWaiting(true)
			ChatsActions.createCallBinotel(
				{internalNumber: iternalNumber.value, phoneNumberClient: getPhoneNumberByZero(phone), integrationID: activeIntegration.value }
			)
		} else if(phone) {
			handleAutoRemoveError('client-phone-placeholder')
		} else {
			handleAutoRemoveError('select-an-operator')
		}

	}

	useEffect(() => {
		if(activeIntegration.id) {
			ChatsActions.internalNumbersBinotel(activeIntegration.id)
		}

		const loadInternal = ChatsActions.internalNumbersBinotel.completed.listen((data) => {
			setIternalList(ChatsStore.getInternalNumbersBinotel)
		})

		return () => {
			loadInternal()
		}
	}, [activeIntegration]);

	useEffect(() => {
		const createCall = ChatsActions.createCallBinotel.completed.listen((data) => {
			onClose()
		})
		const createCallFail = ChatsActions.createCallBinotel.failed.listen((res) => {
			const message = errorHandler(res)

			handleAutoRemoveError(message)
		})

		return () => {
			createCall()
			createCallFail()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChangeIntegration = (value) => {
		setActiveIntegration(value)
		setIternalNumber(null)
	}
	const handleChangeIternalNumber = (value) => {
		setIternalNumber(value)
	}

	const {setErrorMessage,ErrorMessageBlock,errorMessage,handleAutoRemoveError} = ErrorMessageControlHook()


	return <>
		<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
		<Box>
			<Heading size={3}>{T('creating-a-call')}</Heading>
			{!!createListForSelect.length && createListForSelect.length > 1 && <SelectComponents
				onChange={handleChangeIntegration}
				value={activeIntegration}
				list={() => createListForSelect}
				label='integration'
			/>}
			<SelectComponents
				onChange={handleChangeIternalNumber}
				value={iternalNumber}
				list={() => iternalList}
				readOnly
				label='operator'
				placeholder='select-an-operator'
			/>
			<div className='display-flex-row' style={{ justifyContent: 'flex-end' }}>
				<SubmitButton text='create-call' color='info' size='medium' waiting={waiting} fullwidth={false} submit={handleSubmit}/>
				<Button onClick={onClose} size='medium'>
					{T('close')}
				</Button>
			</div>
		</Box>
	</>
}


const BinotelCreateCall = ({phone}) => {
	const parsePhone = parsePhoneNumber(phone)



	return (
			<img
				className='cursor-pointer'
				src='/img/integrations/binotel-phone.png'
				style={{width:'20px',height:'20px'}} alt={`binotel-${parsePhone}`}
				onClick={() => AppStore.openModal(<BinotelModal phone={parsePhone} modal='integrations'/>)}
			/>
	);
};

export default connectToStores(BinotelCreateCall, {_: ChatsStore});