import React from 'react'
import SalesStore from "../sales-store";
import T from "../../../components/i18n";
import OpenInsideModal from "../../../components/modal/open-inside-modal";
import OrdersStore from "../../orders/orders-store";
import ChangeStatusModal from "../change-status-modal";

const SaleStatusButton = ({status, oid, isRefund}) => {

	if(isRefund) {
		return <div
			className={"order-status order-status-refund margin-left-0"}>
			{T('status-' + status)}
		</div>
	}

	return ['cod', 'refund', 'reserve_sale'].indexOf(status) > -1 ? (
		<div
			className={"order-status order-status-"+ status + ' margin-left-0'}>
					{T('status-' + status)}
				</div>
			) : (
				<OpenInsideModal
					size="small"
					text={
						SalesStore.isDefaultStatus(status)
							? "status-" + status
							: OrdersStore.getStatusNameById(status)
					}
					className={"order-status order-status-"+ status}>
					<ChangeStatusModal modal="change-status" oid={oid} status={status}/>
				</OpenInsideModal>
			)
};

export default SaleStatusButton;