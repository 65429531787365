import React from "react";
import yajax from "yajax";
// import axios from 'axios';

import T from "components/i18n";

// import moment from "moment";
import { createStore } from "../../tools/reflux-tools";
import Actions from './products-actions';
import User from '../../user/user-store';
import UserActions from 'user/user-actions';
import ShipmentsActions from '../shipments/shipments-actions';
import ExpensesActions from '../expenses/expenses-actions';
import calcMargin from 'tools/calc-margin';

import AppStore from '../../app-store';
import { filterSearch } from 'tools/filters-helper';
import WarehouseStore from 'modules/warehouse/warehouse-store'
import SalesStore from 'modules/sales/sales-store'
import SalesActions from 'modules/sales/sales-actions'
import LightStore from 'modules/products/light-store'
import LightActions from 'modules/products/light-actions'
import sortByKey from 'tools/sort-by-key';
import createOptionSelect from 'tools/create-option-select';
import UserStore from "../../user/user-store";
import {DTO_products, DTO_stock_products} from "./utils";
import {parseJSON} from "../../tools/error-handler";
import ServicesProductTag
    from "../../components/services-product-tag/services-product-tag";
import ExpirationDateTag
    from "../../components/expiration-date-tag/expiration-date-tag";

const IMAGES_SIZES = [0, 64];
const CLEAR_FILTER = () => ({
    status: "all",
    category: {'value': 0, label: T('all-categories')},
    shipment: {'value': 0, label: T('all')},
    brand: {'value': 0, label: T('all')},
    mid: {'value': 0, label: T('all')},
    instock: window.localStorage.getItem("instock") || '0',
    withoutbarcode: false,
    withoutprice: false,
    search: "",
    margin_from: "",
    margin_to: "",
    price_from: "",
    price_to: "",
    sort: {},
    attr: {},
    reserv: {value: 0, label: T('all')},
    integration: {value: 0, label: T('all')},
    minStock: {value: 0, label: T('all')},
    addFields: {value: 0, label: T('all')},
    expirationDate: {value: 0, label: T('all')},
    type_product: {value: 0, label: T('all')}
});

const ProductsStore = createStore({
	listenables: Actions,
    _load_url: '/bproducts/',
    _prefix_files: '/bproducts-loads/',
    _prefix: '/bproducts/',
    _prefix_img: '/bimages/',
    _prefix_remote: '/bremote/',
    _prefix_remote_csv: '/bremote-csv/',
    newImages: {},
    UNITS: ['units','kg', 'g', 'L', 'ml', 'm', 'cm', 'm2', 'm3', 'cm3', 'pair', 'plt', 'ton', 'pack', 'line', 'set', 'bobbin', 'cut'],
    state: {
        rows: null,
        history: [],
        category: null,
        category_stat: {},
        brand: null,
        attr: null,
        attrDict: {},
        attrDictValues: {},
        select_options: null,
        fromScanner: null,
        stock: {},
        dictProduct: {},
        dictCategory: {},
        categoryChild: {},
        needClearCache: {},
        count_per_page: 20,
        isLoaded: false,
        isLoadedStock: false,
        isLoadedCategory: false,
        isLoadedImages: false,
        openModal: false,
        startDeleted: false,
        needReload: 0,
        tab: 'all',
        _filter: CLEAR_FILTER(),
        barcode: "",
        remoteProducts: null,
        remote_iid: null,
        dictAdditionalInfo: {},
        variableDictIds: {}
    },

    onFilter(name, value) {
        let filters = {};

        if(name === 'instock') {
            window.localStorage.setItem("instock", value)
        }

        for (let i in this.state['_filter']) {
            filters[i] = this.state['_filter'][i];
        }

        // let val = value;
        // if (name === "search") {
        //     val = val.replaceAll('-', ' ');
        // }

        filters[name] = value;

        this.setState({
            _filter: filters,
            page: 0
        });

        return Actions.filter.completed()
    },

    onFilterCompleted() {},
    getProduct(pid) {
        return this.get("dictProduct")[pid] || false;
    },

    getProductByIdInList(pid) {
      return this.get('loadListDict')[pid] || false;
    },

    getIdsProductVariable(parent_id) {
      if(parent_id) {
          const listVariableIds = this.state.variableDictIds;
          if(listVariableIds && listVariableIds[parent_id]) {
              return listVariableIds[parent_id];
          }
      }

      return false
    },

    getProductByMid(pid, mid) {
        let product =  this.get("dictProduct")[pid] || false;

        if (product) {
            const stock = product['stock'][mid] || {}
            const {id, ...rest} = stock
            let data = {...product, ...rest};

            return data || false;
        }


        return false;
    },

    getProductCustomPriceListByPid(pid) {
      const product = this.getProduct(pid)

        const af_fields = parseJSON(product?.af)

        const custom_price_field = af_fields?.custom_price || {}


        let customPriceKeys = Object.keys(custom_price_field)

        const permissionCustomPrices = UserStore.getMoreSetting('custom_prices')

        if(!!permissionCustomPrices && permissionCustomPrices[0] !== 0 && customPriceKeys.length) {
            customPriceKeys = customPriceKeys.filter(item => permissionCustomPrices.includes(item))
        }

        return  customPriceKeys.map(item => ({name: item, value: custom_price_field[item]}))
    },
    getProductPriceByCustomPriceNameAndPid(customPriceName, pid) {
        const product = this.getProduct(pid)
        const af_fields = parseJSON(product?.af)
        const custom_price_field = af_fields?.custom_price || {}

        return custom_price_field[customPriceName]
    },

    getProductName(pid, need_sku) {
        if (need_sku) {
            let pr = this.getProduct(pid)
            return pr.skus +" "+pr.name
        }

        return this.getProduct(pid).name || "";
    },

    getUnits(units) {
        if (typeof(units) === "undefined") {
            return T('items-p')
        }
        return this.UNITS.indexOf(units) > -1 ? T(units+'-short') : units;
    },

    getStockUnits(stock, units) {
        return stock + " "+this.getUnits(units);
    },

    getUnitsList() {
        let list = [];

        for (let i in this.UNITS) {
            list.push({
                    'name': T(this.UNITS[i]),
                    'id': this.UNITS[i]
            });
        }

        return list;

    },

    getProductsByMid(mid) {
        let products = this.getList();

        let pr = products.filter((product) => typeof(product['stock'][mid]) !== "undefined");

        return pr;
    },

    buildAttr(data, text){
          let res = [];
          for (let attr_id in data) {
            let attr = data[attr_id],
                attr_name = this.getAttrName(attr_id);
            if (text) {
                res.push(attr_name+": "+ attr);
            } else {
            res.push(
                <span key={attr_id}><b>{attr_name}:</b> {attr}. </span>
              )
            }
          }

          return res;
    },

    checkOnBoarding() {
        if (User.getOnBoaringStep() === 2 && this.get("rows")) {
            if (this.get("rows").length > 0) {
                User.setOnBoaringStep(3);
            }
        }
    },
    generateSelectSingleOption(pid, mid, withSku) {
        const product = this.getProductByMid(pid, mid)
        const brand = (product['brand'] && product['brand'] !== "") ? product['brand']+". " : "",
              count = this.getStockUnits(product['instock'], product['units']),
              price = (product['price'] || 0)+ " "+User.getCurrency(),
              name = product['name']
        const sku = product.sku && withSku ? `${product.sku}. ` : ''
            let warehouse_name = ""
            if (WarehouseStore.isNeedName()) {
                warehouse_name = T(WarehouseStore.getWarehouseName(mid))+". ";
            }


        return product['id']+". "+brand+name+". "+warehouse_name+sku+count+" ("+price+")";
    },

    generateSelectOptions(pr, single) {
        if (!pr) {
            pr = this.getList();
        }

        let options = [];
        for (let i in pr) {
            let p = pr[i],
                name = p['full_name'];

            // if (p['parent_id']) {
            //     let attr = this.buildAttr(JSON.parse(p['attr_desc']), true);
            //     name += ". "+attr.join(". ");
            // }

            for (let j in p['stock']) {
                const stock = p['stock'][j];
                const brand = (p['brand'] && p['brand'] !== "") ? p['brand']+". " : ""
                const sku = (stock['sku'] && stock['sku'] !== "") ? ". "+stock['sku'] : ""
                const price = (stock['price'] || 0)+ " "+User.getCurrency();
                let count = this.getStockUnits(stock['instock'], p['units'])

                if(WarehouseStore.isDropshipping(stock.marketplace_id) || ProductsStore.isServicesProduct(stock.product_id)) {
                    count = this.getStockUnits('∞', p['units'])
                }

                let warehouse_name = ""
                if (WarehouseStore.isNeedName()) {
                    warehouse_name = T(WarehouseStore.getWarehouseName(stock['marketplace_id']))+". ";
                }

                let name_string;
                const services = <ServicesProductTag typeProduct={p.type_product} short/>
                const exp_date = <ExpirationDateTag pid={p.id} short/>
                if (single) {
                        name_string = <>{exp_date} {services} {p['id']}. {brand+name+sku}. {UserStore.getPermissionEnable('salesprice') ? price : ''}</>
                } else {

                     name_string = (<div className='mySelect'>{exp_date} {services} {p['id']}. {brand}{name}{sku}.<br /><b>{warehouse_name} {count}. {UserStore.getPermissionEnable('salesprice') ? price : ''}</b></div>);
                }



                options.push({ id: this.getIdent(p['id'],stock['marketplace_id']), name: name_string });
            }
        }

        return createOptionSelect(options);
    },

    makeInfoBar(pid, mid, short) {
        const data = this.getProductByMid(pid, mid);
        let rows = [];

        rows.push("ID: "+data['id']);

        if (short) {
            if (data['sku'] && data['sku'] !== "") {
                rows.push(data['sku']);
            }

            if (mid && WarehouseStore.isNeedName()) {
                rows.push(T('product-marketplace')+": "+T(WarehouseStore.getWarehouseName(mid)))
            }

            return rows.join(". ");
        } else {
            if (data['asin'] && data['asin'] !== "") {
                rows.push(data['asin']);
            }

            if (data['sku'] && data['sku'] !== "") {
                rows.push(T('product-vendor-code')+": "+data['sku']);
            }
            if (data['category'] && data['category'] !== "") {
                rows.push(data['category']);
            }
            if (data['brand'] && data['brand'] !== "") {
                rows.push(T('product-brand')+": "+data['brand'])
            }

            if (mid && WarehouseStore.isNeedName()) {
                rows.push(T('product-marketplace')+": "+T(WarehouseStore.getWarehouseName(mid)))
            }

            if (data['amz_status']  && data['amz_status'] !== "") {
                rows.push(data['amz_status'])
            }

            if (data['category_id']  && data['category_id'] !== "") {
                rows.push(T('category')+": "+this.getCategoryName(data['category_id']))
            }
        }

        return rows.join(" | ");

    },

    getProductListForSelect(source, filter) {

        let products = this.getList(),
            result = products.filter(item => this.applyFilterSelect(item, filter));
            if (typeof(this.filterStock) === "function") {
                result  = this.filterStock(result, false, source, filter);
            }

        let count = result.length;
        //  for (let i in result) {
        //     let p = result[i];
        //     count += Object.keys(p['stock']).length
        // }

        return [result, count];
    },

    getFormattingCategoryList() {
        function formatting(list, level) {
            for (let i in list) {
                let cat = list[i], space = "—".repeat(level);

                cat['name'] = space+" "+cat['name'];

                response.push(cat)

                if (parent[cat['id']] !== "undefined") {
                    sortByKey(parent[cat['id']], 'name')
                    formatting(parent[cat['id']], level + 1)
                }
            }
        }
        let categories = this.getCategoryList();

        let response = [], parent = {}, main_category = [], level = 0;
        for (let i in categories) {
            let category = categories[i],
                pid = category['parent_id'];

            if (pid) {
                if (typeof(parent[pid]) === "undefined") {
                    parent[pid] = []
                }

                parent[pid].push({
                    'name': category['name'],
                    'parent_id': pid,
                    'id': category['id']
                })
            } else {
                main_category.push({
                    'name': category['name'],
                    'parent_id': pid,
                    'id': category['id']
                })
            }
        }

        main_category = sortByKey(main_category, 'name');

        formatting(main_category, level);

        return response;
    },

    getCategoryList() {
        const category = this.get("category");
        if (category === null) {
            Actions.loadCategory();
            return [];
        }

        return category;
    },

    getCategoryListWithId(id) {
      const category = this.getCategoryList()
      if(id) {
          const excludeByIdAndChildren = (arr, targetId) => {
              const filteredArray = arr.filter(item => {
                  return item.id !== targetId;
              });

              const filterDescendants = (items, parentId) => {
                  let descendants = items.filter(item => item.parent_id === parentId);
                  descendants.forEach(descendant => {
                      filteredArray.splice(filteredArray.indexOf(descendant), 1);
                      filterDescendants(items, descendant.id);
                  });
              }

              const targetItem = arr.find(item => item.id === targetId);

              if (targetItem) {
                  filterDescendants(arr, targetId);
              }

              return filteredArray;
          };

          return excludeByIdAndChildren(category, id)
      }
      return category
    },

    getIdent(pid, mid) {
        return pid+"_"+mid;
    },

    getCategoryName(cat_id, isReal) {
        let names = [];
        function _get_name(cid) {
            const cat = dict[cid];
            let name =(cat && cat['name'] ? cat['name'] : "");
            names.push(name);
            if (cat?.parent_id) {
                _get_name(cat['parent_id'])
            }
        }
        if (typeof(this.state.dictCategory[cat_id]) == "undefined") {
            this.getCategoryList();
            return "";
        }

        const dict = this.state.dictCategory;
        if(isReal) {
            return dict[cat_id]?.name
        }
        _get_name(cat_id)

        return names.reverse().join(" / ");
    },

    getCategoryById(productId) {
        const product = this.state.dictProduct[productId]
        if(product) {
            return product?.category_id
        }
        return null
    },
    getBrandById(productId) {
        const product = this.state.dictProduct[productId]
        if(product) {
            return product?.brand_id
        }
        return null
    },

    getCategoryStat(cat_id) {
        return this.state.category_stat[cat_id] || {};
    },

    getBrandsList() {
        const brand = this.get("brand");
        if (brand === null) {
            Actions.loadBrand();
            this.setState({
                brand: []
            })
            return [];
        }

        return brand;
    },

    getBrandName(id) {
        const brands = this.getBrandsList()

        let brand = brands.filter((w) => {return parseInt(id) === parseInt(w['id'])})

        if (brand.length > 0) {
            return brand[0]['name'];
        }

        return "";
    },

    onNeedReload() {
        this.setState({
            needReload: this.state.needReload++
        });
    },

    onSetSort(column) {
        let way = this.state.sort_way;
        if (this.state.sort_column === column) {
            way = this.state.sort_way * -1;
        }
        this.setState({
            sort_column: column,
            sort_way: way
        });
    },

    onDeleteCatalog(_type, _id) {
        const params = {
          method: "DELETE",
          url: this._prefix+"catalog",
          data: { type: _type, "id": _id }
        };

        yajax(params).then(
            Actions.deleteCatalog.completed, Actions.deleteCatalog.failed
        );
    },

    onDeleteCatalogCompleted(data) {
        if (data['type'] === "brand") {
            Actions.loadBrand();
            Actions.load(true);
        } else if (data['type'] === "suppliers") {
            ShipmentsActions.loadSuppliers();
            ShipmentsActions.load();
        } else if (data['type'] === "product-category") {
            Actions.loadCategory();
            Actions.load(true);
        } else if (data['type'] === "payments-category") {
            ExpensesActions.loadCategory(true);
            ExpensesActions.load(true);
        } else if (data['type'] === "sales-channels") {
            setTimeout(() => {
                SalesActions.loadChannels();
                SalesActions.load();
            }, 1000);
        }
    },

    onDeleteCatalogFailed() {
        console.log("PROBLEM WITH DELETE CATALOG");
    },

    filterStock(data, all, source, filter) {
        let new_result = [];

        const disabledServicesInSource = ['shipment', 'inventory', 'moving', 'production']

        for (let r in data) {
            let new_stock = [];
            let pr = data[r];
            if (!pr) continue;
            if(source && disabledServicesInSource.includes(source) && (pr.type_product === 4 || pr.type_product === 3)) {
                continue
            }

            if (pr['parent_id'] && all) {
                const getProduct = this.getProduct(pr['parent_id'])
                const filterInstock = this.getFilter('instock')
                if(filterInstock === '1') {
                    const isPushWrapper = getProduct.variable.some(variable =>
                      Object.values(variable.stock).some(item => !!item.instock || WarehouseStore.isDropshipping(item.mid))
                    );
                    if(isPushWrapper) {
                        new_result.push(pr);
                    }
                } else if(filterInstock === '2') {
                    const isPushWrapper = getProduct.variable.some(variable =>
                      Object.values(variable.stock).some(item => !item.instock && !WarehouseStore.isDropshipping(item.mid))
                    );
                    if(isPushWrapper) {
                        new_result.push(pr);
                    }
                } else if (filterInstock === '0') {
                    new_result.push(pr);
                }
                continue;
            }

            for (let i in pr['stock']) {
                let stock = pr['stock'][i];

                if(WarehouseStore.isDropshipping(i) && source === 'production') {
                    continue;
                }

                let result = ProductsStore.filterCheckStock(stock, filter);
                if (result) {
                    new_stock.push(stock)
                }

                if (source === "shipment") {
                    break;
                }
            }


            if (new_stock.length > 0) {
                pr['stock'] = new_stock;
                new_result.push(pr)
            }
        }

        return new_result;
    },

    isServicesProduct(pid) {
        const getProduct = this.getProduct(pid);

        return getProduct?.type_product === 4;
    },
    isBundleProduct(pid) {
        const getProduct = this.getProduct(pid);

        return getProduct?.type_product === 3;
    },

    filterCheckStock(stock, _filter) {
        const filter = _filter || this.state._filter,
              margin = calcMargin(stock['net_price'], stock['price'], stock['sale_price']);
        let result = true;

        if (filter['instock'] !== "0") {
            if (filter['instock'] === "1") {
                result = parseFloat(stock['instock']) > 0 || WarehouseStore.isDropshipping(stock['mid'] || stock['marketplace_id']) || this.isServicesProduct(stock.product_id);
            } else {
                result = parseFloat(stock['instock']) === 0 && !WarehouseStore.isDropshipping(stock['mid'] || stock['marketplace_id']) && !this.isServicesProduct(stock.product_id);
            }
        }

        if (filter['withoutprice']) {
            result = result && (stock['net_price'] === 0 || stock['price'] === 0) ;
        }

        if (filter['price_from'] !== "") {
            result = result && (parseFloat(stock['price']) >= parseFloat(filter['price_from'])) ;
        }

        if (filter['price_to'] !== "") {
            result = result && (parseFloat(stock['price']) <= parseFloat(filter['price_to'])) ;
        }

        if (filter['margin_from'] !== "") {
            result = result && (parseFloat(margin) >= parseFloat(filter['margin_from'])) ;
        }

        if (filter['margin_to'] !== "") {
            result = result && (parseFloat(margin) <= parseFloat(filter['margin_to'])) ;
        }

        const mid = stock['marketplace_id'] ? stock['marketplace_id'] : stock['mid'];
        if (filter['mid']['value'] !== 0) {
            result = result && (parseInt(filter['mid']['value']) === parseInt(mid)) ;
        }

        if (filter['shipment']['value'] !== 0) {
            result = result && (parseInt(filter['shipment']['value']) === parseInt(stock['current_sid'])) ;
        }

        if(filter['reserv']['value'] !== 0) {
            if(filter['reserv']['value'] === 'reserv') {
                result = result && stock.quantity !== stock.instock
            } else if(filter['reserv']['value'] === 'notreserv') {
                result = result && stock.quantity === stock.instock
            }
        }

        if(filter['minStock'] && filter['minStock']['value'] !== 0) {
            const filterMinStock = filter['minStock']['value']
                if(filterMinStock === 'required') {
                    result = result && UserStore.isMinimalStock({mid: stock?.marketplace_id, pid: stock?.product_id, instock: stock?.instock})
                } else if(filterMinStock === 'no-required') {
                    result = result && !UserStore.isMinimalStock({mid: stock?.marketplace_id, pid: stock?.product_id, instock: stock?.instock})
                }
        }

        return result
    },

    applyVariableFilter(parent_id, filter) {
        let var_product = this.getProduct(parent_id);
        let result = true;
        for (let i in var_product['variable']) {
            let variable = JSON.parse(JSON.stringify(var_product['variable'][i]));
            variable['brand'] = var_product['brand'];
            variable['asin'] = var_product['asin'];
            result = this.baseFilter(variable, filter);
            if (result) {
                return true;
            }

        }
        return result;
    },

    filterVariableAttr(attr_desc, _filter) {
      const filter = _filter || this.state._filter

        if (Object.keys(filter['attr']).length === 0) {
            return true;
        }

        return Object.entries(filter['attr']).every(([key, filterItem]) => {
            const { value } = filterItem;
            const parse = parseJSON(attr_desc);
            return value === 0 || (parse && parse[key] === value);
        });
    },

    filterAdditionalFields(af) {
        const filter = this.state._filter

        if (filter['addFields'] && filter['addFields']['value'] === 0) {
            return true;
        }

        const parseAf = parseJSON(af)

        return !!parseAf[filter['addFields']['value']]
    },

    baseFilter(product, _filter) {
        const filter = _filter || this.state._filter;
        // console.log(_filter)
        let sku = [];
        for (let i in product['stock']) {
            let stock = product['stock'][i];
            sku.push(stock['sku'])
        }

        product['sku'] = sku.join(" ");
        let result = true
        if(filter['search']) {
            result = filterSearch(filter['search'], ['id', 'name', 'brand', 'asin', 'asin2', 'sku', 'af', 'attr_dict'], product);
        }

        if (result) {

            if(filter["mid"].value !== 0) {
                if(product['stock'][filter["mid"].value]) {
                    result = result && ProductsStore.filterCheckStock(product['stock'][filter["mid"].value], _filter)
                } else {
                    result = false
                }
            }

            if (filter['withoutbarcode']) {
                result = result && product['asin'] === "";
            }

            let ccat = filter['category']['value'];
            if (ccat !== 0) {
                let childs_category = this.state.categoryChild[ccat]
                if (typeof(childs_category) === "undefined") {
                    childs_category = [ccat];
                }
                result = result && (childs_category.indexOf(product['category_id']) > -1);
            }

            if (filter['brand']['value'] !== 0) {
                result = result && (parseInt(filter['brand']['value']) === product['brand_id']);
            }

            if(filter['integration']["value"] !== 0) {
                if(filter['integration']['value'] !== 'no-integration') {
                    result = result && product?.mp.some(item => item === filter['integration']['value'])
                } else {
                    result = result && !product?.mp?.length
                }
            }

            if(filter['reserv']['value'] !== 0) {
                const stockKey = Object.keys(product.stock)

                if(filter['reserv']['value'] === 'reserv') {
                    result = result && stockKey.some(item => product.stock[item].quantity !== product.stock[item].instock)
                } else if(filter['reserv']['value'] === 'notreserv') {
                    result = result && stockKey.some(item => product.stock[item].quantity === product.stock[item].instock)
                }
            }

            if(filter['minStock'] && filter['minStock']["value"] !== 0) {
                const stock = Array.isArray(product.stock) ? product.stock : Object.values(product.stock)
                const filterMinStock = filter['minStock']['value']
                if(filterMinStock === 'required') {
                    result = result && stock.some(item => UserStore.isMinimalStock({mid: item?.marketplace_id || item.mid, pid: product['id'], instock: item['instock']}))
                } else if(filterMinStock === 'no-required') {
                    result = result && stock.some(item => !UserStore.isMinimalStock({mid: item?.marketplace_id || item.mid, pid: product['id'], instock: item['instock']}))
                }
            }
            result = result && this.filterAdditionalFields(product.af)

            result = result && this.filterVariableAttr(product['attr_desc'], _filter)

            if(filter['expirationDate'] && filter['expirationDate']['value']) {
                const expiratedDate = this.theExpirationDateByPid(product.id)
                result = result && expiratedDate === filter['expirationDate']['value']

            }

            if(filter['type_product'] && filter['type_product']['value']) {
                const getProduct = this.getProduct(product.id)
                if(getProduct) {
                    result = result && getProduct.type_product === parseInt(filter['type_product']['value'])
                } else {
                    result = result && false
                }
            }
        }
        return result;
    },

    applyFilter(product) {
        let result = true;
        if (product['parent_id'] &&  product['parent_id'] !== product['id']) {
            return false;
        }
        if (product['parent_id']) {
            result = this.applyVariableFilter(product['parent_id']);
        } else {
            result = this.baseFilter(product);
        }
        return result;
    },

    applyFilterSelect(product, filter) {
        let result = true;

        if (product['parent_id']) {
            result = this.applyVariableFilter(product['parent_id'], filter);
        } else {
            result = this.baseFilter(product, filter);
        }

        return result;
    },

    clearFilter(withoutTab) {
      const filter = JSON.parse(JSON.stringify(CLEAR_FILTER()))

        if(withoutTab) {
            filter.mid = this.state._filter.mid
        }

      this.setState({
          _filter: {
              ...filter,
              attr: {},
          }
      })
        LightStore.clearSelectGroup()
    },

    filterClearField({globalName, filterName}) {
      const filter = this.get('_filter')
        if(globalName) {
            this.setState({
                _filter: {
                    ...filter,
                    [globalName]: {
                        ...filter[globalName],
                        [filterName]: {value: 0}
                    }
                }
            })
        } else {
            this.setState({
                _filter: {
                    ...filter,
                    [filterName]: CLEAR_FILTER()[filterName]
                }
            })
        }

        LightStore.clearSelectGroup()
    },

    applySort(products) {
        const sort = this.getFilter('sort')
        return products.sort((a, b) => {
            const mapKey = {
                'products-name-date': 'id',
                'available': 'quantity',
                'sales': 'stock',
                'product-info-price': 'price',
                'products-netcost': 'net_price'
            }

            let variableProductsA
            let variableProductsB

            if(a.parent_id) variableProductsA = this.getProduct(a.parent_id)
            if(b.parent_id) variableProductsB = this.getProduct(b.parent_id)


            const calcSum = (item, sortKey, variable, pid) => item.reduce((acc, currentValue) => {
                if (sortKey === 'margin') {
                    const calc = calcMargin(currentValue[variable ? 'netprice' : 'net_price'], currentValue['price'], currentValue['sale_price'])
                    return acc + (calc === '-' ? 0 : calc)
                } else if (sortKey === 'sales') {
                    const sales =  SalesStore.getSaleReportsByProduct(pid, currentValue?.mid || currentValue.marketplace_id)
                    return acc + sales.items_count
                } else if(sortKey !== 'products-name-date'){
                    return acc + currentValue[(mapKey[sortKey] === 'net_price') && variable ? "netprice" : mapKey[sortKey]] || 0
                }
                return 0
            },0)

            const calcVariableSum = (item, sortKey) => item.reduce((acc, currentValue) => {
                const resultCalc = calcSum(Object.values(currentValue.stock), sortKey, true, currentValue.id)
                if(sortKey === 'available' || sortKey === 'sales') {
                    return acc + resultCalc
                }

                return acc + resultCalc / item.length
            },0)

            for (const sortKey in sort) {
                const direction = sort[sortKey];
                let sumA
                let sumB
                if(sortKey === 'products-name-date') {
                    sumA = a.id
                    sumB = b.id
                } else if (variableProductsA && !variableProductsB) {
                    sumA = calcVariableSum(variableProductsA.variable, sortKey)
                    sumB = calcSum(b.stock, sortKey, false, b.id)
                } else if (variableProductsB && !variableProductsA) {
                    sumA = calcSum(a.stock, sortKey,false, a.id)
                    sumB = calcVariableSum(variableProductsB.variable, sortKey)
                } else if (variableProductsB && variableProductsA) {
                    sumA = calcVariableSum(variableProductsA.variable, sortKey)
                    sumB = calcVariableSum(variableProductsB.variable, sortKey)
                } else {
                    sumA = calcSum(a.stock, sortKey,false, a.id)
                    sumB = calcSum(b.stock, sortKey,false, b.id)
                }


                if (direction === 1 ) {
                    if (sumA < sumB) return 1;
                    if (sumA > sumB) return -1;
                } else if (direction === 0) {
                    if (sumA > sumB) return 1;
                    if (sumA < sumB) return -1;
                }
            }
            return 0;
        });
    },

    sortingFunction(a, b) {
            const names = {
                    'products-name': 'name',
                    'available': 'stock',
                    'sales': 'stock',
                    'margin': 'stock',
                    'products-price': 'stock',
                    'products-netcost': 'stock'
            };

            let columnt_name = names[this.state.sort_column];

            let aa = a[columnt_name];
            let bb = b[columnt_name];

            if (this.state.sort_column === "margin") {
                let qa = 0;
                for (let i in aa) {
                    let stock = aa[i];
                    qa = calcMargin(stock['net_price'], stock['price'], stock['sale_price'])
                    break;
                }

                aa = parseFloat(qa);

                let qb = 0;
                for (let i in bb) {
                    let stock = bb[i];
                    qb = calcMargin(stock['net_price'], stock['price'], stock['sale_price'])
                    break;
                }

                bb = parseFloat(qb);
            }
            else if (this.state.sort_column === "products-price") {
                let qa = 0;
                for (let i in aa) {
                    qa = aa[i]['price'] || 0;
                    break;
                }

                aa = parseFloat(qa);

                let qb = 0;
                for (let i in bb) {
                    qb = bb[i]['price'] || 0;
                    break;
                }

                bb = parseFloat(qb);
            }
            else if (this.state.sort_column === "products-netcost") {
                let qa = 0;
                for (let i in aa) {
                    qa = aa[i]['net_price'] || 0;
                    break;
                }

                aa = parseFloat(qa);

                let qb = 0;
                for (let i in bb) {
                    qb = bb[i]['net_price'] || 0;
                    break;
                }

                bb = parseFloat(qb);
            }  else if (this.state.sort_column === "available") {
                let qa = 0;
                for (let i in aa) {
                    qa += aa[i]['quantity'];
                }

                aa = parseFloat(qa);

                let qb = 0;
                for (let i in bb) {
                    qb += bb[i]['quantity'];
                }

                bb = parseFloat(qb);
            } else if (this.state.sort_column === "sales") {
                let sa = 0, sb = 0;

                for (let i in aa) {
                     const report_a = SalesStore.getSaleReportsByProduct(a['id'], i);
                     sa += report_a['items_count'];
                }

                aa = parseInt(sa);



                for (let i in bb) {
                    const report_b = SalesStore.getSaleReportsByProduct(b['id'], i);
                    sb += report_b['items_count'];
                }

                bb = parseInt(sb);
            }

            const way = this.state.sort_way;

            if (typeof(aa) == "string") {
                var textA = aa.toUpperCase();
                var textB = bb.toUpperCase();
                return (textA < textB) ? (-1 * way) : (textA > textB) ? (1 * way) : 0;
            } else if (typeof(aa) == "number") {
                return (aa < bb) ? (-1 * way) : (aa > bb) ? (1 * way) : 0;
            } else {
                return 0;
            }
    },

    setLoader(status) {
        this.setState({
            isLoaded: status
        })
    },

    closeModal() {
        const state = {
            openModal: false
        };

        this.setState(state);
        AppStore.set(state);
    },

    onSetProductFromScanner(barcode) {
        const select_product = this.getProductFromScanner(barcode);

        this.setState({
            'fromScanner': select_product
        })

        Actions.setProductFromScanner.completed(barcode);
    },

    getProductFromScanner(barcode) {
        const product = this.findProductByBarcode(barcode);

        if (!product) {
            return;
        }

        const pid = product['id'],
              name = product['name'],
              only_one = Object.keys(product['stock']).length === 1;


        let mid = 0, _mid = 0;
        for (let s in product['stock']) {
            let stock = product['stock'][s];

            if (only_one) {
                mid = stock['marketplace_id'];
                break;
            } else {
                _mid = stock['marketplace_id'];

                if (stock['instock'] > 0) {
                    mid = stock['mid'];
                    break;
                }
            }
        }

        if (mid === 0 || typeof(mid) === "undefined") {
            mid = _mid;
        }

        return {"select_product": {'value': this.getIdent(pid, mid), 'label': name }}
    },

    findProductByBarcode(barcode) {
        const products = this.getList();

        let info;
        for (let i in products) {
            const product = products[i];

            if ((product['asin'] === String(barcode) && String(barcode).length > 2) || parseInt(barcode) === parseInt(product['id'])) {
                info = product;
                break;
            }
        }

        return info;
    },

    onOpenProductModal(barcode) {
        if (this.state.openModal) {
            return;
        }

        const info = this.findProductByBarcode(barcode),
              _type = info ? "info" : "add";

        console.log("open product modal type "+_type+" asin  barcode: "+barcode)
        this.setState({
            barcode: barcode,
            openModal: _type,
            modalInfo: info
        });

        AppStore.set({openModal: true});
    },

    onOpenProductModalCompleted(data) {

    },

    onOpenProductModalFailed(res) {

    },

    onDeleteProduct(pid, mid, group) {
        if (this.startDeleted) return;
        console.log("start delete product...", pid, mid)

        this.startDeleted = true;

        let data = {};
        if (typeof(pid) === "object") {
            pid = pid.toString();
        } else if (pid.toString().indexOf("_") > -1) {
            let _d = pid.toString().split("_");
            pid = _d[0];
            mid = _d[1];
        }
        if (group) {
            data = {"group": group}
        } else {
            data = {"pid": pid}
        }

        if (mid) {
            data['mid'] = mid;
        }

        const params = {
          method: "DELETE",
          url: this._prefix,
          data: data
      };

        const joinIdent = pid && mid ? [`${pid}_${mid}`] : pid?.split(',')

        return yajax(params).then(
          (res) => Actions.deleteProduct.completed(res, joinIdent), Actions.deleteProduct.failed
        );
    },

    onDeleteProductCompleted(data, ident) {
        this.startDeleted = false;
        if (data['success']) {
            this.deleteInState(ident)

            LightStore.clearSelectGroup();
        }
        console.log("result delete product  : ", data)
    },
    onDeleteProductFailed(){
        this.startDeleted = false;
        console.log("failed deleteeeproducts")
    },


    deleteInState(idents) {
      const list = {...this.state.loadListDict}
      const dict = {...this.state.dictProduct}
      const variableDict = {...this.state.variableDictIds}

      idents.forEach(ident => {
        const splitIdent = ident.split('_')
        const pid = splitIdent[0]
        const mid = splitIdent[1]

        const product = dict[pid]

        if(product) {
            if(product.parent_id) {
                const parentList = [...list[product.parent_id]]
                list[product.parent_id] = parentList.reduce((acc, cur) => {
                    if(parseFloat(cur.id) === parseFloat(pid)) {
                        if(cur.stock[mid]) {
                            delete cur.stock[mid]
                        }
                        if(!!Object.keys(cur.stock).length) {
                            acc.push(cur)
                        } else {
                            variableDict[product.parent_id] = variableDict[product.parent_id].filter(item => item !== cur.id)
                        }
                    } else {
                        acc.push(cur)
                    }

                    return acc
                },[])
            } else {
                const singleProduct = {...list[pid]}

                if(singleProduct.stock[mid]) {
                    delete singleProduct.stock[mid]
                }

                list[pid] = singleProduct
            }

            const productByDict = {...dict[pid]}
            if(productByDict.stock[mid]) {
                delete productByDict.stock[mid]
            }
            dict[pid] = productByDict
        }
      })


      this.setState({
        loadListDict: list,
        dictProduct: dict,
        variableDictIds: variableDict,
      })
      this.startDeleted = false;
    },



    getPrintTabLink(pids, sid, count) {
        let uid = User.get("id");
        let rid = User.get("realId");

        if (sid) {
            return `/bprint/price_tag?uid=${uid}&rid=${rid}&sid=${sid}${count && parseInt(`${count}`) ? `&count=${count}` : ''}`;
        } else {
            return `/bprint/price_tag?uid=${uid}&rid=${rid}&pids=${pids.join(",")}${count && parseInt(`${count}`) ? `&count=${count}` : ''}`;
        }
    },

    getPrepareFilters() {
        let prepare_filter = {};



        for (let i in this.state._filter) {
            let value = this.state._filter[i];

            if (typeof(value?.value) !== "undefined" && i !== 'mid') {
                value = value['value'];
            }

            if(i === 'mid') {
                if(User.isAdmin() || User.isMainUser()) {
                    value = value['value']
                } else {
                    const permissionUser = UserStore.getPermissionWarehouse()
                    if(permissionUser && Array.isArray(permissionUser) &&permissionUser.includes(0)) {
                        value = value['value']
                    } else {
                        if(parseInt(value['value'])) {
                            value = value['value']
                        } else {
                            value = permissionUser[0]
                        }
                    }
                }
            }
            // if(!Array.isArray(Object.keys(i)) && i) {
            //     prepare_filter[i] = value;
            // }

            if (i && !Array.isArray(i) && value && !Array.isArray(value) && typeof value !== 'object') {
                prepare_filter[i] = value;
            }
        }

        return prepare_filter;
    },

    letPrepareFilters(filters) {
        let prepare_filter = {};
        for (let i in filters) {
            let value = filters[i];
            if (typeof(value?.value) !== "undefined") {
                value = value['value'];
            }
            if(i && typeof value !== 'object') {
                prepare_filter[i] = value;
            }
        }

        return prepare_filter;
    },

    getExportCsv(pids) {
        // let uid = User.get("id");
        let prepare_filter = this.getPrepareFilters()
        prepare_filter['token'] = User.getToken();
        if(pids && Array.isArray(pids)) {
            return `${this._prefix_remote_csv}get_csv_products?pid_mid=${pids.join(',')}&token=${User.getToken()}`
        }
        let url = new URLSearchParams(prepare_filter).toString()
        return `${this._prefix_remote_csv}get_csv_products?` + url;
    },

    onPrintTags(pids) {
        if (this.startPrint) return;
        console.log("start print tags product...", pids)

        this.startPrint = true;

        let data = {"pids": pids.join(",")};

        // let headers = {
        //     "ontent-Type'": "application/pdf"
        // }

        // if (!User.isGuest()) {
        //     headers["X-Auth-Token"] = encodeURIComponent(User.getToken())
        // }

        const params = {
          method: "POST",
          url: "/bprint/price_tag",
          // headers: headers,
          data: data
      };

        return yajax(params).then(
            Actions.printTags.completed, Actions.printTags.failed
        );
    },


    getRemoteForSelectProducts() {
        const remoteProducts = this.get("remoteProducts");
        if (remoteProducts === null) {
            return [];
        }
        return remoteProducts.map(item=> ({id: item.remote_id, name: `ID:${item.remote_id}, ${item.name}`}))
    },

    onRemoteProductByPid(iid, pid) {
      const data = {}
      if(iid) data.iid = iid
      if(pid) data.pid = pid

        yajax.get(this._prefix_remote + 'link_products', data).then(Actions.remoteProductByPid.completed)
    },

    onRemoteProductByPidCompleted(data) {
      return data
    },

    onRemoteProductByPidFailed() {
      console.log('get remote product by pid failed')
    },


    onChangeSelectRemote (iid) {
      this.setState({
          remote_iid: iid
      })
        if(iid !== this.state.remote_iid || !this.remoteProducts?.length) {
            Actions.remoteProducts()
        }
    },

    onRemoteProducts() {
      if(this.state.remote_iid) {
          yajax.get(this._prefix_remote + 'link_products', {iid: this.state.remote_iid}).then(
            Actions.remoteProducts.completed, Actions.remoteProducts.failed
          )
      }
    },

    onRemoteProductsCompleted(data) {
        if(data) {
            this.setState({
                remoteProducts: data
            })
        }

        return data
    },
    onRemoteProductsFailed(data) {
        console.log('failed binding from remote', data)
    },

    onPrintTagsCompleted(blob) {
         // // Create blob link to download
         //    const url = window.URL.createObjectURL(
         //      new Blob([blob]),
         //    );
         //    const link = document.createElement('a');
         //    link.href = url;
         //    link.setAttribute(
         //      'download',
         //      `price_tabs.pdf`,
         //    );

         //    // Append to html link element page
         //    document.body.appendChild(link);

         //    // Start download
         //    link.click();

         //    console.log("link ", link);
         //    // Clean up and remove the link
         //    link.parentNode.removeChild(link);
         //    this.startPrint = false;
    },

    onPrintTagsFailed(blob) {
        let data = blob.response;

        var atobData = atob(data);
        var num = new Array(atobData.length);
        for (var i = 0; i < atobData.length; i++) {
            num[i] = atobData.charCodeAt(i);
        }
        var pdfData = new Uint8Array(num);

        //var blob = new Blob([pdfData], { type: 'text/plain' });
        blob = new Blob([pdfData], { type: 'application/pdf;base64' });
        var url = URL.createObjectURL(blob);

        //Open a new tab.
        // if (httpreadtype.length > 0)
        //     window.open(url);
        // else {
            //Download the file.
            var a = document.createElement('a');
            a.href = url;
            a.download = 'File.pdf';
            a.click();
        // }

        // console.log("blob ", blob, da);
        // Create blob link to download
            // const url = window.URL.createObjectURL(
            //   new Blob([blob.response],{type: "application/pdf;charset=UTF-8"} ),
            // );
            // const link = document.createElement('a');
            // link.href = url;
            // link.target = "_blank";
            // // link.setAttribute(
            // //   `price_tabs.pdf`,
            // // );

            // // Append to html link element page
            // document.body.appendChild(link);

            // // Start download
            // link.click();

            // console.log("link ", link);
            // // Clean up and remove the link
            // link.parentNode.removeChild(link);
            // this.startPrint = false;
        this.startPrint = false;
    },

    onImportFromRemote(iid) {
        yajax.post(this._prefix_remote+"import_products", {"iid": iid}).then(
            Actions.importFromRemote.completed, Actions.importFromRemote.failed
        );
    },

    onImportFromRemoteCompleted(data) {
        console.log("complete load from remote ", data)
    },

    onImportFromRemoteFailed(res) {
        console.log("failed load from remote ", res)
    },

    onExportToRemote(iid) {
        yajax.put(this._prefix_remote+"import_products", {"iid": iid}).then(
            Actions.importFromRemote.completed, Actions.importFromRemote.failed
        );
    },

    onExportToRemoteCompleted(data) {
        console.log("complete export too remote ", data)
    },

    onExportToRemoteFailed(res) {
        console.log("failed export to remote ", res)
    },

    buildAttrList(attr_desc){
        let attrParse = JSON.parse(attr_desc)
        let getAttrValues = this.get('attrDictValues')
        if (!getAttrValues) {
            getAttrValues = {}
        }
        if (!attrParse) {
            attrParse = {};
        }

        const keys = Object.keys(attrParse)

        keys.forEach(key => {
            if (!Array.isArray(getAttrValues[key])) {
                getAttrValues[key] = [];
            }

            if (!getAttrValues[key].includes(attrParse[key])) {
                getAttrValues[key].push(attrParse[key]);
            }
        })
    },

    onLoadByPid(pids){
        if(this.loadingByPid) return false
        let url = this._load_url;
        this.loadingByPid = url

        yajax.get(url, {pids, t: Date.now()}).then(Actions.loadByPid.completed, Actions.loadByPid.failed);
    },

    onLoadByPidCompleted(data) {
        this.loadingByPid = false

        const dictProduct = {...this.state.dictProduct};
        const loadListDict = {...this.state.loadListDict};
        const variableDictIds = {...this.state.variableDictIds};
        const rates = User.getCurrencyRates();

        data.data.forEach(item => {
            item = DTO_products(item)

            if(Object.keys(item.stock).length === 0) return

            let skus;

            Object.keys(item.stock).forEach(stock => {
                item.stock[stock] = DTO_stock_products(item.stock[stock])
                const valuesByMid = item.stock[stock]

                const cid = 'currency_id' in valuesByMid ? parseInt(valuesByMid['currency_id']) : 0;


                if (cid !== 0 && cid !== parseInt(User.get("currency_id"))) {
                    let rate = rates[valuesByMid['currency_id']] || 1;
                    valuesByMid['currency_id'] = User.get("currency_id");
                    valuesByMid['raw_net_price'] = rate * valuesByMid['raw_net_price'];
                    valuesByMid['net_price'] = valuesByMid['raw_net_price'] + valuesByMid['delivery_fee'];
                }

                valuesByMid.mid = valuesByMid['marketplace_id'];
                valuesByMid.netprice = valuesByMid['net_price'];
                valuesByMid.currency_id = valuesByMid['currency_id'] || User.get("currency_id");
                valuesByMid.wid = valuesByMid['id'];

                skus = valuesByMid['sku'];
            })

            let product_name = item['name'];

            if (item['is_deleted'] && item['is_deleted'] === "1") {
                product_name += " ("+T('deleted')+")";
            }

            item.real_name = product_name;


            if (item['parent_id']) {
                let attr = this.buildAttr(JSON.parse(item['attr_desc']), true);
                product_name += ". "+attr.join(". ");
            }

            item.full_name = product_name;
            item.units = item['units'] || "units";
            item.skus = skus;
            item.name = product_name;

            dictProduct[item.id] = item

            if(item.parent_id && !!loadListDict[item.parent_id]) {
                loadListDict[item.parent_id] = loadListDict[item.parent_id].map(itemDict => {
                    if(itemDict.id === item.id) {
                        return item
                    }
                    return itemDict
                })
                if(!loadListDict[item.parent_id].find(itemDict => itemDict.id === item.id)) {
                    loadListDict[item.parent_id].push(item)
                }
                if(!variableDictIds[item.parent_id].includes(item.id)) {
                    variableDictIds[item.parent_id].push(item.id)
                }

            } else if(item.parent_id && !loadListDict[item.parent_id]) {
                loadListDict[item.parent_id] = [item]
                variableDictIds[item.parent_id] = [item.id]
            } else {
                loadListDict[item.id] = item
            }
        })


        this.setState({
            dictProduct,
            loadListDict,
            variableDictIds,
        })


    },
    onLoadByPidFailed() {
        this.loadingByPid = false
        console.log('load product for pids failed')
    },


    onLoadCompleted(data) {
        const rates = User.getCurrencyRates();

        const result = data.data.reduce((acc, cur) => {
            cur = DTO_products(cur)

            if(Object.keys(cur.stock).length === 0) return acc

            let skus;

            Object.keys(cur.stock).forEach(stock => {
                cur.stock[stock] = DTO_stock_products(cur.stock[stock])
                const valuesByMid = cur.stock[stock]

                const cid = 'currency_id' in valuesByMid ? parseInt(valuesByMid['currency_id']) : 0;

                if (cid !== 0 && cid !== parseInt(User.get("currency_id"))) {
                    let rate = rates[valuesByMid['currency_id']] || 1;
                    valuesByMid['currency_id'] = User.get("currency_id");
                    valuesByMid['raw_net_price'] = rate * valuesByMid['raw_net_price'];
                    valuesByMid['net_price'] = valuesByMid['raw_net_price'] + valuesByMid['delivery_fee'];
                }

                valuesByMid.mid = valuesByMid['marketplace_id'];
                valuesByMid.netprice = valuesByMid['net_price'];
                valuesByMid.currency_id = valuesByMid['currency_id'] || User.get("currency_id");
                valuesByMid.wid = valuesByMid['id'];

                skus = valuesByMid['sku'];
            })

            let product_name = cur['name'];

            if (cur['is_deleted'] && cur['is_deleted'] === "1") {
                product_name += " ("+T('deleted')+")";
            }

            cur.real_name = product_name;

            if (cur['parent_id']) {
                let attr = this.buildAttr(JSON.parse(cur['attr_desc']), true);
                product_name += ". "+attr.join(". ");
            }

            cur.full_name = product_name
            cur.units = cur['units'] || "units";
            cur.skus = skus;
            cur.name = product_name;

            acc.loadDict[cur.id] = cur


            if(cur.parent_id && !!acc.loadListDict[cur.parent_id]) {
                acc.loadListDict[cur.parent_id].push(cur);
                acc.variableDictIds[cur.parent_id].push(cur.id)
            } else if(cur.parent_id && !acc.loadListDict[cur.parent_id]) {
                acc.loadListDict[cur.parent_id] = [cur]
                acc.variableDictIds[cur.parent_id] = [cur.id]
            } else {
                acc.loadListDict[cur.id] = cur
            }

            return acc
        }, {
            loadDict: {},
            loadListDict: {},
            variableDictIds:{}
        })

        this.setState({
            dictProduct: result.loadDict,
            loadListDict: result.loadListDict,
            variableDictIds: result.variableDictIds
        })

        this.checkOnBoarding();

        this.loading = false;
    },

    onLoadFailed(res) {
        //res.status == 504
        this.loading = false;
        setTimeout(function(){
            this.setLoader(false);
        }.bind(this), 5000);

    },


    newClearMid(product) {
        const filter = this.get('_filter')

        const copyProduct = JSON.parse(JSON.stringify(product))

        const stock = Object.values(copyProduct.stock)

        let result = true

        if(filter.mid.value) {
            if(copyProduct.stock[filter.mid.value]) {
                copyProduct.stock = {[filter.mid.value]: copyProduct.stock[filter.mid.value]};
            } else {
                result = result && false
            }
        }
        if (filter['instock'] !== "0") {
            stock.forEach(item => {
                if(filter['instock'] === '1' && parseFloat(item.instock) === 0 && !WarehouseStore.isDropshipping(item.marketplace_id) && !ProductsStore.isServicesProduct(item.product_id)) {
                    delete copyProduct.stock[item.marketplace_id]
                } else if(filter['instock'] === '2' && (parseFloat(item.instock) > 0 || WarehouseStore.isDropshipping(item.marketplace_id) || ProductsStore.isServicesProduct(item.product_id))) {
                    delete copyProduct.stock[item.marketplace_id]
                }
            })
        }

        if(filter['reserv']['value']) {
            const reservFilter = filter['reserv']['value']

            stock.forEach(item => {
                if(reservFilter === 'reserv' && item.quantity === item.instock) {
                    delete copyProduct.stock[item.marketplace_id]
                } else if(reservFilter === 'notreserv' && item.quantity !== item.instock) {
                    delete copyProduct.stock[item.marketplace_id]
                }
            })
        }

        if(filter['minStock']["value"]) {
            const filterMinStock = filter['minStock']['value']

            stock.forEach(item => {
                const isRequired = UserStore.isMinimalStock({mid: item?.marketplace_id, pid: product['id'], instock: item['instock']})
                if(!isRequired && filterMinStock === 'required') {
                    delete copyProduct.stock[item?.marketplace_id]
                } else if(isRequired && filterMinStock === 'no-required') {
                    delete copyProduct.stock[item?.marketplace_id]
                }
            })
        }

        if (filter['shipment']['value']) {
            stock.forEach(item => {
                const isShipment = parseInt(filter['shipment']['value']) === parseInt(item['current_sid'])
                if(!isShipment) {
                    delete copyProduct.stock[item?.marketplace_id]
                }
            })
        }

        if (filter['price_from']) {
            stock.forEach(item => {
                const isPrice = parseFloat(item['price']) >= parseFloat(filter['price_from'])
                if(!isPrice) {
                    delete copyProduct.stock[item?.marketplace_id]
                }
            })
        }

        if (filter['price_to']) {
            stock.forEach(item => {
                const isPrice = parseFloat(item['price']) <= parseFloat(filter['price_to'])
                if(!isPrice) {
                    delete copyProduct.stock[item?.marketplace_id]
                }
            })
        }

        if (filter['margin_from']) {
            stock.forEach(item => {
                const margin = calcMargin(
                  item["net_price"],
                  item["price"],
                  item["sale_price"]
                );
                const isMargin = parseFloat(margin) >= parseFloat(filter['margin_from'])
                if(!isMargin) {
                    delete copyProduct.stock[item?.marketplace_id]
                }
            })
        }

        if (filter['margin_from']) {
            stock.forEach(item => {
                const margin = calcMargin(
                  item["net_price"],
                  item["price"],
                  item["sale_price"]
                );
                const isMargin = parseFloat(margin) <= parseFloat(filter['margin_to'])
                if(!isMargin) {
                    delete copyProduct.stock[item?.marketplace_id]
                }
            })
        }

        if (filter['withoutprice']) {
            stock.forEach(item => {
                const isWithoutPrice = stock['net_price'] === 0 || stock['price'] === 0
                if(!isWithoutPrice) {
                    delete copyProduct.stock[item?.marketplace_id]
                }
            })
        }


        if(!Object.keys(copyProduct.stock).length) {
            result = result && false
        }

        if(result) {
            return copyProduct
        }

        return false
    },

    newApplyFilter(product) {
        const filter = this.get('_filter')

        let sku = [];
        for (let i in product['stock']) {
            let stock = product['stock'][i];
            sku.push(stock['sku'])
        }

        product['sku'] = sku.join(" ");
        let result = true
        if(filter['search']) {
            result = filterSearch(filter['search'], ['id', 'name', 'brand', 'asin', 'asin2', 'sku', 'af', 'attr_dict'], product);
        }

        if (filter['withoutbarcode']) {
            result = result && product['asin'] === "";
        }

        let categoryFilter = filter['category']['value'];
        if (categoryFilter !== 0) {
            let childs_category = ProductsStore.get("categoryChild")[categoryFilter]
            if (typeof(childs_category) === "undefined") {
                childs_category = [categoryFilter];
            }
            result = result && (childs_category.indexOf(product['category_id']) > -1);
        }

        if (filter['brand']['value'] !== 0) {
            result = result && (parseInt(filter['brand']['value']) === product['brand_id']);
        }

        if(filter['integration']["value"] !== 0) {
            if(filter['integration']['value'] !== 'no-integration') {
                result = result && product?.mp.some(item => item === filter['integration']['value'])
            } else {
                result = result && !product?.mp?.length
            }
        }

        if(filter['type_product']['value']) {
            result = result && product.type_product === parseInt(filter['type_product']['value'])
        }

        if(filter['expirationDate'] && filter['expirationDate']['value']) {
            const expiratedDate = ProductsStore.theExpirationDateByPid(product.id)
            result = result && expiratedDate === filter['expirationDate']['value']
        }

        if (filter['addFields']['value']) {
            const parseAf = parseJSON(product.af)
            result = result && !!parseAf[filter['addFields']['value']]
        }

        if(Object.keys(filter['attr']).length > 0) {
            result = result && Object.entries(filter['attr']).every(([key, filterItem]) => {
                const { value } = filterItem;
                const parse = parseJSON(product['attr_desc']);
                return value === 0 || (parse && parse[key] === value);
            });
        }


        if(filter['reserv']['value']) {
            const stockKey = Object.keys(product.stock)

            if(filter['reserv']['value'] === 'reserv') {
                result = result && stockKey.some(item => product.stock[item].quantity !== product.stock[item].instock)
            } else if(filter['reserv']['value'] === 'notreserv') {
                result = result && stockKey.some(item => product.stock[item].quantity === product.stock[item].instock)
            }
        }

        if(filter['minStock']["value"]) {
            const stock = Object.values(product.stock)
            const filterMinStock = filter['minStock']['value']
            if(filterMinStock === 'required') {
                result = result && stock.some(item => UserStore.isMinimalStock({mid: item?.marketplace_id || item.mid, pid: product['id'], instock: item['instock']}))
            } else if(filterMinStock === 'no-required') {
                result = result && stock.some(item => !UserStore.isMinimalStock({mid: item?.marketplace_id || item.mid, pid: product['id'], instock: item['instock']}))
            }
        }

        return result
    },


    getProductNew(withStatistics) {
        const data = this.get('loadListDict');

        const statistics = {
            available: 0,
            count: 0,
            count_not_available: 0,
            quantity: 0,
            reserv: 0,
            total_cost: 0,
            total_profit: 0,
        }
        const filterInstock = this.getFilter('instock')

        const calcStatistics = (product, stock) => {
            if(product.type_product !== 4) {
                statistics.quantity += stock.quantity
                statistics.instock += stock.instock
            }

            const isDropWarehouse = WarehouseStore.isDropshipping(stock?.mid || stock.marketplace_id)

            if (stock.instock || isDropWarehouse) {
                statistics.available += 1
            } else if (!isDropWarehouse) {
                statistics.count_not_available += 1
            }

            if(product.type_product !== 4) {
                if (filterInstock !== '2' || (filterInstock === '2' && stock.quantity && !stock.instock)) {
                    if (stock.net_price || stock.netprice) {
                        statistics.total_cost += (stock.netprice || stock.net_price) * stock.quantity;
                    }
                    if (stock.price) {
                        statistics.total_profit += stock.price * stock.quantity;
                    }
                }
            }

            statistics.reserv += (stock.quantity - stock.instock)
        }

        const result = Object.values(data || {})?.reduce((acc, cur) => {
            if(Array.isArray(cur)) {
                const variableWithFilter = cur.reduce((filterAcc,filterCur) => {
                    if(Object.keys(filterCur).length === 0) return filterAcc
                    const newItem = this.newClearMid(filterCur)
                    if(!newItem) return filterAcc

                    const itemWithFilter = this.newApplyFilter(newItem)
                    if(!itemWithFilter) return filterAcc

                    Object.values(newItem.stock).forEach(stock => {
                        if(newItem.type_product === 3) return
                        calcStatistics(newItem, stock)
                        statistics.count += 1
                    })

                    filterAcc.push(newItem)
                    return filterAcc
                },[])

                if(!variableWithFilter.length) return acc

                acc.push(variableWithFilter)

            } else {
                if(Object.keys(cur.stock).length === 0) return acc
                const curValueWithFilter = this.newClearMid(cur)
                if(!curValueWithFilter) return acc

                const isFilter = this.newApplyFilter(cur)

                if(!isFilter) return acc

                Object.values(curValueWithFilter.stock).forEach(stock => {
                    if(curValueWithFilter.type_product === 3) return
                    calcStatistics(curValueWithFilter, stock)
                    statistics.count += 1
                })

                acc.push(curValueWithFilter)
            }

            return acc;
        }, [])

        if(withStatistics) {
            return {rows: result.reverse(), statistics}
        } else {
            return result.reverse()
        }
    },

    onLoadStock() {
        this.setState({
            isLoadedStock: true
        })
        return yajax.get(this._prefix+"stock").then(Actions.load.completed, Actions.load.failed);

    },

    onLoadStockCompleted(data) {
        console.log("completed loading stock products ", data['data']);

        let products = this.state.products;
        const stock = data['data'];

        for (let i in products){
            let product = products[i]
            const mid = products['market_id'];
            const pid = products['id'];
            product['stock'] = stock[mid][pid];
        }

        this.setState({
            stock: data['data'],
            rows: products,
            isLoadedStock: false
        })
    },

    onLoadStockFailed() {
        console.log("failed load products");
        this.setState({
            isLoadedStock: false
        })
    },

    onSetPrice(name, wid, amount, pid, mid) {
        console.log("start set price for ")
        yajax.post(this._prefix+"price", {"name": name, "wid": wid, "mid": mid, "amount": amount, "pid": pid}).then(
            Actions.setPrice.completed, Actions.setPrice.failed
        );
    },

    onSetPriceCompleted(data) {

    },

    onSetPriceFailed(){

    },

    onSetGroupPrice(amount, type_changes) {
        let d = LightStore.get("group_change"), group = [];

        for (let i in d) {
            let j = i.split(",")
            group.push(j[0]+"_"+j[1]);
        }

        console.log("start set group price for ", group)
        let data = {
            'group': group,
            'amount': amount,
            'wid': 1
        }
        if (type_changes) {
            data = { ...data, 'type_changes': type_changes };
        }
        yajax.post(this._prefix+"price", data).then(
            Actions.setGroupPrice.completed, Actions.setGroupPrice.failed
        );
    },

    onSetGroupPriceCompleted(data) {
         Actions.load(true);
         LightActions.setProductGroup("all", false);
    },

    onSetGroupPriceFailed(){

    },


    onLoadExcel(file, mid, template) {
        const data = new FormData();
        data.append('file', file);
        data.append('template', template);
        data.append('mid', mid);


        let headers = {
            "X-Source-Type": "desktop"
        }

        if (!User.isGuest()) {
            headers["X-Auth-Token"] = encodeURIComponent(User.getToken())
        }

        console.log("start load files, ", data)
        return yajax.post(this._prefix_files+"files", data, {
            raw: true,
            headers: headers
        }).then(
            Actions.loadExcel.completed, Actions.loadExcel.failed
        );
    },
    onLoadExcelCompleted(data) {
        setTimeout(() => {Actions.loadAttr(true); Actions.loadCategory();Actions.load()}, 5000)
    },
    onLoadExcelFailed(){

    },
    onLoadXml(file, url, mid) {
        const data = new FormData();
        let link;
        if (url) {
            data.append('url', url);
            link = this._prefix_files + "import_xml/url";
        } else {
            data.append('file', file);
            link = this._prefix_files + "import_xml/file";
        }
        data.append('mid', mid);

        let headers = {
            "X-Source-Type": "desktop"
        }

        if (!User.isGuest()) {
            headers["X-Auth-Token"] = encodeURIComponent(User.getToken())
        }

        console.log("start load xml, ", [...data.entries()]);
        return yajax.post(link, data, {
            raw: true,
            headers: headers
        }).then(
            Actions.loadXml.completed, Actions.loadXml.failed
        );
    },
    onLoadXmlCompleted(data) {
        setTimeout(() => {Actions.loadAttr(true); Actions.loadCategory();}, 5000)
    },
    onLoadXmlFailed(){

    },

    onUploadImages(files, pid, type) {
        const data = new FormData();

        // for (let i in files) {
        data.append('files', files);
        // }
        data.append('pid', pid);

        let headers = {
            "X-Source-Type": "desktop"
        }

        if (!User.isGuest()) {
            headers["X-Auth-Token"] = encodeURIComponent(User.getToken())
        }

        return yajax.post(this._prefix_img+"load", data, {
            raw: true,
            headers: headers
        }).then(
          (res) => Actions.uploadImages.completed(res,pid), Actions.uploadImages.failed
        );
    },
    onUploadImagesCompleted(data, pid) {
      if(pid !== 'logo') {
          const response = JSON.parse(data);
          console.log("complete load images: ", response);
          Actions.needClearImageCache(response['pid']);
          this.getImagesList(response['pid'], true);
          this.newImages[response['pid']] = true;
      }
    },

    onUploadImagesFailed(){

    },

    onRotateImage(data) {
        yajax.post(this._prefix_img + 'rotate', data).then(Actions.rotateImage.completed)
    },
    onRotateImageCompleted(data) {
        Actions.updateCashRotate(data)
    },
    onControlImage(data) {
        yajax.post(this._prefix_img + 'control', data).then((res) => Actions.controlImage.completed(res, data.type))
    },
    onControlImageCompleted(data, type) {
      if(type === 'rotate') {
          Actions.updateCashRotate(data)
      } else if(type === 'make_first') {
          Actions.needClearImageCache(data['pid']);
          this.getImagesList(data['pid'], true);
          this.newImages[data['pid']] = true;
      }
    },

    onUpdateCashRotate(data) {
        for (let j in IMAGES_SIZES) {
            let size = IMAGES_SIZES[j],
              url = this.makeImageUrl(data['pid'], data['i'], size);
            yajax.get(url, '', {headers: {'Cache-Control': 'no-cache', 'Content-Type': "image/jpg"}}).then(
              Actions.updateCashRotate.completed, () => Actions.updateCashRotate.failed(size)
            );

            console.log("need update cache image: ", data['i'], size, url);
        }
    },
    onUpdateCashRotateCompleted(data) {

    },
    onUpdateCashRotateFailed(data) {

    },

    makeImageUrl(pid, i, size) {
        const url =   "/bimages/get/"+size+"-"+User.get("id")+"-"+pid+"-"+i+".jpg";
        return url
    },

    onDeleteImage(pid, i) {
        if (this.startDeleted) return;

        this.startDeleted = true;

        const params = {
          method: "DELETE",
          url: this._prefix_img+"delete",
          data: {"pid": pid, "i": i}
        };

        return yajax(params).then(
            Actions.deleteImage.completed, Actions.deleteImage.failed
        );
    },

    onDeleteImageCompleted(data) {
        this.startDeleted = false;
        if (data['success']) {
            Actions.getImagesList(data['pid'], true);
            for (let j in IMAGES_SIZES) {
                let size = IMAGES_SIZES[j];
                const url = this.makeImageUrl(data['pid'], data['i'], size);
                yajax.get(url, '', {headers: {'Cache-Control': 'no-cache', 'Content-Type': "image/jpg"}}).then(
                  (data)=>{console.log("success")}, (data)=>{console.log("fail")}
                );

                console.log("need update cache image: ", data['i'], size, url);
            }
        }
        console.log("result delete image: ", data)
    },

    onDeleteImageFailed(){
        this.startDeleted = false;
        console.log("failed delete image")
    },

    onNeedClearImageCache(pid, j) {
        let clear = {...this.state.needClearCache};
        clear[pid] = Math.round(Date.now()/1000);
        this.setState({
            needClearCache: clear
        });
    },

    needRemoveImageCache(pid) {
        return pid in this.state.needClearCache;
    },

    getImagesList(pid, refresh) {
        const dictProduct = this.get("dictProduct");
        if (!refresh && pid in dictProduct && 'img_list' in dictProduct[pid]) {
            return dictProduct[pid]['img_list'];
        }

        // if (!this.state.isLoadedImages) {
            Actions.getImagesList(pid);
        // }
        return [];
    },

    onGetImagesList(pid) {
        const url = this._prefix_img+pid+"/list";

        this.loadingImagesList = true
        console.log("start load images: %s", pid)
        // this.setState({isLoadedImages: true})
        return yajax.get(url).then(
            Actions.getImagesList.completed, Actions.getImagesList.failed
        );

    },

    onGetImagesListCompleted(data, isListZero) {
        this.loadingImagesList = false

        let product = {}, dictProduct = this.get('dictProduct');
        if (data['pid'] in dictProduct) {
            product = this.state.dictProduct[data['pid']];
        }

        product['img_list'] = data['list'];

        dictProduct[data['pid']] = product;


        if (this.needRemoveImageCache(data['pid'])) {
            for (let i in data['list']) {
                for (let j in IMAGES_SIZES) {
                    let size = IMAGES_SIZES[j],
                        url = this.makeImageUrl(data['pid'], i, size);
                    yajax.get(url, '', {headers: {'Cache-Control': 'no-cache', 'Content-Type': "image/jpg"}}).then(
                        (data)=>{console.log("success")}, (data)=>{console.log("fail")}
                        );

                    console.log("need update cache image: ", i, size, url);
                }
            }
        }

        this.setState({
            'dictProduct': dictProduct
            // 'isLoadedImages': false
        })
        console.info("completed load images: %s", data['list'].length);
    },

    onGetImagesListFailed(){
        this.loadingImagesList = false
        this.setState({isLoadedImages: false});
    },

    onAddProduct(data) {
        console.log("start add product", data)
        return yajax.post(this._prefix, data).then(
            Actions.addProduct.completed, Actions.addProduct.failed
        );
    },
    onAddProductCompleted(data) {

        if(data.pid) {
            Actions.loadByPid([data.pid])
            Actions.loadAdditionalInfoProduct([data.pid])
        }
        // console.log("result add product: ", data);
        UserActions.getCurrentStateTarif();
        // this.checkOnBoarding();
        // Actions.load();
    },
    onAddProductFailed(){
        console.log("failed add product")
    },

    onChangeStock(data) {
        console.log("start change stock", data)
        return yajax.post(this._prefix+"stock", data).then(
            Actions.changeStock.completed, Actions.changeStock.failed
        );
    },
    onChangeStockCompleted(data) {
        console.log("result change stock: ", data);
        ShipmentsActions.load();
        LightActions.setProductGroup("all", false);
    },
    onChangeStockFailed(){
        console.log("failed change stock")
    },

    onLoadCategory() {
        if (this.isLoadedCategory) return;

        this.isLoadedCategory = true;
        console.log("start load category products")
        return yajax.get(this._prefix+"category").then(Actions.loadCategory.completed, Actions.loadCategory.failed);
    },

    onLoadCategoryCompleted(data) {
        let cat = data['data'],
            dictCategory = {},
            parents_ids = {};

        for (let i in cat) {
            let row = cat[i],
            p_id = parseInt(row['parent_id']),
            cid = parseInt(row['id']);
            dictCategory[cid] = row;
            if (p_id) {
                if (typeof(parents_ids[p_id]) === "undefined") {
                    parents_ids[p_id] = [p_id, cid]
                } else {
                    parents_ids[p_id].push(cid)
                }
            }
        }
        for (let _pid in parents_ids) {
            if (dictCategory[_pid]?.parent_id) {
                let ppid = dictCategory[_pid]['parent_id'];
                parents_ids[ppid].push.apply(parents_ids[ppid], parents_ids[_pid]);
                parents_ids[ppid] = [...new Set(parents_ids[ppid])];
            }
        }

        this.setState({
            category: cat,
            categoryChild: parents_ids,
            dictCategory: dictCategory
        });
        this.isLoadedCategory = false;
    },

    onLoadCategoryFailed() {
        this.isLoadedCategory = false;
        // this.setState({
        //     "isLoadedCategory": false
        // })
        console.log("failed load category");
    },

    onAddCategory(data) {
        if (typeof(data['type_category']) === "undefined") {
            data['types'] = 1;
        }

        console.log("start add product category", data)
        return yajax.post(this._prefix+"category", data).then(
            Actions.addCategory.completed, Actions.addCategory.failed
        );
    },
    onAddCategoryCompleted(data) {
        console.log("result add category: ", data)
        this.setState({
            category: data['data'],
        })
    },
    onAddCategoryFailed(){
        console.log("failed add category")
    },

    getHistoryList() {
        const brand = this.get("brand");
        if (brand === null) {
            Actions.loadBrand();
            return [];
        }

        return brand;
    },


    onLoadHistory(pid) {
        if (this.isLoadedHistory) return;

        let data = {};

        if (pid) {
            data['pid'] = pid;
        }

        this.isLoadedHistory = true;
        return yajax.get(this._prefix+"history", data).then(Actions.loadHistory.completed, Actions.loadHistory.failed);
    },

    onLoadHistoryCompleted(data) {
        this.setState({
            history: data['data'],
        });
        this.isLoadedHistory = false;
    },

    onLoadHistoryFailed() {
        this.isLoadedHistory = false;
        console.log("failed load history");
    },


    onLoadBrand() {
        if (this.isLoadedBrand) return;

        this.isLoadedBrand = true;
        console.log("start load brand products")
        return yajax.get(this._prefix+"brand").then(Actions.loadBrand.completed, Actions.loadBrand.failed);
    },

    onLoadBrandCompleted(data) {
        const filterData = data['data'] && Array.isArray(data['data']) && data['data'].filter(item => !!item.id && !!item.name && !!item.name?.trim())
        this.setState({
            brand: filterData,
        });
        this.isLoadedBrand = false;
    },

    onLoadBrandFailed() {
        this.isLoadedBrand = false;
        console.log("failed load brand");
    },

    onAddBrand(data) {
        console.log("start add product brand", data)
        return yajax.post(this._prefix+"brand", data).then(
            Actions.addBrand.completed, Actions.addBrand.failed
        );
    },
    onAddBrandCompleted(data) {
        console.log("result add brand: ", data)
        this.setState({
            brand: data['data'],
        })
    },
    onAddBrandFailed(){
        console.log("failed add brand")
    },

    onLoadAttr() {
        if (this.isLoadedAttr) return;

        this.isLoadedAttr = true;
        console.log("start load Attr products")
        return yajax.get(this._prefix+"attr").then(Actions.loadAttr.completed, Actions.loadAttr.failed);
    },

    onLoadAttrCompleted(data) {
        let dict = {}
        for (let i in data['data']) {
            let attr = data['data'][i];

            dict[attr['id']] = attr;
        }
        this.setState({
            attr: data['data'],
            attrDict: dict
        });
        this.isLoadedAttr = false;
    },

    onLoadAttrFailed() {
        this.isLoadedAttr = false;
        console.log("failed load Attr");
    },

    getAttrName(id) {
        if (!this.state.attrDict) {
            return "";
        }
        const attr = this.state.attrDict[id];

        if (typeof(attr) === "undefined") return "";

        const name = attr['name'];
        // return name.indexOf("t-") > -1 ? T(name) : name;
        return T(name)
    },

    getAttrList() {
        const attr = this.get("attr");
        if (attr === null) {
            Actions.loadAttr();
            return [];
        }

        return attr;
    },

    getListDescByAttr(attrID) {
      const attrDictValues = this.get('attrDictValues')

        if(!!Object.keys(attrDictValues)?.length) {
            return attrDictValues[attrID]?.length ? attrDictValues[attrID].map(item => ({
                id: item,
                name: item
            })) : null
        }
    },

    onAddAttr(data) {
        return yajax.post(this._prefix+"attr", data).then(
            Actions.addAttr.completed, Actions.addAttr.failed
        );
    },
    onAddAttrCompleted(data) {
        let dict = {}
        for (let i in data['data']) {
            let attr = data['data'][i];

            dict[attr['id']] = attr;
        }
        this.setState({
            attr: data['data'],
            attrDict: dict
        });

        Actions.load()
    },
    onAddAttrFailed(){
        console.log("failed add Attr")
    },

    onDeleteAttr(id) {
        return yajax.del(this._prefix+"attr", {data: {id}}).then(
          Actions.deleteAttr.completed, Actions.deleteAttr.failed
        );
    },
    onDeleteAttrCompleted() {
        console.log('delete is completed')
        Actions.loadAttr()
        Actions.load()
    },
    onDeleteAttrFailed() {
        console.log('delete is failed')
    },

    onLoadNetPrices(pid, mid) {
        let lang = User.getLang();
        return yajax.get(this._prefix+"netprices", {pid, mid, lang}).then(
            Actions.loadNetPrices.completed, Actions.loadNetPrices.failed
        );
    },

    onLoadNetPricesCompleted(data) {
        this.setState({
            netprices: data['data'],
        })
    },

    onLoadNetPricesFailed(){
        console.log("failed load netprices")
    },

    onGetBundle(bid) {
        return yajax.get(this._prefix+"bundle", {bid}).then(
            Actions.getBundle.completed, Actions.getBundle.failed
        );
    },

    onGetBundleCompleted(data) {
    },

    onGetBundleFailed(){
    },

    onSetGroupNetPricesPrice(data) {
        return yajax.post(this._prefix+"netprices", data).then(
            Actions.setGroupNetPricesPrice.completed, Actions.setGroupNetPricesPrice.failed
        );
    },

    onSetGroupNetPricesPriceCompleted(data) {
        console.log("completed add Net Prices")
    },

    onSetGroupNetPricesPriceFailed(){
        console.log("failed add Net Prices")
    },

    onLoadPricesList() {
        return yajax.get(this._prefix+"prices-list").then(
            Actions.loadPricesList.completed, Actions.loadPricesList.failed
        );
    },

    onLoadPricesListCompleted(data) {
    },

    onLoadPricesListFailed(){
    },

    onSetPriceList(data) {
        return yajax.post(this._prefix+"prices-list", data).then(
            Actions.setPriceList.completed, Actions.setPriceList.failed
        );
    },

    onSetPriceListCompleted(data) {
        Actions.loadPricesList(true);
    },

    onSetPriceListFailed(){
        console.log("failed add Net Prices")
    },

    onDeletePriceList(id) {
        const params = {
          method: "DELETE",
          url: this._prefix+"prices-list",
          data: { "id": id }
        };

        console.log("start delete price list: ", id)
        return yajax(params).then(
            Actions.deletePriceList.completed, Actions.deletePriceList.failed
        );
    },

    onDeletePriceListCompleted(data) {
        Actions.loadPricesList();
    },

    onDeletePriceListFailed(){
        console.log("failed Delete Price List")
    },


    calcProductsStats(products, mid) {
        const filterInstock = this.getFilter('instock')
        const calcStatistics = (data, typeProduct) => {
            return data.reduce((stockAcc, stockCur) => {
                if(stockCur) {
                    if(typeProduct !== 4) {
                        stockAcc.quantity += stockCur.quantity
                        stockAcc.instock += stockCur.instock
                    }

                    const isDropWarehouse = WarehouseStore.isDropshipping(stockCur?.mid || stockCur.marketplace_id)

                    if (stockCur.instock || isDropWarehouse) {
                        stockAcc.available += 1
                    } else if (!isDropWarehouse) {
                        stockAcc.not_available += 1
                    }

                    if(typeProduct !== 4) {
                        if (filterInstock !== '2' || (filterInstock === '2' && stockCur.quantity && !stockCur.instock)) {
                            if (stockCur.net_price || stockCur.netprice) {
                                stockAcc.total_cost += (stockCur.netprice || stockCur.net_price) * stockCur.quantity;
                            }
                            if (stockCur.price) {
                                stockAcc.total_profit += stockCur.price * stockCur.quantity;
                            }
                        }
                    }
                }
                return stockAcc
            }, {
                quantity: 0,
                instock: 0,
                total_cost: 0,
                total_profit: 0,
                available: 0,
                not_available: 0
            })
        }

        return products.reduce((acc,cur) => {
            let stockStatistics = {}

            if(cur.type_product === 3) {
                return acc
            }

            if(cur.parent_id) {
                const getProduct = this.getProduct(cur.parent_id)
                const productVariable = getProduct?.variable
                const stock = productVariable.flatMap(item => mid !== 'all' ? item.stock[mid] : Object.values(item.stock)).filter(item => item)
                acc.count += stock.length
                stockStatistics = calcStatistics(stock)
            } else {
                acc.count += cur.stock.length
                stockStatistics = calcStatistics(cur.stock, cur.type_product)
            }

            acc.quantity += stockStatistics.instock
            acc.total_cost += stockStatistics.total_cost
            acc.total_profit += stockStatistics.total_profit
            acc.available += stockStatistics.available
            acc.count_not_available += stockStatistics.not_available
            acc.reserv += stockStatistics.quantity - stockStatistics.instock
            return acc
        }, {
            count: 0,
            count_not_available: 0,
            quantity: 0,
            reserv: 0,
            available: 0,
            total_cost: 0,
            total_profit: 0
        })
    },
    getAdditionalInfoByPid(pid) {
        const dictAdditionalInfo = this.get("dictAdditionalInfo")
        if(pid) {
            if(dictAdditionalInfo[pid]) {
                return dictAdditionalInfo[pid]
            } else {
                Actions.loadAdditionalInfoProduct([pid])
                return {}
            }
        }
    },

    onLoadAdditionalInfoProduct(pids) {
      if(this.loadingAdditionalInfo) return
      this.loadingAdditionalInfo = true
      yajax.get(this._prefix+"additional_info", {pids})
        .then(Actions.loadAdditionalInfoProduct.completed, Actions.loadAdditionalInfoProduct.failed)
    },
    onLoadAdditionalInfoProductCompleted(data) {
        this.loadingAdditionalInfo = false
        const dictAdditionalInfo = this.get("dictAdditionalInfo")
        if(data.data) {
            data.data.forEach(item => {
                if(item.expirated_date) {
                    item.expirated_date = item.expirated_date * 1000
                }


                dictAdditionalInfo[item.id] = item
            })
        }

        this.setState({
            dictAdditionalInfo
        })
    },
    onLoadAdditionalInfoProductFailed(){
        this.loadingAdditionalInfo = false
        console.log('load failed additional info')
    },

    theExpirationDateByPid(pid) {
      const getProduct = this.getProduct(pid)
      if(getProduct.expirated_date) {
          const dateNow = new Date();
          const expiratedDate = new Date(getProduct.expirated_date)

          const twoDaysBeforeExpirated = new Date(expiratedDate);
          twoDaysBeforeExpirated.setDate(expiratedDate.getDate() - 2);

          if(dateNow >= expiratedDate) {
              return 'overdue-exp'
          } else if(dateNow >= twoDaysBeforeExpirated && dateNow < expiratedDate) {
              return 'expires-exp'
          }
      }

      return false
    }
});


export default ProductsStore;