import React from 'react'
import ShipmentsStore from "../shipments-store";
import {Dropdown} from "react-bulma-components";
import {Link} from "react-router-dom";
import {createMobileAppLink} from "../../../tools/create-mobile-app-link";
import { LocalIcon } from "whitelables/wl-name/icons";
import T from '../../../components/i18n'
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const ExportDocumentDropdown = () => {
	const {isMobileSize} = useIsMobile()
	return (
		<Dropdown
			className={`full-width-dropdown-mobile button is-info dropdown-button is-rounded ${isMobileSize ? 'is-small' : 'is-large'}`}
			right={true}
			label={<span className='display-flex-center'><LocalIcon icon='export-products'/>{T('unload-reports')}</span>}
		>
			<Dropdown.Item value='pdf'>
				<Link
					className='drop-item'
					to={ShipmentsStore.printShipmentURL('pdf')}
					target="_blank"
					onClick={(evt) => createMobileAppLink(ShipmentsStore.printShipmentURL('pdf'), evt)}
				>
					<LocalIcon icon={'excel'} />  PDF
				</Link>
			</Dropdown.Item>
			<Dropdown.Item value='excel'>
				<Link
					className='drop-item'
					to={ShipmentsStore.printShipmentURL('xls')}
					target="_blank"
					download
					onClick={(evt) => createMobileAppLink(ShipmentsStore.printShipmentURL('xls'), evt)}
				>
					<LocalIcon icon={'excel'} />  EXCEL
				</Link>
			</Dropdown.Item>
		</Dropdown>
	);
};

export default ExportDocumentDropdown;