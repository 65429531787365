import React, { Component } from 'react';
import T from "components/i18n";
import Box from 'react-bulma-components/lib/components/box';
import Actions from './products-actions' 
import LightStore from './light-store'
import Button from 'react-bulma-components/lib/components/button';
import Heading from 'react-bulma-components/lib/components/heading';
import {
    Field, 
    Control, 
    Input, 
    Label,
    Radio, 
} from 'react-bulma-components/lib/components/form';
import Messages from 'components/messages';
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";

class ChangePriceModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      price: "",
      type_changes: "percentage",
      userCurrency: "UAH",
      button: T('change'),
      message: null
    };

    this.onChange = this.onChange.bind(this);
    this.changeButtonText = this.changeButtonText.bind(this);
    this.submit = this.submit.bind(this);
  }
  
  submit() {
    const { onClose } = this.props;
    this.setState({ message: null});

    if (!this.state.price || this.state.price === 0) {
      this.setState({message: T('price-is-false-error-message')})
      return false;
    }
    if (this.state.type_changes === "exact_amount" && this.state.price < 0) {
      this.setState({message: T('change-exact-price-error-message')})
      return
    }
    Actions.setGroupPrice(parseFloat(this.state.price), this.state.type_changes);

    Actions.setGroupPrice.completed.listen((data) => {
      if (typeof (onClose) == "function") {
        setTimeout(() => { onClose() }, 100);
      }
      //window.history.back();
    });
  }
  
  changeButtonText(priceValue, type_changes) {
    let button = T('change');
    const value = parseFloat(priceValue);

    if (type_changes === 'percentage') {
      if (value < 0) {
          button = T('decrease-count-edit-items-btn', {count: value});
        } else if (value > 0) {
          button = T('increase-count-edit-items-btn', {count: value});
        } else {
          button = T('change');
        }
    }
    if (type_changes === 'difference_amount') {
      if (value < 0) {
          button = T('decrease-count-edit-items-btn-difference', {count: value, currency: this.state.userCurrency});
        } else if (value > 0) {
          button = T('increase-count-edit-items-btn-difference', {count: value, currency: this.state.userCurrency});
        } else {
          button = T('change');
        }
    }
    if (type_changes === 'exact_amount') {
      if (value > 0) {
          button = T('change-count-edit-items-btn-exact', {count: value, currency: this.state.userCurrency});
      }
    }
    this.setState({
          button: button,
        });
  }
  
  onChange(evt) {
        const value = evt.target.value,
          name = evt.target.name;
        this.setState({
          [name]: value,
        });
    if (name === 'price') { this.changeButtonText(value, this.state.type_changes) } else {this.changeButtonText(this.state.price, value)}
    }
  

    render () {
        const {onClose} = this.props
        return (
          <Box>
            <Messages message={this.state.message} color='warning' timeout={3000} close={() => this.setState({ message: null })} />
                <Heading size={this.props.isMobileSize ? 5 : 3}>
                    {T('mass-edit-price')} <br />
                </Heading>
                <Heading subtitle className="has-text-danger" size={this.props.isMobileSize ? 6 : 4}>
                    {T('count-edit-items', {count: LightStore.get("count")})}
            </Heading>
            
            <Field>
                      <Control>
                        <Label>{T('mass-edit-price-type-label')} </Label>
                        <Control>
                        <Radio
                            onChange={this.onChange} 
                            checked={this.state.type_changes === 'percentage'} 
                            value="percentage" 
                            size="large"
                            name="type_changes">
                          {T('mass-edit-price-type-1')}
                        </Radio>
                        <Radio 
                            onChange={this.onChange} 
                            checked={this.state.type_changes === 'difference_amount'}
                            value="difference_amount" 
                            size="large"
                            name="type_changes" >
                          {T('mass-edit-price-type-2')}
                        </Radio>
                        <Radio 
                            onChange={this.onChange} 
                            checked={this.state.type_changes === 'exact_amount'}
                            value="exact_amount" 
                            size="large"
                            name="type_changes" >
                          {T('mass-edit-price-type-3')}
                        </Radio>
                    </Control>
                      </Control>
              </Field>

            {this.state.type_changes === 'percentage' ? <Field>
              <Control>
                <Label>{T('change-mass-product-price-percentage')} <br /> <small>{T('change-mass-product-price-percentage-help')}</small> </Label>
                <Input size="large"
                  name="price"
                  type="number"
                  placeholder={"%"}
                  onChange={this.onChange}
                  value={this.state.price}
                  color="primary"  
                />
              </Control>
            </Field> : null}

            {this.state.type_changes === 'difference_amount' ? <Field>
              <Control>
                <Label>{T('change-mass-product-price-difference')} <br /> <small>{T('change-mass-product-price-difference-help', {currency: this.state.userCurrency})}</small> </Label>
                <Input size="large"
                  name="price"
                  type="number"
                  placeholder={this.state.userCurrency}
                  onChange={this.onChange}
                  value={this.state.price}
                  color="primary"  
                />
              </Control>
            </Field> : null}

            {this.state.type_changes === 'exact_amount' ? <Field>
              <Control>
                <Label>{T('change-mass-product-price-exact')} <br /> <small>{T('change-mass-product-price-exact-help', {currency: this.state.userCurrency})}</small> </Label>
                <Input size="large"
                  name="price"
                  type="number"
                  min="0"
                  placeholder={this.state.userCurrency}
                  onChange={this.onChange}
                  value={this.state.price}
                  color="primary"  
                />
              </Control>
            </Field> : null}

                  <Button.Group position="centered">
                    <Button size={this.props.isMobileSize ? "small": "medium"}  rounded color="primary"
                      onClick={this.submit}
                      >{this.state.button}</Button>
                    <Button size={this.props.isMobileSize ? "small": "medium"} rounded color="light"
                      onClick={onClose}
                      >{T('close')}</Button>
                  </Button.Group>
              </Box>
        );
    }

}

export default withIsMobileSize(ChangePriceModal);