import React from "react";
import s from "./styles.module.scss";
import Image from "react-bulma-components/lib/components/image";
import ProductsStore from "../../products/products-store";
import T from "../../../components/i18n";
import List from "react-bulma-components/lib/components/list";
import { Button } from "react-bulma-components";
import AppStore from "../../../app-store";
import RestoreModal from "./restore-modal";
import WarehouseStore from "../../warehouse/warehouse-store";

const RowMobile = ({ data }) => {
  const category = ProductsStore.getCategoryName(data.category_id);
  const brand = ProductsStore.getBrandName(data.brand_id);

  const getWarehousesName = Object.keys(data.stock).map(item => {
    const name = WarehouseStore.getWarehouseName(item)
    if(name) {
      return {
        name,
        id: item
      }
    } else {
      return {
        name: T('warehouse-deleted'),
        id: item,
        isDeleted: true
      }
    }
  })

  const midsWithoutDeleted = getWarehousesName.filter(item => !item.isDeleted)

  const handleRestore = () => {
    AppStore.openModal(<RestoreModal mids={midsWithoutDeleted} pid={data.id} modal='without-scroll'/>)
  }
  return (
    <tr>
      <td>
        <div className="display-flex-column" style={{ padding: "10px" }}>
          <div className={s.wrapper_name}>
            <Image
              src={ProductsStore.makeImageUrl(data.id, 0, 64)}
              size={64}
              style={{ height: "auto" }}
            />
            <div>
              <strong>{data.name}</strong>
              <br />
              {category && (
                <span>
                  {T("product-category")}: {category} {brand && " | "}
                </span>
              )}
              {brand && (
                <span>
                  {T("product-brand")}: {brand}
                </span>
              )}
            </div>
          </div>
          {/*<Label>{T("history-data-marketplace_id")}</Label>*/}
          <List>
            {!!midsWithoutDeleted.length && <>
              {getWarehousesName.map((item) => (
                <List.Item key={`deleted-${item.id}`}>{item.name}</List.Item>
              ))}
            </>}
            <List.Item>
              <b>{T('date-of-deletion')}: </b>{data.updated_at}
            </List.Item>
          </List>
          <div className="display-flex-center">
            <Button color="info" onClick={handleRestore}>
              {T("restore-label")}
            </Button>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default RowMobile;
