import React from "react";
import BaseFilterLayout from "../../../components/base-filter-layout";
import ProductsDeletedFilter from "./products-deleted-filter";
import HistoryActions from "../history-actions";
import HistoryStore from "../history-store";

const FilterPanel = () => {

  const handleChangeSearch = ({ name, value }) => {
    if (name) {
      HistoryActions.filterDeleted(name, value);
    }
  };

  const handleChangeDate = (value) => {
    const daysKey = Object.keys(value).reverse();
    daysKey.forEach((item) => {
      HistoryActions.filterDeleted(item, value[item]);
    });
  };

  return (
    <BaseFilterLayout
      searchStringSetting={{
        onChange: handleChangeSearch,
        defaultValue: HistoryStore.getFilterDeleted('search')
      }}
      dateRangeSetting={{
        onChange: handleChangeDate,
        date_start: HistoryStore.getFilterDeleted("date_start"),
        date_end: HistoryStore.getFilterDeleted("date_end"),
      }}
      hiddenFilter={<ProductsDeletedFilter/>}
    />
  );
};

export default FilterPanel;
