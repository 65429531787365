import React, {useState} from "react";
import List from "react-bulma-components/lib/components/list";
import SalesStore from "../../../../sales/sales-store";
import SalesProductRow from "./sales-product-row";
import usePaginationHook from "../../../../../tools/pagination-hook";
import {Box, Heading} from "react-bulma-components";
import T from "../../../../../components/i18n";
import StatisticsBlock from "../common/statistics-block";
import calcMargin from "../../../../../tools/calc-margin";
import Money from "../../../../../components/money";
import numberFormat from "../../../../../tools/number-format";
import User from "../../../../../user/user-store";
import DateRange from "../../../../../components/base-filter-layout/date-range";
import {getDatePeriod} from "../../../../../tools/date-range";
import SalesActions from "../../../../sales/sales-actions";

const SalesProductBlock = ({ pid, units }) => {
  const sales = SalesStore.getSalesByPidWithDateFilter(pid);
  const { page, end, start, total, setPage, PaginationBlock } =
    usePaginationHook({ listLength: sales.length, perPage: 10 });

  const calcStatistics = sales.reduce(
    (acc, curr) => {
      if (curr.order_status !== "reserve_sale") {
        acc.amount += curr.amount;
        acc.profit += curr.profit;
        acc.quantity += curr.quantity;
        acc.count += 1;
        acc.net_price += curr.net_price;
      }
      return acc;
    },
    {
      count: 0,
      amount: 0,
      profit: 0,
      quantity: 0,
      net_price: 0,
    }
  );

  const statisticsList = [
    {
      name: "total-sales",
      value: (
        <span>
          {calcStatistics["count"]}({numberFormat(calcStatistics["quantity"])}{" "}
          {T("items-e")})
        </span>
      ),
    },
    { name: "saled-on", value: <Money amount={calcStatistics["amount"]} /> },
  ];

  if (User.getPermissionEnable("incomeinformation")) {
    statisticsList.push(
      { name: "earned", value: <Money amount={calcStatistics["profit"]} /> },
      {
        name: "margin",
        value: `${calcMargin(
          calcStatistics["net_price"],
          calcStatistics["amount"]
        )}%`,
      }
    );
  }

  const [period] = useState(getDatePeriod(14));

  const handleChangeDate = (value) => {
    const daysKey = Object.keys(value).reverse();
    daysKey.forEach((item) => {
      SalesActions.filter(item, value[item]);
    });
  };

  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          borderRadius: "0 0 16px 16px",
        }}
      >
        <DateRange
          date_start={period}
          date_end={SalesStore.getFilter("date_end")}
          onChange={handleChangeDate}
        />
      </Box>
      <StatisticsBlock list={statisticsList} />
      <List>
        {sales.slice(start, end).map((item) => (
          <SalesProductRow key={item.id} data={item} units={units} />
        ))}
        {sales.length === 0 && (
          <div align="center" className="margin-10">
            <Heading size={4}>{T("not-have-sales")}</Heading>
          </div> 
        )}
      </List>
      <div className="display-flex-center">
        <PaginationBlock
          totalPage={total}
          setPage={setPage}
          currentPage={page}
          showPrevNext={false}
        />
      </div>
    </>
  );
};

export default SalesProductBlock;
