import React, {useEffect, useState} from "react";
import Level from "react-bulma-components/lib/components/level";
import OpenModalButton from "../../components/modal/open-modal-button";
import T from "../../components/i18n";
import Box from "react-bulma-components/lib/components/box";
import Reports from "../../components/reports";
import WriteOffRowDesktop from "./write-off-row-desktop";
import emptyMessage from "./empty-message";
import WriteOffActions from "./write-off-actions";
import {connectToStores} from "../../tools/reflux-tools";
import WriteOffStore from "./write-off-store";
import WriteOffRowMobile from "./write-off-row-mobile";
import ErrorMessageBlock
	from "../../components/error-message-block/error-message-block";
import {errorHandler} from "../../tools/error-handler";
import {
	useIsMobile
} from "../../components/is-mobile-context/is-mobile-context";

const COLUMNS = [
	"info",
	"write-off-category",
	"status",
	"responseble",
	"",
]

const WriteOff = ({store}) => {
	const {isMobileSize} = useIsMobile()
	const [errorMessage, setErrorMessage] = useState(null)

	const generateErrorMessage = (res) => {
		const message = errorHandler(res)
		setErrorMessage(message)
		setTimeout(() => setErrorMessage(null), 4000)
	}

	useEffect(() => {
		WriteOffActions.load()
		const loadFailed = WriteOffActions.load.failed.listen((res) => {
			generateErrorMessage(res)
		})

		const deleteWriteOffFailed = WriteOffActions.delete.failed.listen((res) => {
			generateErrorMessage(res)
		})

		return () => {
			loadFailed()
			deleteWriteOffFailed()
		}
	}, []);

	const handleDeleteWriteOff = (data) => {
		WriteOffActions.delete(data.id)
	}

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			<Box>
				<Level>
					<Level.Side align="right"></Level.Side>
					<Level.Side>
						<Level.Item>
							<OpenModalButton link="/write-off/add-write-off" text={T('write-off-product')} icon="plus-circle"/>
						</Level.Item>
					</Level.Side>
				</Level>
				<Box className="moving-report">
					<Reports
									 emptyMessage={emptyMessage}
									 columns={COLUMNS}
									 hideFooterOnEmpty
									 rows={store.write_off}
									 load={WriteOffActions.load}
					>
						{isMobileSize ? <WriteOffRowMobile role='row' onDelete={handleDeleteWriteOff}/> : <WriteOffRowDesktop role="row" onDelete={handleDeleteWriteOff}/>}
					</Reports>
				</Box>
			</Box>
		</>
	);
};

export default connectToStores(WriteOff, {store: WriteOffStore })