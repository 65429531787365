import React from "react";
import WarehouseStore from "../../../warehouse/warehouse-store";
import T from "../../../../components/i18n";
import ProductsStore from "../../products-store";

const hideColumnsRefactoring =(array) => {
  return array.map(item => {
    let result
    if(item === 'images') {
      result = 'image'
    } else if (item === 'sku') {
      result = 'history-data-asin'
    } else if(item === 'barcode') {
      result = 'history-data-asin'
    } else if (item === 'instock') {
      result = 'product-info-warehouse-one'
    } else if (item === 'price') {
      result = 'product-info-price'
    }
    return T(result)
  })
}

const customPriceRefactoring = (array) => {
  return array.map(item => {
    if(item === 'Ціна') {
      item = T('basic-price-label')
    }

    return item
  })
}

export const getFilterValue = (name, value) => {
  if (name === "mid") {
    return WarehouseStore.getWarehouseName(value);
  } else if (name === "instock") {
    return value === "1" ? T("in-stock") : T("out-of-stock");
  } else if (name === "brand") {
    return ProductsStore.getBrandName(value);
  } else if (name === "category") {
    return ProductsStore.getCategoryName(value);
  } else if(name === 'sort' && value && typeof value === 'string') {
    const split = value.split(('|'))
    const changeName = split.map(item => {
      if(item === 'desc') {
        return T('DESC')
      } else if (item === 'asc') {
        return T('ASC')
      } else if(item === 'price') {
        return T('product-info-price')
      }
      return T(item)
    })

    return changeName.join(' | ')

  } else if (name === 'params') {
    if(value.hide_columns) {
      return <><b>{T('hidden-columns')}</b>: {hideColumnsRefactoring(value.hide_columns).join(", ")}</>
    }
    if(value.custom_price) {
      return <><b>{T('custom-prices-short')}</b>: {customPriceRefactoring(value.custom_price).join(", ")}</>
    }
    value = ''
  }
  return value;
};

const needFilter = [
  "status",
  "date_start",
  "date_end",
  "withoutbarcode",
  "withoutprice",
  "margin_from",
  "margin_to",
  "price_from",
  "price_to",
  "sort",
  "attr",
];

export const filteringFilterValue = (filters) => {
  const getEntriesFilters = Object.entries(filters);

  return getEntriesFilters.reduce((acc, cur) => {
    const name = cur[0];
    const value = cur[1];

    if (
      value !== 0 &&
      value !== "0" &&
      value !== "" &&
      !needFilter.includes(name)
    ) {
      acc[name] = value;
    }

    return acc;
  }, {});
};
