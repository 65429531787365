import React from 'react'
import {
	Checkbox,
	Control,
	Field, Label
} from "react-bulma-components/lib/components/form";
import T from "../../../../../components/i18n";

const CheckboxGroupShopSupervision = ({dataCheckbox, checkboxValue, label, onChange}) => {
	return (
		<Field>
			{label && <Label>{T(label)}</Label>}
			{dataCheckbox.map(item => (
					<Field className="margin-top-20" key={item.name}>
						<Control>
							<Checkbox
								name={item.name}
								onChange={onChange}
								checked={checkboxValue[item.name] || false}
							>{T(item.title)}</Checkbox>
						</Control>
					</Field>
				))}
		</Field>
	);
};

export default CheckboxGroupShopSupervision;