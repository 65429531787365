import React from 'react'
import ProductsStore from "../../modules/products/products-store";
import s from './styles.module.scss'
import T from '../i18n'

const ExpirationDateTag = ({pid, short}) => {
	const isExpiration = ProductsStore.theExpirationDateByPid(pid)
	return isExpiration && (
		<span className={`${s.wrapper} ${isExpiration ? s[isExpiration] : ''}`}>
			{short ? T('expiration-date-short') : T(isExpiration)}
		</span>
	);
};

export default ExpirationDateTag;