import React, {useState} from 'react'
import List from "react-bulma-components/lib/components/list";
import Columns from "react-bulma-components/lib/components/columns";
import ProductsStore from "../../../products/products-store";
import {getProductName} from "../../utils/getProductName";
import T from "../../../../components/i18n";
import {
	Control, Field,
	Input,
	Label
} from "react-bulma-components/lib/components/form";
import Icon from "react-bulma-components/lib/components/icon";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import User from "../../../../user/user-store";
import Image from "react-bulma-components/lib/components/image";

const AddWriteOffRowMobile = ({data, changeQuantity, removeProduct, onlyShow, step}) => {
	const [inputValue, setInputValue] = useState(data?.quantity_write_off || 1)
	const img = "/bimages/get/34-"+User.get("id")+"-"+data.id+"-0.jpg"
	const product = ProductsStore.getProductByMid(data.id, data.mid)


	const handleChangeInput = (evt) => {
		const pid = evt.target.name,
			quantity_write_off = evt.target.value
		if(product.quantity < quantity_write_off) {
			setInputValue(product.quantity)
		} else {
			setInputValue(quantity_write_off)
		}

		changeQuantity({pid, quantity_write_off, mid: data.mid})

	}

	const handleRemove = () => {
		removeProduct(data)
	}



	return (
		<List.Item align="left">
			<b>
			<Image src={img} size={32} className="align-left"/>
				{product ? getProductName(product) : <span>ID: {data.id}. <span className='text-danger'>{T('product_deleted')}</span></span>}
			</b>
			<hr />
			<Columns className="is-mobile">
				<Columns.Column>
					<Label>{T('product-info-warehouse-one')}:
						<span className="text-success"> {product.instock}{T('items-p')}</span><br />
					</Label>
					{!onlyShow && step !== 5 && step !== 2 && (
					<Field horizontal>
							<Label>{T('write-off-label')}:</Label>
							<Control iconRight>
								<Input
									name={data.id}
									type="number"
									min='1'
									className="for-small-icon"
									onChange={handleChangeInput}
									value={inputValue}
								/>
								<Icon align="right">
									<span className="small-text">{ProductsStore.getUnits(product.id['units'])}</span>
								</Icon>
							</Control>
					</Field>)}
				</Columns.Column>
				{(onlyShow || step === 2) && (
				<Columns.Column>
						<Label>{T('write-off-label')}:
							<span className="text-success"> {inputValue}{T('items-p')}</span><br />
						</Label>
				</Columns.Column>)}
			</Columns>
			{!onlyShow && step !== 2 && (
			<div align="right">
				<Link to="#" onClick={handleRemove} title={T('delete')}>
					<FontAwesomeIcon icon="trash-alt" size="1x" /> {T('delete-from-list')}
				</Link>
			</div>)}
		</List.Item>
	);
};

export default AddWriteOffRowMobile;