import yajax from "yajax";
import { DEFAULT_LANG } from "../configs/i18n";
// import moment from "moment";
import { createStore } from "../tools/reflux-tools";
import Actions from "./user-actions";
import * as session from "./session";
import { parseToken } from "./utils";
import T from "components/i18n";
import ExpensesActions from "modules/expenses/expenses-actions";
import WarehouseActions from "modules/warehouse/warehouse-actions";
import ShipmentsActions from "modules/shipments/shipments-actions";
import SalesActions from "modules/sales/sales-actions";
import WarehouseStore from "modules/warehouse/warehouse-store";
import NotificationActions from "modules/notification/notification-actions";
import ProductsActions from "modules/products/products-actions";
import OrdersActions from "modules/orders/orders-actions";
import HistotyActions from "modules/history/history-actions";
import ClientsActions from "modules/clients/clients-actions";
import AppStore from "app-store";
import { parseJSON } from "../tools/error-handler";
import ExpensesStore from "../modules/expenses/expenses-store";
import ProductsStore from "../modules/products/products-store";
import tarifData from "whitelables/wl-name/user/tarif-data";
import { isIOS } from "react-device-detect";
import SalesStore from "../modules/sales/sales-store";
import {objectsToArray} from "../tools/objects-to-array/objects-to-array";

function ucfirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const FIELD_PRICE_TAG_DEFAULT = {
  page: {
    format: "termo",
    size: {
      width: 6,
      height: 4,
    },
  },
  field: {
    warehouse: {
      size: 10,
      position: 1,
      active: 1,
    },
    barcode: {
      position: 0,
      height: 10,
      width: 0.3,
      size: 14,
      active: 1,
      number_key: 1,
    },
    date: {
      size: 14,
      position: 8,
      active: 1,
    },
    sku: {
      size: 12,
      position: 6,
      active: 1,
    },
    name: {
      size: 14,
      position: 4,
      active: 1,
      brand: true,
    },
    price: {
      size: 18,
      position: 5,
      active: 1,
    },
    attr: true,
  },
};

const DEFAULT_DATA = {
  token: "",
  lang: DEFAULT_LANG,
  start: false,
  firstReg: false,
  isGuest: true,
  isRedirect: false,
  next_payment_date: new Date(),
  last_payment_date: new Date(),
  currency_id: 2,
  messages: [],
  tarif: 1,
  temp_tarif: 1,
  tarif_data: tarifData,
  group: 2,
  balance: 0,
  bonus: 0,
  bonus_days: 0,
  id: 0,
  subusers: [],
  count_subusers: 0,
  transactions: {},
  country: "ua",
  settings: {},
  permissions: "",
  modules: "",
  cards: {},
  current_state: {},
  timeoutMessage: null,
};

const UserStore = createStore({
  listenables: [Actions],

  _setDefaultData() {
    return DEFAULT_DATA;
  },

  NP_ID: 1,
  CH_ID: 4,
  UP_ID: 3,
  WOO_ID: 2,
  PROM_ID: 5,
  RZ_ID: 6,
  OC_ID: 7,
  HS_ID: 8,
  VK_ID: 9,
  TS_ID: 11,
  SP_ID: 12,
  TG_ID: 13,
  INST_ID: 14,
  API_ID: 15,
  LC_ID: 16,
  VB_ID: 17,
  BI_ID: 18,
  OLX_ID: 19,
  state: DEFAULT_DATA,

  _prefix: "/userprofile/",
  _prefix_pay: "/bpayments/",

  _authRequestId: 0,
  _updateCStock: false,

  ADMIN: 2,
  CASHIER: 3,
  WAREHOUSEMAN: 4,
  MANAGER: 5,
  GUEST: 6,

  group_list: [
    { id: 2, name: "main-user" },
    { id: 3, name: "cashier" },
    { id: 4, name: "warehouseman" },
    { id: 5, name: "_manager" },
    { id: 6, name: "guest" },
  ],

  pricetags_size: [
    { id: 1, name: "A4 (6cm x 4cm)" },
    { id: 6, name: "A4 (5.5cm x 5cm)" },
    { id: 7, name: "A4 (3cm x 2cm)" },
    { id: 8, name: "A4 (52.5х29.7мм, 40 шт)" },
    { id: 9, name: "A4 (38х21.2мм, 65 шт)" },
    { id: 11, name: "2cm x 1cm" },
    { id: 12, name: "2.4cm x 1.4cm" },
    { id: 2, name: "3cm x 2cm" },
    { id: 3, name: "4cm x 2.5cm" },
    { id: 4, name: "6cm x 3cm" },
    { id: 5, name: "6cm x 4cm" },
    { id: 14, name: "5.2cm x 3cm" },
    { id: 10, name: "5.5cm x 4cm" },
    { id: 13, name: "7.5cm x 5cm" },
    { id: 15, name: "5.8cm x 5.8cm" },
    { id: 16, name: "8cm x 8cm" },
  ],

  permissions: [
    "show-all-sales",
    "salesprice",
    "showbalance",
    "show-cashflow",
    "show-all-expenses",
    "incomeinformation",
    "editinstock",
    "editproducts",
    "rawcost",
    "edit-sales",
    "refund-products",
    "deleteproducts",
    "deleteshipments",
    "deletesales",
    "delete-client",
    "common-statuses",
    "orders-statuses",
    "sales-statuses",
    "edit-balance",
    "edit-discount",
  ],
  modules: [
    "dashboard",
    "chats",
    "orders",
    "sales",
    "reserv",
    "payments",
    "production",
    "shipments",
    "moving",
    "write-off",
    "inventory",
    "clients",
    "reports",
    "transactions",
    "history",
    "settings",
    "terminal",
    "slip",
    "pricetags",
  ],

  get(field) {
    return this.state[field];
  },

  _set(field, value) {
    const handler = "_onSet" + ucfirst(field);

    if (typeof this[handler] === "function") {
      this[handler](value);
    }

    this.state[field] = value;

    return this;
  },

  getInitialState() {
    return this.state;
  },

  isMobileApp() {
    return session.getIsMobileApp();
  },

  setIsMobileApp(app) {
    session.setIsMobileApp(app);
  },

  getState() {
    return this.state;
  },

  getCurrentState(_type) {
    if (_type in this.state.current_state) {
      return this.state.current_state[_type][1];
    }

    return 0;
  },

  getIntegrationGroup(system) {
    return {
      cms: [this.WOO_ID, this.OC_ID],
      marketplaces: [this.PROM_ID, this.RZ_ID, this.HS_ID],
      sms: [this.TS_ID],
      delivery: [this.NP_ID, this.UP_ID],
      chats: [this.INST_ID, this.TG_ID, this.VB_ID, this.OLX_ID], //this.OLX_ID
      prro: [this.CH_ID, this.VK_ID],
      other_receipt: [this.API_ID, this.LC_ID,this.BI_ID], // this.BI_ID
    }[system];
  },

  getRemoteSystem() {
    return [this.WOO_ID, this.PROM_ID, this.RZ_ID, this.HS_ID, this.OC_ID];
  },

  getNewRemoteSystem() {
    const remote_system = [
      this.NP_ID,
      this.UP_ID,
      this.CH_ID,
      this.VK_ID,
      this.WOO_ID,
      this.PROM_ID,
      this.RZ_ID,
      this.HS_ID,
      this.OC_ID,
      this.TS_ID,
      this.TG_ID,
      this.API_ID,
      this.VB_ID,
      this.LC_ID,
      this.INST_ID,
      this.BI_ID,
      this.OLX_ID
    ];

    // if ([11, 8].indexOf(this.get("id")) > -1) {
    //     remote_system.push(this.INST_ID);
    // }

    return remote_system;
  },

  getChatsSystem() {
    return [this.TG_ID, this.INST_ID, this.VB_ID, this.OLX_ID];
  },

  getLang() {
    return this.get("lang");
  },

  isRussia() {
    return this.get("currency_id") === 3 || this.get("currency_id") === 6;
  },

  isForeign() {
    return this.get("currency_id") !== 2;
  },

  isUkraine() {
    return this.get("currency_id") === 2;
  },

  checkPhone(phone, empty) {
    phone = phone.trim();
    const cleanedPhoneNumber = phone.replace(/\D/g, "");

    if (/^(380|80|0)/.test(cleanedPhoneNumber)) {
      if (!/^380/.test(cleanedPhoneNumber)) {
        return (
          cleanedPhoneNumber.length >= 10 && cleanedPhoneNumber.length <= 11
        );
      } else {
        return (
          cleanedPhoneNumber.length === 12 ||
          (empty &&
            ([3].indexOf(cleanedPhoneNumber.length) > -1 || phone === ""))
        );
      }
    }

    return phone.length >= 10;
  },

  getLenghtPhone() {
    return this.isUkraine() ? 12 : 11;
  },

  getPhoneCode() {
    return this.isRussia() ? "+7" : "+380";
  },

  getEnableNovaPoshta() {
    return this.get("currency_id") === 2;
  },

  getTurboSMSlist() {
    return [
      { id: "MAGAZIN", name: "MAGAZIN" },
      { id: "BRAND", name: "BRAND" },
      { id: "Best-Shop", name: "Best-Shop" },
      { id: "BEAUTY", name: "BEAUTY" },
    ];
  },

  getSenderInfoList(id) {
    let a = {
      1: this.getNpSender(true),
      3: this.getUpSender(true),
    };
    const data = a[id];

    let options = [];
    for (let i in data) {
      let ident = JSON.parse(data[i]["ident"]),
        name = data[i]["name"] + ". " + ident["delivery_name"];

      options.push({ id: data[i]["id"], name: name });
    }

    return options;
  },

  getCurrencyList() {
    const data = this.get("currencies");

    let options = [];
    for (let i in data) {
      if (data[i].id === 3) {
        continue;
      }
      options.push({ id: data[i]["id"], name: data[i]["name"] });
    }

    return options;
  },

  getSlipInfo() {
    return {
      company_info: this.state.settings["company_info"] || "",
      termo_print: this.state.settings["termo_print"] || 0,
      images: this.state.settings["images"] || 0,
      add_info: this.state.settings["add_info"] || "",
      name: this.state.settings["slip_name"] || "",
      is_auto_print: this.getMoreSetting("is_auto_print") || false,
      is_auto_fiscal_print:
        this.getMoreSetting("is_auto_fiscal_print") || false,
    };
  },

  getPriceTagsInfo() {
    return this.state.settings &&
      "pricetags" in this.state.settings &&
      this.state.settings["pricetags"]
      ? JSON.parse(this.state.settings["pricetags"])
      : {};
  },

  calcBonusDays(amount) {
    const bonus = this.get("bonus"),
      count_days = 30,
      cost_per_day = amount / count_days,
      bonus_days = Math.round(bonus / cost_per_day);
    return bonus_days;
  },

  getPaymentCurrency() {
    let account_cur_id = UserStore.get("currency_id");
    let cur_id = 2;

    if (isIOS && this.isMobileApp()) {
      cur_id = 1;
    }

    if (account_cur_id !== 2) {
      cur_id = 1;
    }

    if (AppStore.getWlName()  === "modeler") {
      cur_id = 1;
    }

    return cur_id;
  },

  isStartModule() {
    if (AppStore.getWlName() === "navkolo") {
      return true;
    } else {
      return this.getTarif() === 1 && this.isMainUser();
    }
  },

  hasAccessWhiteLabel(accessArray) {
    const WHITELABEL = AppStore.getWlName();
    if (!accessArray || !Array.isArray(accessArray)) {
      return false;
    }

    return accessArray.includes(WHITELABEL);
  },

  isOnBoarding() {
    if (AppStore.getWlName() === "navkolo") {
      return false;
    }
    return this.getTarif() === 1 && this.isMainUser();
  },

  setOnBoaringStep(step) {
    let settings = this.state.settings;
    settings["onboarding"] = step;

    this.setState({
      settings: settings,
      bonus: this.getOnBoaringStepCurrency(step),
    });

    Actions.updateSettings();
  },

  getOnBoaringStep() {
    // return 4;
    return this.state.settings["onboarding"] || 0;
  },

  getCurrencyType() {
    return this.state.settings["rate_types"].toString() || "2";
  },

  getOnBoaringStepCurrency(step) {
    if (!this.isOnBoarding()) return 0;

    const data = { 1: 3.2, 2: 60, 3: 180 };

    return data[this.getPaymentCurrency()] * step;
  },

  setSlipInfo(data) {
    let settings = this.state.settings;
    settings["company_info"] = data["company_info"];
    settings["images"] = data["images"] ? 1 : 0;
    settings["add_info"] = data["add_info"];
    settings["slip_name"] = data["slip_name"];
    settings["termo_print"] = data["termo_print"] ? 1 : 0;

    const moreSetting = parseJSON(settings.more_setting || "{}");

    moreSetting.is_auto_print = !!data?.is_auto_print;
    moreSetting.is_auto_fiscal_print = !!data?.is_auto_fiscal_print;

    settings.more_setting = JSON.stringify(moreSetting);

    this.setState({
      settings: settings,
    });

    Actions.updateSettings();
  },

  setPriceTagsInfo(data) {
    let settings = this.state.settings;
    settings["pricetags"] = JSON.stringify(data);
    this.setState({
      settings: settings,
    });

    Actions.updateSettings();
  },

  getPriceTagsSetting() {
    let settings = this.state.settings;
    const priceTagsSetting = JSON.parse(settings["pricetags"]);
    const data = {};
    if (priceTagsSetting && priceTagsSetting?.page) {
      data.type = "custom";
      data.page = priceTagsSetting?.page;
      delete priceTagsSetting?.page;
    } else {
      data.type = "templates";
      data.size = priceTagsSetting?.size || 1;
      data.quantity = priceTagsSetting?.quantity || 1;
    }

    data.field = Object.keys(FIELD_PRICE_TAG_DEFAULT.field).map((item) => {
      if (item === "attr") {
        if (priceTagsSetting && priceTagsSetting[item]) {
          return { name: item, active: priceTagsSetting[item] || false };
        } else if (priceTagsSetting) {
          return { name: item, active: false };
        } else {
          return { name: item, active: FIELD_PRICE_TAG_DEFAULT.field[item] };
        }
      } else if (priceTagsSetting && priceTagsSetting[item]) {
        if (data.type === "custom")
          return { name: item, ...priceTagsSetting[item] };
        if (data.type === "templates")
          return {
            name: item,
            ...FIELD_PRICE_TAG_DEFAULT.field[item],
            active: priceTagsSetting[item],
          };
      } else if (priceTagsSetting) {
        if (data.type === "custom")
          return { name: item, ...FIELD_PRICE_TAG_DEFAULT.field[item] };
        if (data.type === "templates")
          return {
            name: item,
            ...FIELD_PRICE_TAG_DEFAULT.field[item],
            active: false,
          };
      } else {
        if (data.type === "custom")
          return { name: item, ...FIELD_PRICE_TAG_DEFAULT.field[item] };
        if (data.type === "templates")
          return { name: item, ...FIELD_PRICE_TAG_DEFAULT.field[item] };
      }
      return {};
    });

    return data;
  },

  setCurrencyRate(currency_rate, types) {
    let settings = this.state.settings;
    settings["currency_rate"] = JSON.stringify(currency_rate);
    settings["rate_types"] = types;

    this.setState({
      settings: settings,
    });

    Actions.updateSettings();
  },

  getAdditionalFieldsList(type) {
    if (type === "order") {
      return JSON.parse(this.state.settings["order_fields"] || "[]");
    }
    if (type === "sale") {
      return JSON.parse(this.state.settings["sale_fields"] || "[]");
    }
    if (type === "product") {
      return JSON.parse(this.state.settings["product_fields"] || "[]");
    }

    return [];
  },

  getAdditionalFieldsLists() {
    // const productField = this.getAdditionalFieldsList('product')
    // const orderField = this.getAdditionalFieldsList('order')
    const salesField = this.getAdditionalFieldsList("sale");

    return [
      // {
      //     label: 'additional-field-product',
      //     list: productField.map(item => ({name: item, id: item})),
      //     type: 'product'
      // },
      // {
      //     label: "additional-field-order",
      //     list: orderField.map(item => ({name: item, id: item})),
      //     type: "order"
      // },
      {
        label: "additional-field-sale",
        list: salesField.map((item) => ({ name: item, id: item })),
        type: "sale",
      },
    ];
  },

  setAdditionalField(type, fields) {
    let settings = this.state.settings;
    if (type === "order") {
      settings["order_fields"] = JSON.stringify(fields);
    }
    if (type === "sale") {
      settings["sale_fields"] = JSON.stringify(fields);
    }
    if (type === "product") {
      settings["product_fields"] = JSON.stringify(fields);
    }

    this.setState({
      settings: settings,
    });

    Actions.updateSettings();
  },

  setAdditionalFieldByName(type, name, edit) {
    const list = this.getAdditionalFieldsList(type);
    if (edit) {
      const data = list.map((item) => {
        if (item === edit) {
          return name;
        }
        return item;
      });
      this.setAdditionalField(type, data);
    } else {
      this.setAdditionalField(type, [...list, name]);
    }
  },

  deleteAdditionalFieldByName(type, name) {
    const list = this.getAdditionalFieldsList(type);

    this.setAdditionalField(
      type,
      list.filter((item) => item !== name)
    );
  },

  getCustomPrice() {
    const customPrice = JSON.parse(this.state.settings["price_fields"] || "[]");
    const permissionCustomPrices = UserStore.getMoreSetting("custom_prices");

    if (
      !!permissionCustomPrices &&
      permissionCustomPrices[0] !== 0 &&
      customPrice.length
    ) {
      return customPrice.filter((item) =>
        permissionCustomPrices.includes(item)
      );
    }

    return customPrice;
  },

  getCustomPriceForList() {
    const list = this.getCustomPrice();

    return list.map((name) => ({ name, id: name }));
  },

  getCustomPriceValueLabelByIds(ids, getField) {
    const idsString =
      ids && typeof ids === "string" ? ids : JSON.stringify(ids || {});
    if (idsString === "{}" || idsString === "[0]") {
      const data = { value: 0, label: T("all") };
      if (getField) {
        return [data[getField]];
      } else {
        return data;
      }
    }

    const getCustomPrice = this.getCustomPrice();

    const filterCustomPrice = getCustomPrice.filter((customPrice) =>
      ids.some((id) => customPrice === id)
    );

    return filterCustomPrice.map((item) => {
      const data = { value: item, label: item };
      if (getField) {
        return data[getField];
      } else {
        return data;
      }
    });
  },

  addCustomPrice(fields) {
    let settings = this.state.settings;

    settings["price_fields"] = JSON.stringify(fields);

    this.setState({
      settings: settings,
    });

    Actions.updateSettings();
  },

  setCustomPrice(name, edit) {
    const list = this.getCustomPrice();
    if (edit) {
      const fields = list.map((item) => {
        if (item === edit) {
          return name;
        }
        return item;
      });
      this.addCustomPrice(fields);
    } else {
      this.addCustomPrice([...list, name]);
    }
  },

  deleteCustomPrice(name) {
    const list = this.getCustomPrice();

    this.addCustomPrice(list.filter((item) => item !== name));
  },

  getUserId() {
    return this.get("id");
  },

  getTarif() {
    return parseInt(this.get("tarif"));
  },

  getTarifDataAccount() {
    return this.get("tarif_data")[this.getTarif()] || {};
  },

  getTarifCost() {
    let tarif = parseInt(this.get("temp_tarif"));
    if (tarif === 1) {
      tarif = this.getTarif();
    }

    let tarif_data = this.get("tarif_data"),
      current_tarif = tarif_data[tarif],
      amount = current_tarif["amount"][this.state.currency_id];

    return amount;
  },

  getAdditionalSettings() {
    const additionalSettings = localStorage.getItem("additionalSettings");

    return JSON.parse(additionalSettings || "{}");
  },

  getCustomColumns(columnName) {
    const additionalSettings = this.getAdditionalSettings();

    return additionalSettings[columnName] || {};
  },

  onSetCustomColumns(columnName, value) {
    const additionalSettings = this.getAdditionalSettings();

    localStorage.setItem(
      "additionalSettings",
      JSON.stringify({ ...additionalSettings, [columnName]: value })
    );
    Actions.setCustomColumns.completed();
  },
  onSetCustomColumnsCompleted() {
    console.log("add custom columns completed");
  },

  onDeleteCustomColumns(columnName) {
    const additionalSettings = this.getAdditionalSettings();

    if (additionalSettings[columnName]) {
      delete additionalSettings[columnName];
    }

    localStorage.setItem(
      "additionalSettings",
      JSON.stringify(additionalSettings)
    );
    Actions.deleteCustomColumns.completed();
  },

  onDeleteCustomColumnsCompleted() {},

  getMoreSetting(list) {
    const moreSetting = JSON.parse(this.state.settings["more_setting"] || "{}");

    return moreSetting[list];
  },

  setMoreSetting(data, listName) {
    const settings = this.state.settings;

    const moreSettingData = JSON.parse(settings["more_setting"] || "{}");

    const newMoreSettingData = { ...moreSettingData, [listName]: data };

    settings["more_setting"] = JSON.stringify(newMoreSettingData);

    this.setState({
      settings: settings,
    });

    Actions.updateSettings();
  },

  getGlobalSetting(list) {
    const globalSetting = JSON.parse(
      this.state.settings["global_setting"] || "{}"
    );

    return globalSetting[list];
  },

  setGlobalSetting(data, listName) {
    const settings = this.state.settings;

    const globalSettingData = JSON.parse(settings["global_setting"] || "{}");

    const newGlobalSettingData = { ...globalSettingData, [listName]: data };

    settings["global_setting"] = JSON.stringify(newGlobalSettingData);

    this.setState({
      settings: settings,
    });

    Actions.updateSettings();
  },

  deleteGlobalSetting(fieldName) {
    const settings = this.state.settings;
    const globalSetting = settings["global_setting"];
    const parseGlobalSetting =
      typeof globalSetting === "string"
        ? JSON.parse(globalSetting || "{}")
        : globalSetting;

    if (parseGlobalSetting[fieldName]) {
      delete parseGlobalSetting[fieldName];

      settings["global_setting"] = JSON.stringify(parseGlobalSetting);
    }

    this.setState({
      settings: settings,
    });

    Actions.updateSettings();
  },

  getMinStockProduct() {
    const getStocksProduct = this.getGlobalSetting("minStocks");
    if (getStocksProduct) {
      return Object.keys(getStocksProduct).reduce((acc, cur) => {
        if (cur) {
          if (cur === "general") {
            acc.push({
              type: cur,
              name: [T("general")],
              minStock: getStocksProduct[cur][0].minStock,
              index: 0,
            });
          } else {
            getStocksProduct[cur].forEach((item, index) => {
              const name = [];
              const selectList = [];

              item.ids.forEach((id) => {
                let realName = "";
                if (id === 0) {
                  let allName = "";
                  if (cur === "category") {
                    allName = T("all-categories");
                  } else if (cur === "brand") {
                    allName = T("all-brands");
                  } else if (cur === "warehouse") {
                    allName = T("all-warehouse");
                  }
                  realName = T("all");
                  name.push(allName);
                } else {
                  if (cur === "category") {
                    realName = ProductsStore.getCategoryName(id, true);
                  } else if (cur === "brand") {
                    realName = ProductsStore.getBrandName(id);
                  } else if (cur === "warehouse") {
                    realName = WarehouseStore.getWarehouseName(id);
                  }
                  if (realName) {
                    name.push(realName);
                  }
                }
                selectList.push({ value: id, label: realName });
              });

              acc.push({
                ids: item.ids,
                type: cur,
                name,
                minStock: item.minStock,
                index,
                selectList,
              });
            });
          }
        }
        return acc;
      }, []);
    }

    return [];
  },

  setMinStockProduct({ type, minStock, ids, index }) {
    const data = JSON.parse(
      JSON.stringify(this.getGlobalSetting("minStocks") || {})
    );
    if ((index || index === 0) && data[type] && data[type][index]) {
      data[type][index] = { minStock, ids };
    } else if (data[type]) {
      data[type] = [...data[type], { minStock, ids }];
    } else {
      data[type] = [{ minStock, ids }];
    }

    this.setGlobalSetting(data, "minStocks");
  },
  deleteMinStockProduct({ type, index }) {
    const data = JSON.parse(
      JSON.stringify(this.getGlobalSetting("minStocks") || {})
    );

    if ((index || index === 0) && data[type] && data[type][index]) {
      if (data[type].length === 1) {
        delete data[type];
      } else {
        data[type] = data[type].splice(index, 1);
      }
    }

    this.setGlobalSetting(data, "minStocks");
  },

  isMinimalStock({ mid, pid, instock }) {
    if (WarehouseStore.isDropshipping(mid)) return false;

    const getMinStockSettings = this.getGlobalSetting("minStocks");

    const product = ProductsStore.getProductByMid(pid, mid);
    if(product.type_product === 4) return false

    const brand = product?.brand_id;
    const category = product?.category_id;

    if (getMinStockSettings) {
      const findCategoryMin = getMinStockSettings["category"]?.find(
        (item) => item.ids.includes(category) || item.ids.includes(0)
      );
      const findBrandMin = getMinStockSettings["brand"]?.find(
        (item) => item.ids.includes(brand) || item.ids.includes(0)
      );
      const findWarehouseMin = getMinStockSettings["warehouse"]?.find(
        (item) => item.ids.includes(mid) || item.ids.includes(0)
      );

      const generalMin =
        (getMinStockSettings["general"] &&
          getMinStockSettings["general"][0]?.minStock) ||
        0;
      const categoryMin = findCategoryMin?.minStock || 0;
      const brandMin = findBrandMin?.minStock || 0;
      const warehouseMin = findWarehouseMin?.minStock || 0;

      const maxStock = Math.max(categoryMin, warehouseMin, brandMin);
      if (!maxStock && !generalMin) {
        return false;
      } else if (maxStock > generalMin) {
        return maxStock > instock;
      }

      return generalMin > instock;
    }

    return false;
  },

  getBalance() {
    return this.get("balance") || 0;
  },

  getGroupList() {
    let data = this.group_list;
    if (!AppStore.isEnableForWhiteLable("cashier")) {
      data = data.filter(
        function (gr) {
          return parseInt(gr.id) !== this.CASHIER;
        }.bind(this)
      );
    }
    return data;
  },

  getPriceTagsSizeList() {
    return this.pricetags_size;
  },

  getGroupName(group_id) {
    const data = this.getGroupList().filter(function (gr) {
      return parseInt(gr.id) === parseInt(group_id);
    });
    return data.length > 0 ? T(data[0]["name"]) : "";
  },

  getMyGroup() {
    return this.getGroupName(this.state.group);
  },

  isMainUser() {
    return parseInt(this.get("realId")) === parseInt(this.get("uid"));
  },

  isCashier() {
    return parseInt(this.get("group")) === this.CASHIER;
  },

  isAdmin() {
    return parseInt(this.get("group")) === this.ADMIN || this.isMainUser();
  },

  isManager() {
    return parseInt(this.get("group")) === this.MANAGER;
  },

  isWarehouseMan() {
    return parseInt(this.get("group")) === this.WAREHOUSEMAN;
  },

  getPermissionNames() {
    return this.permissions;
  },

  getModulesNames() {
    return this.modules;
  },

  getPermissionWarehouse() {
    const setting = this.state.settings.warehouse;

    return typeof setting === "string" ? JSON.parse(setting) : setting;
  },
  getPermissionAccount() {
    const setting = this.state.settings.accounts;

    return typeof setting === "string" ? JSON.parse(setting) : setting;
  },

  getLastPaymentDate() {
    let date = this.get("last_payment_date");
    if (typeof date == "string") {
      date = new Date(date);
    }
    return date.toLocaleDateString();
  },

  getNextPaymentDate() {
    let date = this.get("next_payment_date");
    if (typeof date === "string") {
      const parsedDate = Date.parse(date);

      if (!isNaN(parsedDate)) {
        date = new Date(parsedDate);
      } else {
        return date;
      }
    }

    if (!(date instanceof Date) || isNaN(date)) {
      return date;
    }

    return date.toLocaleDateString();
  },

  getRouteEnable(route) {
    if (route === "/") return true;

    const module = route.split("/")[1];
    return this.getModuleEnable(module);
  },

  isMovingEnable() {
    return (
      this.getModuleEnable("moving") &&
      WarehouseStore.getWarehouseShipmentList().length > 1
    );
  },

  getModuleEnable(name) {
    if (!AppStore.isEnableForWhiteLable(name)) {
      return false;
    }

    if (name === "reserve_sale") {
      name = "reserv";
    } else if (name === "delivery") {
      return this.isDeliveryEnable();
    }

    // if (name === "transactions" && !this.isMainUser()) {
    //     // return false;
    // }

    let modules = [];
    if (this.state.modules) {
      modules = this.state.modules.split(",");
    }

    if (this.isMainUser() || modules.length === 0) {
      const settings = this.state["settings"];
      if ("modules" in settings) {
        return name in settings["modules"] ? !!settings["modules"][name] : true;
      } else {
        return true;
      }
    }

    if (modules.includes(name)) {
      return false;
    } else {
      return true;
    }
  },

  isCanAddSale() {
    return this.getModuleEnable("sales") && !this.isWarehouseMan();
  },

  isDeliveryEnable() {
    const settings = this.state["settings"];
    return settings["np_key"] !== "" || this.getDeliveryService().length > 0;
  },

  isNpCreateTTNEnable() {
    const delivery = this.getNpSender(true);
    return (
      delivery.length > 0 &&
      !delivery[0]["is_new"] &&
      delivery[0]["integration_id"] === this.NP_ID
    );
  },

  isUpCreateTTNEnable() {
    const delivery = this.getUpSender(true);
    return (
      delivery.length > 0 &&
      !delivery[0]["is_new"] &&
      delivery[0]["integration_id"] === this.UP_ID
    );
  },

  getNpSender(withActive) {
    let d = this.getDeliveryService();

    if (withActive) {
      return d.filter(
        (row) =>
          parseInt(row["integration_id"]) === this.NP_ID &&
          parseInt(row.is_active) === 1
      );
    } else {
      return d.filter((row) => parseInt(row["integration_id"]) === this.NP_ID);
    }
  },

  getUpSender(withActive) {
    let d = this.getDeliveryService();
    if (withActive) {
      return d.filter(
        (row) =>
          parseInt(row["integration_id"]) === this.UP_ID &&
          parseInt(row.is_active) === 1
      );
    } else {
      return d.filter((row) => parseInt(row["integration_id"]) === this.UP_ID);
    }
  },

  getDeliveryServiceBase() {
    if (this.hasAccessWhiteLabel(["modeler"])) {
      return [
        { name: "Kazpost", id: "Kazpost" },
        { name: "DHL", id: "DHL" },
        { name: "Takebs", id: "Takebs" },
        { name: "ЕМЕХ", id: "ЕМЕХ" },
        {
          name: "Expert logistic Kazakhstan",
          id: "Expert logistic Kazakhstan",
        },
        { name: "Post Express", id: "Post Express" },
        { name: "Pony Express", id: "Pony Express" },
        { name: "MILog", id: "MILog" },
        { name: "ExLine", id: "ExLine" },
      ];
    }

    if (this.isForeign()) {
      return [
        { name: T("UPS"), id: T("UPS") },
        { name: T("DHL"), id: T("DHL") },
        { name: T("post office"), id: T("post office") },
        { name: T("pickup"), id: T("pickup") },
        { name: T("city-delivery"), id: T("city-delivery") },
        { name: T("outcity-delivery"), id: T("outcity-delivery") },
        { name: T("other"), id: T("other") },
      ];
    } else {
      return [
        { name: T("nova_poshta"), id: 1 },
        { name: T("ukr_poshta"), id: 3 },
        { name: T("meest"), id: T("meest") },
        { name: T("rozetka"), id: 6 },
        { name: T("justin"), id: T("justin") },
        { name: T("pickup"), id: T("pickup") },
        { name: T("city-delivery"), id: T("city-delivery") },
        { name: T("outcity-delivery"), id: T("outcity-delivery") },
        { name: T("international"), id: T("international") },
      ];
    }
  },

  // getIdDeliveryServiceByName(name) {
  //     let list = this.getDeliveryServiceBase();
  //     for (var i = 0; i < list.length; i++) {
  //         if (list[i].name === name) {
  //             return list[i].id;
  //         }
  //     }
  //     return null;
  // },

  getCreatableDeliveryService() {
    let d = this.getDeliveryServiceBase();
    return d.filter((e) => e.id !== 0);
  },

  getCheckboxEnable() {
    const data = this.getIntegrationData(this.CH_ID);
    if (data.length > 1) {
      let is_ok = false;
      for (let i in data) {
        let cc = data[i];
        if (typeof cc["ident"] !== "undefined") {
          is_ok = true;
        }
      }
      return is_ok;
    }
    if (!data || typeof data["ident"] === "undefined") {
      return false;
    }

    return data;
  },

  getPRROList(orderId, isAccount) {
    const checkbox = this.getIntegrationData(this.CH_ID);
    const vchasno = this.getIntegrationData(this.VK_ID);
    const toArray = (data) => {
      if (Array.isArray(data)) {
        return data;
      }
      if (typeof data === "object" && data !== null) {
        return [data];
      }
      return [];
    };
    const checkboxArray = toArray(checkbox);
    const vchasnoArray = toArray(vchasno);

    const combinedArray = [...checkboxArray, ...vchasnoArray];

    const isActiveIntegration = combinedArray.filter(item => !!item.is_active)

    if(isAccount) {
      return !!isActiveIntegration.length
    } else {
      if(!orderId) return []
      const order = SalesStore.getSalesByOid(orderId)
      if(!Object.keys(order)?.length) return []
      const mids = Object.values(order.items).map(item => item.mid)
      const uniqueMids = [...new Set(mids)];

      const realId = this.get("realId");

      const refactoringName = combinedArray.map(item => ({
        ...item,
        name: item?.api_key || ''
      }))

      return refactoringName.filter((item) => {
        if (!item.is_active) return false;
        const parseIdent = parseJSON(item.ident);
        if(parseIdent && !parseIdent?.rid) return true
        if(parseIdent && !parseIdent?.mid) return true
        let result = true
        if (parseIdent && parseIdent.rid && parseIdent.rid[0] === 0) {
          result = result && true
        } else {
          result = result && parseIdent.rid.includes(parseInt(realId))
        }

        if(parseIdent && parseIdent.mid && parseIdent.mid[0] === 0) {
          result = result && true
        } else {
          result = result  &&  uniqueMids.every(item => parseIdent.mid.includes(parseInt(item)))
        }

        return result

      });
    }
  },

  getBinotelList() {
    const list = this.getIntegrationData(this.BI_ID)
    const listArray = objectsToArray(list)

    return listArray.filter(item => !!item.is_active)
  },

  getBinotelUserById(id) {
    const list = this.getBinotelList && Array.isArray(this.getBinotelList()) && this.getBinotelList()[0]


    if(list?.ident) {
      const internalNumber = list?.ident?.internal_numbers

      if(internalNumber) {
        return internalNumber.find(item => item.internalNumber === id)
      }
    }

    return false
  },

  getPRROName(iid) {
    if (iid === 9) {
      return "vchasno";
    } else if (iid === 4) {
      return "checkbox";
    }
    return null;
  },

    isButtonDps({data, orderID}) {
        if(this.getPRROList(orderID).length) {
            if(!data) return true

      if (data && Array.isArray(data) && data?.length) {
        return !data.some((item) => item.prepaid === 0);
      }

      return true;
    }

    return false;
  },

  isButtonPrepaymentDps({ data, prepaid, accountPrepaid, orderID }) {
    if (prepaid > 0 && !ExpensesStore.isDebt(accountPrepaid)) {
      if (this.isButtonDps({ data, orderID })) {
        if (!data) return true;

        if (data && Array.isArray(data) && data?.length) {
          return !data.some((item) => item.prepaid === 1);
        }

        return true;
      }
    }

    return false;
  },

  getPrintCheckboxEnable() {
    const data = this.getIntegrationData(this.CH_ID);

    if (data.length > 1) {
      let is_auto = 1;
      for (let i in data) {
        let ch = data[i],
          _ident;
        if (typeof ch["ident"] === "object") {
          _ident = ch["ident"];
        } else {
          _ident = JSON.parse(ch["ident"]);
        }
        if (parseInt(_ident["is_auto"]) === 0) {
          is_auto = 0;
        }
      }

      return is_auto === 0;
    }

    if (!data || typeof data["ident"] === "undefined") {
      return false;
    }

    let ident = data["ident"];

    return parseInt(ident["is_auto"]) === 0;
  },

  getCheckboxIsAutoEnable() {
    const data = this.getIntegrationData(this.CH_ID);

    if (data.length > 1) {
      let is_auto = 0;
      for (let i in data) {
        let ch = data[i],
          _ident;
        if (typeof ch["ident"] === "object") {
          _ident = ch["ident"];
        } else {
          _ident = JSON.parse(ch["ident"]);
        }

        if (parseInt(_ident["is_auto"]) === 1) {
          is_auto = 1;
        }
      }

      return !!is_auto;
    }

    if (!data || typeof data["ident"] === "undefined") {
      return false;
    }

    let ident = data["ident"];

    return parseInt(ident["is_auto"]) === 1;
  },

  getIntegrationData(id) {
    const settings = this.state["settings"];
    // alert(settings['delivery']);
    let response = [];
    if ("integrations" in settings) {
      let integrations = settings["integrations"];
      if (integrations.length > 0) {
        if (typeof id === "object") {
          response = integrations.filter((d) => {
            return id.indexOf(parseInt(d["integration_id"])) > -1;
          });
        } else {
          response = integrations.filter((d) => {
            return parseInt(d["integration_id"]) === parseInt(id);
          });
        }
        if (response.length === 1) {
          response = response[0];
          if (typeof response["ident"] === "string") {
            response["ident"] = JSON.parse(response["ident"]);
          }
        }
      }
    }

    return response;
  },

  getIntegrationNameById(id) {
    const settings = this.state["settings"];
    // alert(settings['delivery']);
    let response = {};
    if ("integrations" in settings) {
      let integrations = settings["integrations"];
      if (integrations.length > 0) {
        response = integrations.filter((d) => {
          return parseInt(d["id"]) === parseInt(id);
        });
      }
    }

    if (response.length === 1) {
      response = response[0];
      if (typeof response["ident"] === "string") {
        response["ident"] = JSON.parse(response["ident"]);
      }
    }

    return response;
  },

  getIntegrationList(mid) {
    let data = this.getIntegrationData(this.getRemoteSystem());

    let options = [],
      list = [];
    if (data.length === undefined) {
      list.push(data);
    } else {
      list = data;
    }

    for (let i in list) {
      let d = list[i];
      if (mid) {
        const identParse =
          typeof d.ident === "string" ? JSON.parse(d.ident || "{}") : d.ident;
        if (identParse.mid !== mid) continue;
      }
      options.push({
        id: d["id"],
        name: T("integration-" + d["integration_id"]),
      });
    }

    return options;
  },

  getIntegrationListWithChannelName(mid) {
    let data = this.getIntegrationData(this.getRemoteSystem());
    let options = [],
      list = [];
    if (data.length === undefined) {
      list.push(data);
    } else {
      list = data;
    }

    for (let i in list) {
      let d = list[i];
      if (mid) {
        const identParse =
          typeof d.ident === "string" ? JSON.parse(d.ident || "{}") : d.ident;
        if (identParse.mid !== mid) continue;
      }
      if(!d.is_active) continue;
      const getChannel = SalesStore.foundChannelsByIntegrationId(d["id"])
      const getChannelsName = SalesStore.getChannelsName(getChannel)
      const name = getChannelsName === 'not known' ? T("integration-" + d["integration_id"]) : getChannelsName
      options.push({
        id: d["id"],
        name,
      });
    }

    return options;
  },

  getIsIntegration(remote_system_ID) {
    const isIntegrationData = this.getIntegrationData(remote_system_ID);

    return !!isIntegrationData?.is_active;
  },

  isIntegrationEnable() {
    let data = this.getIntegrationData(this.getRemoteSystem());

    return Object.keys(data).length > 0;
  },

  getProductIntegrationModuleEnable(lengthIntegrationProduct) {
    const integrationListLength = this.getIntegrationList().length;

    if (!integrationListLength || !this.isAdmin()) return false;

    return lengthIntegrationProduct < integrationListLength;
  },

  getIntegrationById(iid) {
    const integration = this.getIntegrationData(this.getRemoteSystem());

    let findIntegration;

    if (integration && Array.isArray(integration)) {
      findIntegration = integration.find((item) => item.id === iid);
    } else if (typeof integration === "object") {
      if (integration && integration?.id === iid) {
        findIntegration = integration;
      } else {
        findIntegration = {};
      }
    }

    if (typeof findIntegration?.ident === "string") {
      findIntegration.ident = JSON.parse(findIntegration.ident || "{}");
    }
    return findIntegration;
  },

  isIntegrationWithWarehouse({ mid, iid }) {
    if (iid) {
      const integration = this.getIntegrationById(iid);

      return parseFloat(integration?.ident?.mid) === parseFloat(mid);
    }
    return false;
  },

  isIntegrationChats() {
    let data = this.getIntegrationData(this.getChatsSystem());
    return Object.keys(data).length > 0;
  },

  isEnableWoocommerce() {
    let data = this.getIntegrationData(this.WOO_ID);

    return Object.keys(data).length > 0 ? true : false;
  },

  getDeliveryService() {
    const settings = this.state["settings"];
    // alert(settings['delivery']);
    if ("delivery" in settings) {
      return settings["delivery"];
    } else {
      return [];
    }
  },

  getPermissionEnable(name) {
    let permissions = [];
    if (this.state.permissions) {
      permissions = this.state.permissions.split(",");
    }

    if (this.isMainUser() || permissions.length === 0) {
      const settings = this.state["settings"];
      if ("permissions" in settings) {
        return name in settings["permissions"]
          ? !!settings["permissions"][name]
          : true;
      } else {
        return true;
      }
    }

    if (permissions.includes(name)) {
      return false;
    } else {
      return true;
    }
  },

  isNeedShipment() {
    return (
      WarehouseStore.getWarehouseShipmentList().length > 0 &&
      this.getModuleEnable("shipments")
    );
  },

  setModuleEnable(name, val, _type) {
    let settings = JSON.parse(JSON.stringify(this.state.settings)),
      modules = settings["modules"],
      permissions = settings["permissions"];

    if (_type === "modules") {
      modules[name] = val ? 1 : 0;
    } else if (_type === "permissions") {
      permissions[name] = val ? 1 : 0;
    }

    if (name === "cinstock") {
      this._updateCStock = true;
    }

    settings["modules"] = modules;
    settings["permissions"] = permissions;

    this.setState({
      settings: settings,
    });

    Actions.updateSettings();
  },

  setCurrencyAccount(value) {
    let settings = JSON.parse(JSON.stringify(this.state.settings));

    settings["currency_id"] = value;
    this.setState({
      currency_id: value,
      settings: settings,
    });

    Actions.updateSettings();
  },

  onCheckIp(real_check) {
    if (!real_check) {
      this.setState({
        country: AppStore.getWlName() === "modeler" ? "RU" : "UA",
        currency_id: AppStore.getWlName() === "modeler" ? 6 : 2,
      });
    } else {
      // console.log("this.state", this.state)
      yajax
        .get(this._prefix + "settings")
        .then(Actions.checkIp.completed, Actions.checkIp.failed);
    }
  },

  onCheckIpCompleted(response) {
    if (response["country_code"] === "RU") {
      this.setState({
        country: response["country_code"],
        currency_id: 3,
      });
    } else if (response["country_code"] === "UA") {
      this.setState({
        country: response["country_code"],
        currency_id: 2,
      });
    } else if (response["country_code"] === "KZ") {
      this.setState({
        country: response["country_code"],
        currency_id: 6,
      });
    } else if (response["country_code"] === "TJ") {
      this.setState({
        country: response["country_code"],
        currency_id: 13,
      });
    } else if (response["country_code"] === "UZ") {
      this.setState({
        country: response["country_code"],
        currency_id: 14,
      });
    } else if (response["country_code"] === "BY") {
      this.setState({
        country: response["country_code"],
        currency_id: 3,
      });
    } else {
      this.setState({
        country: "en",
        currency_id: 1,
      });
    }
  },

  onCheckIpFailed() {
    console.log("PROBLEM WITH GET IP");
  },

  onUpdateSettings() {
    yajax
      .post(this._prefix + "settings", {
        settings: JSON.stringify(this.state.settings),
      })
      .then(Actions.updateSettings.completed, Actions.updateSettings.failed);
  },

  onUpdateSettingsCompleted() {
    Actions.loadProfile();
    if (this._updateCStock) {
      setTimeout(WarehouseActions.load, 1000);
      // ProductsActions.needReload();
      this._updateCStock = false;
    }
  },

  onUpdateSettingsFailed() {
    console.log("PROBLEM WITH UPDATE SETTINGS");
  },

  setOnTempIntegration(data) {
    let settings = JSON.parse(JSON.stringify(this.state.settings));
    settings["integrations"].push(data);

    this.setState({
      settings: settings,
    });
  },

  onUpdateIntegrations(data) {
    yajax
      .post(this._prefix + "integrations", { data: JSON.stringify(data) })
      .then(
        Actions.updateIntegrations.completed,
        Actions.updateIntegrations.failed
      );
  },

  onUpdateIntegrationsCompleted(data) {
    if (!data?.error) {
      Actions.loadProfile();
      SalesActions.loadChannels();
    }
  },

  onUpdateIntegrationsFailed() {
    console.log("PROBLEM WITH UPDATE INTEGRATIONS");
  },

  onDeleteIntegration(iid) {
    yajax({
      method: "DELETE",
      url: this._prefix + "integrations",
      data: { iid: iid },
    }).then(
      Actions.deleteIntegration.completed,
      Actions.deleteIntegration.failed
    );
  },

  onDeleteIntegrationCompleted() {
    Actions.loadProfile();
  },

  onDeleteIntegrationFailed() {
    console.log("PROBLEM WITH UPDATE INTEGRATIONS");
  },

  onClearAccount(pass) {
    yajax({
      method: "DELETE",
      url: this._prefix + "settings",
      data: { password: pass },
    }).then(Actions.clearAccount.completed, Actions.clearAccount.failed);
  },

  onClearAccountCompleted() {
    console.log("SUCCESS WITH CLEAR ACCOOUNT ");

    ProductsActions.loadBrand();
    ProductsActions.load(true);
    ProductsActions.loadCategory();
    ShipmentsActions.loadSuppliers();
    ShipmentsActions.load(true);
    ExpensesActions.loadCategory(true);
    ExpensesActions.loadAccounts(true);
    ExpensesActions.load(true);
    SalesActions.load(true);
    ClientsActions.load(true);
    OrdersActions.load(true);
    HistotyActions.load(true);
    SalesActions.loadReports(true);
  },

  onClearAccountFailed() {
    console.log("PROBLEM WITH CLEAR ACCOOUNT ");
  },

  onChangePasswd(old, _new, confirm) {
    yajax({
      method: "POST",
      url: this._prefix + "change-password",
      data: { password: old, new_password: _new, confirm_password: confirm },
    }).then(Actions.changePasswd.completed, Actions.changePasswd.failed);
  },

  onChangePasswdCompleted() {
    console.log("SUCCESS CHANGE PASSWORD ");
  },

  onChangePasswdFailed() {
    console.log("PROBLEM WITH CHANGE PASSWORD ");
  },

  onRecoveryPass(email) {
    yajax({
      method: "POST",
      url: this._prefix + "recovery-password-request",
      data: { email: email, lang: this.getLang() },
    }).then(Actions.recoveryPass.completed, Actions.recoveryPass.failed);
  },

  onRecoveryPassCompleted() {
    console.log("SUCCESS RECOVERY PASSWORD ");
  },

  onRecoveryPassFailed() {
    console.log("PROBLEM WITH RECOVERY PASSWORD ");
  },

  onRecoveryPassCode(code, pass) {
    yajax({
      method: "POST",
      url: this._prefix + "recovery-password",
      data: { code: code, pass: pass, lang: this.getLang() },
    }).then(
      Actions.recoveryPassCode.completed,
      Actions.recoveryPassCode.failed
    );
  },

  onRecoveryPassCodeCompleted() {
    console.log("SUCCESS RECOVERY PASSWORD ");
  },

  onRecoveryPassCodeFailed() {
    console.log("PROBLEM WITH RECOVERY PASSWORD ");
  },

  getCurrency(curr) {
    return T("currency-" + (curr || this.state.currency_id));
  },

  getCurrencyRates(id) {
    let rates = JSON.parse(this.state.settings["currency_rate"] || "{}");
    if (typeof id !== "undefined") {
      return rates[parseInt(id)] || 1;
    }
    return rates;
  },

  getCurrencyCode(curr) {
    const data = this.get("currencies");

    let code = "";
    for (let i in data) {
      if (parseInt(curr) === parseInt(data[i]["id"])) {
        code = data[i]["code"];
      }
    }

    return code;
  },

  getToken() {
    return session.getToken();
  },

  isGuest() {
    return !!this.get("isGuest");
  },

  isRedirect() {
    return !!this.get("isRedirect");
  },

  isStart() {
    return !!this.get("start");
  },

  isFirstReg() {
    return !!this.get("firstReg");
  },

  getTimeZone() {
    return this.get("tz");
  },

  onInitSession(params) {
    session.configure(params);
    if (!session.getToken()) {
      return Actions.initSession.completed();
    }
    this._resolveOnLogin = Actions.initSession.completed;
    this._set("hashHistory", []);
  },

  onInitSessionCompleted() {
    if (this.get("isGuest")) {
    } else {
    }
  },

  keepAlive() {
    session.ping();
  },

  _onSetLang(lang) {
    this.setState({
      lang: lang,
    });
  },

  setFirstReg(reg) {
    this.setState({
      firstReg: reg,
    });
  },

  triggerChange() {
    this.trigger(this.getState());
  },

  _addDelayedTokenRenewal() {
    const token = parseToken(this.getToken());
    if (!token) {
      return false;
    }
    const delay = token.etime - Date.now() - 15 * 60000;
    clearTimeout(this._tokenRenewTimeoutId);
    this._tokenRenewTimeoutId = setTimeout(Actions.renewToken, delay);
  },

  onRenewToken() {
    yajax
      .post(
        this._prefix + "renew",
        { token: encodeURIComponent(this.getToken()) },
        { responseDecoder: (data) => data }
      )
      .then(Actions.renewToken.completed, Actions.renewToken.failed);
  },

  onRenewTokenCompleted(token) {
    if (token) {
      Actions.changeToken(token);
    } else {
      Actions.logout("can't renew token");
    }
  },

  onRenewTokenFailed() {
    Actions.logout("can't renew token");
  },

  getResponsebleName(user_id) {
    let name = "";
    if (!user_id || user_id === this.get("id")) {
      name = this.getGroupName(2);
    } else {
      const user_data = this.getSubUserData(user_id);
      name = this.getGroupName(user_data["group"]);
      if (user_data["name"] && user_data["name"] !== "") {
        name += " (" + user_data["name"] + ")";
      }
    }

    return name;
  },

  getSubUserData(user_id) {
    const data = this.state.subusers.filter((user) => {
      return parseInt(user.user_id) === parseInt(user_id);
    });

    return data.length > 0 ? data[0] : {};
  },

  onGetSubUsers() {
    yajax
      .get(this._prefix + "personnel")
      .then(Actions.getSubUsers.completed, Actions.getSubUsers.failed);
  },

  onGetSubUsersCompleted(data) {
    const users = data["users"].map((user) => {
      if (user.more_setting) {
        user.more_setting = parseJSON(user.more_setting);
      }
      return user;
    });

    if (data["success"]) {
      this.setState({
        subusers: users,
        count_subusers: data["users"].length,
      });
    }
  },

  onGetSubUsersFailed() {
    console.log("fail get subusers");
    // Actions.logout("can't renew token");
  },

  getSubUsersDataList(withPermission, withMainUser) {
    const subusers = this.get("subusers");

    if (subusers === null) {
      Actions.getSubUsers();
      this.setState({
        subusers: [],
      });
      return [];
    }

    if (withPermission) {
      if (!(this.isAdmin() && UserStore.getModuleEnable('settings')) && !this.isMainUser()) {
        const realId = this.get("realId");
        return subusers.filter(
          (item) => parseInt(item.user_id) === parseInt(realId)
        );
      }
    }
    if (this.isMainUser()) {
      return [this.getMainUser(), ...subusers];
    }

    return subusers;
  },
  getMainUser() {
    const userState = this.state;

    return {
      user_id: this.getUserId(),
      email: userState.email,
      group: userState.group,
      is_active: 1,
      more_setting: parseJSON(userState.settings.more_setting),
      name: userState.firstName || userState.fullname || T("main-user"),
    };
  },

  getSubUsersList() {
    const subusers = this.get("subusers");
    if (subusers === null) {
      Actions.getSubUsers();
      return [];
    }

    let return_data = [
      {
        name: this.getGroupName(2),
        id: this.get("id"),
      },
    ];

    for (let i in subusers) {
      let user = subusers[i];
      return_data.push({
        name:
          user["name"] +
          "(" +
          (user["phone"] || user["email"]) +
          "). " +
          this.getGroupName(user["group"]),
        id: user["user_id"],
      });
    }

    return return_data;
  },

  onLogout() {
    yajax
      .post(
        this._prefix + "logout",
        { token: encodeURIComponent(this.getToken()) },
        { responseDecoder: (data) => data }
      )
      .then(Actions.logout.completed, Actions.logout.failed);
  },

  onLogoutCompleted() {
    session.close(true);
    this.setState(this._setDefaultData());
    this.triggerChange();
    console.log("LOGOUT SUCCESS");

    this.postMessage("logout");

    setTimeout(()=>{window.location.reload()}, 1000);
  },

  onLogoutFailed() {},

  normalizeUrl(url) {
    const isHttp =
      url.startsWith("http://") ||
      url.startsWith("https://") ||
      url.startsWith("viber://") ||
      url.startsWith("tg://");

    if (!isHttp) {
      const currentProtocol = window.location.protocol;
      const currentDomain = window.location.host;
      url = currentProtocol + "//" + currentDomain + url;
    }

    return url;
  },

  postMessage(message) {
    if (!this.isMobileApp()) {
      return false;
    }

    let types = message.split("::")[0];

    if (["open-url", "print-url"].indexOf(types) > -1) {
      let url = this.normalizeUrl(message.split("::")[1]);
      // if (types === "print-url") {
      //     if (url.includes("format=pdf")) {
      //       url = url.replace("format=pdf", "format=html");
      //     } else if (url.includes("format=termo")) {
      //       url = url.replace("format=termo", "format=html");
      //     } else if (!url.includes("format=")) {
      //       if (url.includes("?")) {
      //         url += "&format=html";
      //       } else {
      //         url += "?format=html";
      //       }
      //     }
      // }

      message = types + "::" + url;
    }
    if (message === "show-barcode" || message === "hide-barcode") {
      const showBarcode = this.getMoreSetting("scanBarcode");

      if (showBarcode === undefined || !!showBarcode) {
        window.parent.postMessage(message, "*");
      } else {
        window.parent.postMessage("hide-barcode", "*");
      }
    } else {
      window.parent.postMessage(message, "*");
    }
  },

  onChangeTarif(tarif, name) {
    this.setState({ temp_tarif: tarif });
    const amount = this.getTarifCost(),
      bonus_days = this.calcBonusDays(amount);
    yajax
      .post(this._prefix_pay + "change-tarif", {
        tarif: tarif,
        name: name,
        days: bonus_days,
      })
      .then(Actions.changeTarif.completed, Actions.changeTarif.failed);
  },

  onChangeTarifCompleted() {},

  onChangeTarifFailed() {},

  onPaymentSuccess(data) {
    yajax
      .post(this._prefix_pay + "ph/callback", { our: "ourpayment", data: data })
      .then(Actions.paymentSuccess.completed, Actions.paymentSuccess.failed);
  },

  onPaymentSuccessCompleted() {},

  onPaymentSuccessFailed() {},

  onGetCurrentStateTarif() {
    // console.log("start load current state");
    yajax
      .post(this._prefix_pay + "current-state")
      .then(
        Actions.getCurrentStateTarif.completed,
        Actions.getCurrentStateTarif.failed
      );
  },

  onGetCurrentStateTarifCompleted(data) {
    if (!this.isMainUser() && data.logout) {
      return Actions.logout();
    }
    this.setState({
      current_state: data,
    });

    if (this.isMainUser() || this.isAdmin()) {
      const sales_state = data["sales"][0],
        sales_limit = data["sales"][1];
      if (sales_state === "limit") {
        NotificationActions.addFrontNotification(
          "limit-sales",
          T("HTTP 400: Bad Request (Limit: sales)"),
          "danger"
        );
      } else if (sales_state !== "allow") {
        let change_tarif_mess = T("sales-please-change-tarif"),
          message = T("sales_limit_warning", { sales: sales_limit });
        NotificationActions.addFrontNotification(
          "limit-sales",
          [message, " ", change_tarif_mess],
          sales_state === "high" ? "danger" : "warning"
        );
      }

      const products_state = data["products"][0],
        products_limit = data["products"][1];
      if (products_state === "limit") {
        NotificationActions.addFrontNotification(
          "limit-products",
          T("HTTP 400: Bad Request (Limit: products)"),
          "danger"
        );
      } else if (products_state !== "allow") {
        let change_tarif_mess = T("products-please-change-tarif"),
          message = T("products_limit_warning", { products: products_limit });
        NotificationActions.addFrontNotification(
          "limit-products",
          [message, " ", change_tarif_mess],
          products_state === "high" ? "danger" : "warning"
        );
      }

      const payments_state = data["payments"][0],
        payment_days = data["payments"][1];

      if (payments_state === "warning") {
        NotificationActions.addFrontNotification(
          "blocked-payment",
          T("warning-payment", { days: payment_days }),
          "warning"
        );
      } else if (payments_state === "blocked") {
        NotificationActions.addFrontNotification(
          "blocked-payment",
          T("HTTP 400: Bad Request (Limit: payments)"),
          "danger"
        );
      } else if (payments_state === "soon") {
        NotificationActions.addFrontNotification(
          "blocked-payment",
          T("soon-payment"),
          "success"
        );
      } else if (payments_state === "now") {
        NotificationActions.addFrontNotification(
          "blocked-payment",
          T("now-payment"),
          "success"
        );
      } else if (payments_state === "limited") {
        NotificationActions.addFrontNotification(
          "blocked-payment",
          T("limited-payment"),
          "danger"
        );
      } else if (payments_state === "soon_limited") {
        NotificationActions.addFrontNotification(
          "blocked-payment",
          T("limited-payment-soon"),
          "warning",
          payment_days
        );
      }
    } else {
      return null;
    }

    // if (this.isMobileApp()) {
    //     NotificationActions.addFrontNotification('warning', T('update-app'), 'warning')
    // }

    // NotificationActions.addFrontNotification('success', T('telegram-subscribe'), 'success')
  },

  onGetCurrentStateTarifFailed() {},

  onCancelSubscribe() {
    yajax({
      method: "DELETE",
      url: this._prefix_pay + "change-tarif",
    }).then(Actions.cancelSubscribe.completed, Actions.cancelSubscribe.failed);
  },

  onCancelSubscribeCompleted() {
    alert("Вы успешно отписаны. Возвращайтесь к нам!");
    window.location.reload();
  },

  onCancelSubscribeFailed() {},

  onConfirmChangeTarif(tarif) {
    yajax
      .post(this._prefix_pay + "confirm-tarif", { tarif: tarif })
      .then(
        Actions.confirmChangeTarif.completed,
        Actions.confirmChangeTarif.failed
      );
  },

  onConfirmChangeTarifCompleted() {},

  onConfirmChangeTarifFailed() {},

  onLoadTransactions() {
    yajax
      .get(this._prefix_pay + "change-tarif")
      .then(
        Actions.loadTransactions.completed,
        Actions.loadTransactions.failed
      );
  },

  onLoadTransactionsCompleted(result) {
    this.setState({
      transactions: result["data"],
      cards: result["cards"],
    });
  },

  onLoadTransactionsFailed() {},

  onReadMessage(ident) {
    let c = ident.split("-");
    let id = c[c.length - 1];
    yajax.post("/bexpenses/messages", { id: id });
  },

  // onLoadMessages() {
  //     clearTimeout(this.timeoutMessage)
  //
  //     yajax.get(
  //       '/bexpenses/messages'
  //     ).then(Actions.loadMessages.completed, Actions.loadMessages.failed);
  //
  // },
  //
  // onLoadMessagesCompleted(result) {
  //     clearTimeout(this.timeoutMessage)
  //     let data = result['data'];
  //
  //     for (let i in data) {
  //         let mess = data[i],
  //           source = mess['source'];
  //         let _s =  source?.split("-");
  //
  //         if (_s && _s[0] === "integration") {
  //             let integr = this.getIntegrationNameById(_s[1]);
  //             if (!integr['integration_id']) {
  //                 continue;
  //             }
  //
  //             source = T('integration-'+integr['integration_id']);
  //         }
  //
  //         NotificationActions.addFrontNotification(mess['source']+"-"+mess['id'], T(source)+": "+T(mess['name']), mess['color']);
  //     }
  //     this.timeoutMessage = setTimeout(Actions.loadMessages, 1000*60*3)
  //
  //     this.setState({
  //         'messages': data
  //     })
  //
  // },
  //
  // onLoadMessagesFailed() {
  //     clearTimeout(this.timeoutMessage)
  //
  //     this.timeoutMessage = setTimeout(Actions.loadMessages, 1000*60*3)
  // },

  onChangeToken(token, isRedirect) {
    if (!token && this.get("isGuest")) {
      return Actions.changeToken.completed();
    }
    session.start(token, true);
    const data = parseToken(token);
    if (!data) {
      return Actions.changeToken.failed("invalid token: " + token);
    }
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        this._set(key, data[key]);
      }
    }

    this._set(
      "fullname",
      [this.get("firstName") || "", this.get("lastName") || ""]
        .filter(Boolean)
        .join(" ")
    );
    // this._set("isGuest", false);
    Actions.loadProfile();

    return Actions.changeToken.completed(isRedirect);
  },

  onChangeTokenCompleted(isRedirect) {
    this.setState({
      isGuest: !this.getToken(),
      token: this.getToken(),
    });

    this.triggerChange();
    if (this._resolveOnLogin) {
      this._resolveOnLogin();
      delete this._resolveOnLogin;
    }

    this._addDelayedTokenRenewal();
  },

  onChangeTokenFailed(error) {
    if (error.status === 401) {
      Actions.logout("token change failed");
    }
  },

  onKeepAlive() {
    this.keepAlive();
  },

  onLogin(email, pass) {
    if(!this.loadingLogin) {
      this.loadingLogin = true
      const params = {
        method: "POST",
        url: this._prefix + "login",
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },
        data: { email: email, password: pass },
      };

      this._authRequestId += 1;
      const cAuthRequestId = this._authRequestId;

      yajax(params)
        .then((data) => {
          if (this._authRequestId !== cAuthRequestId) {
            return Promise.reject("abort");
          }
          if (data.success) {
            this.postMessage("profit-security:" + email + ";" + pass);
            Actions.changeToken(data.token);
            return data;
          } else {
            return Promise.reject(data.error);
          }
        })
        .then(Actions.login.completed, Actions.login.failed);
    }
  },

  onLoginCompleted(data) {
    this.loadingLogin = false
    // this.setState({"start": data['start']})
  },

  onLoginFailed() {
    this.loadingLogin = false
    // alert('login faieled');
  },

  startCompleted() {
    this.setState({ start: false });
  },

  onLoadProfile(notLoadAll) {
    const token = this.getToken();
    if (!token) {
      return Actions.loadProfile.failed("guest");
    }

    return yajax({
      url: this._prefix + "user-data",
      method: "POST",
      headers: {
        "X-Auth-Token": encodeURIComponent(this.getToken()),
        "Content-Type": "application/x-www-form-urlencoded;",
      },
    }).then(
      (d) => Actions.loadProfile.completed(d, notLoadAll),
      Actions.loadProfile.failed
    );
  },

  getBonusSum() {
    let bonus = this.getOnBoaringStepCurrency(
      this.state.settings['onboarding']
    );

    this._set('bonus', bonus);

    return bonus;
  },

  onLoadProfileCompleted(d, notLoadAll) {
    let data = d["data"];

    if (data.logout) {
      Actions.logout();
    }

    data["bonus"] = this.getOnBoaringStepCurrency(
      data["settings"]["onboarding"]
    );

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        this._set(key, data[key]);
      }
    }

    if (!data["marketplace_id"]) {
      this.setState({ start: true });
    }

    ExpensesActions.loadAccounts();
    let currency_id = this.get("currency_id");
    this.waitingAccounts = ExpensesActions.loadAccounts.completed.listen(
      (data) => {
        let balance = 0;
        for (let r in data["data"]) {
          let account = data["data"][r];
          if (account["not_allow_calc"] !== 1 && account["is_deleted"] !== 1) {
            let amount = parseFloat(account["balance"]);
            if (account["currency_id"] !== currency_id) {
              let rate = this.getCurrencyRates(account["currency_id"]);
              amount = amount * rate;
            }
            balance += amount;
          }
        }

        this.setState({ balance: balance });
      }
    );

    if (!notLoadAll) {
      Actions.getSubUsers();
      WarehouseActions.load();
    }
    this.triggerChange();
  },

  onLoadProfileFailed(error) {
    if (error.status === 401) {
      return Actions.logout("load profile failed");
    }

    //may be we should retry... ?
  },

  // onReg() {
  //   let data = {}

  //   yajax({
  //         url: "/user/reg",
  //         method: "POST",
  //         headers: {
  //             "X-Auth-Token": encodeURIComponent(this.getToken()),
  //             "Content-Type": "application/x-www-form-urlencoded;"
  //         },
  //         data: data
  //   }).then(UserActions.reg.completed, UserActions.reg.failed);
  // },

  // onRegCompleted() {

  // },

  // onRegFailed() {

  // },

  getAdminInternalNumberBinotel() {
    const binotel = this.getBinotelList()

    if(binotel && binotel[0]) {
      return binotel[0].ident.telephone_line
    }

    return null
  },

  onDownloadListCategories(iid) {
    yajax.post('/bremote/import_remote_categories', {iid}).then(Actions.downloadListCategories.completed, Actions.downloadListCategories.failed)
  },
  onDownloadListCategoriesCompleted() {},
  onDownloadListCategoriesFailed() {},

  onDownloadListIntegrationStatus(iid) {
    yajax.post('/bremote/import_remote_order_status', {iid}).then(Actions.downloadListIntegrationStatus.completed, Actions.downloadListIntegrationStatus.failed)
  },
  onDownloadListIntegrationStatusCompleted() {},
  onDownloadListIntegrationStatusFailed() {}
});

export default UserStore;
