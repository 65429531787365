import React, {useState} from "react";
import calcMargin from "../../../../../tools/calc-margin";
import T from "../../../../../components/i18n";
import ExpensesStore from "../../../../expenses/expenses-store";
import List from "react-bulma-components/lib/components/list";
import formatDate from "../../../../../tools/format-date";
import WarehouseStore from "../../../../warehouse/warehouse-store";
import ProductsStore from "../../../products-store";
import User from "../../../../../user/user-store";
import Money from "../../../../../components/money";
import DOM from "react-dom-factories";
import ChannelList from "../../../../sales/common/channel-list";
import { Heading } from "react-bulma-components";
import FieldDesc from "../common/field-desc";

const SalesProductRow = ({ data, units }) => {
  const [isOpen, setIsOpen] = useState(false)
  const margin = calcMargin(data["net_price"], data["amount"]);
  let account = T(ExpensesStore.getAccountName(data["account_id"]));
  if (data["prapaid_account_id"]) {
    account +=
      "/" + T(ExpensesStore.getAccountName(data["prapaid_account_id"]));
  }
  return (
    <List.Item className={isOpen ? 'selected-border' : ''}>
      <div className='cursor-pointer' onClick={() => setIsOpen(prev => !prev)}>
        <Heading className="margin-bottom-0" size={6}>
          ID: {data["order_number"] || data["id"]}. {account}.{" "}
          {WarehouseStore.getWarehouseName(data["mid"])}.{" "}
          {formatDate(data["purchase_date"])}.
        </Heading>
      </div>
      {isOpen && <div className="display-flex-row-gap flex-wrap">
        <div className="display-flex-column">
           <span>
            <FieldDesc
              name="responseble"
              value={User.getResponsebleName(data["responsible_user_id"])}
            />
          </span>
          <span>
            <FieldDesc
              name="sale-channel"
              value={<ChannelList channels={data.channel_id}/>}
            />
          </span>
          <span>
            <FieldDesc
              name="quantity"
              value={ProductsStore.getStockUnits(data["quantity"], units)}
            />
          </span>
        </div>

        <span>
          {User.getPermissionEnable("incomeinformation") && (
            <span>
              <FieldDesc
                name="products-netcost"
                value={
                  <Money
                    aid={data["account_id"]}
                    amount={data["net_price"]}
                    wrapper={DOM.span}
                  />
                }
              />
              <br/>
            </span>
          )}
          <span>
            <FieldDesc
              name="products-price"
              value={
                <Money
                  aid={data["account_id"]}
                  amount={data["amount"]}
                  wrapper={DOM.span}
                />
              }
            />
          </span>
        </span>
        {User.getPermissionEnable("incomeinformation") && (
          <span>
            <span>
              <FieldDesc
                name="sales-profit"
                value={
                  <Money
                    aid={data["account_id"]}
                    amount={data["profit"]}
                    className="has-text-success"
                    wrapper={DOM.span}
                  />
                }
              />
            </span>
            <br/>
            <span>
              <FieldDesc name="margin" value={`${margin}%`}/>
            </span>
          </span>
        )}
      </div>}
    </List.Item>
  );
};

export default SalesProductRow;
