import React from "react";
import {
  Field,
  Control,
  Checkbox,
  Label,
  Radio,
} from "react-bulma-components/lib/components/form";
import BaseFilter from "components/modules/base-filters";
import { connectToStores } from "tools/reflux-tools";
import Image from "react-bulma-components/lib/components/image";
import Columns from "react-bulma-components/lib/components/columns";
import Button from "react-bulma-components/lib/components/button";
import T from "components/i18n";
import ProductsStore from "modules/products/products-store";
import OpenModalFactory from "components/modal/open-modal-factory";
import AddProductsModal from "modules/products/modal/add-product/add-products-modal";
import Actions from "modules/products/products-actions";
import WarehouseStore from "modules/warehouse/warehouse-store";
import { components } from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import User from "user/user-store";
import { filterSearch } from "tools/filters-helper";
import PaginationBlock from "components/pagination";
import { LocalIcon } from "whitelables/wl-name/icons";
import SelectComponent from "../../tools/select-component";
import WarehouseActions from "../../modules/warehouse/warehouse-actions";
import ProductsActions from "modules/products/products-actions";
import SelectProductStore from "./select-product-store";
import SelectProductActions from "./select-product-actions";
import {withIsMobileSize} from "../is-mobile-context/is-mobile-context";
import {isMobile} from "react-device-detect";

const { Menu } = components;

const CustomDropdownIndicator = (props) => {
  const handleBarcodeIconClick = (event) => {
    event.stopPropagation();
    User.postMessage("init-scan");
  };

  return (
    <div style={{ marginLeft: "5px", display: "flex", alignItems: "center" }}>
      {User.isMobileApp() ? (
        <div
          onClick={handleBarcodeIconClick}
          onTouchStart={handleBarcodeIconClick}
        >
          <LocalIcon icon="barcode-scan" width={14} />
        </div>
      ) : null}
      <components.DropdownIndicator {...props} />
    </div>
  );
};

class SelectProducts extends BaseFilter {
  constructor(props) {
    super(props);

    const all = SelectProductStore.getProductList('', props.source)

    this.state = {
      addProductModal: false,
      iaLoader: false,
      focus: true,
      page: 0,
      selected: {},
      menuIsOpen: false,
      successAdd: false,
      all_products: all,
      count: all.length,
      value: "",
      refresh: 0,
    };
    if (props.mid) {
      SelectProductActions.filter("mid", { value: props.mid });
    } else if (props.source === "shipment") {
      console.log("mid change shipment");
      setTimeout(() => {
        SelectProductActions.filter("mid", { value: 0 });
      }, 200);
    }

    this.store = ProductsStore;
    this.count_page = this.store.get("count_page");
    this.actions = Actions;

    this.is_scanner = false;
    this.onChange = this.onChange.bind(this);
    this.blurFocus = this.blurFocus.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.closeAddProduct = this.closeAddProduct.bind(this);
    this.customMenu = this.customMenu.bind(this);
    this.Option = this.Option.bind(this);
    this.onChangeStock = this.onChangeStock.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.getBrandFilter = this.getBrandFilter.bind(this);
    this.getWarehouseFilter = this.getWarehouseFilter.bind(this);
    this.getCategoryFilter = this.getCategoryFilter.bind(this);
    this.getStockFilter = this.getStockFilter.bind(this);
  }

  blurFocus() {
    this.setState({ focus: false });
  }

  onFocus() {
    this.setState({ focus: true });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.mid !== prevProps.mid) {
      SelectProductActions.filter("mid", { value: this.props.mid });
    }
  }

  componentDidMount() {
    const { onChange, selected } = this.props;

    if (selected) {
      let select = {};
      for (let i in selected) {
        let ident = selected[i];
        select[ident] = true;
      }

      this.setState({
        selected: select,
      });
    }

    this.listenAdd = Actions.addProduct.completed.listen((data) => {
      if (data["mid"].length === 1) {
        let newSelect = {
          value: ProductsStore.getIdent(data["pid"], data["mid"][0]),
          label: data["name"],
        };
        if (this.newValue) {
          this.newValue[this.newValue.length - 1] = newSelect;
        } else {
          this.newValue = [newSelect];
        }
        this.setState({ successAdd: true });
      }
    });

    this.listenLoad = Actions.loadByPid.completed.listen((data) => {
      if (this.state.successAdd) {
        onChange(this.newValue, false, true);
        this.setState({
          successAdd: false,
          isLoader: false,
        });
      }
    });
    this.listenFilter = SelectProductActions.filter.listen((data) => {
      const all = SelectProductStore.getProductList('', this.props.source)

      this.setState({
        all_products: all,
        page: 0,
        count: all.length,
      });
    });

    if (this.props.mid) {
      SelectProductActions.filter("mid", { value: this.props.mid });
    }

    this.listenFromScanner = Actions.setProductFromScanner.completed.listen(
      (barcode) => {
        // if (this.state.focus) {
        //     this.is_scanner = true;
        //     return false;
        // }

        const _scan_product = ProductsStore.get("fromScanner");
        console.log("listen from scanner ", _scan_product);
        if (_scan_product) {
          onChange(_scan_product);
          this.closeWindow();
        } else {
          this.newValue = [{ value: barcode, label: barcode, __isNew__: true }];
          this.setState({
            addProductModal: true,
            barcode: barcode,
          });
        }
      }
    );
  }

  componentWillUnmount() {
    if (typeof this.listenAdd == "function") {
      this.listenAdd();
    }
    if (typeof this.listenLoad == "function") {
      this.listenLoad();
    }
    if (typeof this.listenFromScanner == "function") {
      this.listenFromScanner();
    }
    if (typeof this.listenFilter == "function") {
      this.listenFilter();
    }

    SelectProductStore.clearFilter();
  }

  closeWindow(e) {
    const { disableSubmit } = this.props;

    if (typeof disableSubmit === "function") {
      disableSubmit();
    }

    this.setState({ menuIsOpen: false, search: "" });
  }

  onChangeStock(evt) {
    const value = evt.target.value,
      name = evt.target.name;
    SelectProductActions.filter(name, value);

    this.setState({
      refresh: this.state.refresh + 1,
    });
  }

  onChangeSelect(selectedOption, actionMeta) {
    SelectProductActions.filter(actionMeta.name, selectedOption);
  }

  getBrandFilter(label) {
    let list = ProductsStore.getBrandsList();
    if (list.length <= 1) return null;
    return (
      <div className="margin-bottom-10">
        <SelectComponent
          name="brand"
          label={label}
          empty="all-brands"
          autoSelect={false}
          onChange={this.onChangeSelect}
          list={ProductsStore.getBrandsList}
          value={SelectProductStore.getFilter("brand")}
          creatable={false}
        />
      </div>
    );
  }

  getWarehouseFilter(label) {
    let list = WarehouseStore.getWarehouseList();
    if (list.length <= 1) return null;

    return (
      <SelectComponent
        name="mid"
        empty="all-warehouse"
        label={label || false}
        autoSelect={false}
        onChange={this.onChangeSelect}
        value={SelectProductStore.getFilter("mid")}
        list={WarehouseStore.getWarehouseList}
        load={WarehouseActions.load}
        creatable={false}
      />
    );
  }

  getStockFilter(instock) {
    return (
      <Field>
        <Control>
          <Radio
            key="instock-0"
            onChange={this.onChangeStock}
            checked={instock === "0"}
            value="0"
            size="large"
            name="instock"
            className="mySelect"
          >
            {T("_all")}
          </Radio>
          <Radio
            key="instock-1"
            onChange={this.onChangeStock}
            checked={instock === "1"}
            value="1"
            size="large"
            name="instock"
            className="mySelect"
          >
            {T("in-stock")}
          </Radio>
          <Radio
            key="instock-2"
            onChange={this.onChangeStock}
            checked={instock === "2"}
            value="2"
            size="small"
            name="instock"
            className="mySelect"
          >
            {T("out-of-stock")}
          </Radio>
        </Control>
      </Field>
    );
  }

  getCategoryFilter(label) {
    return (
      <SelectComponent
        name="category"
        label={label}
        empty={"all-categories"}
        onChange={this.onChangeSelect}
        autoSelect={false}
        value={SelectProductStore.getFilter("category")}
        list={ProductsStore.getFormattingCategoryList}
        load={ProductsActions.loadCategory}
        creatable={false}
      />
    );
  }

  customMenu({ children, ...props }) {
    const { create_mid, mid } = this.props;
    let warehouse = this.getWarehouseFilter();
    let brand = this.getBrandFilter();
    let className = "select-produt-filter";
    let menuClassName = "select-products-menu";

    if (this.props.isMobileSize) className += " is-mobile";

    return (
      <Menu {...props} className={menuClassName}>
        <Columns className={className}>
          {warehouse && !create_mid && !mid ? (
            <Columns.Column style={{ minWidth: "150px" }}>
              {" "}
              {warehouse}{" "}
            </Columns.Column>
          ) : null}
          <Columns.Column style={{ minWidth: "150px" }}>
            {" "}
            {this.getCategoryFilter()}{" "}
          </Columns.Column>
          {/*{shipment ? <Columns.Column> {shipment} </Columns.Column> : null}*/}
          {brand ? (
            <Columns.Column style={{ minWidth: "150px" }}>
              {" "}
              {brand}{" "}
            </Columns.Column>
          ) : null}
        </Columns>
        <div style={{ padding: "0 0 0.75rem 1rem" }} key={this.state.refresh}>
          {this.getStockFilter(SelectProductStore.getFilter("instock"))}
        </div>
        {children}
        <Columns className="is-mobile marginn-5" multiline={false} onClick={evt => evt.stopPropagation()}>
          <Columns.Column size="one-fifth">
            <div>
              <b>
                {Object.keys(this.state.selected).length} {T("items-short")}
              </b>
            </div>
          </Columns.Column>
          <Columns.Column>
            <PaginationBlock
              buttonClassName="mySelect"
              showPrevNext={false}
              setPage={(page) => {
                this.setState({ page: page });
              }}
              delta={1}
              totalPage={this.count_page}
              currentPage={this.state.page}
            />
          </Columns.Column>
        </Columns>
        <Button.Group
          position="right"
          className="margin-bottom-10 margin-right-5"
        >
          <Button
            size={"small"}
            fullwidth={false}
            rounded
            className="mySelect"
            color="light"
            onClick={() => {
              if(!isMobile) {
                this.closeWindow();
              }
            }}
            onTouchStart={() => {
              this.closeWindow()
            }}
          >
            {T("close")}
          </Button>
          <Button
            size={"small"}
            fullwidth={false}
            rounded
            className="mySelect"
            color="info"
            onClick={() => {
              if(!isMobile) {
                this.closeWindow();
              }
            }}
            onTouchStart={() => {
              this.closeWindow()
            }}
          >
            {T("select-product-button")}
          </Button>{" "}
          <br />
          <br />
        </Button.Group>
      </Menu>
    );
  }

  handleClick(event, value, data) {
    const { disableSubmit } = this.props;

    if (event.target.type === "checkbox") {
      this.onChangeCheckbox(data);
      event.stopPropagation();
    } else {
      if (typeof disableSubmit === "function") {
        disableSubmit();
      }
    }
  }

  Option(props) {
    const { source, single } = this.props;
    const { data } = props;
    const img =
      "/bimages/get/34-" +
      User.get("id") +
      "-" +
      data.value.split("_")[0] +
      "-0.jpg";

    let value = data.value;

    if (["shipment", "moving", "inventory"].indexOf(source) > -1) {
      value = value.split("_")[0];
    }
    // myProps.onClick = handleClick;
    // const img = "/bimages/"+data.value+"/0/get?token="+encodeURIComponent(User.getToken());
    return (
      <components.Option {...props} className="pr-item mySelect">
        {!data.__isNew__ && !single ? (
          <Field onClick={(e) => this.handleClick(e, value, data)}>
            <Control className="field-control">
              <Checkbox
                name="pr"
                onChange={() => {}}
                checked={this.state.selected[String(value)] || false}
              />
              <Image src={img} size={32} />
              {data.label}
            </Control>
          </Field>
        ) : (
          <span className="mySelect">{data.label}</span>
        )}
      </components.Option>
    );
  }

  closeAddProduct() {
    this.setState({
      addProductModal: false,
    });
  }

  onChangeCheckbox(newValue) {
    let { onChange, source } = this.props;

    if (newValue) {
      let ident = newValue.value,
        selected = this.state.selected;

      if (["shipment", "moving", "inventory"].indexOf(source) > -1) {
        ident = String(ident.split("_")[0]);
      }

      if (typeof selected[ident] !== "undefined") {
        delete selected[ident];
        onChange([newValue], true);
      } else {
        selected[ident] = true;
        onChange([newValue]);
      }

      this.setState({ selected: selected });
    }
  }

  onChange(newValue, actionMeta) {
    let { onChange } = this.props;
    this.newValue = newValue;

    if (!newValue) {
      onChange([]);
    }

    if (actionMeta.action === "create-option") {
      const newProduct = newValue[newValue.length - 1].value;
      const isbarcode = /^\d+$/.test(newProduct);

      let name = "",
        barcode = "";
      if (isbarcode) {
        barcode = newProduct;
      } else {
        name = newProduct;
      }

      this.setState({
        addProductModal: true,
        barcode: barcode,
        name: name,
      });
    }
    // } else if (actionMeta.action === "select-option") {
    //   onChange(newValue);
    // }
    else if (newValue) {
      let ident = false,
        selected = this.state.selected;
      if (newValue.length > 0) {
        ident = String(newValue[0].value);
      }

      if (typeof selected[ident] !== "undefined" && !this.is_scanner) {
        // if (ident) {
        //   delete selected[ident];
        // }
        // onChange(newValue, true);
        onChange(newValue);
      } else {
        if (ident) {
          selected[ident] = true;
        }
        onChange(newValue);
      }

      this.setState({ selected: selected });
    }

    this.is_scanner = false;
    this.setState({ menuIsOpen: false, search: "" });
  }

  defaultOption(inputValue, products) {
    const { pids, source } = this.props;

    let list = products || this.state.all_products
    if (pids) {
      list = list.filter((i) => pids.indexOf(parseInt(i["id"])) === -1);
    }

    if (inputValue.length > 0 || this.state.search) {
      list = list.filter(
        (item) => {
          return filterSearch(
            inputValue || this.state.search,
            ['id', 'name', 'brand', 'asin', 'asin2', 'sku', 'af', 'attr_dict'],
            item
          )
        }
      );
    }

    let lengthWarehouse = 0;

    if (SelectProductStore.get("_filter").mid.value === 0) {
      const length = WarehouseStore.getList().length;
      if (length > 5 && length < 15) {
        lengthWarehouse = length;
      } else if (length > 15) {
        lengthWarehouse = 15;
      }
    }

    const start =
        this.state.page *
        (ProductsStore.get("count_per_page") - lengthWarehouse),
      end = start + (ProductsStore.get("count_per_page") - lengthWarehouse);

    this.count_page = Math.ceil(
      list.length / (ProductsStore.get("count_per_page") - lengthWarehouse)
    );

    if (this.count_page < this.state.page) {
      this.setState({
        page: 0,
      });
    }

    return SelectProductStore.generateSelectOptions(
      list.slice(start, end),
      source === "shipment"
    );
  }

  async promiseOptions(inputValue) {
    if(inputValue) {
      return new Promise((resolve) => {
        resolve(this.defaultOption(inputValue));
      });
    }
  }

  handleMenuOpen() {
    const { disableSubmit, selected } = this.props;

    this.setState({ menuIsOpen: true });
    if (typeof disableSubmit === "function") {
      disableSubmit(true);
    }

    let sp = typeof selected === "function" ? selected() : false;
    if (sp) {
      let select = {};
      for (let i in sp) {
        let ident = sp[i];

        select[ident] = true;
      }

      this.setState({
        selected: select,
      });
    }
  }

  handleMenuClose(e) {
    const { disableSubmit } = this.props;

    if (typeof disableSubmit === "function") {
      disableSubmit(false);
      return;
    }
    // this.setState({"menuIsOpen": false})
  }

  render() {
    const { value, mid, create_mid, title, quantity, source, disabled, zIndex } =
        this.props,
      onChangeProduct = this.onChange;
    return (
      <Field>
        <Label>{title || T("Product")}</Label>
        <Control className="font-size80" style={{zIndex: zIndex || 5}}>
          <AsyncCreatableSelect
            key={this.state.page}
            isClearable
            components={{
              Option: this.Option,
              Menu: this.customMenu,
              DropdownIndicator: CustomDropdownIndicator,
            }}
            formatCreateLabel={(userInput) =>
              `${T("create-new-product")}: ${userInput}`
            }
            placeholder={T("select-product")}
            inputValue={this.state.search}
            onInputChange={(input, {action}) => {
              if(action === 'input-change') {
                this.setState({ search: input })
              }
            }}
            autoFocus={!this.props.isMobileSize}
            styles={{
              multiValue: (base) => ({
                ...base,
                display: "flex",
                width: "160px",
              }),
            }}
            defaultOptions={this.defaultOption("", this.state.all_products)}
            onBlur={this.blurFocus}
            isDisabled={disabled || false}
            onFocus={this.onFocus}
            loadOptions={(inp) => this.promiseOptions(inp)}
            defaultValue={0}
            classNamePrefix={this.props.isMobileSize ? "fixed-copy-select" : "mySelectProduct"}
            onChange={onChangeProduct}
            onMenuOpen={() => this.handleMenuOpen()}
            closeMenuOnSelect={false}
            menuIsOpen={this.state.menuIsOpen}
            onMenuClose={(e) => this.handleMenuClose(e)}
            isMulti
            name="product"
            value={value}
          />
        </Control>
        {this.state.addProductModal
          ? OpenModalFactory(
              <AddProductsModal
                modal="product-modal"
                mid={create_mid || mid}
                quantity={quantity}
                source={source || false}
                barcode={this.state.barcode}
                name={this.state.name}
              />,
              this.closeAddProduct
            )
          : ""}
      </Field>
    );
  }
}

export default connectToStores(withIsMobileSize(SelectProducts), {
  products: ProductsStore,
  warehouse: WarehouseStore,
  select: SelectProductStore,
});
