import React from "react";
import AppStore from "../../../app-store";

const messages = {
  "phone-number-number": {
    ru: "(093) 627-26-65",
    en: "(093) 627-26-65",
    ua: "(093) 627-26-65",
  },
  viber: {
    ru: "Написать в Viber",
    en: "Send message to Viber",
    ua: "Написати в Viber",
  },
  telegram: {
    ru: "Написать в Telegram",
    en: "Send message to Telegram",
    ua: "Написати в Telegram",
  },  
  "country-KZ": {
    ru: "Казахстан",
    en: "Kazakhstan",
    ua: "Казахстан",
  },
  "country-TJ": {
    ru: "Таджикистан",
    en: "Tajikistan",
    ua: "Таджикистан",
  },
  "country-UZ": {
    ru: "Узбекистан",
    en: "Uzbekistan",
    ua: "Узбекистан",
  },
  "country-BY": {
    ru: "Беларусь",
    en: "Belarus",
    ua: "Білорусь",
  },
  "default-title": {
    ru: {
      plural:
        "{name} - простой учет финансов, учет товаров, складской учет, учет продаж и доставок для малого бизнеса. ",
    },
    en: {
      plural:
        "{name} - simple accounting of finance, goods, sales and deliveries for small businesses",
    },
    ua: {
      plural:
        "{name} - простий облік фінансів, облік товарів, складський облік, облік продажів та доставок для малого бізнесу.",
    },
  },
  "default-desc": {
    ru: {
      plural:
        "{name} - это максимально простой сервис для учета остатков товара, контроля продаж и наглядного отображения доходов и расходов вашего магазина. Ничего лишнего. Все просто и удобно. Не нужно никаких специальных знаний и умений",
    },
    en: {
      plural:
        "{name} is the simplest service for keeping track of inventory, monitoring sales and visually displaying your store's income and expenses. Nothing extra. Everything is simple and convenient. No special knowledge and skills required",
    },
    ua: {
      plural:
        "{name} - це максимально простий сервіс для обліку залишків товару, контролю продажів і наочного відображення доходів і витрат вашого магазину. Нічого зайвого. Все просто і зручно. Не потрібно ніяких спеціальних знань і умінь",
    },
  },
  desc: {
    ru: {
      plural:
        "{name} – CRM-система для товарного бизнеса. Учет продаж, товаров, финансов, создание и отслеживание ТТН, работа с клиентской базой.",
    },
    en: {
      plural:
        "{name} is a CRM system for the commodity business. Accounting for sales, goods, creation and tracking of TTN, work with the customer base.",
    },
    ua: {
      plural:
        "{name} — CRM-система для товарного бізнеса. Облік продажів, товарів, фінансів, створення і відстеження ТТН, робота з клієнтською базою.",
    },
  },
  "mobile-app-title": {
    ru: "Мобильные приложения для товарного бизнеса",
    en: "Mobile applications for commodity business",
    ua: "Мобільні додатки (застосунки) для обліку товарного бізнесу",
  },
  "mobile-app-title-app": {
    ru: "Мобильные приложения для учета продаж",
    en: "Mobile applications for sales accounting",
    ua: "Мобільні додатки (застосунки) для обліку продажів",
  },
  "mobile-app-title-shop": {
    ru: "Мобильные приложения для учета в магазине",
    en: "Mobile applications for store accounting",
    ua: "Мобільні додатки (застосунки) для обліку магазину",
  },
  "mobile-app-title-warehouse": {
    ru: "Мобильные приложения для складского учета",
    en: "Mobile applications for warehouse accounting",
    ua: "Мобільні додатки (застосунки) для складського обліку",
  },
  "use-mobile-app": {
    ru: "Используйте все преимущества мобильного устройства для ведения товарного учета: мобильный сканер штрих-кодов, добавление продаж в 2 клика, создание и отслеживание ТТН",
    en: "Use all the advantages of a mobile device for product accounting: mobile barcode scanner, adding a sale in 2 clicks, creating and tracking TTN",
    ua: "Використовуйте всі переваги мобільного пристрою для ведення товарного обліку: мобільний сканер штрих-кодів, додавання продажів у 2 кліки, створення та відстеження ТТН",
  },
  "main-landing-top": {
    ru: <span>CRM-система для товарного бизнеса</span>,
    en: <span>CRM system for commodity business</span>,
    ua: <span>CRM-система для товарного бізнеса</span>,
  },
  "main-landing-top-desc": {
    ru: (
      <span>
        Простой учет финансов, товаров, продаж и доставок для малого бизнеса
      </span>
    ),
    en: (
      <span>
        Simple accounting of finance, goods, sales and deliveries for small
        businesses
      </span>
    ),
    ua: (
      <span>
        Простий облік фінансів, товарів, продаж та доставок для малого бізнесу
      </span>
    ),
  },
  "main-warehouse-top-sub-1": {
    ru: (
      <span>
        <b className="red_text">Самый простой</b> складской учет товаров на
        любом количестве складов или магазинов
      </span>
    ),
    en: (
      <span>
        <b className="red_text">The easiest</b> inventory control of goods in
        any number of warehouses or shops
      </span>
    ),
    ua: (
      <span>
        <b className="red_text">Найпростіший</b> складський облік товарів на
        будь-якій кількості складів чи магазинів
      </span>
    ),
  },
  "main-landing-top-sub-1": {
    ru: "Хватит вести свой учет в Эксель или блокноте! ",
    en: "It is enough to keep the account in Excel!",
    ua: "Досить вести свій облік в Ексель або блокноті!",
  },
  "main-landing-top-sub-2": {
    ru: "Легко контролируйте количество товара, его стоимость и цену продажи на любом количестве складов",
    en: "Easily control the quantity of goods, their value and the selling price at any number of warehouses",
    ua: "Легко контролюйте кількість товару, його вартість і ціну продажу на будь-якій кількості складів",
  },
  "main-landing-top-sub-2-1": {
    ru: (
      <span>
        Легко контролируйте <b className="red_text">количество товара</b>, его
        стоимость и цену продажи на любом количестве складов
      </span>
    ),
    en: (
      <span>
        Easily control <b className="red_text">the quantity of goods</b>, their
        value and the selling price at any number of warehouses
      </span>
    ),
    ua: (
      <span>
        Легко контролюйте <b className="red_text">кількість товару</b>, його
        вартість і ціну продажу на будь-якій кількості складів
      </span>
    ),
  },
  "main-warehouse-top-sub-2-1": {
    ru: (
      <span>
        Добавляйте <b className="red_text">любое количество</b> сотрудников:
        кассиров, менеджеров, работников склада. Выставляйте им необходимые
        права доступа.
      </span>
    ),
    en: (
      <span>
        Add <b className="red_text">any number</b> of employees: cashiers,
        managers, warehouse workers. Give them the necessary permissions.
      </span>
    ),
    ua: (
      <span>
        Додайте <b className="red_text">будь-яку кількість</b> співробітників:
        касирів, менеджерів, працівників складу. Виставляйте їм необхідні права
        доступу.
      </span>
    ),
  },
  "main-landing-top-sub-3": {
    ru: "Ведите учет ваших денег в наличке, безналичке, долгах, карточках и в нычках",
    en: "Keep track of your money in cash, non-cash, debts, cards and in a safe",
    ua: "Ведіть облік ваших грошей в готівці, безготівці, боргах, картках і в хованках",
  },
  "main-landing-top-sub-3-1": {
    ru: (
      <span>
        Ведите <b className="red_text">учет ваших денег</b> в наличке,
        безналичке, долгах, карточках и в нычках. Зарплаты, аренда, закупки
        товара и прочие расходы.
      </span>
    ),
    en: (
      <span>
        Keep <b className="red_text">track of your money</b> in cash, non-cash,
        debts, cards and in a safe. Salaries, rent, purchase of goods and other
        expenses.
      </span>
    ),
    ua: (
      <span>
        Ведіть <b className="red_text">облік ваших грошей</b> в готівці,
        безготівці, боргах, картках і в хованках. Зарплати, оренда, закупівлі
        товару та інші витрати.
      </span>
    ),
  },
  "main-landing-top-sub-0-1": {
    ru: (
      <span>
        <b className="red_text">Добавляйте продажи.</b> С телефона, с дому или с
        рабочего места кассира с помощью сканера штрих-кодов. Узнайте какой
        доход от каждого товара.{" "}
      </span>
    ),
    en: (
      <span>
        <b className="red_text"> Add sales. </b> From your phone, from your
        home, or from the cashier's desk using a barcode scanner. Find out how
        much income each product has.
      </span>
    ),
    ua: (
      <span>
        <b className="red_text"> Додавайте продажі. </b> З телефону, з дому або
        з робочого місця касира за допомогою сканера штрих-кодів. Дізнайтеся
        який дохід від кожного товару.
      </span>
    ),
  },
  "main-landing-top-sub-0-1-online": {
    ru: (
      <span>
        <b className="red_text">Добавляйте продажи.</b> С телефона или
        компьютера, поиском или по штрих-коду. Узнайте какая прибыль от каждого
        товара.{" "}
      </span>
    ),
    en: (
      <span>
        <b className="red_text"> Add sales. </b> From a phone or computer, by
        search or by barcode. Find out how much profit from each product.
      </span>
    ),
    ua: (
      <span>
        <b className="red_text"> Додавайте продажі. </b> З телефону або
        комп'ютера, пошуком або штрих-кодом. Дізнайтесь який прибуток від
        кожного товару.
      </span>
    ),
  },
  "main-landing-top-sub-4": {
    ru: "Контролируйте весь процесс отправки товара через службы доставки",
    en: "Control the entire process of sending goods",
    ua: "Контролюйте весь процес відправки товару через почтові служби",
  },
  "main-landing-top-sub-4-1": {
    ru: (
      <span>
        Контролируйте весь процесс <b className="red_text">отправки товара</b>{" "}
        через службы доставки
      </span>
    ),
    en: (
      <span>
        Control the entire process of <b className="red_text">sending goods</b>
      </span>
    ),
    ua: (
      <span>
        Контролюйте весь процес <b className="red_text">відправки товару</b>{" "}
        через почтові служби
      </span>
    ),
  },
  "main-warehouse-top-sub-4-1": {
    ru: (
      <span>
        Синхронизируй <b className="red_text">товарные остатки</b> с
        интернет-магазинами и маркетплейсами (Prom.ua, WooCommerce, Rozetka)
      </span>
    ),
    en: (
      <span>
        Synchronize <b className="red_text">stock balances</b> with online
        stores and marketplaces (Prom.ua, WooCommerce, Rozetka)
      </span>
    ),
    ua: (
      <span>
        Синхронізуй <b className="red_text">товарні залишки</b> з
        інтернет-магазинами та маркетплейсами (Prom.ua, WooCommerce, Rozetka)
      </span>
    ),
  },
  "main-landing-top-sub": {
    ru: "HugeProfit - это максимально простой сервис, который мы разработали, исходя из собственного опыта продаж. Вы легко сможете фиксировать продажи, контролировать наличие товара и наглядно видеть Ваши реальные доходы и расходы. Ничего лишнего! Все просто и удобно. Не нужно долго вникать и разбираться.",
    en: "HugeProfit is the simplest service that we have developed based on our own sales experience. You can easily record sales, control the availability of goods and visually see your real income and expenses. Nothing extra! Everything is simple and convenient. You do not need to delve into and understand for a long time.",
    ua: "HugeProfit - це максимально простий сервіс, який ми розробили, виходячи з власного досвіду продажів. Ви легко зможете фіксувати продажу, контролювати наявність товару і наочно бачити Ваші реальні доходи і витрати. Нічого зайвого! Все просто і зручно. Не потрібно довго вникати і розбиратися.",
  },
  "try-free": {
    ru: "Начать бесплатно",
    en: "Get Started For Free",
    ua: "Почати безкоштовно",
  },
  "hprofit-title": {
    ru: "HugeProfit - Самый простой учет Ваших продаж",
    en: "HugeProfit - The easiest accounting of your sales",
    ua: "HugeProfit - Найпростіший облік Ваших продажів",
  },
  "price-link": {
    ru: "Цены",
    en: "Prices",
    ua: "Ціни",
  },
  "review-link": {
    ru: "Отзывы",
    en: "Review",
    ua: "Відгуки",
  },
  "service-link": {
    ru: "Услуги",
    en: "Services",
    ua: "Послуги",
  },
  "contact-link": {
    ru: "Контакты",
    en: "Contacts",
    ua: "Контакти",
  },
  "second-block-title": {
    ru: "Кому необходим наш сервис",
    en: "Who needs our service",
    ua: "Кому потрібен наш сервіс",
  },
  "second-block-title-1": {
    ru: "Кому необходима программа для магазина",
    en: "Who needs a store program",
    ua: "Кому потрібна програма для магазину",
  },
  "second-block-title-production": {
    ru: "Кому необходима программа для простого учета производства",
    en: "Who needs a program for simple production accounting",
    ua: "Кому потрібна програма для простого обліку виробництва",
  },
  "second-block-title-warehouse-1": {
    ru: "Кому необходима программа для складского учета",
    en: "Who needs a warehouse management software",
    ua: "Кому потрібна програма для складського обліку",
  },
  need1: {
    ru: "Владельцам магазинов в социальных сетях и мессенджерах",
    en: "Shop owners in social networks and messengers",
    ua: "Власникам магазинів в соціальних мережах і месенджерах",
  },
  need2: {
    ru: "Продавцам на olx, prom, rozetka и других маркетплейсах",
    en: "For sellers on amazon, etsy, ebay and other marketplaces",
    ua: "Продавцям на olx, prom, rozetka і інших маркетплейсах",
  },
  "need2-kz": {
    ru: "Продавцам на маркетплейсах",
    en: "For sellers on marketplaces",
    ua: "Продавцям на маркетплейсах",
  },
  "prod-need1": {
    ru: "Владельцам домашних производств",
    en: "Home production owners",
    ua: "Власникам домашніх виробництв",
  },
  "prod-need2": {
    ru: "Производителям готовой продукции",
    en: "Manufacturers of finished products",
    ua: "Виробникам готової продукції",
  },
  "prod-need3": {
    ru: "Производителям кулинарных изделий",
    en: "Producers of culinary products",
    ua: "Виробникам кулінарних виробів",
  },
  "prod-need4": {
    ru: "Небольшим швейным производствам",
    en: "Small sewing industries",
    ua: "Невеликим швейним виробництвам",
  },
  "sell-on-rozetka": {
    ru: "Как продавать на Розетке?",
    en: "How to sell on the Rozetka.ua?",
    ua: "Як продавати на Розетці?",
  },
  need3: {
    ru: "Интернет-магазинам и Дропшипперам",
    en: "Online Stores and Dropshippers",
    ua: "Інтернет-магазинам і Дропшіпперам",
  },
  need5: {
    ru: "Интернет-магазинам и Оптовым продавцам",
    en: "Online stores and wholesalers",
    ua: "Інтернет-магазинам та оптовим продавцям",
  },
  need4: {
    ru: "Небольшим розничным магазинам в Вашем городе",
    en: "Small retail stores in your city",
    ua: "Невеликим роздрібним магазинам в Вашому місті",
  },
  "second-block-subtitle": {
    ru: "Возьмите под контроль Вашу торговлю!",
    en: "Take control of your trading!",
    ua: "Візьміть під контроль Вашу торгівлю!",
  },
  "second-block-warehouse": {
    ru: "Возьмите под контроль складской учет!",
    en: "Take control of inventory!",
    ua: "Візьміть під контроль складський облік!",
  },
  "thirt-block-title": {
    ru: "Поможет ли наш сервис и Вам?",
    en: "Will our service help you too?",
    ua: "Чи допоможе наш сервіс і Вам?",
  },
  "thirt-block-title-1": {
    ru: "Поможет ли программа для магазина и Вам?",
    en: "Will the store program help you too?",
    ua: "Чи допоможе програма для магазину і Вам?",
  },
  "thirt-block-subtitle": {
    ru: "Попробуйте ответить на эти вопросы о Вашем магазине:",
    en: "Try to answer these questions about your store:",
    ua: "Спробуйте відповісти на ці питання про Ваш магазин:",
  },
  question1: {
    ru: (
      <span>
        Вы знаете, сколько <b>ЧИСТОЙ</b> прибыли Вы получили за вчера? За
        неделю, месяц? <b>Уверены?</b>
      </span>
    ),
    en: (
      <span>
        {" "}
        Do you know how much <b> CLEAN </b> profit you made yesterday? For a
        week, a month? <b> Are you sure? </b>{" "}
      </span>
    ),
    ua: (
      <span>
        {" "}
        Ви знаєте, скільки <b> ЧИСТОГО </b> прибутку Ви отримали за вчора? За
        тиждень, місяць? <b> Чи впевнені? </b>{" "}
      </span>
    ),
  },
  question2: {
    ru: "Сколько и каких товаров у Вас осталось на складе? А сколько денег вложено в товар?",
    en: "How many and what products do you have in stock? How much money is invested in the product?",
    ua: "Скільки і яких товарів у Вас залишилося на складі? А скільки грошей вкладено в товар?",
  },
  question3: {
    ru: "Сколько у Вас было случайных и запланированных  расходов за прошлый месяц?",
    en: "How much incidental and planned expenses did you have in the past month?",
    ua: "Скільки у Вас було випадкових і запланованих витрат за минулий місяць?",
  },
  question4: {
    ru: "Сколько Вам нужно продать, чтобы покрыть все расходы и выйти в плюс? (Ваша точка безубыточности).",
    en: "How much do you need to sell to cover all costs and make a profit? (Your breakeven point).",
    ua: "Скільки Вам потрібно продати, щоб покрити всі витрати і вийти в плюс? (Ваша точка беззбитковості).",
  },
  question5: {
    ru: "Какой канал продаж самый прибыльный?",
    en: "What is the most profitable sales channel?",
    ua: "Який канал продажів найприбутковіший?",
  },
  question6: {
    ru: "Сколько у Вас постоянных покупателей (повторных продаж)? ",
    en: "How many regular customers (repeat sales) do you have? ",
    ua: "Скільки у Вас постійних покупців (повторних продажів)?",
  },
  question7: {
    ru: "Какой товар самый покупаемый? А самый прибыльный?",
    en: "What is the most popular product? And the most profitable one?",
    ua: "Який товар більш за все покупають? А який найприбутковіший?",
  },
  "thirt-block-finish": {
    ru: `Если Вы не смогли ответить хотя бы на один из этих вопросов, ${AppStore.getAppName()} однозначно Вам поможет!`,
    en: `If you could not answer at least one of these questions, ${AppStore.getAppName()} will definitely help you!`,
    ua: `Якщо Ви не змогли відповісти хоча б на одне з цих питань, ${AppStore.getAppName()} однозначно Вам допоможе!`,
  },
  "four-title": {
    ru: "Какие Ваши задачи мы можем помочь решить:",
    en: "What are your tasks we can help to solve:",
    ua: "Які Ваші задачі ми можемо допомогти вирішити:",
  },
  "four-title-shop": {
    ru: "Какие Ваши задачи программа для магазина поможет решить:",
    en: "What your tasks will the store program help to solve?:",
    ua: "Які Ваші завдання програма для магазину допоможе вирішити:",
  },
  "four-title-online-shop": {
    ru: "Как программа для учета продаж может вам помочь:",
    en: "How a Sales Accounting Software Can Help You:",
    ua: "Як програма для обліку продажів може вам допомогти:",
  },
  "four-title-production": {
    ru: "Как программа для учета производства может вам помочь:",
    en: "How a Production Accounting Software Can Help You:",
    ua: "Як програма для обліку виробництва може вам допомогти:",
  },
  "more-review": {
    ru: "Больше реальных отзывов о программе HugeProfit",
    en: "More real testimonials from HugeProfit service",
    ua: "Більше реальних відгуків користувачів сервісу HugeProfit",
  },
  "more-review-facebook": {
    ru: "Отзывы о HugeProfit на Facebook",
    en: "HugeProfit reviews on Facebook",
    ua: "Відгуки про HugeProfit на Facebook",
  },
  "four-title-warehouse": {
    ru: "Как программа для складского учета товаров может вам помочь:",
    en: "How an inventory control software can help you:",
    ua: "Як програма для складського обліку товарів може вам допомогти:",
  },
  "your-service-1": {
    ru: "Простой учет товаров",
    en: "Simple Accounting for goods",
    ua: "Простий облік товарів",
  },
  "your-service-1-desk": {
    ru: "Сколько и какой товар у Вас хранится и на какую сумму. Завоз нового товара (Поставки \\ Закупки). Печать ценников. Прибыль по каждой товарной позиции.",
    en: "How much and what kind of goods do you have and for what amount. Delivery of a new product (Supply \\ Purchase). Printing price tags. Profit for each item.",
    ua: "Скільки і який товар у Вас зберігається і на яку суму. Завезення нового товару (Поставки \\ Закупівлі). Друк цінників. Прибуток за кожною товарною позицією.",
  },

  "your-service-2": {
    ru: "Учёт продаж",
    en: "Sales accounting",
    ua: "Облік продажів",
  },

  "your-service-2-desk": {
    ru: "Добавляй продажи очень быстро с помощью сканера штрих-кодов или вручную. Доходы с каждой продажи.  Скидки постоянным клиентам. Товарные чеки.",
    en: "Add sales very quickly using a barcode scanner or manually. Income from each sale.  Discounts for regular customers. Sales receipts.",
    ua: "Додавай продажі дуже швидко за допомогою сканера штрих-кодів або вручну. Доходи з кожного продажу.  Знижки постійним клієнтам. Товарні чеки.",
  },

  "your-service-2-online": {
    ru: "Учёт продаж в 2 клика",
    en: "Sales accounting in 2 clicks",
    ua: "Облік продажів у 2 кліки",
  },

  "your-service-2-online-desk": {
    ru: "Добавляй продажи двумя кликами. Узнавай прибыль с каждой продажи. Скидки постоянным клиентам. Фискальные товарные чеки.",
    en: "Add sales with two clicks. Find out the profit from each sale. Discounts for regular customers. Fiscal sales receipts.",
    ua: "Додай продаж двома кліками. Дізнайся прибуток з кожного продажу. Знижки для постійних клієнтів. Фіскальні товарні чеки.",
  },

  "your-service-3": {
    ru: "Финансовый учет",
    en: "Financial Accounting",
    ua: "Фінансовий облік",
  },

  "your-service-3-online": {
    ru: "Записывай расходы",
    en: "Record your expenses",
    ua: "Записуй витрати",
  },

  "your-service-3-desk": {
    ru: "Возможность вести несколько счетов. Добавление доходов и расходов. Возвраты от клиентов.",
    en: "The ability to maintain multiple accounts. Adding income and expenses. Customer returns.",
    ua: "Можливість вести кілька рахунків. Додавання доходів і витрат.  Повернення від клієнтів.",
  },

  "your-service-4": {
    ru: "Работа с клиентами",
    en: "Work with clients",
    ua: "Робота з клієнтами",
  },

  "your-service-4-desk": {
    ru: "Программа лояльности. Определение постоянных клиентов. Напоминание о днях рождения. Адреса для доставки.",
    en: "Loyalty program. Definition of regular customers. Birthday reminder. Delivery addresses.",
    ua: "Програма лояльності. Визначення постійних клієнтів. Нагадування про дні народження. Адреси для доставки.",
  },

  "your-service-5": {
    ru: "Интеграция с Новой Почтой, Укрпочтой",
    en: "Integration with Nova Poshta, UkrPoshta",
    ua: "Інтеграція з Новою Поштою, Укрпоштою",
  },

  "your-service-5-shop": {
    ru: "Работа с отправками товара по почте",
    en: "Working with shipments of goods by mail",
    ua: "Робота з відправленням товарів поштою",
  },

  "your-service-5-desk": {
    ru: "Автоматическое создание накладных. Отслеживание статуса доставки. Уведомление, если посылку долго не забирают.",
    en: "Automatic invoice creation. Tracking delivery status. Notification if the parcel is not picked up for a long time.",
    ua: "Автоматичне створення накладних. Відстеження статусу доставки. Повідомлення якщо посилку довго не забирають.",
  },

  "your-service-5-desk-shop": {
    ru: "Список заказов на отправку. Отслеживание статуса доставки. Уведомление, если посылку долго не забирают. Возврат товара.",
    en: "List of orders for shipment. Tracking delivery status. Notification if the package is not picked up for a long time. Purchase returns.",
    ua: "Список замовлень на відправку. Відстеження статусу доставки. Повідомлення якщо посилку довго не забирають. Повернення товару.",
  },

  "your-service-6": {
    ru: "Каналы продаж",
    en: "Sales channels",
    ua: "Канали продажів",
  },

  "your-service-6-desk": {
    ru: "Фильтрация продаж по каналам. Определение самых прибыльных источников продаж.",
    en: "Filtering sales by channel. Identifying the most profitable sources of sales.",
    ua: "Фільтрація продажів по каналах. Визначення найприбутковіших джерел продажів.",
  },
  "your-service-7": {
    ru: "Интеграция с ПРРО (Checkbox)",
    en: "Integration with Checkbox",
    ua: "Інтеграція з ПРРО (Checkbox)",
  },

  "your-service-7-desk": {
    ru: "С 01.01.2022 в Украине обязательные кассовые аппараты. Автоматически делайте фискальные чеки, прямо в нашем интерфейсе.",
    en: "From 01.01.2022 in Ukraine obligatory cash registers. Automatically make fiscal checks, right in our interface.",
    ua: "З 01.01.2022 в Україні є обов'язкові касові апарати. Автоматично робіть фіскальні чеки прямо в нашому інтерфейсі.",
  },
  "your-service-8": {
    ru: "Telegram Bot",
    en: "Telegram Bot",
    ua: "Telegram Bot",
  },

  "your-service-8-desk": {
    ru: "Уведомления о статусах заказов. Регулярные отчеты о доходах. Запись расходов и многое другое.",
    en: "Order status notifications. Regular income reports. Record expenses and more.",
    ua: "Повідомлення про статуси замовлень. Регулярні звіти про прибутки. Запис витрат та багато іншого.",
  },
  "your-service-9": {
    ru: "Интеграция с Woocommerce, Prom, Rozetka, Хорошоп",
    en: "Integration with Woocommerce, Prom, Rozetka, Horoshop",
    ua: "Інтеграція з Woocommerce, Prom, Rozetka, Хорошоп",
  },

  "your-service-9-desk": {
    ru: "Продаешь свои товары на Woocommerce или Prom? Загружай товары, получай заказы, обновляй остатки в одном кабинете ",
    en: "Selling your products on Woocommerce or Prom? Upload products, receive orders, update balances in one account",
    ua: "Продаєш свої товари на Woocommerce чи Prom? Завантажуй товари, отримуй замовлення, оновлюй залишки в одному кабінеті",
  },
  "your-service-10": {
    ru: "Мультивалютность",
    en: "Multicurrency",
    ua: "Мультивалютність",
  },

  "your-service-10-desk": {
    ru: "Закупаешь товары в разных валютах? Любое количество счетов в любой валюте. Перевод между счетами. Оформление поставок ",
    en: "Do you buy goods in different currencies? Any number of accounts in any currency. Transfer between accounts.",
    ua: "Закуповуєш товари у різних валютах? Будь-яка кількість рахунків у будь-якій валюті. Переказ між рахунками. Оформлення постачання",
  },

  "your-service-12": {
    ru: "Поставки\\Перемещение товара",
    en: "Shipments\\Movement of goods",
    ua: "Поставки\\Переміщення товару",
  },

  "your-service-12-desk": {
    ru: "Оформляй поставки товара на склад\\магазин. Перемещай товары со склада в магазин. Печатай накладные и ценники. ",
    en: "Arrange shipments of goods to the warehouse\\shop. Move goods from warehouse to store. Print invoices and price tags.",
    ua: "Оформляй поставки товару на склад магазин. Переміщуй товари зі складу до магазину. Друкуй накладні та цінники.",
  },

  "your-service-13": {
    ru: "Управляй складом с мобильного телефона",
    en: "Manage your warehouse from your mobile phone",
    ua: "Керуй складом з мобільного телефону",
  },

  "your-service-13-desk": {
    ru: "Приложение для iPhone, Android. Встроенный сканер штрих-кодов. Складской учет никогда не был таким простым. ",
    en: "Application for iPhone, Android. Built-in barcode scanner. Inventory management has never been so easy.",
    ua: "Застосунок для iPhone, Android. Вбудований сканер штрих-кодів. Складський облік ніколи не був таким простим.",
  },

  "your-service-11": {
    ru: "Складской учет товаров",
    en: "Warehouse accounting of goods",
    ua: "Складський облік товарів",
  },

  "your-service-15": {
    ru: "Мини-Производство",
    en: "Mini Production",
    ua: "Міні-Виробництво",
  },

  "your-service-15-desk": {
    ru: "Выбирай компоненты, добавляй расходы, подсчитывай себестоимость и производи товары!",
    en: "Select components, add expenses, calculate production costs and produce goods!",
    ua: "Вибирай компоненти, додай витрати, підраховувай собівартість та виробляй товари!",
  },

  "your-service-14": {
    ru: "Интеграция с Telegram-bot, Viber-bot, Instagram, Facebook",
    en: "Integration with Telegram-bot, Viber-bot, Instagram, Facebook",
    ua: "Інтеграція з Telegram-ботом, Viber-ботом, Instagram, Facebook",
  },

  "your-service-14-desk": {
    ru: "Общайся с клиентами в одном чате! Принимай заказы, формируй чеки, смотри историю продаж",
    en: "Select components, add expenses, calculate production costs and produce goods!",
    ua: "Вибирай компоненти, додай витрати, підраховувай собівартість та виробляй товари!",
  },

  "review-salesman": {
    ru: "Отзывы пользователей сервиса",
    en: "Service user reviews",
    ua: "Відгуки користувачів сервісу",
  },
  "many-sales": {
    ru: "Добавили продаж",
    en: "Added sales",
    ua: "Додали продажів",
  },

  "our-clients": {
    ru: "Наши клиенты",
    en: "Our Clients",
    ua: "Наші клієнти",
  },

  "many-products": {
    ru: "Продают товаров",
    en: "Sell goods",
    ua: "Продають товарів",
  },

  "many-sell": {
    ru: "Полученный доход",
    en: "Received income",
    ua: "Отриманий дохід",
  },

  "many-profit": {
    ru: "Получили прибыли",
    en: "Got profit",
    ua: "Отримали прибутків",
  },

  "many-margin": {
    ru: "Средняя маржа",
    en: "Average margin",
    ua: "Середня маржа",
  },

  "connect-to-socialnetwork": {
    ru: "Присоединяйся к нашим официальным группам!",
    en: "Join our official groups!",
    ua: "Приєднуйся до наших офіційних груп!",
  },
  "free-tarif": {
    ru: "Бесплатный тариф",
    en: "Free Plan",
    ua: "Безкоштовний тариф",
  },
  "free-tarif-desc": {
    ru: "Используйте абсолютно ВСЕ функции и интеграции БЕСПЛАТНО неограниченное количество времени. ",
    en: "Use absolutely ALL features and integrations for FREE for an unlimited amount of time.",
    ua: "Використовуйте абсолютно ВСІ функції та інтеграції БЕЗКОШТОВНО необмежену кількість часу.",
  },
  "free-tarif-shop": {
    ru: "Бесплатная программа для магазина",
    en: "Free software for the store",
    ua: "Безкоштовна програма для магазину",
  },
  "free-tarif-accounting": {
    ru: "Бесплатная программа для учета продаж",
    en: "Free software for the sales accounting",
    ua: "Безкоштовна програма для обліку продажів",
  },
  "free-tarif-production": {
    ru: "Бесплатная программа для учета производства",
    en: "Free software for production accounting",
    ua: "Безкоштовна програма для обліку виробництва",
  },
  "free-tarif-warehouse": {
    ru: "Бесплатная программа для складского учета товаров",
    en: "Free program for inventory control of goods",
    ua: "Безкоштовна програма для складського обліку товарів",
  },
  "start-free": {
    ru: "Начать бесплатно",
    en: "Get started for free",
    ua: "Почати безкоштовно",
  },
  "free-tarif-desk": {
    ru: "Пользуйся системой с полным функционалом абсолютно бесплатно до 50 продаж в месяц с возможностью добавить до 300 товаров",
    en: "Use the system with full functionality absolutely free of charge up to 50 sales per month with the ability to add up to 300 products",
    ua: "Користуйся системою з повним функціоналом абсолютно безкоштовно до 50 продажів в місяць з можливістю додати до 300 товарів",
  },
  "for-partner": {
    ru: "Предложения по поводу сотрудничества — пишите на partners@h-profit.com",
    en: "Cooperation proposals - write to partners@h-profit.com",
    ua: "Пропозиції щодо співпраці — пишіть на partners@h-profit.com",
  },
  "for-partner-good-profit-crm": {
    ru: "Предложения по поводу сотрудничества — пишите на partners@gp-crm.com",
    en: "Cooperation proposals - write to partners@gp-crm.com",
    ua: "Пропозиції щодо співпраці — пишіть на partners@gp-crm.com",
  },
  "why-we": {
    ru: "Почему Вам выгодно быть с нами?",
    en: "Why is it profitable for you to be with us?",
    ua: "Чому Вам вигідно бути з нами?",
  },
  "feature-1": {
    ru: "Очень просто и понятно",
    en: "Very simple and straightforward",
    ua: "Дуже просто і зрозуміло",
  },
  "feature-1-desk": {
    ru: "Более простого и понятного сервиса по складскому и финансовому учету Вы не найдете.",
    en: "You will not find a simpler and more understandable service for warehouse and financial accounting.",
    ua: "Більш простого і зрозумілого сервісу за складським та фінансового обліку Ви не знайдете.",
  },
  "feature-2": {
    ru: "Сопровождение",
    en: "Escort",
    ua: "Супровід",
  },
  "feature-2-desk": {
    ru: "Мы рядом с Вами на всех этапах работы. Поможем разобраться, настроить, адаптировать под свои нужды.",
    en: "We are by your side at all stages of work. We will help you figure it out, configure, adapt to your needs.",
    ua: "Ми поруч з Вами на всіх етапах роботи. Допоможемо розібратися, налаштувати, адаптувати під свої потреби.",
  },
  "feature-3": {
    ru: "Мобильно",
    en: "Mobile",
    ua: "Мобільно",
  },
  "feature-3-desk": {
    ru: "Вы можете вносить все данные прямо со своего смартфона на ходу.",
    en: "You can enter all data directly from your smartphone on the go.",
    ua: "Ви можете вносити всі дані прямо зі свого смартфона на ходу.",
  },
  "feature-4": {
    ru: "Не нужно специальных знаний",
    en: "No special knowledge required",
    ua: "Не потрібно спеціальних знань",
  },
  "feature-4-desk": {
    ru: "Не нужно бухгалтерского образования. Все термины адаптированы на понятный язык.",
    en: "No accounting education required. All terms are adapted to understandable language.",
    ua: "Не потрібно бухгалтерської освіти. Всі терміни адаптовані на зрозумілу мову.",
  },
  "feature-5": {
    ru: "Контроль и развитие",
    en: "Control and development",
    ua: "Контроль і розвиток",
  },
  "feature-5-desk": {
    ru: "С нами Вы почувствуете, что полностью контролируете свой бизнес. Имея все данные под рукой в удобном виде можете планировать развитие.",
    en: "With us you will feel that you are in complete control of your business. Having all the data at hand in a convenient form, you can plan development.",
    ua: "З нами Ви відчуєте, що повністю контролюєте свій бізнес. Маючи всі дані під рукою в зручному вигляді можете планувати розвиток.",
  },
  "feature-6": {
    ru: "Безопасно",
    en: "Safely",
    ua: "Безпечно",
  },
  "feature-6-desk": {
    ru: "Все ваши данные хранятся в зашифрованном виде в облачном хранилище.",
    en: "All your data is stored encrypted in the cloud storage.",
    ua: "Всі ваші дані зберігаються в зашифрованому вигляді в хмарному сховищі.",
  },
  "tarif-plan": {
    ru: "Наши тарифные планы",
    en: "Our tariff plans",
    ua: "Наші тарифні плани",
  },
  "your-tarif-plan": {
    ru: "Ваш тарифный план",
    en: "Your tariff plan",
    ua: "Ваш тарифний план",
  },
  "tarif-plan-traine": {
    ru: "Бесплатно",
    en: "FREE",
    ua: "Безкоштовно",
  },
  "tarif-plan-begin": {
    ru: "Начинающий",
    en: "Beginner",
    ua: "Початківець",
  },
  "tarif-plan-middle": {
    ru: "Уверенный",
    en: "Sure",
    ua: "Впевнений",
  },
  "tarif-plan-standart": {
    ru: "Опытный",
    en: "Experienced",
    ua: "Досвідчений",
  },
  "tarif-plan-pro": {
    ru: "Профи",
    en: "Pro",
    ua: "Профі",
  },
  "tarif-plan-shark": {
    ru: "Акула бизнеса",
    en: "Shark",
    ua: "Акула бізнесу",
  },
  "tarif-plan-surenew": {
    ru: "Уверенный",
    en: "Sure",
    ua: "Впевнений",
  },
  "tarif-plan-pronew": {
    ru: "Опытный",
    en: "Experienced",
    ua: "Досвідчений",
  },
  "tarif-plan-sharknew": {
    ru: "Профи",
    en: "Pro",
    ua: "Профі",
  },  
  "tarif-plan-start": {
    ru: "Старт",
    en: "Start",
    ua: "Старт",
  },  
  "tarif-plan-takeoff": {
    ru: "Взлет",
    en: "Takeoff",
    ua: "Взліт",
  },  
  "tarif-plan-liftoff": {
    ru: "Отрыв",
    en: "Liftoff",
    ua: "Відрив",
  },  
  "tarif-plan-profi": {
    ru: "Профи",
    en: "Profi",
    ua: "Профі",
  },  
  "tarif-plan-bussines": {
    ru: "Бизнес",
    en: "Business",
    ua: "Бізнес",
  },
  unlim: {
    ru: "Безлимитно",
    en: "Unlim",
    ua: "Безлімітно",
  },
  "all-tarif-plan-include": {
    ru: "Все тарифные планы включают",
    en: "All tariff plans include",
    ua: "Всі тарифні плани включають",
  },
  "you-can-add": {
    ru: "Вы можете добавить",
    en: "You can add",
    ua: "Ви можете додати",
  },
  "you-can-add-full": {
    ru: "На этом тарифе Вы можете добавлять",
    en: "With this plan you can add",
    ua: "На цьому тарифі Ви можете додавати",
  },
  "sales-month": {
    ru: "продаж/месяц",
    en: "sales/month",
    ua: "продажів/місяць",
  },
  "tarif-products": {
    ru: "товаров",
    en: "items",
    ua: "товарів",
  },
  "cur-month-1": {
    ru: "usd/месяц",
    en: "usd/month",
    ua: "usd/місяць",
  },
  "cur-month-2": {
    ru: "грн/месяц",
    en: "uah/month",
    ua: "грн/місяць",
  },
  "cur-month-3": {
    ru: "руб/месяц",
    en: "rub/month",
    ua: "руб/місяць",
  },
  "dashboard-desk": {
    ru: "Ваша рабочая панель, на которой представлены все важные составляющие Вашего бизнеса в удобном виде.",
    en: "Your dashboard, which presents all the important components of your business in a convenient way.",
    ua: "Ваша робоча панель, на якій представлені всі важливі складові Вашого бізнесу в зручному вигляді.",
  },
  "sales-desk": {
    ru: "Простой учёт продаж. Добавляйте продажи, оформляйте возвраты, смотрите историю и отчеты по продажам. Фильтр и поиск.",
    en: "Simple sales accounting. Add sales, issue returns, view sales history and reports. Filter and search.",
    ua: "Простий облік продажів. Додавайте продажі, оформляйте повернення, дивіться історію і звіти з продажів. Фільтр і пошук.",
  },
  "clients-desk": {
    ru: "Добавляйте Ваших покупателей, назначайте скидки, смотрите историю их покупок",
    en: "Add your customers, assign discounts, see the history of their purchases",
    ua: "Додавайте Ваших покупців, призначайте знижки, дивіться історію їх покупок",
  },
  "payments-desk": {
    ru: "Все Ваши доходы и расходы денег. Это поможет понять реальную доходность Вашего бизнеса.",
    en: "All your income and expenses are money. This will help you understand the real profitability of your business..",
    ua: "Всі Ваші доходи і витрати грошей. Це допоможе зрозуміти реальну прибутковість Вашого бізнесу.",
  },
  "shipments-desk": {
    ru: "Закупили товар по новым ценам для продажи? Оформите поставку",
    en: "Have you purchased an item at new prices for sale? Make delivery",
    ua: "Закупили товар за новими цінами для продажу? оформіть поставку",
  },
  "warehouse-desk": {
    ru: "Если у Вас несколько складов или вы продаете по дропшипингу - создайте несколько складов",
    en: "If you have several warehouses or you are selling by dropshipping, create several warehouses",
    ua: "Якщо у Вас декілька складів або ви продаєте по дропшіпінгу - створіть кілька складів",
  },
  integrations: {
    ru: "Интеграции",
    en: "Integration",
    ua: "Інтеграції",
  },
  "integration-desk": {
    ru: "Мы планируем подключать интеграции с различными сервисами для расширения наших возможностей",
    en: "We plan to connect integrations with various services to expand our capabilities",
    ua: "Ми плануємо підключати інтеграції з різними сервісами для розширення наших можливостей",
  },
  soon: {
    ru: "(cкоро)",
    en: "(soon)",
    ua: "(cкоро)",
  },
  policy: {
    ru: "Политика конфиденциальности",
    en: "Privacy Policy",
    ua: "Політика конфіденційності",
  },
  agreement: {
    ru: "Публичный договор",
    en: "Agreement",
    ua: "Публічний договір",
  },  
  agreement_offer: {
    ru: "Публичная оферта",
    en: "Agreement",
  },
  "social-network-auth": {
    ru: "Авторизация через социальные сети",
    en: "Social media authorization",
    ua: "Авторизація через соціальні мережі",
  },
  contact_info: {
    ru: "Контактная информация",
    en: "Contact information",
    ua: "Контактна інформація",
  },
  general_question: {
    ru: "Общие вопросы",
    en: "General issues",
    ua: "Загальні питання",
  },
  for_partner: {
    ru: "Для партнеров",
    en: "For partners",
    ua: "Для партнерів",
  },
  company_address: {
    ru: "Юридическое название",
    en: "Legal name",
    ua: "Юридична назва",
  },
  for_post: {
    ru: "Юридический адрес",
    en: "Legal address",
    ua: "Юридична адреса",
  },
  for_address: {
    ru: "Фактический адрес",
    en: "Actual address",
    ua: "Фактична адреса",
  },
  pay_return_garant: {
    ru: "Оплата. Возврат. Гарантии",
    en: "Payment. Return. Warranty",
    ua: "Оплата. Повернення. Гарантії",
  },
  program_for_store: {
    ru: "Программа для магазина",
    en: "Program for the store",
    ua: "Програма для магазину",
  },
  "home-title": {
    ru: "CRM система для товарного бизнеса, простой учет продаж и товаров | HugeProfit",
    en: "CRM system for commodity business, simple accounting of finances and goods | HugeProfit",
    ua: "CRM система для товарного бізнесу, простий облік фінансів та товарів | HugeProfit",
  },
  "home-desc": {
    ru: "Простой учет финансов, товаров, продаж и доставок для малого бизнеса ✅ Очень просто и понятно ⚡ Мобильно ⭐ Безопасно ☎️",
    en: "Simple accounting of finances, goods, sales and deliveries for small business ✅ Very simple and understandable ⚡ Mobile ⭐ Safe ☎️",
    ua: "Простий облік фінансів, товарів, продаж та доставок для малого бізнесу ✅ Дуже просто і зрозуміло ⚡ Мобільно ⭐ Безпечно ☎️",
  },
  "home-keywords": {
    ru: "Отслеживание продаж, Управление запасами, Система управления продажами, Отслеживание онлайн и офлайн, Простая бизнес-система, Комбинированные продажи",
    en: "Sales tracking, Inventory management, Sales management system, Online and offline tracking, Simple business system, Combined sales",
    ua: "Відстеження продажів, Управління запасами, Система управління продажами, Онлайн та офлайн відстеження, Проста бізнес-система, Комбіновані продажі"
  },
  "shop-title": {
    ru: "Программа для магазина, система учета для магазина | HugeProfit",
    en: "A program for a store, a program for accounting for goods | HugeProfit",
    ua: "Програма для магазину, програма для обліку товару | HugeProfit",
  },
  "shop-desc": {
    ru: "Самая простая программа для магазина с которой вы легко наведете порядок в своем бизнесе и увеличите доходы ✅ Очень просто и понятно ⚡ Мобильно ⭐ Безопасно ☎️",
    en: "Simple accounting of finances, goods, sales and deliveries for small business ✅ Very simple and clear ⚡ Mobile ⭐ Safe ☎️",
    ua: "Простий облік фінансів, товарів, продаж та доставок для малого бізнесу ✅ Дуже просто і зрозуміло ⚡ Мобільно ⭐ Безпечно ☎️",
  },
  "accounting-title": {
    ru: "Программа для продажи, программа для учета продаж | HugeProfit",
    en: "A program for keeping track of goods in a store, a program for keeping track of goods | HugeProfit",
    ua: "Програма для ведення обліку товару в магазині, програма для обліку товару | HugeProfit",
  },
  "accounting-desc": {
    ru: "Контролюй залишки, продажі, доходи, витрати, створювай ТТН у 2 кліки ✅ Дуже просто і зрозуміло ⚡ Мобільно ⭐ Безпечно ☎️ ",
    en: "Control balances, sales, income, expenses, create TTN in 2 clicks ✅ Very simple and clear ⚡ Mobile ⭐ Safe ☎️",
    ua: "Контролюй залишки, продажі, доходи, витрати, створювай ТТН у 2 кліки ✅ Дуже просто і зрозуміло ⚡ Мобільно ⭐ Безпечно ☎️",
  },
  "warehouse-title": {
    ru: "Складской учет, программа для учета склада | HugeProfit",
    en: "Warehouse accounting, a program for warehouse accounting HugeProfit",
    ua: "Складський облік, програма для обліку складу | HugeProfit",
  },
  "warehouse-desc": {
    ru: "Контролируй учет складских остатков, продаж, доходов и расходов ✅ Очень просто и понятно ⚡ Мобильно ⭐ Безопасно ☎️",
    en: "Control the accounting of warehouse balances, sales, income and expenses ✅ Very simple and clear ⚡ Mobile ⭐ Safe ☎️",
    ua: "Контролюй облік складських залишків, продажів, доходів та витрат ✅ Дуже просто і зрозуміло ⚡ Мобільно ⭐ Безпечно ☎️",
  },
  "contact-title": {
    ru: "Наши контакты | HugeProfit",
    en: "Our contacts | HugeProfit",
    ua: "Наші контакти | HugeProfit",
  },
  "contact-desc": {
    ru: "Контролируй учет складских остатков, продаж, доходов и расходов ✅ Очень просто и понятно ⚡ Мобильно ⭐ Безопасно ☎️",
    en: "If you have any difficulties, you have found any errors or have suggestions for improvement, write to us in the online chat at any time ☎️ ",
    ua: "Якщо у Вас виникли якісь складнощі, Ви виявили якісь помилки або маєте пропозиції щодо вдосконалення, пишіть нам в онлайн чат в будь-який час ☎️",
  },
  program_for_sales: {
    ru: "Программа для учета продаж",
    en: "Sales accounting software",
    ua: "Програма для обліку продажів",
  },
  program_for_warehouse: {
    ru: "Складской учет товаров",
    en: "Warehouse accounting of goods",
    ua: "Складський облік товарів",
  },
  "main-landing-top-sub-1-2": {
    ru: "Они блокируют развитие вашего бизнеса",
    en: "It blocks the development of your business",
    ua: "Вони блокують розвиток вашого бізнесу",
  },
  store_program_1: {
    ru: "Самая простая программа для магазина с которой вы легко наведете порядок в своем бизнесе и увеличите доходы",
    en: "The simplest program for a store with which you can easily put things in order in your business and increase income",
    ua: "Найпростіша програма для магазину з якою ви легко наведете порядок в своєму бізнесі і збільшите доходи",
  },
  store_program_online: {
    ru: "Контролируй остатки, продажи, доходы, расходы, создавай ТТН в 2 клика.",
    en: "Control balances, sales, income, expenses, create TTN in 2 clicks.",
    ua: "Контролюй залишки, продажі, доходи, витрати, створювай ТТН у 2 кліки.",
  },
  production_desc: {
    ru: "Рассчитывай себестоимость производства, производи продукцию, контролируй остатки, добавляй продажи, создавай ТТН в 2 клика.",
    en: "Calculate the cost of production, produce products, control stocks, add sales, create TTN in 2 clicks.",
    ua: "Розраховуй собівартість виробництва, виробляй продукцію, контролюй залишки, додай продаж, створювай ТТН у 2 кліки.",
  },
  warehouse_desck: {
    ru: "Контролируй учет складских остатков, продаж, доходов и расходов",
    en: "Keep records of inventory balances, sales, income and expenses",
    ua: "Контролюй облік складських залишків, продажів, доходів та витрат",
  },
  "secure-save": {
    ru: "Безопасность и анонимность",
    en: "Security and anonymity",
    ua: "Безпека і анонімність",
  },
  "secure-title-1": {
    ru: "256-битное SSL шифрование",
    en: "256-bit SSL encryption",
    ua: "256-бітне SSL-шифрування",
  },
  "secure-desk-1": {
    ru: "Международный стандарт безопасности. Никто не может перехватить ваши данные.",
    en: "International safety standard. No one can intercept your data.",
    ua: "Міжнародний стандарт безпеки. Ніхто не може перехопити ваші дані.",
  },
  "secure-title-2": {
    ru: "Полная анонимность",
    en: "Complete anonymity",
    ua: "Повна анонімність",
  },
  "secure-desk-2": {
    ru: "Мы не требуем вносить никаких официальных данных! Ваших реквизитов, названий и адресов. Ваши данные обезличены и зашифрованы.",
    en: "We do not require you to enter any official data! Your details, names and addresses. Your data is anonymized and encrypted.",
    ua: "Ми не вимагаємо вносити ніяких офіційних даних! Ваших реквізитів, назв і адрес. Ваші дані знеособлені і зашифровані.",
  },
  "secure-title-3": {
    ru: "Облачный сервис",
    en: "Cloud service",
    ua: "Хмарний сервіс",
  },
  "secure-desk-3": {
    ru: "Все данные хранятся в облаке, а не на вашем компьютере, их никто не скопирует, не заразит вирусом и данные не потеряются из-за поломки.",
    en: "All data is stored in the cloud, not on your computer, no one will copy it, infect it with a virus, and the data will not be lost due to breakdown.",
    ua: "Всі дані зберігаються в хмарі, а не на вашому комп'ютері, їх ніхто не скопіює, не заразить вірусом і дані не загубляться через поломки.",
  },
  "secure-title-4": {
    ru: "Ежедневное резервирование",
    en: "Daily Backups",
    ua: "Щоденне резервування",
  },
  "secure-desk-4": {
    ru: "Все ваши данные полностью в безопасности, так как защищены многократным резервированием.",
    en: "All your data is completely safe as it is protected by multiple backups.",
    ua: "Всі ваші дані повністю в безпеці, так як захищені багаторазовим резервуванням.",
  },
  "secure-save-sub-1": {
    ru: (
      <span>
        <b className="red_text">Используем 256-битное SSL шифровани.</b>{" "}
        Международный стандарт безопасности. Никто не может перехватить ваши
        данные.{" "}
      </span>
    ),
    en: (
      <span>
        <b className="red_text">We use 256-bit SSL encryption.</b> International
        safety standard. No one can intercept your data.
      </span>
    ),
    ua: (
      <span>
        <b className="red_text">Використовуємо 256-бітне SSL-шифрування.</b>{" "}
        Міжнародний стандарт безпеки. Ніхто не може перехопити ваші дані.
      </span>
    ),
  },
  "secure-save-sub-2": {
    ru: (
      <span>
        <b className="red_text">Полная анонимность</b>. Мы не требуем вносить
        никаких официальных данных! Ваших реквизитов, названий и адресов. Ваши
        данные обезличены и зашифрованы.{" "}
      </span>
    ),
    en: (
      <span>
        <b className="red_text">Complete anonymity</b>. We do not require you to
        enter any official data! Your details, names and addresses. Your data is
        anonymized and encrypted.
      </span>
    ),
    ua: (
      <span>
        <b className="red_text">Повна анонімність</b>. Ми не вимагаємо вносити
        ніяких офіційних даних! Ваших реквізитів, назв і адрес. Ваші дані
        знеособлені і зашифровані.
      </span>
    ),
  },
  "secure-save-sub-3": {
    ru: (
      <span>
        <b className="red_text">Облачный сервис</b>. Все данные хранятся в
        облаке, а не на вашем компьютере, их никто не скопирует, не заразит
        вирусом и данные не потеряются из-за поломки.{" "}
      </span>
    ),
    en: (
      <span>
        <b className="red_text">Cloud service</b>. All data is stored in the
        cloud, not on your computer, no one will copy it, infect it with a
        virus, and the data will not be lost due to breakdown.
      </span>
    ),
    ua: (
      <span>
        <b className="red_text">Хмарний сервіс</b>. Всі дані зберігаються в
        хмарі, а не на вашому комп'ютері, їх ніхто не скопіює, не заразить
        вірусом і дані не загубляться через поломки.
      </span>
    ),
  },
  "secure-save-sub-4": {
    ru: (
      <span>
        <b className="red_text">Ежедневное резервирование</b>. Все ваши данные
        полностью в безопасности, так как защищены многократным резервированием.{" "}
      </span>
    ),
    en: (
      <span>
        <b className="red_text">Daily Backups</b>. All your data is completely
        safe as it is protected by multiple backups.
      </span>
    ),
    ua: (
      <span>
        <b className="red_text">Щоденне резервування</b>. Всі ваші дані повністю
        в безпеці, так як захищені багаторазовим резервуванням.
      </span>
    ),
  },
  "support-team": {
    ru: "Живая служба заботы",
    en: "Live care service",
    ua: "Жива служба турботи",
  },
  "support-team-item-1": {
    ru: "Мы не только отказываемся от использования роботов и автоответчиков, но и гарантируем, что каждый клиент получит индивидуальное и внимательное обслуживание от наших компетентных специалистов.",
    en: "We not only refrain from using robots and autoresponders, but also ensure that each client receives individual and attentive service from our competent professionals.",
    ua: "Ми не тільки відмовляємось від використання роботів і автовідповідачів, але й гарантуємо, що кожен клієнт отримає індивідуальне та уважне обслуговування від наших компетентних фахівців.",
  },
  "support-team-title-1": {
    ru: "Живое и Компетентное Обслуживание",
    en: "Live and Competent Service",
    ua: "Живе та Компетентне Обслуговування",
  },
  "support-team-item-2": {
    ru: "Наши услуги доступны вам через популярные мессенджеры, такие как Viber, Telegram, WhatsApp и Messenger, обеспечивая максимальный комфорт и удобство взаимодействия.",
    en: "Our services are available to you via popular messengers such as Viber, Telegram, WhatsApp and Messenger, providing maximum comfort and convenience of interaction.",
    ua: "Наші послуги доступні вам через популярні месенджери, такі як Viber, Telegram, WhatsApp і Messenger, забезпечуючи максимальний комфорт і зручність взаємодії.",
  },
  "support-team-title-2": {
    ru: "Удобство и Доступность",
    en: "Convenience and Accessibility",
    ua: "Зручність та Доступність",
  },
  "support-team-item-3": {
    ru: "Наша служба заботы готова сопровождать вас на всех этапах интеграции программы для магазина, предоставляя профессиональную консультационную поддержку и помощь в решении всех вопросов, связанных с финансовым и складским учетом.",
    en: "Our customer care service is ready to accompany you at all stages of integrating a program for your store, providing professional consultancy support and assistance in resolving all issues related to financial and inventory accounting.",
    ua: "Наша служба турботи готова супроводжувати вас на всіх етапах інтеграції програми для магазину, надаючи професійну консультаційну підтримку та допомогу у вирішенні всіх питань, пов'язаних з фінансовим та складським обліком.",
  },
  "support-team-title-3": {
    ru: "Экспертная Поддержка на Каждом Этапе",
    en: "Expert Support at Every Stage",
    ua: "Експертна Підтримка на Кожному Етапі",
  },
  "support-team-item-4": {
    ru: "Мы обеспечиваем индивидуальный подход к каждому клиенту, разрабатывая оптимальные стратегии и решения, направленные на решение конкретных задач и потребностей.",
    en: "We provide an individual approach to each client, developing optimal strategies and solutions tailored to address specific tasks and needs.",
    ua: "Ми забезпечуємо індивідуальний підхід до кожного клієнта, розробляючи оптимальні стратегії та рішення, спрямовані на вирішення конкретних завдань та потреб.",
  },
  "support-team-title-4": {
    ru: "Индивидуальные Решения для Каждого",
    en: "Tailored Solutions for Everyone",
    ua: "Індивідуальні Рішення для Кожного",
  },
  "support-team-item-5": {
    ru: "Наши специалисты имеют многолетний опыт работы в обслуживании клиентов и владеют всеми необходимыми знаниями и навыками для успешного решения даже самых сложных задач.",
    en: "Our experts have many years of experience in customer service and possess all the necessary knowledge and skills to successfully tackle even the most complex tasks.",
    ua: "Наші фахівці мають багаторічний досвід роботи у сфері обслуговування клієнтів та володіють всіма необхідними знаннями та навичками для успішного вирішення навіть найскладніших завдань.",
  },
  "support-team-title-5": {
    ru: "Опыт и Навыки на Службе Вашего Бизнеса",
    en: "Experience and Expertise at Your Service",
    ua: "Досвід та Навички на Службі Вашого Бізнесу",
  },
  "support-team-item-6": {
    ru: "Мы гарантируем вам высокое качество услуг и полное удовлетворение от сотрудничества с нашей компанией благодаря профессионализму наших сотрудников и индивидуальному подходу к каждому клиенту.",
    en: "We guarantee you high-quality services and complete satisfaction from cooperating with our company, thanks to the professionalism of our employees and an individual approach to each client.",
    ua: "Ми гарантуємо вам високу якість послуг та повне задоволення від співпраці з нашою компанією, завдяки професіоналізму наших співробітників та індивідуальному підходу до кожного клієнта.",
  },
  "support-team-title-6": {
    ru: "Качество и Удовольствие от Сотрудничества",
    en: "Quality and Satisfaction in Collaboration",
    ua: "Якість та Задоволення від Співпраці",
  },
  "try-free-last": {
    ru: `Попробуйте ${AppStore.getAppName()} бесплатно`,
    en: `Try ${AppStore.getAppName()} for free`,
    ua: `Спробуйте ${AppStore.getAppName()} безкоштовно`,
  },
  "more-review-warehouse": {
    ru: "Больше отзывов про программу складского учета HugeProfit",
    en: "More reviews about the HugeProfit warehouse accounting program",
    ua: "Більше відгуків про програму складського обліку HugeProfit",
  },
  "try-free-last-desk": {
    ru: "Убедитесь лично, что контролировать свой бизнес - это просто!",
    en: "Make sure personally that controlling your business is easy!",
    ua: "Переконайтеся особисто, що контролювати свій бізнес - це просто!",
  },
  "try-free-last-desk-1": {
    ru: "Внедрите сервис за 5 минут самостоятельно или с нашей поддержкой",
    en: "Implement the service in 5 minutes on your own or with our support",
    ua: "Впровадьте сервіс за 5 хвилин самостійно або з нашою підтримкою",
  },
  "try-free-last-desk-2": {
    ru: "Наведите порядок в остатках ваших товаров и упорядочьте поставки",
    en: "Organize your inventory and deliveries",
    ua: "Наведіть порядок у залишках ваших товарів і упорядкуйте поставки",
  },
  "try-free-last-desk-3": {
    ru: "Контролируйте все расходы и платежи. Узнайте реальную картину вашего бизнеса",
    en: "Control all expenses and payments. Find out the real picture of your business",
    ua: "Контролюйте всі витрати і платежі. Дізнайтеся реальну картину вашого бізнесу",
  },
  "try-free-last-desk-4": {
    ru: "Получай заказы с Prom, Rozetka, OpenCart, Woocommerce",
    en: "Receive orders from Prom, Rozetka, OpenCart, Woocommerce",
    ua: "Отримуй замовлення з Prom, Rozetka, OpenCart, Woocommerce",
  },
  blog: {
    ru: "Блог",
    en: "Blog",
    ua: "Блог",
  },
  write_us: {
    ru: "Напишите нам",
    en: "Write to us",
    ua: "Напишіть нам",
  },

  "small-production": {
    ru: "Программа для учета производства",
    en: "Accounting for production",
    ua: "Програма для обліку виробництва",
  },
  "small-production-company": {
    ru: "Самый простой учет производства собственной продукции",
    en: "The easiest accounting for the production of your own products",
    ua: "Найпростіший облік виробництва власної продукції",
  },

  "start-accounting-sales": {
    ru: "Начать вести учет продаж",
    en: "Start keeping track of sales",
    ua: "Почати вести облік продажів",
  },

  "home-text-h1": {
    ru: "Лонгрид от создателей сервиса по простому учету продаж",
    en: "Longread from the creators of the service for simple sales accounting",
    ua: "Лонгрід від творців сервісу по простому обліку продажів",
  },
  marketplaces: {
    ru: "Маркетплейсы",
    en: "Marketplaces",
    ua: "Маркетплейси",
  },

  "marketplaces-desc": {
    ru: "Принимайте заказы с Rozetka, Prom, Woocommerce в одном месте!",
    en: "Take orders from Rozetka, Prom, Woocommerce in one place!",
    ua: "Приймайте замовлення з Rozetka, Prom, Woocommerce в одному місці!",
  },

  "k.": {
    ru: "тыс",
    en: "k",
    ua: "тис",
  },
  mln: {
    ru: "млн",
    en: "mln",
    ua: "млн",
  },
  mlr: {
    ru: "млрд",
    en: "billion",
    ua: "млрд",
  },
  "create-account": {
    ru: "Создать аккаунт",
    en: "Create account",
    ua: "Створити аккаунт",
  },

  "home-text-h1-dest": {
    ru: (
      <div>
        <h2>Простой учет продаж и товаров</h2>
        <p>
          {AppStore.getAppName()} - это главное рабочее пространство для
          современных продавцов физических товаров, где предприниматель может
          контролировать остатки товаров на складе, добавлять и обрабатывать
          продажи, вести базу покупателей, контролировать все расходы и реально
          узнать сколько он зарабатывает!
        </p>
        <p>
          Этот продукт мы начали разрабатывать в первую очередь для себя, после
          того как сами занялись товарным бизнесом, погрузились в проблемы и не
          смогли найти хороших и простых решений.
        </p>
        <h2>А вы похожи на Марину?</h2>
        <i>
          <p>
            Представьте Марину, владелеца маленького магазина детских товаров в
            своем городе, которая продает свои товары не только в магазине, но и
            через Instagram, OLX, Viber, Facebook, Prom, через свой сайт и через
            партнеров. У нее в наличии тысячи единиц товаров и тысячи товаров по
            системе дропшиппинг. В команде у нее только она и один продавец в
            магазине. Товары у нее хранятся в магазине, у партнеров, а также
            дома на балконе.{" "}
          </p>
          <p>
            Марина ежедневно отправляет через службы доставки десятки посылок
            как с предоплатой так и наложенным платежом. В связи с ее
            деятельностью у нее есть регулярные платежи, такие как{" "}
            <b>
              аренда, коммунальные, зарплата, оплата интернета, смена ковриков в
              магазине
            </b>{" "}
            и многое другое. Но также регулярно бывают и дополнительные расходы
            связанные с оплатой{" "}
            <b>рекламы у блогеров, контекстной рекламы, объявлений</b>и другое.
          </p>
          <p>
            Марина ежедневно страдает от того, что когда ей пишут покупатели,
            она не знает, какие товары у нее есть в наличии и где они лежат, в
            магазине, у партнера или на балконе?{" "}
            <b>Продали мы того синего зайца или он в магазине? </b>
          </p>
          <p>
            А также у нее не всегда получается отследить и проконтролировать все
            отправки товаров. Отправил ли продавец посылку в Киев или забыл?{" "}
          </p>
          <p>
            Марина ежедневно тратит огромное количество сил и времени, на то
            чтобы добавлять новые позиции и следить за наличием товаров на все
            10 площадок, где она продает!{" "}
          </p>
          <p>
            А как узнать какой из каналов продаж самый выгодный? Вроде с
            инстаграмма много заказов, но вроде и с сайта неплохо, куда лучше
            больше уделять внимание?{" "}
          </p>
          <p>
            А сколько она все же заработала за этот месяц? Сколько денег она
            может взять из бизнеса не повредив бизнесу?{" "}
          </p>
          <p>
            <b>Как Марине все это удержать в голове и проконтролировать? </b>
          </p>
        </i>
        <p>
          Если вы узнали себя в Марине, тогда наш сервис для простого учета
          продаж и товаров однозначно вам поможет! Вам не прийдется больше
          беспокоиться о складском учете, об учете продаж и учете товаров. Наша
          система возьмет это на себя и поможет автоматизировать сложные
          процессы.
        </p>
        <h2>Мы столкнулись со всем этим</h2>
        <p>
          Мы столкнулись со всем этим, когда запустили свой товарный бизнес. С
          этим ежедневно сталкиваются множество наших друзей, которые также
          занимаются производством и продажей различных товаров и десятки тысяч
          других продавцов. Нам нужен был <strong>простой учет товара</strong>,
          куда можно было просто добавить весь товар и знать сколько и какого
          товара у нас хранится!
        </p>
        <h2>Простой складской учет товара без специальных терминов</h2>
        <p>
          {AppStore.getAppName()} - это максимально простой учет продаж,
          складской учет и простой учет финансов для малого бизнеса, в котором
          мы не используем сложные бухгалтерские термины, такие как проводки,
          номенклатура, приходные ордера и другие.{" "}
        </p>
        <p>
          Наш сервис поможет любому продавцу взять под контроль свой товарный
          бизнес и облегчить множество задач: складской учет, учет товаров,
          финансовый учет, контроль отправок, анализ по каналам продаж, учет
          клиентов и многое другое. И это все доступно в одном простом
          интерфейсе, понятному любому начинающему продавцу.
        </p>
        <p>
          А если вам чего-то не хватает в сервисе, вы всегда можете написать нам
          и мы постараемся в короткие сроки это добавить для вас!
        </p>
      </div>
    ),
    en: (
      <div>
        <h2> Simple accounting of sales and goods </h2>
        <p>
          {" "}
          {AppStore.getAppName()} is the main workspace for modern sellers of
          physical goods, where the entrepreneur can control the balance of
          goods in the warehouse, add and process sales, maintain a customer
          base, control all expenses and really find out how much he earns!{" "}
        </p>
        <p>
          {" "}
          We began to develop this product primarily for ourselves, after we
          ourselves went into the commodity business, plunged into problems and
          could not find good and simple solutions.{" "}
        </p>
        <h2> Do you look like Marina? </h2>
        <i>
          {" "}
          <p>
            {" "}
            Imagine Marina, the owner of a small children's goods store in her
            city, who sells her goods not only in the store, but also through
            Instagram, OLX, Viber, Facebook, Prom, through her website and
            through partners. She has thousands of items in stock and thousands
            of dropshipping items. In the team she has only her and one
            salesperson in the store. Her goods are stored in the store, with
            partners, as well as at home on the balcony.{" "}
          </p>
          <p>
            {" "}
            Marina daily sends dozens of parcels through delivery services, both
            prepaid and cash on delivery. In connection with her activities, she
            has recurring payments such as{" "}
            <b>
              {" "}
              rent, utilities, salaries, internet bills, change of rugs in the
              store{" "}
            </b>{" "}
            and much more. But there are also regular additional costs
            associated with paying for{" "}
            <b> advertising from bloggers, contextual advertising, ads </b> and
            more.{" "}
          </p>
          <p>
            {" "}
            Marina suffers daily from the fact that when customers write to her,
            she does not know what products she has in stock and where they are,
            in a store, with a partner or on the balcony?{" "}
            <b> Did we sell that blue hare or is it in the store? </b>{" "}
          </p>
          <p>
            {" "}
            And also she is not always able to track and control all shipments
            of goods. Did the seller send the package to Kiev or forgot?{" "}
          </p>
          <p>
            {" "}
            Marina spends a huge amount of time and effort every day to add new
            positions and monitor the availability of goods at all 10 sites
            where she sells!{" "}
          </p>
          <p>
            {" "}
            How do you know which of the sales channels is the most profitable?
            It seems that there are a lot of orders from Instagram, but it seems
            that it is not bad from the site, where is it better to pay more
            attention?{" "}
          </p>
          <p>
            {" "}
            And how much did she earn this month? How much money can she take
            from the business without hurting the business?{" "}
          </p>
          <p>
            {" "}
            <b> How can Marina keep all this in her head and control? </b>
          </p>{" "}
        </i>
        <p>
          {" "}
          If you recognize yourself in the Marina, then our service for simple
          accounting of sales and goods will definitely help you!{" "}
        </p>
        <h2> We ran into all this </h2>
        <p>
          {" "}
          We faced all this when we launched our commodity business. Many of our
          friends, who are also engaged in the production and sale of various
          goods and tens of thousands of other sellers, face this every day. We
          needed a <strong> simple inventory of goods </strong>, where you could
          just add all the goods and know how much and what kind of goods we
          have stored!{" "}
        </p>
        <h2> Simple accounting of goods without special terms </h2>
        <p>
          {" "}
          {AppStore.getAppName()} is the simplest possible sales accounting and
          simple finance accounting for small businesses, in which we do not use
          complex accounting terms such as transactions, items, receipts and
          others.{" "}
        </p>
        <p>
          {" "}
          Our service will help any seller to take control of their commodity
          business and facilitate many tasks: warehouse accounting, goods
          accounting, financial accounting, control of shipments, analysis by
          sales channels, customer accounting and much more. And all this is
          available in one simple interface, understandable to any novice
          seller.{" "}
        </p>
        <p>
          {" "}
          And if you are missing something in the service, you can always write
          to us and we will try to add it for you as soon as possible!{" "}
        </p>
      </div>
    ),
    ua: (
      <div>
        <h2>Простий облік продажів і товарів </h2>
        <p>
          {" "}
          {AppStore.getAppName()} - це головний робочій простір для сучасних
          продавців фізичних товарів, де підприємець може контролювати залишки
          товарів на складі, додавати і обробляти продажі, вести базу покупців,
          контролювати всі витрати і реально дізнатися скільки він заробляє!{" "}
        </p>
        <p>
          {" "}
          Цей продукт ми почали розробляти в першу чергу для себе, після того як
          самі зайнялися товарним бізнесом, занурилися в проблеми і не змогли
          знайти хороших і простих рішень.{" "}
        </p>
        <h2> А ви схожі на Марину? </h2>
        <i>
          {" "}
          <p>
            {" "}
            Уявіть Марину, власник маленького магазину дитячих товарів в своєму
            місті, яка продає свої товари не тільки в магазині, але і через
            Instagram, OLX, Viber, Facebook, Prom, через свій сайт і через
            партнерів. У неї в наявності тисячі одиниць товарів і тисячі товарів
            за системою дропшіппінг. У команді у неї тільки вона і один
            продавець в магазині. Товари у неї зберігаються в магазині, у
            партнерів, а також дома на балконі.{" "}
          </p>
          <p>
            {" "}
            Марина щодня відправляє через служби доставки десятки посилок як з
            передоплатою так і наложеним платежем. У зв'язку з її діяльністю у
            неї є регулярні платежі, такі як{" "}
            <b>
              {" "}
              оренда, комунальні, зарплата, оплата інтернету, зміна килимків в
              магазині{" "}
            </b>{" "}
            і багато іншого. Але також регулярно бувають і додаткові витрати
            пов'язані з оплатою{" "}
            <b> реклами у блогерів, контекстної реклами, оголошень </b> і інше.{" "}
          </p>
          <p>
            {" "}
            Марина щодня страждає від того, що коли їй пишуть покупці, вона не
            знає, які товари у неї є в наявності і де вони лежать, в магазині, у
            партнера або на балконі?{" "}
            <b> Продали ми того синього зайця або він в магазині? </b>{" "}
          </p>
          <p>
            {" "}
            А також у неї не завжди виходить відстежити і проконтролювати всі
            відправки товарів. Відправив продавець посилку до Києва або забув?{" "}
          </p>
          <p>
            {" "}
            Марина щодня витрачає величезну кількість сил і часу, на те щоб
            додавати нові позиції і стежити за наявністю товарів на всіх 10
            майданчиків, де вона продає!{" "}
          </p>
          <p>
            {" "}
            А як дізнатися який з каналів продажів найвигідніший? Начебто з
            інстаграмма багато замовлень, але ніби й з сайту непогано, куди
            краще більше приділяти увагу?{" "}
          </p>
          <p>
            {" "}
            А скільки вона все ж заробила за цей місяць? Скільки грошей вона
            може взяти з бізнесу не пошкодивши бізнесу?{" "}
          </p>
          <p>
            {" "}
            <b> Як Марині все це утримати в голові і проконтролювати? </b>{" "}
          </p>{" "}
        </i>
        <p>
          {" "}
          Якщо ви впізнали себе в Марині, тоді наш сервіс для простого обліку
          продажів і товарів однозначно вам допоможе!{" "}
        </p>
        <h2> Ми зіткнулися з усім цим </h2>
        <p>
          {" "}
          Ми зіткнулися з усім цим, коли запустили свій товарний бізнес. З цим
          щодня стикаються безліч наших друзів, які також займаються
          виробництвом і продажем різних товарів і десятки тисяч інших
          продавців. Нам потрібен був <strong> простий облік товару </strong>,
          куди можна було просто додати весь товар і знати скільки і якого
          товару у нас зберігається!{" "}
        </p>
        <h2> Простий облік товару без спеціальних термінів </h2>
        <p>
          {" "}
          {AppStore.getAppName()} - це максимально простий облік продажів і
          простий облік фінансів для малого бізнесу, в якому ми не
          використовуємо складні бухгалтерські терміни, такі як проводки,
          номенклатура, прибуткові ордери та інші.{" "}
        </p>
        <p>
          {" "}
          Наш сервіс допоможе будь-якому продавцеві взяти під контроль свій
          товарний бізнес і полегшити безліч завдань: складський облік, облік
          товарів, фінансовий облік, контроль відправок, аналіз по каналах
          продажів, облік клієнтів і багато іншого. І це все доступно в одному
          простому інтерфейсі, зрозумілому будь-якому починаючому продавцю.{" "}
        </p>
        <p>
          {" "}
          А якщо вам чогось не вистачає в сервісі, ви завжди можете написати нам
          і ми постараємося в короткі терміни це додати для вас!{" "}
        </p>
      </div>
    ),
  },

  "home-text-h1-dest-warehouse": {
    ru: (
      <div>
        <div>
          <h1>Автоматизация складского учета товаров</h1>
          <p>
            Мы не раз упоминали о том, что автоматизация бизнес-процессов и, в
            частности, складского учета в разы облегчает жизнь предпринимателям.
          </p>
          <p>
            На первый взгляд, вести учет не так и сложно и самостоятельно:
            всего-то нужно знать сколько у вас товаров, за сколько вы его
            закупили и за сколько продаете. Многие все еще ведут эти дела в
            Google Таблицах или специальном блокноте. Сотрудники просто ведут
            файл, дополняя его по мере новых событий.
          </p>
          <ul>
            <p>
              Однако даже при небольшом количестве товаров, и даже при небольшом
              коллективе могут возникнуть сложности при ведении складского
              учета. Ведь появляются новые товары, нанимаются новые работники,
              прилагаются разные склады и открываются новые магазины или точки
              продаж. Такие мелкие моменты могут добавить много путаницы и
              усложнить процесс учета.
            </p>
            <p>
              С развитием вашего бизнеса учет будет становиться еще сложнее.{" "}
            </p>
            <p>
              Заполнение накладных и оприходование товаров будет занимать все
              больше времени, а также добавлять больше проблем. Чтобы не
              разрушить свой бизнес, следует переходить на автоматизацию всех
              процессов.
            </p>
            <p> Это облегчит вам и вашим работникам жизнь</p>
            <h2>
              HugeProfit - программа для складского учета имеет возможности:
            </h2>
            <li>Создавать детальный учет товаров</li>
            <li>Резервировать товар</li>
            <li>Оформлять доставку и возврат товаров</li>
            <li>Печать ценники и чеки</li>
            <li>Добавлять вариативные товары</li>
            <li>Фильтр товаров</li>
            <li>Добавлять несколько различных составов</li>
            <li>
              Предоставлять доступ сотрудникам с различными правами доступа
            </li>
            <li>Оформлять закупки и поставки</li>
            <li>Перемещать товары со склада на склад</li>
            <li>Генерировать отчеты</li>
            <li>Управлять рабочим процессом с мобильного приложения.</li>
          </ul>
          <p>
            Автоматизация складского учета позволяет упорядочить товарный учет
            без лишнего времени и сил. Благодаря специальным ПО все процессы
            можно осуществлять через несколько минут.{" "}
          </p>
          <p>
            К тому же все сотрудники будут работать в одном информационном поле,
            а у вас всегда будет доступ к необходимым данным.{" "}
          </p>
        </div>
      </div>
    ),
    en: (
      <div>
        <div>
          <h1>Automation of warehouse accounting of goods</h1>
          <p>
            We have mentioned more than once that the automation of business
            processes, and, in particular, warehouse accounting, makes the life
            of entrepreneurs many times easier.
          </p>
          <p>
            At first glance, it is not so difficult to keep records on your own:
            you just need to know how many goods you have, how much you bought
            them for, and how much you sell them for. Many still manage these
            matters in Google Sheets or a special notebook. Employees simply
            maintain a file, adding to it as new events occur.
          </p>
          <ul>
            <p>
              However, even with a small number of goods, and even with a small
              team, difficulties may arise when keeping warehouse records. After
              all, new products appear, new employees are hired, various
              warehouses are added and new stores or sales points are opened.
              Such small points can add a lot of confusion and complicate the
              accounting process.
            </p>
            <p>
              As your business grows, accounting will become even more complex.{" "}
            </p>
            <p>
              Filling out invoices and posting goods will take more and more
              time and add more problems. In order not to destroy your business,
              you should switch to automation of all processes.
            </p>
            <p> This will make life easier for you and your employees</p>
            <h2>
              HugeProfit - a program for warehouse accounting has the following
              features:
            </h2>
            <li>Create a detailed record of goods</li>
            <li>Reserve product</li>
            <li>Make delivery and return of goods</li>
            <li>Print price tags and receipts</li>
            <li>Add variable products</li>
            <li>Filter products</li>
            <li>Add several different syllables</li>
            <li>Give access to employees with different access rights</li>
            <li>Make purchases and deliveries</li>
            <li>Move goods from warehouse to warehouse</li>
            <li>Generate reports</li>
            <li>Manage the workflow from the mobile application.</li>
          </ul>
          <p>
            Automation of warehouse accounting allows you to organize product
            accounting without unnecessary time and effort. Thanks to special
            software, all processes can be carried out in a few minutes.{" "}
          </p>
          <p>
            In addition, all employees will work in the same information field,
            and you will always have access to the necessary data.{" "}
          </p>
        </div>
      </div>
    ),
    ua: (
      <div>
        <h1>Автоматизація складського обліку товарів</h1>
        <p>
          Ми не раз згадували про те, що автоматизація бізнес-процесів, і,
          зокрема, складського обліку в рази полегшує життя підприємцям.
        </p>
        <p>
          На перший погляд, вести облік не так і складно й самотужки: всього
          лише потрібно знати скільки у вас товарів, за скільки ви його закупили
          і за скільки продаєте. Багато хто все ще веде ці справи в Google
          Таблицях чи спеціальному блокноті. Співробітники просто ведуть файл,
          доповнюючи його у міру нових подій.
        </p>
        <ul>
          <p>
            Однак, навіть при невеликій кількості товарів, і навіть при
            невеликому колективі можуть виникнути складності при веденні
            складського обліку. Адже з’являються нові товари, наймаються нові
            працівники, додаються різні склади і відкриваються нові магазини чи
            точки продажів. Такі дрібні моменти можуть додати багато плутанини і
            ускладнити процес обліку.
          </p>
          <p>З розвитком вашого бізнесу, облік ставатиме ще складнішим. </p>
          <p>
            Заповнення накладних та оприбуткування товарів займатиме все більше
            часу, а також додавати більше проблем. Аби не зруйнувати свій
            бізнес, варто переходити на автоматизацію усіх процесів.
          </p>
          <p> Це полегшить вам і вашим працівникам життя</p>
          <h2>HugeProfit - программа для складського обліку має можливості:</h2>
          <li>Створювати детальний облік товарів</li>
          <li>Резервувати товар</li>
          <li>Оформляти доставку та повернення товарів</li>
          <li>Друкувати цінники та чеки</li>
          <li>Додавати варіативні товари</li>
          <li>Фільтрувати товари</li>
          <li>Додавати декілька різних складів</li>
          <li>Надавати доступ працівникам з різними правами доступу</li>
          <li>Оформляти закупівлі та поставки</li>
          <li>Переміщувати товари зі складу на склад</li>
          <li>Генерувати звіти</li>
          <li>Керувати робочим процесом з мобільного додатку.</li>
        </ul>
        <p>
          Автоматизація складського обліку дає змогу упорядкувати товарний облік
          без зайвого часу та сил. Завдяки спеціальним ПЗ усі процеси можна
          здійснювати за декілька хвилин.{" "}
        </p>
        <p>
          До того ж, всі співробітники будуть працювати в одному інформаційному
          полі, а у вас завжди буде доступ до необхідних даних.{" "}
        </p>
      </div>
    ),
  },

  "home-text-h1-dest-production": {
    ru: (
      <div>
        <h1>Простой учет производства товара для малого и микро бизнеса</h1>
        <ul>
          <li>
            <strong>Учет производства собственной продукции</strong> является
            важным аспектом управления бизнесом любого размера. Владельцы малого
            и среднего бизнеса, которые производят собственные товары, должны
            понимать, как эффективно вести учет производства товаров. В этом
            тексте мы рассмотрим, что такое учет производства товаров, почему он
            важен и какие системы учета можно использовать.
          </li>
          <li>
            <strong>Учет производства товаров</strong> - это процесс
            отслеживания процесса создания товаров в производственной среде. Он
            включает в себя учет затрат на сырье, материалы, труд, а также
            накладные расходы. Учет производства товаров позволяет владельцам
            бизнеса оценить, сколько стоит производство каждого товара и какие
            затраты нужно учесть для получения прибыли.
          </li>
          <li>
            <strong>Производство готовой продукции</strong> - это процесс,
            включающий в себя все этапы производства, начиная с закупки сырья и
            материалов, производства изделий, сборки и упаковки. Все эти этапы
            должны быть отслежены и учтены для определения стоимости
            производства и управления производственными запасами.
          </li>
          <li>
            <strong>Учет домашнего производства</strong> - это система учета,
            которая помогает владельцам домашнего бизнеса отслеживать расходы на
            производство и управлять производственными запасами. Это важно для
            тех, кто производит товары на дому и продаёт их через Интернет или
            на ярмарках.
          </li>
          <li>
            <strong>Система учета производства</strong> - это комплексный подход
            к учету производства товаров, который включает в себя учет затрат,
            управление запасами и производственными процессами. Такие системы
            позволяют автоматизировать процессы учета, уменьшить ручной труд и
            снизить ошибки в учетных записях.
          </li>
        </ul>
        <p>
          Для эффективного учета производства товаров необходимо выбрать
          подходящую систему учета, которая наиболее подходит для вашего
          бизнеса. Некоторые компании предпочитают использовать программное
          обеспечение учета производства, которое автоматизирует процесс учета и
          управления запасами. Другие предпочитают вести учет вручную, используя
          электронные таблицы или книг&nbsp; и ведомости.
        </p>
        <p>
          В любом случае, система учета производства должна отвечать
          определенным требованиям. Она должна быть гибкой и настраиваемой для
          учета различных видов затрат и производственных процессов. Кроме того,
          она должна обеспечивать точность данных и возможность быстрого доступа
          к информации.
        </p>
        <p>
          Для учета производства товаров также можно использовать стандартные
          методы учета затрат на производство. Один из таких методов -
          стандартные затраты на единицу продукции. Этот метод позволяет
          определить затраты на каждую единицу продукции на основе
          среднестатистических затрат на производство.
        </p>
        <p>
          Еще один метод - метод себестоимости продукции. Этот метод учитывает
          все затраты на производство, включая материалы, труд и накладные
          расходы. На основе этих затрат можно определить себестоимость
          продукции и, следовательно, оптимальную цену продажи.
        </p>
        <p>
          Кроме того, для учета производства товаров необходимо правильно
          организовать учет производственных запасов. Это позволяет управлять
          запасами и избежать недостатка или переизбытка продукции. В системе
          учета производства также должна быть возможность отслеживать
          перемещение запасов, производственных ресурсов и готовой продукции.
        </p>
        <p>
          В заключение, учет производства товаров является важным элементом
          управления бизнесом, особенно для тех, кто производит собственную
          продукцию. Для эффективного учета необходимо выбрать подходящую
          систему учета, которая будет наиболее подходить для вашего бизнеса.
          Независимо от выбранной системы, необходимо учитывать все затраты на
          производство и отслеживать производственные запасы, чтобы эффективно
          управлять бизнесом и получать прибыль.
        </p>
        <h2>
          Для кого подходит HugeProfit – программа для простого учета
          производства товаров
        </h2>
        <ul>
          <li>
            Желающим вести простой и понятный учет производства товара без
            сложностей.
          </li>
          <li>Владельцам небольших домашних производств</li>
          <li>Производителям не сложной готовой продукции</li>
          <li>Изготовителям одежды, сумок, мебели и т.д</li>
          <li>Handmade-производствам</li>
          <li>И многим другим небольшим производствам</li>
        </ul>
        <h2>
          <b>Кому НЕ подходит HugeProfit - система для учета производства</b>
        </h2>
        <ul>
          <li>Владельцам крупных и сложных производств</li>
          <li>Заводам и фабрикам</li>
          <li>Желающим учитывать амортизацию оборудования</li>
          <li>
            Компаниям, которым нужный полный документооборот и сложные
            производственные процессы.
          </li>
          <li>И многим крупным производственным организациям</li>
        </ul>
        <p>
          <b>Как нас находят:</b>
        </p>
        <ol>
          <li>программа для учета производства</li>
          <li>простой учет изготовления товаров</li>
          <li>учет производство и реализации товаров</li>
          <li>подсчет себестоимости производства товаров</li>
          <li>как подсчитать себестоимость производства товаров</li>
          <li>простой учет производства</li>
        </ol>
      </div>
    ),
    en: (
      <div>
        <h1>
          Simple accounting for the production of goods for small and micro
          businesses
        </h1>
        <ul>
          <li>
            <strong>In-house production accounting</strong> is an important
            aspect of running a business of any size. Small and medium business
            owners who manufacture their own products must understand how to
            effectively keep records of the production of goods. In this text,
            we will look at what accounting for the production of goods is, why
            it is important and what accounting systems can be used.
          </li>
          <li>
            <strong>Product Production Accounting</strong> is the process of
            tracking the creation of goods in a production environment. It
            includes accounting for the cost of raw materials, materials, labor,
            as well as overhead costs. Accounting for the production of goods
            allows business owners to estimate how much it costs to produce each
            product and what costs need to be taken into account to make a
            profit.
          </li>
          <li>
            <strong>Production of finished products</strong> is a process that
            includes all stages of production, from the purchase of raw
            materials and materials, production of products, assembly and
            packaging. All these steps must be tracked and accounted for in
            order to determine the cost of production and manage inventory.
          </li>
          <li>
            <strong>Home Production Accounting</strong> is an accounting system
            that helps home business owners track production costs and manage
            their inventory. This is important for those who make products at
            home and sell them online or at fairs.
          </li>
          <li>
            <strong>Production accounting system</strong> is a comprehensive
            approach to accounting for the production of goods, which includes
            cost accounting, inventory management and production processes. Such
            systems allow you to automate accounting processes, reduce manual
            labor and reduce errors in accounts.
          </li>
        </ul>
        <p>
          To effectively record the production of goods, you need to choose the
          appropriate accounting system that is most suitable for your business.
          Some companies prefer to use production accounting software, which
          automates the accounting and inventory management process. Others
          prefer to keep records manually using spreadsheets or books&nbsp; and
          statements.
        </p>
        <p>
          In any case, the production accounting system must meet certain
          requirements. It should be flexible and customizable to accommodate
          different types of costs and production processes. In addition, it
          must ensure the accuracy of the data and the ability to quickly access
          information.
        </p>
        <p>
          You can also use standard production costing methods to account for
          the production of goods. One such method is the standard unit cost.
          This method allows you to determine the cost of each unit of
          production based on the average cost of production.
        </p>
        <p>
          Another method is the production cost method. This method accounts for
          all production costs, including materials, labor, and overheads. Based
          on these costs, you can determine the cost of production and,
          therefore, the optimal selling price.
        </p>
        <p>
          In addition, to account for the production of goods, it is necessary
          to properly organize the accounting of inventories. This allows you to
          manage inventory and avoid shortages or oversupply. The production
          accounting system should also be able to track the movement of stocks,
          production resources and finished products.
        </p>
        <p>
          In conclusion, accounting for the production of goods is an important
          element of business management, especially for those who manufacture
          their own products. For effective accounting, you need to choose the
          right accounting system that will be most suitable for your business.
          Regardless of the system chosen, it is necessary to take into account
          all production costs and keep track of inventories in order to
          effectively manage the business and make a profit.
        </p>
        <h2>
          For whom HugeProfit is suitable - a program for simply accounting for
          the production of goods
        </h2>
        <ul>
          <li>
            Wishing to keep a simple and understandable record of the production
            of goods without difficulties.
          </li>
          <li>For owners of small home industries</li>
          <li>Producers of simple finished products</li>
          <li>To manufacturers of clothing, bags, furniture, etc.</li>
          <li>Handmade-industries</li>
          <li>And many other small industries</li>
        </ul>
        <p>
          <b>
            Who is NOT suitable for HugeProfit - a system for accounting for
            production
          </b>
        </p>
        <ul>
          <li>For owners of large and complex industries</li>
          <li>For plants and factories</li>
          <li>For those who wish to consider equipment depreciation</li>
          <li>
            Companies that require complete document workflows and complex
            workflows.
          </li>
          <li>And many large manufacturing organizations</li>
        </ul>
        <h2>
          <b>
            Who is NOT suitable for HugeProfit - a system for accounting for
            production
          </b>
        </h2>
        <ul>
          <li>For owners of large and complex industries</li>
          <li>For plants and factories</li>
          <li>For those who wish to consider equipment depreciation</li>
          <li>
            Companies that require complete document workflows and complex
            workflows.
          </li>
          <li>And many large manufacturing organizations</li>
        </ul>
        <p>
          <b>How they find us:</b>
        </p>
        <ol>
          <li>program for production accounting</li>
          <li>simple accounting for the manufacture of goods</li>
          <li>accounting for the production and sale of goods</li>
          <li>calculation of the cost of production of goods</li>
          <li>how to calculate the cost of production of goods</li>
          <li>simple production accounting</li>
        </ol>
      </div>
    ),
    ua: (
      <div>
        <h1>Простий облік виробництва товару для малого та мікро бізнесу</h1>
        <ul>
          <li>
            <strong>Облік виробництва власної продукції</strong> є важливим
            аспектом управління бізнесом будь-якого розміру. Власники малого та
            середнього бізнесу, які виробляють власні товари, мають розуміти, як
            ефективно вести облік виробництва товарів. У цьому тексті ми
            розглянемо, що таке облік виробництва товарів, чому він важливий і
            які системи обліку можна використовувати.
          </li>
          <li>
            <strong>Облік виробництва товарів</strong> - це процес відстеження
            процесу створення товарів у виробничому середовищі. Він включає
            облік витрат на сировину, матеріали, працю, а також накладні
            витрати. Облік виробництва товарів дозволяє власникам бізнесу
            оцінити, скільки коштує виробництво кожного товару та які витрати
            потрібно врахувати для отримання прибутку.
          </li>
          <li>
            <strong>Виробництво готової продукції</strong> - це процес, що
            включає всі етапи виробництва, починаючи з закупівлі сировини і
            матеріалів, виробництва виробів, складання та упаковки. Усі ці етапи
            мають бути відстежені та враховані для визначення вартості
            виробництва та управління виробничими запасами.
          </li>
          <li>
            <strong>Облік домашнього виробництва</strong> - це система обліку,
            яка допомагає власникам домашнього бізнесу відстежувати витрати на
            виробництво та керувати виробничими запасами. Це важливо для тих,
            хто виробляє товари вдома та продає їх через Інтернет або на
            ярмарках.
          </li>
          <li>
            <strong>Система обліку виробництва</strong> - це комплексний підхід
            до обліку виробництва товарів, який включає облік витрат, управління
            запасами та виробничими процесами. Такі системи дозволяють
            автоматизувати процеси обліку, зменшити ручну працю та знизити
            помилки в облікових записах.
          </li>
        </ul>
        <p>
          Для ефективного обліку виробництва товарів необхідно вибрати
          відповідну систему обліку, яка найбільше підходить для вашого бізнесу.
          Деякі компанії вважають за краще використовувати програмне
          забезпечення обліку виробництва, яке автоматизує процес обліку та
          управління запасами. Інші вважають за краще вести облік вручну,
          використовуючи електронні таблиці або книги. та відомості.
        </p>
        <p>
          У будь-якому випадку, система обліку виробництва має відповідати
          певним вимогам. Вона повинна бути гнучкою та настроюваною для обліку
          різних видів витрат та виробничих процесів. Крім того, вона повинна
          забезпечувати точність даних та можливість швидкого доступу до
          інформації.
        </p>
        <p>
          Для обліку виробництва товарів також можна використовувати стандартні
          методи обліку витрат на виробництво. Один із таких методів –
          стандартні витрати на одиницю продукції. Цей метод дозволяє визначити
          витрати на кожну одиницю продукції на основі середньостатистичних
          витрат на виробництво.
        </p>
        <p>
          Ще один метод – метод собівартості продукції. Цей метод враховує всі
          витрати на виробництво, включаючи матеріали, працю та накладні
          витрати. На основі цих витрат можна визначити собівартість продукції
          та, отже, оптимальну ціну продажу.
        </p>
        <p>
          Окрім того, для обліку виробництва товарів необхідно правильно
          організувати облік виробничих запасів. Це дозволяє керувати запасами
          та уникнути нестачі чи надлишку продукції. У системі обліку
          виробництва також має бути можливість відстежувати переміщення
          запасів, виробничих ресурсів та готової продукції.
        </p>
        <p>
          На закінчення, облік виробництва товарів є важливим елементом
          управління бізнесом, особливо для тих, хто виробляє власну продукцію.
          Для ефективного обліку необхідно вибрати відповідну систему обліку,
          яка найбільше підходить для вашого бізнесу. Незалежно від обраної
          системи, необхідно враховувати всі витрати на виробництво та
          відстежувати виробничі запаси, щоб ефективно управляти бізнесом та
          отримувати прибуток.
        </p>
        <h2>
          Для кого підходить HugeProfit – програма для простого обліку
          виробництва товарів
        </h2>
        <ul>
          <li>
            Бажаючим вести простий та зрозумілий облік виробництва товару без
            складнощів.
          </li>
          <li>Власникам невеликих домашніх виробництв</li>
          <li>Виробникам нескладної готової продукції</li>
          <li>Виробникам одягу, сумок, меблів тощо</li>
          <li>Handmade-виробництвам</li>
          <li>І багатьом іншим невеликим виробництвам</li>
        </ul>
        <p>
          <b>Кому НЕ підходить HugeProfit – система для обліку виробництва</b>
        </p>
        <ul>
          <li>Власникам великих та складних виробництв</li>
          <li>Заводів та фабрик</li>
          <li>Бажаючим враховувати амортизацію обладнання</li>
          <li>
            Компаніям, яким потрібний повний документообіг та складні виробничі
            процеси.
          </li>
          <li>І багатьом великим виробничим організаціям</li>
        </ul>
        <h2>
          <b>Кому НЕ підходить HugeProfit – система для обліку виробництва</b>
        </h2>
        <ul>
          <li>Власникам великих та складних виробництв</li>
          <li>Заводів та фабрик</li>
          <li>Бажаючим враховувати амортизацію обладнання</li>
          <li>
            Компаніям, яким потрібний повний документообіг та складні виробничі
            процеси.
          </li>
          <li>І багатьом великим виробничим організаціям</li>
        </ul>
        <p>
          <b>Як нас знаходять:</b>
        </p>
        <ol>
          <li>програма для обліку виробництва</li>
          <li>простий облік виготовлення товарів</li>
          <li>облік виробництва та реалізації товарів</li>
          <li>підрахунок собівартості виробництва товарів</li>
          <li>як підрахувати собівартість виробництва товарів</li>
          <li>простий облік виробництва</li>
        </ol>
      </div>
    ),
  },
  "home-text-h1-dest-shop": {
    ru: (
      <div>
        <h1>Учет продаж</h1>
        <p>
          {" "}
          Точный учет продаж – это залог систематического роста бизнеса.
          Невозможно развивать бизнес без учета продаж и товаров.{" "}
        </p>
        <p>
          {" "}
          А в современном мире нужен не только учет, а максимально простой и
          точный, который не усложняет жизнь предприятия, а наоборот помогает
          сделать больше, качественнее и помогает увидеть новые перспективы
          роста!{" "}
        </p>
        <p>
          Сервис HugeProfit это новейшая система учета продаж и товаров, которая
          поможет вам не только знать, сколько вы продали, сколько заработали,
          сколько у вас осталось товара, а поможет полностью облегчить вашу
          работу!{" "}
        </p>
        <h2>Учет продаж в современном бизнесе</h2>
        <p>
          В современном мире просто физический магазин почти не может
          существовать и конкурировать с интернет-магазинами. Сейчас у любого
          продавца есть несколько каналов продаж, это может быть физический
          магазин, сайт, страница в Instagram, магазин на Prom и объявления на
          OLX, а может и все вместе! То есть продавец должен вести учет продаж и
          товаров, принимать заказы, следить за расходами, считать доходы,
          работать с клиентами, производить отправки товара По почте и т.д.
        </p>
        <p>
          Сейчас просто недостаточно программы, в которой работает бухгалтер и
          делает просто бухгалтерский отчет в табличке и все. Нужна программа,
          которая может контролировать весь товарный бизнес как физический, так
          и в онлайн пространстве.{" "}
        </p>
        <p>Наш сервис HugeProfit как раз и занимается этим.</p>
        <h2>Что вы можете делать в HugeProfit – программе для учета продаж</h2>
        <ul>
          <li>
            <b>Вести учета товаров.</b> Вы очень просто можете добавлять все
            ваши товары как вручную, так и с помощью Excel-файлов. А также вы
            можете импортировать все товары с вашего сайта или площадки для
            продаж.{" "}
          </li>
          <li>
            <strong>Вести учета продаж.</strong> Добавляйте ваши продажи в 2
            клика вручную, с помощью телефона, сканера штрих-кода, или получайте
            заказ автоматически с маркетплейсов или сайта. Легко ведите учет
            реализации товаров со скидками для постоянных клиентов или
            спонтанных скидок покупателю.
          </li>
          <li>
            <b>Учет прибыли по каждой продаже. </b>Добавляя продажи, вы сразу
            можете увидеть сколько вы заработали на этой продаже! Ибо при
            добавлении товаров вы указываете себестоимость этого товара!
          </li>
          <li>
            <b>Финансовый учет.</b> Сервис HugeProfit – это не только учет
            продаж или складской учет. Вы полноценно можете вести финансовый
            учет на любом количестве счетов: наличными, на карточках, на
            банковских счетах или у себя под матрасом! Добавляя продажу, вы
            указываете на какой счет идут средства, а также вы можете
            фиксировать все расходы связанные с бизнесом и с какого счета они
            были потрачены!
          </li>
          <li>
            <b>Приход товара.</b> Оформляйте приход товара в ваш склад.
            Сохраняйте реальную цену себестоимости, даже при изменении
            закупочной цены. Контролируйте остатки товара.
          </li>
          <li>
            <b>Работать с несколькими магазинами.</b> Вы можете добавить
            множество магазинов и работать с разными точками продаж.{" "}
          </li>
          <li>
            <b>Добавлять кассиров и менеджеров по продажам.</b>В HugeProfit –
            программе для учета продаж, вы можете добавлять любое количество
            работников с разным уровнем доступа. Вы можете добавить только
            кассира или полного администратора, можете закрыть финансовые данные
            или информацию об остатках.{" "}
          </li>
          <li>И многое другое функционала</li>
        </ul>
        <h2>Для кого подходит HugeProfit – программа для учета продаж</h2>
        <ul>
          <li>
            Владельцам небольших магазинов или сетей магазинов физических
            товаров
          </li>
          <li>Продавцам в социальной сети Instagram</li>
          <li>Продавцам в мессенджере Viber, Telegram</li>
          <li>Продавцам на маркетплейсах Prom.ua, Rozetka.ua</li>
          <li>Владельцам интернет-магазинов</li>
          <li>Производителям физических товаров</li>
          <li>Да многим еще</li>
        </ul>
        <p>Как нас находят:</p>
        <ol>
          <li>программа для учета продаж</li>
          <li>учет продажи товаров</li>
          <li>учет реализации товаров со скидками</li>
          <li>учет реализации товаров</li>
          <li>учет реализации товаров в розничной торговле</li>
          <li>учет продажи товаров для фоп</li>
        </ol>
      </div>
    ),
    en: (
      <div>
        <h1> Sales Accounting </h1>
        <p>
          {" "}
          Accurate sales accounting is the key to systematic business growth. It
          is impossible to develop a business without taking into account sales
          and goods.{" "}
        </p>
        <p>
          {" "}
          And in today's world you need not just some accounting, but the
          simplest and most accurate, which does not complicate the life of the
          enterprise, but on the contrary helps to do more, better and helps to
          see new growth prospects!{" "}
        </p>
        <p>
          {" "}
          HugeProfit service is the latest sales and product accounting system
          that will help you not only know how much you sold, how much you
          earned, how much product you have left, but also help to make your
          work completely easier!{" "}
        </p>
        <h2> Accounting for sales in modern business </h2>
        <p>
          {" "}
          In today's world, a physical store can hardly exist and compete with
          online stores. Now any retailer has several sales channels, it can be
          a physical store, a website, an Instagram page, a Prom store and an
          OLX ad, or all together! That is, the seller must keep records of
          sales and goods, accept orders, keep track of expenses, calculate
          profits, work with customers, make shipments by mail, etc.{" "}
        </p>
        <p>
          {" "}
          Now there is simply not enough program in which the accountant works
          and which makes simply the accounting report in a table and all. You
          need a program that can control the entire commodity business, both
          physical and online.{" "}
        </p>
        <p> Our HugeProfit service does just that. </p>
        <h2> What you can do in HugeProfit - sales accounting program </h2>
        <ul>
          <li>
            {" "}
            <b> Keep track of goods. </b> You can easily add all your goods both
            manually and using Excel files. And you can import all the goods
            from your site or platform for sales.{" "}
          </li>
          <li>
            {" "}
            <strong> Keep track of sales. </strong> Add your sales in 2 clicks
            manually, by phone, barcode scanner, or receive orders automatically
            from marketplaces or the site. Easily keep track of sales of
            discounted goods for regular customers or spontaneous discounts to
            the buyer.
          </li>
          <li>
            {" "}
            <b> Accounting for Profits from Each Sale. </b> By adding a sale,
            you immediately have the opportunity to see how much you have earned
            on this sale! Because when you add goods, you specify the cost of
            this product!{" "}
          </li>
          <li>
            {" "}
            <b> Financial Accounting. </b> HugeProfit is not just about sales
            accounting or inventory. You can fully keep financial records in any
            number of accounts: in cash, on cards, in bank accounts or under
            your mattress! By adding a sale, you indicate which account the
            funds go to, and you can record all the costs associated with the
            business and from which account they were spent!
          </li>
          <li>
            {" "}
            <b> Arrival of goods. </b> Make a receipt of goods to your
            warehouse. Keep the real cost price, even when the purchase price
            changes. Control the balance of the goods.
          </li>
          <li>
            {" "}
            <b> Work with multiple stores. </b> You can add multiple stores and
            work with different outlets.{" "}
          </li>
          <li>
            {" "}
            <b> Add cashiers and sales managers. </b> In HugeProfit, a sales
            accounting program, you can add any number of employees with
            different levels of access. You can only add a cashier or a full
            administrator, you can close financial data or balance information.{" "}
          </li>
          <li> And many other features </li>
        </ul>
        <h2> Who HugeProfit is for - a sales accounting program </h2>
        <ul>
          <li> Owners of small shops or chains of physical goods stores </li>
          <li> Sellers on Instagram </li>
          <li> Vendors in Viber, Telegram </li>
          <li> To sellers on Prom.ua and Rozetka.ua marketplaces</li>
          <li> For online store owners</li>
          <li> Manufacturers of physical goods </li>
          <li> And many more </li>
        </ul>
        <p> How to find us: </p>
        <ol>
          <li> Sales Accounting Software </li>
          <li> accounting for sales of goods </li>
          <li> accounting for sales of discounted goods </li>
          <li> goods sales accounting </li>
          <li> accounting for sales of goods in retail </li>
          <li> accounting for sales of goods for fop </li>
        </ol>
      </div>
    ),
    ua: (
      <div>
        <h1>Облік продажів </h1>
        <p>
          {" "}
          Точний облік продажів це залог систематичного зростання бізнесу. Не
          можливо розвивати бізнес без обліку продажів та товарів.{" "}
        </p>
        <p>
          {" "}
          А у сучасному світі потрібен не аби який облік, а максимально простий
          і точний, який не ускладнює життя підприємства, а навпаки помагає
          зробити більше, якісніше та допомагає побачити нові перспективи
          зростання!{" "}
        </p>
        <p>
          Сервіс HugeProfit це новітня система обліку продажів і товарів яка
          допоможе вам не тільки знати скільки ви продали, скільки заробили,
          скільки у вас залишилось товару, а допоможе повністю облегшити вашу
          роботу!{" "}
        </p>
        <h2>Облік продажів в сучасному бізнесі</h2>
        <p>
          В сучасному світі просто фізичний магазин майже не може існувати та
          конкурувати з інтернет-магазинами. Зараз у будь-якого продавця є
          декілька каналів продажів, це може бути фізичний магазин, сайт,
          сторінка в Instagram, магазин на Prom та оголошення на OLX, а може й
          все разом! Тобто продавець повинен вести облік продажів і товарів,
          приймати замовлення, слідкувати за витратами, рахувати прибутки,
          працювати з клієнтами, робити відправки товару Поштою тощо.
        </p>
        <p>
          Зараз просто недостатньо програми, в якій працює бухгалтер і яка
          робить просто бухгалтерський звіт в табличці та все. Потрібна
          програма, яка можете контролювати весь товарний бізнес як фізичний,
          так і в онлайн просторі.{" "}
        </p>
        <p>Наш сервіс HugeProfit як раз і займається цим.</p>
        <h2>Що ви можете робити в HugeProfit - програмі для обліку продажів</h2>
        <ul>
          <li>
            <b>Вести облік товарів.</b> Ви дуже просто можете додавати всі ваші
            товари як вручну, так і за допомогою Excel-файлів. А також ви можете
            імпортувати всі товари з вашого сайту, чи майданчику для продажів.{" "}
          </li>
          <li>
            <strong>Вести облік продажів.</strong> Додавайте ваші продажі у 2
            кліки вручну, за допомогою телефону, сканеру штрих-коду, чи
            отримуйте замовлення автоматично з маркетплейсів чи сайту. Легко
            ведіть облік реалізації товарів зі знижками для постійних клієнтів
            чи спонтанні знижки покупцеві.
          </li>
          <li>
            <b>Облік прибутку з кожної продажі. </b>Додаючи продажу ви зразу
            маєте можливість бачити скільки ви заробили на цій продажі! Бо при
            додаванні товарів ви вказуєте собівартість цього товару!
          </li>
          <li>
            <b>Фінансовий облік.</b> Сервіс HugeProfit це не тільки облік
            продажів чи складський облік. Ви повноцінно можете вести фінансовий
            облік на любій кількості рахунків: в готівці, на картках, на
            банківських рахунках чи у себе під матрацом! Додаючи продаж, ви
            вказуєте на який рахунок йдуть кошти, а також ви можете фіксувати
            всі витрати повʼязані з бізнесом і з якого рахунку вони були
            витрачені!
          </li>
          <li>
            <b>Прихід товару.</b> Оформлюйте прихід товару до вашого складу.
            Зберігайте реальну ціну собівартості, навіть при зміні закупівельної
            ціни. Контролюйте залишки товару.
          </li>
          <li>
            <b>Працювати з декількома магазинами.</b> Ви маєте можливість додати
            безліч магазинів, та працювати з різними точками продажу.{" "}
          </li>
          <li>
            <b>Додавайти касирів та менеджерів з продажу.</b>В HugeProfit -
            програмі для обліку продажів, ви можете додавати будь-яку кількість
            працівників з різним рівнем доступу. Ви можете додати тільки касира,
            чи повного адміністратора, можете закрити фінансові дані, чи
            інформацію о залишках.{" "}
          </li>
          <li>Та багато іншого функціонала</li>
        </ul>
        <h2>Для кого підходить HugeProfit - програма для обліку продажів</h2>
        <ul>
          <li>
            Власникам невеликих магазинів чи мереж магазинів фізичних товарів
          </li>
          <li>Продавцям в соціальній мережі Instagram</li>
          <li>Продавцям в мессенджері Viber, Telegram</li>
          <li>Продавцям на маркетплейсах Prom.ua, Rozetka.ua</li>
          <li>Власникам інтернет-магазинів</li>
          <li>Виробникам фізичних товарів</li>
          <li>Та багато кому ще</li>
        </ul>
        <p>Як нас знаходять:</p>
        <ol>
          <li>програма для обліку продажів</li>
          <li>облік продажу товарів</li>
          <li>облік реалізації товарів зі знижками</li>
          <li>облік реалізації товарів</li>
          <li>облік реалізації товарів у роздрібній торгівлі</li>
          <li>облік продажу товарів для фоп</li>
        </ol>
      </div>
    ),
  },
  "tied-search-production": {
    ru: (
      <span>
        Устали от поиска простой системы учета{" "}
        <strong className="red_text">производства собственной продукции</strong>
        ? Тогда вы ее нашли! Самый простой расчет себестоимости и учет продаж
        готовой продукции!
      </span>
    ),
    en: (
      <span>
        Tired of looking for a simple accounting system for{" "}
        <strong className="red_text">
          the production of your own products
        </strong>
        ? Then you have found it! The easiest cost calculation and sales
        accounting for finished products!
      </span>
    ),
    ua: (
      <span>
        Втомилися від пошуку простої системи обліку{" "}
        <strong className="red_text">виробництва власної продукції</strong>?
        Тоді ви її знайшли! Найпростіший розрахунок собівартості та облік
        продажів готової продукції!
      </span>
    ),
  },
  "byy-component-production": {
    ru: (
      <span>
        Закупай комплектующие и материалы по разной стоимости.{" "}
        <strong className="red_text">Рассчитывай себестоимость</strong> готовой
        продукции. Учитывай все расходы на производство. Производи новые товары!
      </span>
    ),
    en: (
      <span>
        Buy components and materials at different prices.{" "}
        <strong className="red_text">Calculate the cost</strong> of finished
        products. Account for all production costs. Produce new goods!
      </span>
    ),
    ua: (
      <span>
        Купуй комплектуючі та матеріали за різною вартістю.{" "}
        <strong className="red_text">Розрахуй собівартість</strong> готової
        продукції. Враховуй всі витрати на виробництво. Виробляй нові товари
      </span>
    ),
  },

  "tied-search-production=1": {
    ru: "",
    en: "",
    ua: "",
  },
  traine: {
    ru: "Бесплатно",
    en: "Free",
    ua: "Безкоштовно",
  },
  surenew: {
    ru: "Уверенный",
    en: "Confident",
    ua: "Впевнений",
  },
  pronew: {
    ru: "Опытный",
    en: "Experienced",
    ua: "Досвідчений",
  },
  sharknew: {
    ru: "Профи",
    en: "Pro",
    ua: "Профі",
  },
  "unlimited-employees": {
    ru: "∞ сотрудников",
    en: "∞ employees",
    ua: " ∞ співробітників",
  },
  "unlimited-warehouse": {
    ru: "∞ складов",
    en: "∞ warehouses",
    ua: "∞ складів",
  },
  "unlimited-integrations": {
    ru: "∞ интеграций",
    en: "∞ integrations",
    ua: "∞ інтеграцій",
  },
  "review-1": {
    ru: "Простой сервис, который удовлетворяет всем моим потребностям. Имею небольшую торговую точку в Каменском, получаю заказы из прома и розетки, везде автоматически синхронизируются остатки, очень удобно.  Также добавляю все расходы, трекаю посылки, и вижу свою прибыль. Очень рекомендую 😉 😉 Очень рекомендую.",
    en: "A simple service that meets all my needs. I have a small retail outlet in Kamianske, receive orders from the promo and the outlet, and the balances are automatically synchronized everywhere, which is very convenient.  I also add all expenses, track parcels, and see my profit. I highly recommend it! 😉",
    ua: "Простий сервіс, який задовольняє всім моїм потребам. Маю невиличку торгівельну точку в Камʼянском, отримую замовлення з прому та розетки, всюди автоматично синхронизуються залишки, дуже зручно.  Також додаю всі витрати, трекаю посилки, та бачу свій прибуток. Дуже рекомендую! 😉",
  },
  "review-1-name": {
    ru: "Виталий Вакуленко",
    en: "Vitaliy Vakulenko",
    ua: "Віталій Вакуленко",
  },
  "review-2": {
    ru:
      "Очень крутой сервис, перехожу с клаудшоп рашиского, HugeProfit это единственный украинский сервис, который закрывает большинство моих потребностей.\n" +
      " Несколько не хватает функций, но поддержка очень хорошая и ребята уже добавили две новые функции по моим запросам - за что им очень благодарна! \n" +
      "Продолжайте в том же духе и запускайте больше рекламы! вам ее однозначно не хватает.",
    en:
      "A very cool service, I'm switching from Rushissky's Cloudshop, HugeProfit is the only Ukrainian service that covers most of my needs.\n" +
      " There are some missing features, but the support is very good and the guys have already added two new features at my request - for which I am very grateful! \n" +
      "Keep up the good work and launch more ads! you definitely need them.",
    ua:
      "Дуже крутий сервіс, переходжу з клаудшоп рашиського, HugeProfit це единий український сервіс, який закриває більшість моїх потреб.\n" +
      " Дещо не вистачає функцій, але підтримка дуже добра і хлопці вже додали дві нові функції по моїм запитам - за що їм дуже вдячна! \n" +
      "Продовжуйте в тому ж дусі і запускайте більше реклами! вам її однозначно не вистачає.",
  },
  "review-2-name": {
    ru: "Тумма Урманн",
    en: "Tumma Uhrmann",
    ua: "Тумма Урманн",
  },
  "review-3": {
    ru: "Искала программу для фискализации чеков и чтобы можно было вести учет продаж и записывать расходы связанные с бизнесом. Это просто идеальное решение! Пользуюсь уже месяц и всем рекомендую! 👍👍👍👍👍",
    en: "I was looking for a program to fiscalize receipts and keep track of sales and record business-related expenses. This is just the perfect solution! I've been using it for a month and recommend it to everyone! 👍👍👍👍👍",
    ua: "Шукала програму для фіскалізації чеків та щоб можно було вести облік продажів та записувати витрати повʼязані з бізнесом. Це просто ідеальне рішення! Користуюсь вже місяць і всім рекомендую!👍👍👍👍👍",
  },
  "review-3-name": {
    ru: "Дарина Божидай",
    en: "Darina Bozhidai",
    ua: "Дарина Божидай",
  },
  "review-4": {
    ru: "Отличная программа! Пользуюсь ей уже больше года. Постоянно модернизируется и улучшается. Конечно есть ещё над чем поробоботать. Отдельно отмечу службу поддержки, реагируют молниеносно всегда помогут.",
    en: "Great program! I have been using it for over a year. It is constantly being modernized and improved. Of course, there is still something to work on. I would also like to mention the support service, they react lightning fast and always help.",
    ua: "Чудова програма! Користуюся нею вже понад рік. Постійно модернізується і поліпшується. Звичайно є ще над чим попрацювати. Окремо відзначу службу підтримки, реагують блискавично, завжди допоможуть."
  },
  "review-4-name": {
    ru: "Богдан Дробязко",
    en: "Bogdan Drobyazko",
    ua: "Богдан Дробязко",
  },
  "review-5": {
    ru: "Лучший сервис для предпринимателей, торгующих онлайн и оффлайн. Замечательное сочетание всех необходимых разделов в одном месте. Лучшая поддержка. Рекомендую.",
    en: "The best service for entrepreneurs selling online and offline. A great combination of all the necessary sections in one place. The best support. Recommendation.",
    ua: "Найкращий сервіс для підприємців, що торгують онлайн та офлайн. Чудове поєднання усіх необхідних розділів в одному місці. Найкраща підтримка. Рекомендую."
  },
  "review-5-name": {
    ru: "Игорь Тхорук",
    en: "Ihor Tkhoruk",
    ua: "Ігор Тхорук",
  },
  "review-6": {
    ru: "Пользуюсь программой уже три месяца. Тех-поддержка у ребят отличная, быстро работает добавляет необходимые функции. Отличная программ, как альтернатива 1С для многих. Полностью довольна и могу рекомендовать на все 100%👍",
    en: "I have been using the program for three months. The guys' technical support is excellent, they work quickly and add the necessary functions. Great program as an alternative to 1C for many. Completely satisfied and can recommend 100%👍",
    ua: "Користуюся програмою вже три місяці. Тех-підтримка у хлопців чудова, швидко працює, додає необхідні функції. Відмінна програм, як альтернатива 1С для багатьох. Повністю задоволена і можу рекомендувати на всі 100%👍"
  },
  "review-6-name": {
    ru: "Настя Шеломкова",
    en: "Nastya Shelomkova ",
    ua: "Настя Шеломкова ",
  },
  "review-7": {
    ru: "Пользуюсь сервисом каждый день! Много чего еще не хватает, но ребята быстро реагируют на мои просьбы и постепенно добавляют все нужные для меня инструменты! 🙂  Так что не стесняйтесь, пишите им!  🙂",
    en: "I use the service every day! A lot of things are still missing, but the guys respond quickly to my requests and gradually add all the tools I need! 🙂 So don't hesitate to write to them! 🙂 🙂",
    ua: "Користуюся сервісом щодня! Багато чого ще не вистачає, але хлопці швидко реагують на мої прохання і поступово додають усі потрібні для мене інструменти! 🙂 Так що не соромтеся, пишіть їм! 🙂 🙂"
  },
  "review-7-name": {
    ru: "Ангелина Овчинникова",
    en: "Angelina Ovchinnikova",
    ua: "Ангелина Овчинникова",
  },
  "review-8": {
    ru: "Пользуюсь сервисом уже четвертый месяц. Поначалу было непросто разобраться во всех особенностях, но по основным разделам есть пошаговые инструкции со скринами - изучила и почти все поняла.) А еще пару раз писала в поддержку - отвечали моментально и очень подробно. Каких-то критических недоработок не заметила. Плюс, ребята постоянно работают над улучшением сервиса, за что им респект.",
    en: "I have been using the service for four months now. At first, it was not easy to understand all the features, but there are step-by-step instructions with screenshots for the main sections - I studied them and understood almost everything). I also wrote to support several times, and they responded immediately and in great detail. I did not notice any critical flaws. Plus, the guys are constantly working to improve the service, for which they are to be commended.",
    ua: "Користуюся сервісом уже четвертий місяць. Спочатку було непросто розібратися у всіх особливостях, але за основними розділами є покрокові інструкції зі скрінами - вивчила і майже все зрозуміла.) А ще кілька разів писала на підтримку - відповідали моментально і дуже докладно. Якихось критичних недоробок не помітила. Плюс, хлопці постійно працюють над поліпшенням сервісу, за що їм респект."
  },
  "review-8-name": {
    ru: "Дарья Р.",
    en: "Daria R.",
    ua: "Дар'я Р.",
  },
  "review-9": {
    ru: "Добрый день. Хочу написать первое впечатление о пользовании вашим сервисом! Мы в восторге! Очень удобный интерфейс, весь товар в одной системе, видны остатки, продажи и поставки, а так же, наконец,  систематизированы финансы! Однозначно, рекомендуем всем!",
    en: "Good afternoon. I want to write a first impression of using your service! We are delighted! Very convenient interface, all the goods in one system, you can see balances, sales and deliveries, as well as, finally, systematized finances! Definitely recommend it to everyone!",
    ua: "Добрий день. Хочу написати перше враження про користування вашим сервісом! Ми в захваті! Дуже зручний інтерфейс, увесь товар в одній системі, видно залишки, продажі та постачання, а так само, нарешті, систематизовано фінанси! Однозначно, рекомендуємо всім!"
  },
  "review-9-name": {
    ru: "Ирина Понаровская",
    en: "Irina Ponarovskaya",
    ua: "Ірина Понаровська",
  },
  "integration-heading": {
    ru: "Доступные интеграции",
    en: "Available integrations",
    ua: "Доступні інтеграції",
  },
  "integration-sub-heading": {
    ru: "HugeProfit легко подключить к сервисам, которыми вы пользуетесь.",
    en: "HugeProfit is easy to connect to the services you use.",
    ua: "HugeProfit легко підключити до сервісів, якими ви користуєтесь.",
  },
  "we-are-trusted": {
    ru: "Нам доверяют",
    en: "We are trusted",
    ua: "Нам довіряють",
  },
  "tarif-heading": {
    ru: "Выбирайте лучший план и развивайте свой бизнес вместе с нами",
    en: "Choose the best plan and grow your business with us",
    ua: "Обирайте найкращий план та розвивайте свій бізнес разом з нами",
  },
  "more-reviews": {
    ru: "Больше отзывов",
    en: "More reviews",
    ua: "Більше відгуків",
  },
  "buy-now": {
    ru: "Купить сейчас",
    en: "Buy now",
    ua: "Купити зараз",
  },
  "most-popular": {
    ru: "Самый популярный",
    en: "Most popular",
    ua: "Найпопулярніший"
  },
  "say-about-us": {
    ru: "Что о нас говорят",
    en: "What people say about us",
    ua: "Що про нас кажуть"
  },
  'sales-module-sub-title': {
    ru: "Добавляйте все ваши онлайн и оффлайн продажи в удобном кабинете, контролируйте весь процесс и отслеживайте доходность от вашей деятельности.",
    en: "Add all your online and offline sales in a convenient account, control the entire process, and track the profitability of your business.",
    ua: "Додавайте всі ваші онлайн та оффлайн продажі у зручному кабінеті, контролюйте весь процесс та відстежуйте дохідність від вашої діяльности."
  },
  'sales-module-benefit-1': {
    ru: "добавляйте продажи в 2 клика в удобном интерфейсе;",
    en: "add sales in 2 clicks in a user-friendly interface;",
    ua: "додавайте продажі в 2 кліки в зручному інтерфейсі;"
  },
  'sales-module-benefit-2': {
    ru: "используйте сканер штрих-кодов и подсчет остатков для удобства;",
    en: "use a barcode scanner and count the change for convenience;",
    ua: "використовуйте сканер штрих-кодів та підрахунок решти для зручности;"
  },
  'sales-module-benefit-3': {
    ru: "формируйте и печатайте нужные документы: фискальные чеки, накладные, акты;",
    en: "generate and print the necessary documents: fiscal receipts, invoices, acts;",
    ua: "формуйте і друкуйте потрібні документи: фіскальні чеки, накладні, акти;"
  },
  'sales-module-benefit-4': {
    ru: "принимайте оплаты разными способами;",
    en: "accept payments in different ways;",
    ua: "приймайте оплати різними способами;"
  },
  'sales-module-benefit-5': {
    ru: "принимайте оплаты разными способами;",
    en: "accept payments in different ways;",
    ua: "фіксуйте канали продажів та клієнтів;"
  },
  'sales-module-benefit-6': {
    ru: "учитывайте дополнительные комиссии;",
    en: "take into account additional commissions;",
    ua: "враховуйте додаткові комісіії;"
  },
  'sales-module-benefit-7': {
    ru: "получайте информацию о реальном доходе с каждой продажи!",
    en: "get information about the real income from each sale!",
    ua: "отримуйте інформацію про реальний дохід з кожної продажі!"
  },
  'products-module-sub-title': {
    ru: "Вам нужно контролировать остатки товаров на вашем складе и на ВСЕХ маркетплейсах, оформлять поставки, проводить инвентаризацию, знать прибыль по каждой товарной позиции?",
    en: "Do you need to control the balances of goods in your warehouse and on ALL marketplaces, arrange deliveries, take inventory, know the profit for each product item?",
    ua: "Вам потрібно контролювати залишки товарів на вашому складі та на ВСІХ маркетплейсах, оформлювати поставки, проводити інвентаризацію, знати прибуток по кожній товарній позиції?"
  },
  'products-module-benefit-1': {
    ru: "контролировать остатки товаров на всех маркетплейсах;",
    en: "control product balances on all marketplaces;",
    ua: "контролювати залишки товарів на всіх маркетплейсах;"
  },
  'products-module-benefit-2': {
    ru: "делать поставки товаров по разной себестоимости и в разных валютах;",
    en: "deliver goods at different costs and in different currencies;",
    ua: "робити поставки товарів за різною собівартістю та в різних валютах;"
  },
  'products-module-benefit-3': {
    ru: "проводить расчет себестоимости;",
    en: "calculate the cost price;",
    ua: "проводити розрахунок собівартості;"
  },
  'products-module-benefit-4': {
    ru: "делать перемещения между складами;",
    en: "move between warehouses;",
    ua: "робити переміщення між складами;"
  },
  'products-module-benefit-5': {
    ru: "проводить инвентаризацию, создавать резервы товаров;",
    en: "take inventory and create reserves of goods;",
    ua: "проводити інвентаризацію, створювати резерви товарів;"
  },
  'products-module-benefit-6': {
    ru: "печатать ценники и накладные на товар;",
    en: "print price tags and invoices for goods;",
    ua: "друкувати цінники та накладні на товар;"
  },
  'products-module-benefit-7': {
    ru: "создавать комплекты товаров и контролировать цены;",
    en: "create product bundles and control prices;",
    ua: "створювати комплекти товарів та контролювати ціни;"
  },
  "finance-module-sub-title": {
    ru: "Невозможно контролировать бизнес без учета всех ваших финансовых затрат. Как можно вести учет остатков, без учета финансов? ",
    en: "It is impossible to control your business without taking into account all your financial expenses. How can you keep track of balances without accounting for finances? ",
    ua: "Не можливо контролювати бізнес без урахування всіх ваших фінансових витрат. Як можно вести облік залишків, без обліку фінансів? "
  },
  'finance-module-benefit-1': {
    ru: "создавайте множество счетов (наличные, карточки, сейф, у Сергея, долги, дома);",
    en: "create multiple accounts (cash, cards, safe, at Sergei's, debts, at home);",
    ua: "створюйте безліч рахунків (готівка, картки, сейф, у Сергія, борги, дома);"
  },
  'finance-module-benefit-2': {
    ru: "делайте переводы между счетами в разных валютах;",
    en: "make transfers between accounts in different currencies;",
    ua: "робіть перекази між рахунками в різних валютах;"
  },
  'finance-module-benefit-3': {
    ru: "записывайте все расходы на аренду, зарплату, закупки;",
    en: "record all expenses for rent, salaries, and purchases;",
    ua: "записуйте всі витрати на оренду, зарплатню, закупки;"
  },
  'finance-module-benefit-4': {
    ru: "смотри отчеты по обороту денег;",
    en: "see the reports on cash flow;",
    ua: "дивись звіти по обігу грошей;"
  },
  'finance-module-benefit-5': {
    ru: "контролируй финансы в торговых точках.",
    en: "control the finances of your outlets.",
    ua: "контролюй фінанси в торгових точках."
  },
  "order-module-sub-title": {
    ru: "Подключайте все ваши маркетплейсы и сайты к нашей системе, и получайте и обрабатывайте заказы в одном месте! ",
    en: "Connect all your marketplaces and websites to our system and receive and process orders in one place! ",
    ua: "Підключайте всі ваші маркетплейси та сайти до нашої системи, та отримуйте і обробляйте замовлення в одному місці! "
  },
  "order-module-benefit-1": {
    ru: "получайте заказы со всех площадок в одном месте;",
    en: "receive orders from all platforms in one place;",
    ua: "отримуйте замовлення зі всіх майданчиків в одному місці;"
  },
  "order-module-benefit-2": {
    ru: "автоматическое резервирование количества товаров на складе под заказ;",
    en: "automatic reservation of the quantity of goods in the warehouse to order;",
    ua: "автоматичне резервування кількості товарів на складі під замовлення;"
  },
  "order-module-benefit-3": {
    ru: "автоматическое обновление остатков сразу на всех сайтах и маркетплейсах;",
    en: "automatic updating of balances on all websites and marketplaces at once;",
    ua: "автоматичне оновлення залишків відразу на всіх сайтах та маркетплейсах;"
  },
  "order-module-benefit-4": {
    ru: "создавайте свою воронку продаж;",
    en: "create your own sales funnel;",
    ua: "створюйте свою воронку продажів;"
  },
  "order-module-benefit-5": {
    ru: "формируйте списки на отправку;",
    en: "create lists for sending;",
    ua: "формуйте списки на відправку;"
  },
  "order-module-benefit-6": {
    ru: "автоматически обновляйте статусы продаж не открывая площадку;",
    en: "automatically update sales statuses without opening the platform;",
    ua: "автоматично оновлюйте статуси продажів не відкриваючи майданчик;"
  },
  "order-module-benefit-7": {
    ru: "создавайте ТТН и отслеживайте отправления;",
    en: "create bill of lading and track shipments;",
    ua: "створюйте ТТН та відслідковуйте відправлення;"
  },
  "order-module-benefit-8": {
    ru: "фискализируйте продажи автоматически при получении заказа на Почте клиентом. ",
    en: "fiscalize sales automatically when a customer receives an order by mail. ",
    ua: "фіскалізуйте продажі автоматично під час отримання замовлення на Пошті клієнтом. "
  },
  "clients-module-sub-title": {
    ru: "Ваши клиенты это и есть ваш бизнес! Повторные продажи это залог успеха любого бизнеса! Будь вместе со своими покупателями.",
    en: "Your customers are your business! Repeat sales are the key to the success of any business! Stay with your customers.",
    ua: "Ваші клієнти це і є ваш бізнес! Повторні продажі це залог успіху будь-якого бізнесу! Будь разом зі своїми покупцями."
  },
  "clients-module-benefit-1": {
    ru: "сохраняйте все данные о клиентах;",
    en: "store all customer data;",
    ua: "зберігайте всі данні о клієнтах;"
  },
  "clients-module-benefit-2": {
    ru: "добавляйте адреса доставок;",
    en: "add delivery addresses;",
    ua: "додавайте адреси доставок;"
  },
  "clients-module-benefit-3": {
    ru: "добавляйте теги к клиентам, чтобы понимать что за клиент;",
    en: "add tags to customers to understand what kind of customer they are;",
    ua: "додавайте теги до клієнтів, щоб розуміти що за клієнт;"
  },
  "clients-module-benefit-4": {
    ru: "используйте программу лояльности, делайте скидки и накапливайте бонусы;",
    en: "use the loyalty program, get discounts and accumulate bonuses;",
    ua: "використуйте программу лояльності, робіть знижки та накопичуйте бонуси;"
  },
  "clients-module-benefit-5": {
    ru: "быстро связывайтесь с клиентом разными способами",
    en: "quickly communicate with the client in different ways",
    ua: "швидко зʼязуйтесь з клієнтом різними способами"
  },
  "chats-module-sub-title": {
    ru: "Общение с клиентами в мессенджерах в современном мире это неотъемлемая часть бизнеса. Но ведь на сколько удобнее, это делать в одном специализированном кабинете!",
    en: "In the modern world, communication with clients in messengers is not an integral part of business. But how much more convenient it is to do it in one specialized office!",
    ua: "Спілкування з клієнтами в мессенджерах у сучастному світі це не відʼємна частина бізнесу. Але ж на скільки зручніше, це робити в одному спеціалізованому кабінеті!"
  },
  "chats-module-benefit-1": {
    ru: "подключайте Telegram и Viber боты;",
    en: "connect Telegram and Viber bots;",
    ua: "підключайте Telegram та Viber боти;"
  },
  "chats-module-benefit-2": {
    ru: "подключайте свои страницы из Instagram и Facebook;",
    en: "connect your Instagram and Facebook pages;",
    ua: "підключайте свої сторінки з Instagram та Facebook;"
  },
  "chats-module-benefit-3": {
    ru: "сразу оформляйте продажи, не выходя из чата;",
    en: "make sales immediately without leaving the chat;",
    ua: "відразу оформлюйте продажі, не виходячи з чату;"
  },
  "chats-module-benefit-4": {
    ru: "видна вся история покупок клиента, и активные заказы;",
    en: "you can see the customer's entire purchase history and active orders;",
    ua: "видно всю історію покупок клієнта, та активні замовлення;"
  },
  "chats-module-benefit-5": {
    ru: "воронка статусов;",
    en: "status funnel;",
    ua: "воронка статусів;"
  },
  "chats-module-benefit-6": {
    ru: "тегирование клиентов",
    en: "customer tagging ",
    ua: "тегування клієнтів"
  },
  "shipments-module-sub-title": {
    ru: "Оформляй закупки товаров в любой валюте, рассчитывайся с поставщиками частями, сохраняй разную себестоимость в разрезе поставок. Система поставок позволяет:",
    en: "Make purchases of goods in any currency, pay suppliers in installments, and keep different costs per delivery. The supply system allows you to:",
    ua: "Оформлюй закупки товарів в будь-якій валюті, розраховуйся з постачальниками частинами, зберігай різну собівартість в розрізі поставок. Система поставок позволяє:"
  },
  "shipments-module-benefit-1": {
    ru: "оформлять закупку товаров в разной валюте;",
    en: "to make purchases of goods in different currencies;",
    ua: "оформлювати закупку товарів в різній валюті;"
  },
  "shipments-module-benefit-2": {
    ru: "оформлять поставку в несколько шагов;",
    en: "arrange delivery in a few steps;",
    ua: "оформлювати поставку в декілька кроків;"
  },
  "shipments-module-benefit-3": {
    ru: "контролировать расчеты с поставщиками;",
    en: "control settlements with suppliers;",
    ua: "контролювати разрахунки з постачальниками;"
  },
  "shipments-module-benefit-4": {
    ru: "контролировать распродажность товара по каждой поставке;",
    en: "control the sale of products from each delivery;",
    ua: "контролювати розпроданість товара з кожної поставки;"
  },
  "shipments-module-benefit-5": {
    ru: "сохранять разную закупочную стоимость товара от поставки к поставке;",
    en: "maintain a different purchase price for the goods from delivery to delivery;",
    ua: "зберігати різну закупівельну вартість товару від поставки до поставки;"
  },
  "shipments-module-benefit-6": {
    ru: "создавать накладные для поставщика;",
    en: "create invoices for the supplier;",
    ua: "створювати накладні для постачальника;"
  },
  "shipments-module-benefit-7": {
    ru: "учитывать в себестоимость стоимость доставки.",
    en: "include the cost of delivery in the cost price.",
    ua: "враховувати у собівартість вартість доставки."
  },
  "production-module-sub-title": {
    ru: "У вас есть свое мини-производство, но тяжелые бухгалтерские программы для учета производства пугают вас? А вам нужно только подсчитать себестоимость продукции? Тогда наш модуль Производства подходит вам лучше всего!",
    en: "Do you have your own mini-production, but heavy accounting software for production accounting scares you? Do you only need to calculate the cost of production? Then our Production module is the best fit for you!",
    ua: "У вас є своє міні-виробництво, але важкі бухгалтерські прогами для обілку виробництва лякають вас? А вам потрібно лише підрахувати собівартість продукції? Тоді наш модуль Виробництва підходить вам краще за все!"
  },
  "production-module-benefit-1": {
    ru: "максимально простой интерфейс;",
    en: "the simplest possible interface;",
    ua: "максимально простий інтерфейс;"
  },
  "production-module-benefit-2": {
    ru: "создание шаблонов производства;",
    en: "creating production templates;",
    ua: "створення шаблонів виробництва;"
  },
  "production-module-benefit-3": {
    ru: "подсчет себестоимости сырья и дополнительных финансовых затрат;",
    en: "calculating the cost of raw materials and additional financial expenses;",
    ua: "підрахунок собівартості сировини та додаткових фінасових витрат;"
  },
  "production-module-benefit-4": {
    ru: "закупка комплектующих товаров.",
    en: "purchase of component parts.",
    ua: "закупка комплектуючих товарів."
  },
  "employees-module-sub-title": {
    ru: "Добавляйте своих сотрудников, менеджеров и кассиров, контролируйте их продажи и производительность, разделяйте права доступа. ",
    en: "Add your employees, managers, and cashiers, monitor their sales and productivity, and share access rights. ",
    ua: "Додавайте своїх співробників, менеджерів та касирів, контролюйте їх продажі та продуктивність, розділяйте права доступу. "
  },
  "employees-module-benefit-1": {
    ru: "отчеты по сотрудникам;",
    en: "reports on employees;",
    ua: "звіти по співробітникам;"
  },
  "employees-module-benefit-2": {
    ru: "начисление процента и фиксированной виногороды с каждой продажи;",
    en: "charging a percentage and a fixed wine royalty on each sale;",
    ua: "нарахування проценту та фіксованої виногороди з кожного продажу;"
  },
  "employees-module-benefit-3": {
    ru: "просмотр продаж по продавцам в режиме онлайн;",
    en: "view sales by seller online;",
    ua: "перегляд продажів по продавцям в режимі онлайн;"
  },
  "employees-module-benefit-4": {
    ru: "привязка сотрудников к магазинам и счетам;",
    en: "linking employees to stores and accounts;",
    ua: "привʼязка співробітників до магазинів та рахунків;"
  },
  "employees-module-benefit-5": {
    ru: "ограничение доступа к различным разделам и финансовой информации;",
    en: "restricting access to various sections and financial information;",
    ua: "обмеження доступу до різних розділів та фінансової інформації;"
  },
  "reports-module-sub-title": {
    ru: "Просматривайте отчеты по всему вашему товарному бизнесу.",
    en: 'View reports across your entire commodity business.',
    ua: "Переглядайте звіти по всьому вашому товарному бізнесу."
  },
  "reports-module-benefit-1": {
    ru: "отчеты за любой период;",
    en: "reports for any period;",
    ua: "звіти за будь-який період;"
  },
  "reports-module-benefit-2": {
    ru: "реальный доход с учетом всех расходов и комиссий;",
    en: "real income including all expenses and commissions;",
    ua: "реальний дохід з урахуванням всіх витрат та комісій;"
  },
  "reports-module-benefit-3": {
    ru: "доходность каждого отдельного продукта или всей группы товаров;",
    en: "profitability of each individual product or the entire product group;",
    ua: "дохідність кожного окремого продукту чи всієї группи товарів;"
  },
  "reports-module-benefit-4": {
    ru: "отчеты по сотрудникам;",
    en: "reports on employees;",
    ua: "звіти по співробникам;"
  },
  "reports-module-benefit-5": {
    ru: "отчеты в разрезе каналов продаж;",
    en: "reports by sales channel;",
    ua: "звіти в розрізі каналів продажу;"
  },
  "reports-module-benefit-6": {
    ru: "ROI вашего бизнеса.",
    en: "ROI of your business.",
    ua: "ROI вашого бізнесу."
  },
  "hero-title": {
    ru: (
      <span>CRM/ERP система для товарного бизнеса с <span className='has-text-danger'>Online</span> и <span className='has-text-danger'>Offline</span> продажами!</span>
    ),
    en: (
      <span>CRM/ERP system for commodity business with <span className='has-text-danger'>Online</span> and <span className='has-text-danger'>Offline</span> sales!</span>
    ),
    ua: (
      <span>CRM/ERP система для товарного бізнесу з <span className='has-text-danger'>Online</span> та <span className='has-text-danger'>Offline</span> продажами!</span>
    )
  },
  "hero-sub-title": {
    ru: (
      <span>Более простого и понятного сервиса для учета продаж, склада и финансов вы не найдете - или мы вам <span className='has-text-danger'>вернем деньги! </span></span>
    ),
    en: (
      <span>You won't find a simpler and more intuitive service for sales, warehouse, and financial accounting - or we'll <span className='has-text-danger'>give you a refund!</span> </span>
    ),
    ua: (
      <span>Простішого та зрозумілішого сервісу для обліку продажів, складу та фінансів ви не знайдете - чи ми вам <span className='has-text-danger'>повернемо гроші!</span></span>
    )
  },
  "home-reg-block-title": {
    ru: "Начните эффективную работу вместе с ",
    en: "Start effective work with ",
    ua: "Почніть ефективну роботу разом із "
  },
  "home-reg-block-subtitle": {
    ru: "Простая регистрация для контроля и развития вашего бизнеса!",
    en: "Simple registration to control and develop your business!",
    ua: "Проста реєстрація для контролю й розвитку вашого бізнесу!"
  },
  "tarif-1-desc": {
    ru: "Если у вас немного продаж и товаров, вы можете пользоваться программой учета бесплатно!",
    en: "If you don't have many sales and products, you can use the accounting software for free!",
    ua: "Якщо у вас не багато продажів та товарів, ви можете користуватися програмою обліку безкоштовно!"
  },
  "tarif-8-desc": {
    ru: "Для небольших бизнесов, имеющих не более 200 продаж в месяц, этот тариф идеален.",
    en: "For small businesses with no more than 200 sales per month, this rate is ideal",
    ua: "Для невеликих бізнесів, які мають не більше 200 продажів на місяць, цей тариф ідеальний"
  },
  "tarif-9-desc": {
    ru: "Оптимальный тариф для опытных бизнесменов, включающий в себя 1000 продаж в месяц. ",
    en: "The optimal tariff for experienced businessmen, including 1000 sales per month.",
    ua: "Оптимальний тариф для досвідчених бізнесменів, що включає 1000 продажів на місяць."
  },
  "tarif-10-desc": {
    ru: "Безлимитный тариф для большого бизнеса. Нет ограничений по учету продаж",
    en: "Unlimited tariff for large businesses. No restrictions on sales accounting",
    ua: "Безлімітний тариф для великого бізнесу. Немає обмежень щодо обліку продажів"
  },
  "header-country": {
    ru: "Учет товаров и продаж",
    en: "Accounting for goods and sales",
    ua: "Облік товарів та продажів"
  }
};

export default messages;
