import React from 'react'
import SalesStore from "../sales-store";
import numberFormat from "../../../tools/number-format";
import T from "../../../components/i18n";
import Money from "../../../components/money";
import StatisticsBlock from "../../../components/statistics-block";
import User from "../../../user/user-store";
import ComparativeStatisticsBlock
	from "../../../components/statistics-block/comparative-statistics-block";
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

const SalesStatistics = ({data, tab, isSaleTab}) => {
	const {isMobileSize} = useIsMobile()
	const statistics = SalesStore.getCalcStatisticsPlusAfterPercentage(data, tab)

	const list = [
		{
			name: 'total-sales',
			value: <>{statistics.count.value} <small>({numberFormat(statistics.quantity.value)} {T('items-e')})</small></>,
			additionalComponent: isSaleTab && <ComparativeStatisticsBlock value={statistics.count.percentage} title={T('in-comparison-to', {start:statistics.dateStartAfter, end:statistics.dateEndAfter })}/>
		},
		{
			name: 'saled-on',
			value: <Money amount={statistics.amount.value} />,
			additionalComponent: isSaleTab && <ComparativeStatisticsBlock value={statistics.amount.percentage} title={T('in-comparison-to', {start:statistics.dateStartAfter, end:statistics.dateEndAfter })}/>
		},
		{
			name: 'earned',
			value: <Money amount={statistics.profit.value} />,
			additionalComponent: isSaleTab && <ComparativeStatisticsBlock value={statistics.profit.percentage} title={T('in-comparison-to', {start:statistics.dateStartAfter, end:statistics.dateEndAfter })}/>,
			permission: User.getPermissionEnable("incomeinformation"),
		},
		{
			name: 'not-paid',
			value: <Money amount={statistics.not_paid.value} className="has-text-danger"/>,
			additionalComponent: isSaleTab && <ComparativeStatisticsBlock value={statistics.not_paid.percentage} title={T('in-comparison-to', {start:statistics.dateStartAfter, end:statistics.dateEndAfter })}/>,
			permission: statistics.not_paid.value !== 0,
		},
		{
			name: 'sum-discount',
			value: <Money amount={statistics.discount.value} />,
			additionalComponent: isSaleTab && <ComparativeStatisticsBlock value={statistics.discount.percentage} title={T('in-comparison-to', {start:statistics.dateStartAfter, end:statistics.dateEndAfter })}/>,
			permission: !isMobileSize && statistics.discount.value !== 0 ,
		},
		{
			name: 'margin',
			value: `${statistics.margin.value}%`,
			additionalComponent: isSaleTab && <ComparativeStatisticsBlock value={statistics.margin.percentage} title={T('in-comparison-to', {start:statistics.dateStartAfter, end:statistics.dateEndAfter })}/>,
			permission: User.getPermissionEnable("incomeinformation") && !isMobileSize,
		},
		{
			name: 'commission',
			value: <Money amount={statistics.commission.value} />,
			additionalComponent: isSaleTab && <ComparativeStatisticsBlock value={statistics.commission.percentage} title={T('in-comparison-to', {start:statistics.dateStartAfter, end:statistics.dateEndAfter })}/>,
			permission: User.getPermissionEnable("incomeinformation") && !isMobileSize,
		}
	]

	return (
		<div>
			<StatisticsBlock list={list} classNameText='title margin-bottom-0' classNameTitle='font-size-07' isLoading={SalesStore.get("isLoading")}/>
		</div>
	);
};

export default SalesStatistics;