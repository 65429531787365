import React, {useEffect, useState} from "react";
import List from "react-bulma-components/lib/components/list";
import Columns from "react-bulma-components/lib/components/columns";
import T from "../../../../components/i18n";
import {Control, Input} from "react-bulma-components/lib/components/form";
import Icon from "react-bulma-components/lib/components/icon";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProductsStore from "../../../products/products-store";
import {getProductName} from "../../utils/getProductName";

const AddWriteOffRowDesktop = ({data,onlyShow,step, changeQuantity, removeProduct}) => {
	const [inputValue, setInputValue] = useState(data?.quantity_write_off || 1)

	const product = ProductsStore.getProductByMid(data.id, data.mid)

	const handleChangeInput = (evt) => {
		const pid = evt.target.name,
					quantity_write_off = evt.target.value

		if(product.quantity < quantity_write_off) {
			setInputValue(product.quantity)
		} else {
			setInputValue(quantity_write_off)
		}

		changeQuantity({pid, mid: data.mid, quantity_write_off})
	}

	const handleRemove = () => {
		removeProduct(data)
	}

	useEffect(()=> {
		if(data?.quantity_write_off)
		setInputValue(data?.quantity_write_off)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.quantity_write_off])


	console.log(product)
	return (
		<List.Item className="font-size-80">
			<Columns >
				<Columns.Column size={"two-thirds"}>
					{product ? getProductName(product) : <span>ID: {data.id}. <span className='text-danger'>{T('product_deleted')}</span></span>}
				</Columns.Column>
				<Columns.Column>
					<Columns>
						{!onlyShow && (<Columns.Column >
							<b>{product.instock} {T('items-p')}</b>
						</Columns.Column >)}
						<Columns.Column>
							{!onlyShow && step !== 5 && step !== 2 && <Control iconRight>
									<Input
										name={data.id}
										type="number"
										className="for-slim-icon"
										min="1"
										onChange={handleChangeInput}
										value={inputValue}
									/>
									<Icon align="right" className="for-slim-icon ">
										<span className="small-text">{T('items-p')}</span>
									</Icon>
							</Control>}
							{(onlyShow || step === 2) && <b>{inputValue} {T('items-p')}</b>}
						</Columns.Column>
						{!onlyShow && step !== 2 && <Columns.Column>
							<Link to="#" onClick={handleRemove} title={T('delete')}>
								<FontAwesomeIcon icon="trash-alt" size="1x" />
							</Link>
						</Columns.Column>}
					</Columns>
				</Columns.Column>
			</Columns>
		</List.Item>
	);
};

export default AddWriteOffRowDesktop;