import React, { useState } from "react";
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import { Input } from "react-bulma-components/lib/components/form";
import ProductsStore from "../../modules/products/products-store";
import T from "../i18n";
import { Button } from "react-bulma-components";
import UserStore from "../../user/user-store";
// import {createMobileAppLink} from "../../tools/create-mobile-app-link";

const PriceTagCountModal = ({ products, sid, onClose }) => {
  const priceTagSetting = UserStore.getPriceTagsSetting();

	const getInitialValue = () => {
		if (sid) return 0;

		if (priceTagSetting) {
			if (priceTagSetting.quantity) {
				return priceTagSetting.quantity;
			}

			if (priceTagSetting.page && priceTagSetting.page.quantity) {
				return priceTagSetting.page.quantity;
			}
		}

		return 0;
	}

  const [valueInput, setValueInput] = useState(getInitialValue());

	const handleOpenLinkMobileAndClose = (evt) => {
		// createMobileAppLink(ProductsStore.getPrintTabLink(products, sid, valueInput), evt)
		setTimeout(() => onClose(), 3000)
	}

  return (
    <Box>
      <Heading size={3}>{T('price-tags-print-label')}</Heading>
      <small>{T(sid ? 'pricetags-quantity-desk-with-sid' : "pricetags-quantity-desk-default")}</small>
      <Input
        value={valueInput}
        onChange={({ target: { value } }) => setValueInput(value)}
      />
      <div
        className="display-flex-row margin-top-10"
        style={{ justifyContent: "flex-end" }}
      >
        <a
          className="button is-medium is-success is-rounded"
          target="_blank"
          onClick={handleOpenLinkMobileAndClose}
          href={ProductsStore.getPrintTabLink(products, sid, valueInput)}
					rel="noopener noreferrer"
        >
          {T("print")}
        </a>
        <Button onClick={onClose} size="medium" rounded>
          {T("close")}
        </Button>
      </div>
    </Box>
  );
};

export default PriceTagCountModal;
