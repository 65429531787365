import T from "../../../components/i18n";

export const headingCheckbox = [
  {
    label: "clients-name",
    value: "client_name",
  },
  {
    label: "clients-phone",
    value: "client_phone",
  },
  {
    label: "client-email",
    value: "client_email",
  },
  {
    label: "tracking-number",
    value: "tracking_number",
  },
  {
    label: "delivery-delivery",
    value: "delivery_info",
  },
  {
    label: "product-barcode",
    value: "barcode",
  },
  {
    label: 'sale-date',
    value: 'create_date'
  },
  {
    label: 'who-add-sale',
    value: 'seller'
  },
  {
    label: "client-company",
    value: "client_company"
  },
  {
    label: "client-invoice",
    value: 'client_invoice'
  }
];

export const tableCheckbox = [
  {
    label: "numbering",
    value: "order",
  },
  {
    label: "product-images",
    value: "images",
  },
  {
    label: "product-vendor-code",
    value: "sku",
  },
  {
    label: "name",
    value: "name",
  },
  {
    label: "units-of-measurement",
    value: "uom",
  },
  {
    label: "quantity",
    value: "count",
  },
  {
    label: "product-info-price",
    value: "cost",
  },
  {
    label: "amount",
    value: "amount",
  },
  {
    label: 'sales-discount',
    value: 'discount'
  }
];

export const headingInput = [
  {
    label: "price-tag-font-size",
    name: "font",
    type: "number",
    icon: "pt",
  },
  {
    label: "company-info",
    name: "company_info",
    type: "textarea",
  },
];

export const tableInput = [
  {
    label: "price-tag-font-size",
    name: "font",
    type: "number",
    icon: "pt",
  },
];

export const nameInput = [
  {
    label: "price-tag-font-size",
    name: "font",
    type: "number",
    icon: "pt",
  },
  {
    label: "name",
    name: "text",
    type: "text",
  },
];

export const listFooter = [
  {
    name: "price_in_words",
    label: "price-words",
    children: [
      {
        name: "font",
        label: "price-tag-font-size",
        type: "number",
        icon: "pt",
      },
    ],
  },
  {
    name: "signature_seller",
    label: "signature-seller",
    children: [
      {
        name: "font",
        label: "price-tag-font-size",
        type: "number",
        icon: "pt",
      },
    ],
  },
  {
    name: "signature_buyer",
    label: "signature-buyer",
    children: [
      {
        name: "font",
        label: "price-tag-font-size",
        type: "number",
        icon: "pt",
      },
    ],
  },
  {
    name: "add_info",
    label: "additional-info",
    children: [
      {
        name: "font",
        label: "price-tag-font-size",
        type: "number",
        icon: "pt",
      },
      { name: "text", label: "additional-info", type: "textarea" },
    ],
  },
];

export const defaultValueFunc = (name, label) => {
  if (name === "delivery_note") {
    return {
      table: {
        font: 8,
        settings: {
          order: true,
          images: true,
          sku: true,
          name: true,
          uom: true,
          count: true,
          cost: true,
          amount: true,
          discount: true,
        },
      },
      heading: {
        font: 8,
        company_info: "",
        logo: false,
        settings: {
          client_name: true,
          client_phone: true,
          client_email: true,
          tracking_number: true,
          delivery_info: true,
          barcode: true,
          create_date: false,
          seller: false,
          client_company: false,
          client_invoice: false
        },
      },
      slipName: {
        font: 8,
        text: `${T(label)} {order_id} {date_created}`,
      },
      isTermoPrint: 0,
      footer: {
        price_in_words: {
          font: 8,
        },
        signature_seller: {
          font: 8,
        },
        signature_buyer: {
          font: 8,
        },
        add_info: {
          font: 8,
          text: "",
        },
      },
    };
  }
  if (name === "invoice_payment") {
    return {
      table: {
        font: 8,
        settings: {
          order: true,
          images: true,
          sku: true,
          name: true,
          uom: true,
          count: true,
          cost: true,
          amount: true,
          discount: false,
        },
      },
      heading: {
        font: 8,
        company_info: "",
        logo: false,
        settings: {
          client_name: true,
          client_phone: true,
          client_email: true,
          tracking_number: false,
          delivery_info: true,
          barcode: false,
          create_date: false,
          seller: false,
          client_company: false,
          client_invoice: false
        },
      },
      slipName: {
        font: 8,
        text: `${T(label)} {order_id} {date_created}`,
      },
      isTermoPrint: 0,
      footer: {
        price_in_words: {
          font: 8,
        },
        signature_seller: false,
        signature_buyer: {
          font: 8,
        },
        add_info: {
          font: 8,
          text: "",
        },
      },
    };
  }
  if (name === "bill_lading") {
    return {
      table: {
        font: 8,
        settings: {
          order: true,
          images: true,
          sku: true,
          name: true,
          uom: true,
          count: true,
          cost: true,
          amount: true,
          discount: true,
        },
      },
      heading: {
        font: 8,
        company_info: "",
        logo: false,
        settings: {
          client_name: true,
          client_phone: true,
          client_email: true,
          tracking_number: true,
          delivery_info: true,
          barcode: false,
          create_date: false,
          seller: false,
          client_company: false,
          client_invoice: false
        },
      },
      slipName: {
        font: 8,
        text: `${T(label)} {order_id} {date_created}`,
      },
      isTermoPrint: 0,
      footer: {
        price_in_words: {
          font: 8,
        },
        signature_seller: {
          font: 8,
        },
        signature_buyer: {
          font: 8,
        },
        add_info: {
          font: 8,
          text: "",
        },
      },
    };
  }
  if (name === "sales_receipt") {
    return {
      table: {
        font: 8,
        settings: {
          order: true,
          images: false,
          sku: false,
          name: true,
          uom: false,
          count: true,
          cost: true,
          amount: true,
          discount: true,
        },
      },
      heading: {
        font: 8,
        company_info: "",
        logo: false,
        settings: {
          client_name: false,
          client_phone: false,
          client_email: false,
          tracking_number: true,
          delivery_info: true,
          barcode: true,
          create_date: true,
          seller: false,
          client_company: false,
          client_invoice: false
        },
      },
      slipName: {
        font: 8,
        text: `${T(label)} {order_id}`,
      },
      isTermoPrint: 1,
      footer: {
        price_in_words: false,
        signature_seller: false,
        signature_buyer: false,
        add_info: false
      },
    };
  }

  return {};
};

export const checkFont = (value, listName) => {
  if(parseFloat(value) > 20 || parseFloat(value) < 3) {
    return listName;
  }
}