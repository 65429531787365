import yajax from "yajax";
import { createStore } from "../../tools/reflux-tools";
import Actions from './clients-actions';
import { filterSearch } from 'tools/filters-helper';
import User from 'user/user-store';
import {setDateWithString} from "../../tools/date-range";
import UserStore from "user/user-store";
import {LocalIcon} from 'whitelables/wl-name/icons'
import T from '../../components/i18n'
import React from "react";
import ProductsStore from "../products/products-store";
import {parseJSON} from "../../tools/error-handler";

const CLEAR_FILTER = {
    date_start: setDateWithString('all-period'),
    date_end: new Date(),
    search: "",
    total_amount_from: "",
    total_amount_to: "",
    city: {value: 0},
    tags: {value: 0,label: T('all')},
    birthday_start: new Date(),
    birthday_end: new Date(),
    birthday_child_start: new Date(),
    birthday_child_end: new Date(),
    withBirthday: '0',
    withBirthdayChild: '0',
    debt_from: "",
    debt_to: "",
    sort: {},
    company: {value: 0, label: T('all')},
    clientsCity: {value: 0, label: T('all')},
}

const ClientsStore = createStore({
	listenables: Actions,
    _prefix: "/bsales/clients",
    _prefix_city: "/userprofile/city",
    _prefix_postoffice: "/userprofile/postoffice",
    _prefix_delivery: "/bsales/delivery",
    _prefix_products: "/bproducts",
    state: {
        rows: null,
        clients: null,
        clients_delivery: {},
        clientsCountDict: {},
        clients_tags: null,
        city: null,
        postoffice: [],
        phone: null,
        lastCity: null,
        openModal: false,
        isLoadedCity: false,
        isLoadedPostoffice: false,
        isLoaded: false,
        isLoadedAddToClientTags: false,
        page: 0,
        count_page: 0,
        count_per_page: 10,
        _filter: CLEAR_FILTER,
        isDiscountSystemEnable: false,
        discounts: [],
        discountType: 'discount',
        uniqueCitiesCollection: {},
        uniqueClientsIds: [],
        company: [],
        cityClients: []
    },

    onSetPage(page) {
        this.setState({
            page: parseInt(page)
        });
    }, 

    onSetCountPerPage(count) {
        this.setState({
            count_per_page: count
        });
    },

    get(field) {
        return this.state[field];
    },

    clearFilter () {
      this.setState({
          _filter: CLEAR_FILTER,
      })
    },

    filterClearField({globalName, filterName}) {
        const filter = this.get('_filter')
        if(globalName) {
            this.setState({
                _filter: {
                    ...filter,
                    [globalName]: {
                        ...filter[globalName],
                        [filterName]: {value: 0}
                    }
                }
            })
        } else {
            this.setState({
                _filter: {
                    ...filter,
                    [filterName]: CLEAR_FILTER[filterName]
                }
            })
        }

    },

    getFilter(field) {
        return this.state['_filter'][field];
    },

    onFilter(name, value) {
        let filters = {};

        for (let i in this.state['_filter']) {
            filters[i] = this.state['_filter'][i];
        }


        filters[name] = value;

        this.setState({
            _filter: filters
        });
    },

    applySort(clients) {
        const sort = this.getFilter('sort')

        return clients.sort((a, b) => {
            for (const key in sort) {
                const direction = sort[key];

                const typeString = typeof a[key] === 'string' && typeof b[key] === 'string'

                const ItemA =  typeof a[key] === 'string'? a[key].toUpperCase() : a[key];
                const ItemB = typeof b[key] === 'string'? b[key].toUpperCase() : b[key]

                if (direction === 1 ) {
                    if (ItemA < ItemB) return typeString ? -1 : 1;
                    if (ItemA > ItemB) return typeString ? 1 : -1;
                } else if (direction === 0) {
                    if (ItemA > ItemB) return typeString ? -1 : 1;
                    if (ItemA < ItemB) return typeString ? 1 : -1;
                }
            }
            return 0;
        });
    },

    applyFilter(clients) {
        const filter = this.state._filter,
            date = new Date(clients['created_at']*1000);
        let result = true;


        filter['date_start'].setHours(0,0,0,0);
        filter['date_end'].setHours(23,59,59,999);
        if (filter['search'] !== "" && typeof(filter['search']) !== "undefined") {
            let search_data = {
                "name": clients['name'],
                "phone": clients['phone'],
                "middle_name": clients['middle_name']
            };

            return filterSearch(filter['search'], ['name', 'phone', 'middle_name'], search_data);

        }
        if(date < filter['date_start'] || date > filter['date_end']) {
            return false;
        }

        if(filter['city'] && filter['city']['value'] !== 0) {
            const clientsDelivery = this.get("clients_delivery")
            if(clientsDelivery[clients['id']]) {
                result = result && clientsDelivery[clients['id']].some(item => item.city === filter['city'].value)
            } else {
                result = false
            }
        }

        if (filter['clientsCity'] && !!filter['clientsCity']['value']) {
            result = result &&  filter['clientsCity']['value'] === clients['city'];
        }

        if(filter['tags'] && filter['tags']['value'] !== 0) {
            result = result && clients.tags?.some(item => item === filter["tags"]['value'])
        }

        if (filter['total_amount_from'] !== "") {
            result = result && (parseFloat(clients['total_amount']) >= parseFloat(filter['total_amount_from'])) ;
        }

        if (filter['total_amount_to'] !== "") {
            result = result && (parseFloat(clients['total_amount']) <= parseFloat(filter['total_amount_to'])) ;
        }

        if (filter['debt_from'] !== "") {
            result = result && (parseFloat(clients['debt']) >= parseFloat(filter['debt_from'])) ;
        }

        if (filter['debt_to'] !== "") {
            result = result && (parseFloat(clients['debt']) <= parseFloat(filter['debt_to'])) ;
        }

        if (filter['withBirthday'] !== "0") {
            if (clients.birth_day) {
                const clientBirthday = new Date(clients.birth_day);
                const clientMonthDay = clientBirthday.getMonth() * 100 + clientBirthday.getDate();

                const startDate = new Date(filter['birthday_start']);
                const startMonthDay = startDate.getMonth() * 100 + startDate.getDate();

                const endDate = new Date(filter['birthday_end']);
                const endMonthDay = endDate.getMonth() * 100 + endDate.getDate();

                result = result && clientMonthDay >= startMonthDay && clientMonthDay <= endMonthDay;
            } else {
                result = false;
            }
        }

        if (filter['withBirthdayChild'] !== "0") {
            if (clients.birth_day_child_1 || clients.birth_day_child_2) {
                const childrenBirthdayOne = new Date(clients.birth_day_child_1);
                const childrenBirthdayTwo = new Date(clients.birth_day_child_2);
                const childrenBirthdayOneMonthDay = childrenBirthdayOne.getMonth() * 100 + childrenBirthdayOne.getDate();
                const childrenBirthdayTwoMonthDay = childrenBirthdayTwo.getMonth() * 100 + childrenBirthdayTwo.getDate();

                const startDate = new Date(filter['birthday_child_start']);
                const startMonthDay = startDate.getMonth() * 100 + startDate.getDate();

                const endDate = new Date(filter['birthday_child_end']);
                const endMonthDay = endDate.getMonth() * 100 + endDate.getDate();

                result = result && ((childrenBirthdayOneMonthDay >= startMonthDay && childrenBirthdayOneMonthDay <= endMonthDay) || (childrenBirthdayTwoMonthDay >= startMonthDay && childrenBirthdayTwoMonthDay <= endMonthDay));
            } else {
                result = false;
            }
        }


        if(filter['company'] && filter['company']["value"]) {
            result = result && clients.company === filter['company']["value"]
        }



        return result;
    },

    getClientById(id) {
        const client = this.get("clients");

        if(client === null) {
            Actions.load()
            return {}
        }

        return client && id in client ? client[id] : {};
    },

    getSocialClient(clientID) {
      const client = this.getClientById(clientID);

      if(client) {
          return parseJSON(client?.more_info)?.social
      }

      return false
    },

    getExportUrl() {
      const filter = this.state._filter;
        filter['date_start'].setHours(0,0,0,0);
        filter['date_end'].setHours(23,59,59,999);
        const start = filter['date_start'] / 1000;
        const end = filter['date_end'] / 1000;

        const data = []

        if(filter.search) {
            data.push(`&search=${filter.search}`);
        }
        if(filter.city.value) {
            data.push(`&city=${filter.city.value}`);
        }
        if(filter.tags.value) {
            data.push(`&tag=${filter.tags.value}`);
        }
        if(filter['total_amount_from'] || filter['total_amount_to']) {
            data.push(`&purchased_amount_min=${filter['total_amount_from'] || 0}&purchased_amount_max=${filter['total_amount_to'] || 9999999999999}`)
        }
        if(filter['withBirthday'] !== "0") {
            filter['birthday_start'].setHours(0,0,0,0);
            filter['birthday_end'].setHours(23,59,59,999);
            const birthdayStart = filter['birthday_start'] / 1000
            const birthdayEnd = filter['birthday_end'] / 1000
            data.push(`&birthday_min=${Math.round(birthdayStart)}&birthday_max=${Math.round(birthdayEnd)}`);
        }

        if(filter?.sort && !!Object.keys(filter?.sort || {})?.length) {
            const keysSort = Object.keys(filter?.sort || {})
            keysSort.forEach(item => {
                let field = item
                if(item === 'total_amount') {
                    field = 'amount'
                }
                if(filter.sort[item] && parseInt(filter.sort[item]) !== 2) {
                    data.push(`&order_by=${field}`)
                } else if(parseInt(filter.sort[item]) !== 2){
                    data.push(`&order_by=${field}&sort_reverse=${true}`)
                }
            })
        }

        return `${ProductsStore._prefix_remote_csv}get_csv_clients?uid=${User.get("id")}${data.join('')}&date_start=${Math.round(start)}&date_end=${Math.round(end)}&lang=${User.getLang()}&token=${encodeURIComponent(User.getToken())}`
    },


    getClientByPhone(phone) {
        const clients = this.get("clients");

        if(clients === null) {
            Actions.load()
            return false
        }

        for (let i in clients) {
            let client = clients[i];

            if (client['phone'].indexOf(phone) > -1) {
                return client;
            }
        }
        return false;
    },

    getClientsExpensesList() {
        const clients = this.getClientsList(),
            result = clients.filter((row) => (row['is_expenses'] === 1));

        return result;
    },

    getClients() {
        const clients = this.getClientsList();
        return clients.filter(this.applyFilter);
    },

    getClientsByPages() {
        const clients = this.getClientsList(),
              start = this.state.page*this.state.count_per_page,
              end = start + this.state.count_per_page;
        const result = clients.filter(this.applyFilter);

        const resultWithSort = this.applySort(result)

        this.state.count_page = Math.ceil(resultWithSort.length/this.state.count_per_page);
        if (this.state.count_page < this.state.page) {
            this.setState({
                page: 0
            });
        }
        
        return resultWithSort.slice(start,end);
    },

    getClientsList() {
        const clients = this.get("rows");

        if (clients === null) {
            Actions.load();
            return [];
        }

        return clients;
    }, 


    onAddClient(data) {
        this.setState({
            openModal: true,
            lastCity: null,
            phone: data['name']
        });
    },

    closeModal() {
        this.setState({
            openModal: false
        });
    },

    getClientDeliveryList(client_id) {
        if (!client_id || client_id === 0) return [];

        if (typeof(this.state.clients_delivery[client_id]) === "undefined") {
            return [];
            // Actions.loadDelivery();
        } else {
            return this.state.clients_delivery[client_id];
        }
    }, 

    getClientDeliveryById(client_id, id) {
        const list = this.getClientDeliveryList(client_id);

        let response = {}
        for (let i in list) {
            let l = list[i];
            if (parseInt(l['id']) === parseInt(id)) {
                response = l;
                break;
            }
        }

        return response
    },  

    getClientDeliveryNane(d, lang) {
        if (!d) return null;
        let name = ""
        if (d['delivery_id'] === User.UP_ID) {
            name = d['post_office'];
        } else {
            let city_name = d['city_name_'+lang] || d['city_name_ua'],
                office_name = d['office_name_'+lang];
                name = city_name+", "+office_name;
        }

        return name;
    }, 

    getClientDeliveryNameById(client_id, id, lang) {
        if (!id) return null;
        let d = this.getClientDeliveryById(client_id, id);
        if (!d) return null;
        let city_name = d['city_name_'+lang] || d['city_name_ua'] || d['city'],
            office_name = d['office_name_'+lang] || d['post_office'];

        if(city_name || office_name) {
            return city_name ? `${city_name}, ${office_name ? office_name : ''}` : office_name
        }

        return ''
    },

    getClientDeliveryShort(client_id, id, lang) {
        if (!id) return null;
        let d = JSON.parse(JSON.stringify(this.getClientDeliveryById(client_id, id)));
        if (!d) return null;
        let city_name = d['city_name_'+lang] || d['city_name_ua'],
            number = d['number'];

        if (!city_name) {
            city_name = d['post_office'] || "";
        }

        return <span className='display-flex-row' style={{display: 'inline-flex'}}>
            {number && <LocalIcon icon='nova-poshta' size='small'/>}
            {city_name + (number ? ", НП: #" + number : "")}
        </span>
        ;
    },

    getClientDeliveryShortLabel(client_id, id, lang) {
        if (!id) return null;
        let d = JSON.parse(JSON.stringify(this.getClientDeliveryById(client_id, id)));
        if (!d) return null;
        let city_name = d['city_name_'+lang] || d['city_name_ua'],
          number = d['number'];

        if (!city_name) {
            city_name = `, ${d['post_office']}` || "";
        }

        return `${city_name}${number ? ", НП: #" + number : ""}`
    },

    getClientDeliveryListSelect(client_id, lang, delivery_id) {
        if (!client_id || client_id === 0) return [];

        let list_delivery = []
        if (typeof(this.state.clients_delivery[client_id]) == "undefined") {
            // Actions.loadDelivery();
        } else {
            const client_delivery = this.state.clients_delivery[client_id];

            for (let i in client_delivery) {
              let d = client_delivery[i],
                deliv_name = this.getClientDeliveryNane(d, lang);

                if (typeof(delivery_id) != "undefined" && parseInt(delivery_id) !== d['delivery_id']) {
                    continue;
                }
                list_delivery.push(
                  {'id': d['id'], 'name': deliv_name}
                )
            }
        }

        return list_delivery;
    },

    getDeliveryListCity() {
      const list = this.get('uniqueCitiesCollection')
      return Object.values(list)
    },

    hasDeliveryIdForCity(deliveryID, cityID) {
        const listCity = this.get('uniqueCitiesCollection');

        if (listCity[cityID]) {
            return listCity[cityID].deliveryIds.includes(deliveryID);
        }

        return false;
    },

    onLoadDelivery(refresh, client_id) {
        if (this.isLoadedDelivery) return;
        this.isLoadedDelivery = true;
        console.log("start load delivery service for client")
        let data = {}
        if (refresh) {
            data['refresh'] = true;
        }
        if (client_id) {
            data['client_id'] = client_id
        }
        yajax.get(this._prefix_delivery, data).then(Actions.loadDelivery.completed, Actions.loadDelivery.failed);
    },

    onLoadDeliveryCompleted(data) {
        console.log("completed loading delivery for clients");

        let client_delivery = {};
        let uniqueCitiesCollection = { ...this.state.uniqueCitiesCollection };

        if (typeof(data['client_id']) !== "undefined") {
            client_delivery = this.state.clients_delivery;
            client_delivery[parseInt(data['client_id'])] = data['data']
        } else {
            for (let i in data['data']) {
                let client = data['data'][i];
                const cityId = client['city']

                if (typeof(client_delivery[client['client_id']]) === "undefined") {
                    client_delivery[client['client_id']] = [];
                }
                client_delivery[client['client_id']].push(client);

                if(cityId) {
                    if (!uniqueCitiesCollection[cityId]) {
                        let cityName = client[`city_name_${UserStore.getLang()}`];
                        if (!cityName) {
                            cityName = client.city_name_ua;
                        }

                        uniqueCitiesCollection[cityId] = {
                            id: cityId,
                            name: cityName,
                            deliveryIds: []
                        };
                    }
                    uniqueCitiesCollection[cityId].deliveryIds.push(client.id)
                }
            }
        }

        this.setState({
            clients_delivery: client_delivery,
            lastCity : null,
            uniqueCitiesCollection
        });

        this.isLoadedDelivery = false;
    },

    onLoadDeliveryFailed() {
        this.isLoadedDelivery = false;
        console.log("failed load clients delivery");
    },

    onLoadByCids(cid) {
        if (this.state.isLoaded) return;

        this.setState({
            'isLoaded': true
        });

        yajax.get(this._prefix, {cid, t: Date.now()}).then(Actions.loadByCids.completed, Actions.loadByCids.failed);
    },

    onLoadByCidsCompleted(data) {
        const rows = JSON.parse(JSON.stringify(this.state.rows))
        const clients = JSON.parse(JSON.stringify(this.state.clients))
        data.data.forEach(client => {
            const isName = (client['first_name'] && client['first_name'] !== "") || (client['last_name'] && client['last_name'] !== "");

            client['name'] = "";
            if (isName) {
                client['name'] = client['first_name']+" " + client['last_name'];
            }

            if (client['nickname'] && client['nickname'] !== "") {
                client['name'] = client['nickname']+" "+client['name'];
            }

            if (client['company'] && client['company'] !== "") {
                client['name'] += ". "+client['company'];
            }

            client['simple_name'] = client['name'];

            if (client['phone'] && client['phone'] !== "")  {
                client['name'] += " (+"+client['phone']+")"
            }
            if(clients[client.id]) {
                const index = clients[client.id].index
                if(!!index || index === "0") {
                    rows[index] = client
                }
                clients[client.id] = client
            } else {
                rows.push(client)
                clients[client.id] = client
            }
        })

        this.setState({
            rows,
            count_page: Math.ceil(rows.length/this.state.count_per_page),
            clients,
            isLoaded: false
        })
    },
    onLoadByCidsFailed() {
        console.log("failed load clients");

        this.setState({
            'isLoaded': false
        });
    },
    onLoad(refresh) {
        if (this.state.isLoaded) return;

        this.setState({
            'isLoaded': true
        });

        let data = {};
        if (refresh) {
            data['refresh'] = true;
        }

        yajax.get(this._prefix, data).then(Actions.load.completed, Actions.load.failed);
    },

    onLoadCompleted(data) {
        console.log("completed loading clients", data);

        let result = [];
        let result_by_id = {};
        const company = new Set()
        const cityClients = new Set()

        for (let i in data['data']) {
            let client = data['data'][i];

            const isName = (client['first_name'] && client['first_name'] !== "") || (client['last_name'] && client['last_name'] !== "");

            client['name'] = "";
            if (isName) {
                client['name'] = client['first_name']+" " + client['last_name'];
            } 

            if (client['nickname'] && client['nickname'] !== "") {
                client['name'] = client['nickname']+" "+client['name']; 
            }

            if (client['company'] && client['company'] !== "") {
                client['name'] += ". "+client['company']; 
            }

            client['simple_name'] = client['name'];

            if (client['phone'] && client['phone'] !== "")  {
                client['name'] += " (+"+client['phone']+")"
            }

            if(client.company) {
                company.add(client.company)
            }

            if(client.city) {
                cityClients.add(client.city)
            }

            client.index = i

            result.push(client);
            result_by_id[client['id']] = client;
        }

        this.setState({
            rows: result,
            count_page: Math.ceil(result.length/this.state.count_per_page),
            clients: result_by_id,
            isLoaded: false,
            company: [...company],
            cityClients: [...cityClients]
        })
    },

    onLoadFailed() {
        console.log("failed load clients");

        this.setState({
            'isLoaded': false
        });
    },

    onAdd(data) {
        yajax.post(
            this._prefix,
            data,
        ).then(Actions.add.completed, Actions.add.failed);
    },

    onAddCompleted(data) {
        console.log("completed add clients ", data);
        Actions.load();
        Actions.loadDelivery();
        Actions.addClient.completed(data);
        this.setState({
            openModal: false,
            lastCity : null       
        });
    },

    onAddFailed() {
        console.log("failed add clients");
    }, 

    onImportClients(file) {
        const data = new FormData();
        data.append('file', file);

        let headers = {
            "X-Source-Type": "desktop"
        }

        if (!User.isGuest()) {
            headers["X-Auth-Token"] = encodeURIComponent(User.getToken())
        }
        
        console.log("start load files, ", data)
        return yajax.post(this._prefix_products+"/import_clients", data, {
            raw: true,
            headers: headers
        }).then(
            Actions.importClients.completed, Actions.importClients.failed
        );
    },

    onImportClientsCompleted(data) {
        console.log("completed imports clients ", data.length);
        Actions.load();
    },

    onImportClientsFailed() {
        console.log("failed add clients");
    }, 

    onAddDeliveryService(data) {

        yajax.post(
            this._prefix_delivery,
            data,
        ).then(Actions.addDeliveryService.completed, Actions.addDeliveryService.failed);
    },

    onAddDeliveryServiceCompleted(data) {
        console.log("completed  add delivery service for clients", data);
        if (data['success']) {
            Actions.loadDelivery();
            // Actions.load();
        }
    },

    onAddDeliveryServiceFailed() {
        console.log("failed add delivery service for clients");
    },

    getCityList() {
        const city = this.get("city");

        if (city === null) {
            Actions.loadCity();
            return [];
        }
        return city;
    },

    cleanPostoffice() {
        this.setState({
            "postoffice": []
        });
    },

    getPostOfficeList(city) {
        const postoffice = this.get("postoffice");
        
        if (postoffice.length < 1 && city && city.value !== 0) {
            Actions.loadPostoffice(city.value);
            return [];
        }

        return postoffice;
    },

    getLastCity() {
        return this.state.lastCity;
    },

    getCityNameById(cityID) {
      const getCity = this.getCityList()

      return getCity?.find(item => item.id === cityID)?.name
    },

    getPostOfficeNameById(cityID, postOfficeID) {
      const getPostOffice = this.getPostOfficeList({value: cityID})
      return getPostOffice?.find(item => item.id === postOfficeID)?.name
    },

    onLoadPostoffice(city) {
        if (this.state.isLoadedPostoffice || this.state.lastCity === city) return;

        this.setState({
            "isLoadedPostoffice": true,
            "lastCity": city
        })

        if (!city || city === "")  {
         return new Promise(resolve => {
            resolve([]);
          });
        }

        yajax.post(this._prefix_postoffice, {'lang': 'ru', 'city': city}).then(Actions.loadPostoffice.completed, Actions.loadPostoffice.failed);
    },    

    onLoadPostofficeCompleted(data) {
        console.log("completed loading postoffice", data);

        this.setState({
            postoffice: (data['data'].length > 0 ? data['data']: [{'id': 0, 'name': 'Нет отделений'}]),
            isLoadedPostoffice: false
        });
    },

    onLoadPostofficeFailed() {
        console.log("failed load city");
        this.setState({
            'isLoadedPostoffice': false
        })
    },

    onLoadCity() {
        if (this.state.isLoadedCity) return;

        this.setState({
            'isLoadedCity': true
        });
        yajax.get(this._prefix_city,{
                "lang": User.getLang()
            }).then(Actions.loadCity.completed, Actions.loadCity.failed);
    },

    onLoadCityCompleted(data) {
        console.log("completed loading city", data);
        this.setState({
            city: data['data'],
            isLoadedCity: false
        })
    },

    onLoadCityFailed() {
        console.log("failed load city");
        this.setState({
            'isLoadedCity': false
        })
    },

    onAddCity(name) {
        yajax.post(
            this._prefix,
            {
                "name": name
            },
        ).then(Actions.addCity.completed, Actions.addCity.failed);
    },

    onAddCityCompleted(data) {
        console.log("completed add city");
        Actions.loadCity();
    },

    onAddCityFailed() {
        console.log("failed add city");
    },

    onDeleteClient(id) {
        console.log("start delete client", id)

        const params = {
          method: "DELETE",
          url: this._prefix,
          data: {
            "cid": id
          }
        };

        return yajax(params).then(
            Actions.deleteClient.completed, Actions.deleteClient.failed
        );
    },

    onDeleteClientCompleted(data) {
        console.log("result delete client: ", data)
        Actions.load(true);
    },

    onDeleteClientFailed(){
        console.log("failed delete client")
    },    

    onDeleteDelivery(id) {
        console.log("start delete delivery", id)

        const params = {
          method: "DELETE",
          url: this._prefix_delivery,
          data: {
            "delivery_id": id
          }
        };

        return yajax(params).then(
            Actions.loadDelivery.completed, Actions.deleteDelivery.failed
        );
    },

    onDeleteDeliveryFailed(){
        console.log("failed delete delivery")
    },

    getClientTagList(){
        const clientTagList = this.get('clients_tags')

        if(clientTagList) {
            return clientTagList
        } else {
            Actions.loadClientsTags()
            this.setState({
                clients_tags: []
            })
            return []
        }
    },

    getGroupClientTagByClientId(id){
        const clientTagsList = this.getClientTagList()
        const getClientTag = this.getClientById(id)?.tags

        if(getClientTag && clientTagsList) {
            return  clientTagsList.filter(tag => getClientTag.includes(tag.id));
        }


        return false
    },

    getGroupClientTagListWithoutActiveForSelect(id) {
        const filterClientTagsList = this.getGroupClientTagByClientId(id)
        const getClientTag = this.getClientTagList()

        if(filterClientTagsList && filterClientTagsList) {
            return getClientTag?.filter(item => {
                if(!filterClientTagsList.some(clientTag => clientTag.id === item.id)) {
                    return {label: item.name, value: item.id}
                }
                return false
            })
        }
        return false
    },

    getDiscounts() {
      return this.get('discounts')
    },

    getEnabledDiscount() {
      return this.get('isDiscountSystemEnable')
    },

    getDiscountTypes() {
        return this.get('discountType')
    },

    onAddClientsTags(data){
        yajax.put(this._prefix + '/tags', data).then(
          Actions.addClientsTags.completed, Actions.addClientsTags.failed
        )
    },
    onAddClientsTagsCompleted(data) {
      Actions.loadClientsTags()
      console.log('add clients tags completed')
    },
    onAddClientsTagsFailed() {
      console.log('add clients tags failed')
    },

    onDeleteClientsTag(data){
      const params = {
          url: this._prefix + '/tags',
          method: "DELETE",
          data
      }
      yajax(params).then(
        Actions.deleteClientsTag.completed, Actions.deleteClientsTag.failed
      )
    },
    onDeleteClientsTagCompleted() {
        console.log('tag delete completed')
        Actions.load()

    },
    onDeleteClientsTagFailed() {
        console.log('tag delete failed')
    },

    onAddToClientsTags(data){
        yajax.post(this._prefix + '/tags', data).then(
          Actions.addToClientsTags.completed, Actions.addToClientsTags.failed
        )
    },
    onAddToClientsTagsCompleted(){
        console.log('add to clients tag completed')
        Actions.load()
    },
    onAddToClientsTagsFailed(){
        console.log('add to clients tag failed')
    },


    onLoadClientsTags() {
      if(!this.state.isLoadedAddToClientTags) {
          yajax.get(this._prefix + '/tags').then(
            Actions.loadClientsTags.completed, Actions.loadClientsTags.failed
          )
          this.setState({
              isLoadedAddToClientTags: true
          })
      }
    },
    onLoadClientsTagsCompleted(data) {
        this.setState({
            clients_tags: data.data,
            isLoadedAddToClientTags: false
        })
        console.log('load clients tag completed')
    },
    onLoadClientsTagsFailed(res) {
      this.setState({
          isLoadedAddToClientTags: false
      })
        console.log('load clients tag failed')
    },

    onLoadDiscounts() {
        return yajax.get(this._prefix + "/discounts").then(Actions.loadDiscounts.completed, Actions.loadDiscounts.failed);
    },
    onLoadDiscountsCompleted({ data }) {
        if (data && data.length > 0) {
            this.setState({
                isDiscountSystemEnable: data[0]?.is_enabled,
                discounts: JSON.parse(data[0]?.discounts),
                discountType: data[0]?.types
            });
        }
    },
    onLoadDiscountsFailed(){
    },

    onSetDiscountSystem(isEnable, discounts, types) {
        let data = {
            "is_enabled": isEnable,
            "discounts": discounts || this.state.discounts,
            "types": types || this.state.discountType
        };

        return yajax.put(this._prefix + "/discounts", { data: JSON.stringify(data)}).then(Actions.setDiscountSystem.completed(isEnable, discounts), Actions.setDiscountSystem.failed);
    },
    onSetDiscountSystemCompleted(isEnable, discounts) {
        this.setState({
            isDiscountSystemEnable: isEnable,
            discounts: discounts
        });
        // Actions.recalcDiscounts("all")
    },
    onSetDiscountSystemFailed(){
    },

    // onRecalcDiscounts(recalc) {
    //     return yajax.post(this._prefix + "/discounts", {recalc: JSON.stringify(recalc)}).then(Actions.loadDiscounts.completed, Actions.loadDiscounts.failed);
    // },
    // onRecalcDiscountsCompleted() {
    //     Actions.load();
    // },
    // onRecalcDiscountsFailed(){
    // },

    onLoadClientsCount(cids) {
      if(this.loadClientsCount) return

        this.loadClientsCount = true

        yajax.get('/bsales-tr/client_counts', {cids}).then(Actions.loadClientsCount.completed, Actions.loadClientsCount.failed)
    },
    onLoadClientsCountCompleted(data) {
        this.loadClientsCount = false

        const clientsCountDict = JSON.parse(JSON.stringify(this.get('clientsCountDict')))

        if(Array.isArray(data.data))
        data.data.forEach(item => {
            clientsCountDict[item.id] = item;
        })

        this.setState({
            clientsCountDict:clientsCountDict
        })
    },

    onLoadClientsCountFailed() {
        this.loadClientsCount = false
    },

    getClientCountById(clientId) {
        const clientsCount = JSON.parse(JSON.stringify(this.get('clientsCountDict')))

        if(clientsCount[clientId]) {
            return clientsCount[clientId]
        }

        return false
    },

    queueClientsCount(cid) {
      clearTimeout(this.timeoutQueue)
      const uniqueClientsIds = JSON.parse(JSON.stringify(this.get('uniqueClientsIds')))

      if(!uniqueClientsIds.includes(cid)) {
          uniqueClientsIds.push(cid)

          this.setState({
              uniqueClientsIds
          })
      }

      this.timeoutQueue = setTimeout(() => {
          const getUniqueClientsIds = JSON.parse(JSON.stringify(this.get('uniqueClientsIds')))
          const filterClientCount = getUniqueClientsIds.filter(item => {
              const clientCount = this.getClientCountById(item)
              return !clientCount
          })
          if(!!filterClientCount.length) {
              Actions.loadClientsCount(filterClientCount)
          }
          this.setState({
              uniqueClientsIds: []
          })
          clearTimeout(this.timeoutQueue)
      }, 2000)
    },

    addCountSale(cid) {
      const clientsCountDict = JSON.parse(JSON.stringify(this.get('clientsCountDict')))

      if(clientsCountDict[cid]) {
          clientsCountDict[cid].non_delete_orders_count += 1
      }

      this.setState({
          clientsCountDict
      })
    },

    getCompanyForSelect() {
        const company = this.get('company')
        if(company.length) {
            return company.map(item => ({name: item, id: item}))
        }

        return false
    },

    getClientsCity() {
      const list = this.get('cityClients')

      return list.map(item => ({name: item, id: item}))
    }
});
    

export default ClientsStore;
