import React from 'react';
import T from "components/i18n";
import SubmitButton from 'components/submit-button';
import ScrollToTopOnMount from 'tools/scroll-top'
import Notification from 'react-bulma-components/lib/components/notification';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import numberFormat from "tools/number-format";
import { connectToStores } from "tools/reflux-tools";
import { redirectTo } from 'tools/redirect-to'
import { 
    Field, 
    Control, 
    Input, 
    Label, 
    Textarea 
} from 'react-bulma-components/lib/components/form';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import Icon from 'react-bulma-components/lib/components/icon';
import Image from 'react-bulma-components/lib/components/image';
import List from 'react-bulma-components/lib/components/list';
import Columns from 'react-bulma-components/lib/components/columns';
import Heading from 'react-bulma-components/lib/components/heading';
import ShipmentsStore from '../shipments-store'
import Actions from '../shipments-actions'
import ProductsStore from '../../products/products-store'
import ProductsActions from '../../products/products-actions'
import WarehouseStore from '../../warehouse/warehouse-store'
import WarehouseActions from '../../warehouse/warehouse-actions'
import ExpensesStore from '../../expenses/expenses-store'
import ExpensesActions from '../../expenses/expenses-actions'
import SelectComponent from 'tools/select-component'
import calcMargin from 'tools/calc-margin';
import SelectProducts from 'components/select-products';
import InfoBlock from 'components/info-block';
import Money from "components/money";
import User from 'user/user-store';
import AppStore from 'app-store';
import "react-datepicker/dist/react-datepicker.css";
import BaseForm from 'components/modules/base-form';
import trackEvent from 'tools/track-events';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import OpenInsideModal from 'components/modal/open-inside-modal';
import LoadExcelShipmentsModal from '../load-excel-shipments-modal';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import AddSupplierModal from '../add-supplier-modal';
import NumberInput from "../../../components/number-input/number-input";
import EditProductsModal from "../../products/edit-products-modal";
import PriceTagCountModal
  from "../../../components/price-tag-count-modal/price-tag-count-modal";
import {
  withIsMobileSize
} from "../../../components/is-mobile-context/is-mobile-context";
import DOM from "react-dom-factories";
import ShipmentsActions from "../shipments-actions";
import InfoWrapper from "../../../components/info-wrapper";

class AddShipmentBase extends BaseForm {
    constructor(props) {
        super(props);
        let state = {},
            product_data = {},
            close = false;
        if (typeof(props.location.state) != "undefined") {
           state = props.location.state;
        }

        const data = state.data || {},
              edit = state.edit || false,
              step = state.step || 0;

        this.group_select = false;
        if (state.group_select) {
          AppStore.closeModal();
          this.group_select = state.group_select.select_product;
        }
        
        this.received = state.received || false;

        let waiting_count = 0, waiting_amount = 0;

        const items = data['items'] || {};

        if (Object.keys(items).length > 0) {
          for (let key in items) {
            const pr = items[key],
                  pid = pr['product_id'];
            let data_info = JSON.parse(JSON.stringify(ProductsStore.getProductByMid(pid, state.data.marketplace_id)));
            if (data_info) {
              data_info['quantity_shipped'] = pr['quantity_shipped'];
              data_info['quantity_received'] = pr['quantity_received'];
              if (parseFloat(pr['purchase_price']) > 0) {
                data_info['raw_net_price'] = pr['purchase_price'];
              }
              if (this.received) {
                waiting_count += pr['quantity_shipped'];
                waiting_amount += (pr['purchase_price']*pr['quantity_shipped']);
              }

              data_info["fee_per_unit"] = pr.fee_per_unit || 0

              product_data[pid] = this.formattingProductInfo(data_info, state.data.marketplace_id);
            } else {
              product_data[pid] = this.formattingProductInfoRemoveProduct(pid);
              //close = true;
            }
          }
        }


        this.product_data_list = [];

        for (let i in items) {
          this.product_data_list.push(items[i]['product_id']);
        }

        this.product_data_list = [...new Set(this.product_data_list)];

        const count_name = this.getCountName(),
              result = this.calcSaleAmount(product_data, count_name);

        let delivery_cost = null, delivery_account_id = null;
        if (data?.delivery_expenses) {
          delivery_cost = data.delivery_expenses.amount;
          // let ac = ExpensesStore.getAccountById(data.delivery_expenses.account_id);
          delivery_account_id = {'value': data.delivery_expenses.account_id};
          data['account'] = {'value': data.delivery_expenses.account_id}
        }

        this.state = {
          date: new Date(),
          delivery_date: new Date(),
          delivery_cost: delivery_cost|| data.fee_total || 0,
          delivery_service: data.delivery_id || null,
          account: delivery_account_id || null,
          is_paid: data.expenses_id || false,
          mid: {'value': data.marketplace_id || 0},
          id: data.id || null,
          suppliers: {'value': data.supplier_id || 0},
          currency: {'value': data.currency || User.get("currency_id")},
          currency_rate: data.currency_rate || User.getCurrencyRates(User.get("currency_id")),
          expenses_id: data.expenses_id || null,
          products_select: data.select_product || [],
          product_data: product_data, 
          comment: data.comment || "",
          shipment_name: data.name || "",
          received_amount: result['amount'] || 0, //amount after received items
          quantity: result['count'] || 0, //quantity items after received items
          shipment_cost: waiting_amount || data.amount || 0, //awaiting amount. this amout was get from account balance.
          shipment_count_item: waiting_count || data.expected_quantity || 0,  //how many items we waiting
          // account: data.account || {value: 0},
          shipment_account: data.shipment_account || {value: 0},
          step: step,
          edit: edit,
          close: close,
          waiting: null,
          message: null,
          addSupplierModal: false,
          refresh: 0
        };

        this.item_length = 0;
        this.checkShipment = this.checkShipment.bind(this)
        this.onChange = this.onChange.bind(this);
        this.nextStep = this.nextStep.bind(this);
        this.backStep = this.backStep.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeCount = this.onChangeCount.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.onChangeProductData = this.onChangeProductData.bind(this);
        this.onChangeRetailPrice = this.onChangeRetailPrice.bind(this);
        this.onChangeSelectProduct = this.onChangeSelectProduct.bind(this);
        this.renderStepOne = this.renderStepOne.bind(this);
        this.renderStepSecond = this.renderStepSecond.bind(this);
        this.renderStepThird = this.renderStepThird.bind(this);
        this.renderStepFourth = this.renderStepFourth.bind(this);
        this.renderStepFinish = this.renderStepFinish.bind(this);
        this.submit = this.submit.bind(this);
        this.createShipment = this.createShipment.bind(this);
        this.sendRetailPrice = this.sendRetailPrice.bind(this);
        this.deleteShipmentItem = this.deleteShipmentItem.bind(this);
        this.getAccountList = this.getAccountList.bind(this);
        this.onFinishBtnClick = this.onFinishBtnClick.bind(this);
        this.onChangeDeliveryPrice = this.onChangeDeliveryPrice.bind(this);


        this.listenAdd = null;
    }

    componentWillUnmount() {

      User.postMessage("show-barcode");

      if (typeof(this.listenAdd) == "function") {
          this.listenAdd();
      }

      if (typeof(this.listenGetList) == "function") {
        this.listenGetList();
      }

      if (typeof(this.listenAddFailed) == "function") {
        this.listenAddFailed();
      }

      if (typeof(this.listenDelete) == "function") {
        this.listenDelete();
      }

      if (typeof(this.listenLoadAddProduct) == "function") {
        this.listenLoadAddProduct();
      }
      if (typeof(this.loadShipment) == "function") {
        this.loadShipment();
      }
      if (typeof(this.listenLoadByPidInAddProduct) == "function") {
        this.listenLoadByPidInAddProduct();
      }
      if (typeof(this.loadShipmentByStepFour) == "function") {
        this.loadShipmentByStepFour();
      }
    }

    componentDidMount() {
        User.postMessage("hide-barcode");

        const {select_product} = this.props;

        if (select_product) {
            this.onChangeSelectProduct(select_product);
        }

        if (this.group_select && this.state.edit) {
            this.onChangeSelectProduct(this.group_select);
            this.group_select = false;
        }

        ProductsStore.closeModal();
        
        this.listenAdd = Actions.add.completed.listen((data) => {
            if(data.step === 4) {
              ShipmentsActions.load()
              this.loadShipmentByStepFour = ShipmentsActions.load.completed.listen(() => {
                this.setState({
                  close: true
                })
              })
            } else {
              this.setState({
                "step": 1,
                "waiting": false,
                "startAddProduct": false,
                "id": data['sid']
              });
              if (this.group_select) {
                this.onChangeSelectProduct(this.group_select);
                this.group_select = false;
              }

              if (this.state.product_data) {
                const keysProductData = Object.keys(this.state.product_data || {})
                if (keysProductData && keysProductData.length) {
                  ProductsActions.loadByPid(keysProductData)
                }
              }

              if (data.step === 2) {
                this.loadShipment = ShipmentsActions.load.completed.listen(() => {
                  this.checkShipment()
                })
              }
            }
        });

        this.listenDelete = Actions.delete.completed.listen((data) => {
            this.setState({
              "waiting": false,
              "startAddProduct": false
            });
        });

        this.listenDelete = Actions.delete.failed.listen((data) => {
            this.setState({
              "waiting": false,
              "startAddProduct": false
            });
        });

        this.listenGetList = ProductsActions.getImagesList.completed.listen(data => {
          this.setState(prev =>({
            refresh: prev.refresh + 1
          }))
        })
        this.listenLoadAddProduct = ProductsActions.addProduct.completed.listen(data => {
          this.listenLoadByPidInAddProduct = ProductsActions.loadByPid.completed.listen(() => {
            if(data.pid) {
              const getProduct = ProductsStore.getProductByMid(data.pid, this.getMid())
              if (getProduct) {
                this.setState(prev => {
                  if(prev.product_data[data.pid]) {
                    getProduct['quantity_shipped'] = prev.product_data[data.pid]['quantity_shipped'];
                    getProduct['quantity_received'] = prev.product_data[data.pid]['quantity_received'];
                    getProduct['raw_net_price'] = prev.product_data[data.pid]['raw_net_price'];
                  }

                  const formatingProduct = this.formattingProductInfo(getProduct, this.state.mid)
                  return {
                    product_data: {
                      ...prev.product_data,
                      [data.pid]: formatingProduct
                    },
                  }
                })


              }
            }
          })
        })

        this.listenAddFailed = Actions.add.failed.listen((res) => {
            const data = JSON.parse(res['response']);
            
            this.setState({
              "waiting": false,
              "startAddProduct": false,
              "message": T(data['error']),
            });
        });
    }
  
    checkShipment() {
    const ship = ShipmentsStore.getShipmentById(this.state.id);

    let product_data = {};
    // let waiting_count = 0, waiting_amount = 0;
      if (ship && ship?.items && ship?.items?.length > 0) {
          for (let pr of ship.items) {
            const pid = pr['product_id'];
            let data_info = JSON.parse(JSON.stringify(ProductsStore.getProductByMid(pid, this.state.mid.value)));
            // console.log('PRODUCT', data_info)
            if (data_info) {
              data_info['quantity_shipped'] = pr['quantity_shipped'];
              data_info['quantity_received'] = pr['quantity_received'];
              if (parseFloat(pr['purchase_price']) > 0) {
                data_info['raw_net_price'] = pr['purchase_price'];
              }
              // if (this.received) {
              //   waiting_count += pr['quantity_shipped'];
              //   waiting_amount += (pr['purchase_price']*pr['quantity_shipped']);
              // }
              data_info["fee_per_unit"] = pr.fee_per_unit || 0
              product_data[pid] = this.formattingProductInfo(data_info, this.state.mid);
            } else {
              product_data[pid] = this.formattingProductInfoRemoveProduct(pid);
            }
          }
      }

    product_data = { ...this.state.product_data, ...product_data };
    this.updateData(product_data)
      if(ship && ship.items) {
        for (let i in ship.items) {
          this.product_data_list.push(ship.items[i]['product_id']);
        }
        this.product_data_list = [...new Set(this.product_data_list)];
      }
    }


    getMid() {
      return this.state.mid.value || this.state.mid;
    }

    getMessage() {
        setTimeout(() => {
          this.setState({
            "message": null
          })
        }, 4000);
        return (
              <Notification color="danger">
                    <ScrollToTopOnMount />
                    {this.state.message}
                    <Button remove onClick={() => this.setState({message: null})} />
              </Notification>
        )
    }

    nextStep() {
        let step = this.state.step + 1, error_message;

        if (step === 1 && this.state.edit) {
          this.createShipment();
        } else if (step === 2) {
            if (this.received){
              if (Object.keys(this.state.product_data).length < 1) {
                  error_message = "select-min-one-item";
              } else if (this.state.quantity === 0) {
                  error_message = "not-received-product";
              }

              if (error_message) {
                this.setState({
                  "message": T(error_message)
                })
                return;
              }
            }

            let prd = this.state.product_data;


            for (let i in this.state.product_data) {
              prd[i]['quantity_received'] = this.state.product_data[i]['quantity_received'] || 0;
              prd[i]['retail_price'] = this.state.product_data[i]['retail_price'] || 0;
            }

            this.setState({"product_data": prd})

            Actions.add({
                "step": 1,
                "sid": this.state.id,
                "products": this.state.product_data
            });

            this.listenAdd = Actions.add.completed.listen((data) => {
                let state = {} 
                if (!this.received) {
                  state = {"step": 4};
                } else {
                  state = {
                    "shipment_cost": this.state.received_amount,
                    "step": step
                  }
                }

                this.setState(state);
            });
        } else {
          this.setState({"step": step});
        }

        // useEffect(() => {
          window.scrollTo(0, 0);
        // }, [])
    }

    backStep() {
        this.setState({"step": this.state.step - 1});
    }

    getShipmentsName() {
      const date = this.state.date.toLocaleDateString(),
           q = T('shipment') + " " + this.state.quantity + " "+T('items-e')+ " ";

      return q + T('from') + " " + date;
    }

    submit() {
        const state = this.state;        

        let data = {
            "delivery_date": state.delivery_date,
            "step": state.step,
            "comment": state.comment,
            "name": state.shipment_name || this.getShipmentsName(),
            "sid": state.id,
            "delivery_cost": state.delivery_cost || 0,
            "delivery_id": state.delivery_service.value,
            "mid": state.mid.value || state.mid,
            "currency": state.currency.value,
            "shipment_cost": parseFloat(state.shipment_cost) || 0,
            "quantity": state.quantity
        }

        if (state.shipment_account) {
            data["account_id"] = state.shipment_account.value;
        }

        if (state.account) {
            data["delivery_account_id"] = state.account.value;
        }
          
        Actions.add(data);

        this.listenAdd = Actions.add.completed.listen((data) => {
            this.setState({"step": 3, "waiting": false});
            trackEvent('users', 'working', 'add-shipment');
        });
    }

    createShipment() {
      console.log("start create shipment");
        const state = this.state;

        let data = {
            "comment": state.comment,
            "name": state.shipment_name || this.getShipmentsName(),
            "shipment_count_item": state.shipment_count_item,
            "shipment_cost": state.shipment_cost || 0,
            "mid": state.mid.value,
            "currency": state.currency.value,
            "currency_rate": state.currency_rate || 1,
            "step": state.step,
            "date": state.date,
            "supplier_id": state.suppliers ? state.suppliers.value : 0,
        }

        if (state.shipment_account) {
            data["account_id"] = state.shipment_account.value;
        }

        if (state.edit) {
          data['sid'] = state.id;
        }

        this.setState({'waiting': true})

        Actions.add(data);
    }

    sendRetailPrice() {
      this.setState({
        waiting: true
      })
        Actions.add({
            "step": 4,
            "sid": this.state.id,
            'currency_rate': this.state.currency_rate,
            "products": this.state.product_data
        });
    }

    deleteShipmentItem(pid) {
      let product_data = this.state.product_data;
      const state = this.state;

      if (typeof(product_data[pid]) != "undefined") {
        delete product_data[pid];

        let new_list = []
        for (let i in this.product_data_list) {
            if (parseInt(this.product_data_list[i]) !== parseInt(pid)) {
              new_list.push(parseInt(this.product_data_list[i]));
            }
        }

        this.product_data_list = [...new Set(new_list)];

        this.updateData(product_data);

        let data = {
            "pid": pid,
            "sid": state.id
        }
          
        Actions.delete(data);
      }
    }

    updateData(product_data) {
        const count_name = this.getCountName(),
              result = this.calcSaleAmount(product_data, count_name);

        this.setState({
            'product_data': product_data,
            'received_amount': result['amount'],
            'quantity': result['count']
        });
    }

    calcSaleAmount(products, count_name) {
      let amount = 0;
      let count = 0;
      for (let i in products) {
        const prod = products[i],
              count_item = this.received ? parseFloat(prod['quantity_received'] || 0) : parseFloat(prod['quantity_shipped'] || 0);
             amount += parseFloat((prod['raw_net_price'] || 0)*count_item);
            count += count_item;
      }

      amount = parseFloat(amount.toFixed(2));

      return {amount, count};
    }

    formattingProductInfo(data, mid) {
      let name = data['name'],
          instock = data['instock'],
          quantity = data['quantity'];

      if (typeof(data['stock']) !== "undefined" && mid &&
        typeof(data['stock'][mid]) !== "undefined") {
        instock = data['stock'][mid]['instock'];
        quantity = data['stock'][mid]['quantity'];
      }



      return {
                'id': data['id'],
                'name': name,
                'brand': data['brand'] || "",
                'retail_price': data['price'] || "",
                'finish_price': data['net_price'] || 0,
                'raw_net_price': data['raw_net_price'] || 0,
                'quantity_shipped': data['quantity_shipped'] || 0,
                'quantity_received': data['quantity_received'] || 0,
                'sku': data['sku'],
                'asin': data['asin'],
                'units': data['units'],
                'instock': instock || 0,
                'quantity': quantity || 0,
                'wid': data['wid'],
                'fee_per_unit': data['fee_per_unit'] || 0
              }
    }

    formattingProductInfoRemoveProduct(pid) {
      return {
            'id': pid,
            'remove': true,
            'name': T('product-remove'),
            'brand': "",
            'retail_price': "",
            'finish_price': 0,
            'raw_net_price':  0,
            'quantity_shipped': 0,
            'quantity_received': 0,
            'sku': "",
            'asin': "",
            'instock': "",
            'quantity': "",
            'wid': ""
          }
    }

    onChangeSelectProduct(selectedOption, need_delete, isEdit) {
      if (this.state.startAddProduct) {
        return false;
      }
      this.setState({
        'startAddProduct': true
      });
      let product_data = this.state.product_data;
      let send_data = {};
      for (let p in selectedOption) {
          let pid = selectedOption[p]['value'].split("_")[0],
              data = ProductsStore.getProductByMid(pid, this.getMid());
          if (data) {
            if (need_delete) {
              this.deleteShipmentItem(pid);
            } else {
                if (typeof(product_data[pid]) != "undefined") {
                  if(!isEdit) {
                    if (this.received) {
                      product_data[pid]['quantity_received'] = parseFloat(product_data[pid]['quantity_received']);
                      product_data[pid]['quantity_received'] += 1;
                    } else {
                      product_data[pid]['quantity_shipped'] = parseFloat(product_data[pid]['quantity_shipped']);
                      product_data[pid]['quantity_shipped'] += 1;
                    }
                  }
                } else {
                  let product_info = this.formattingProductInfo(data, this.getMid());
                  this.product_data_list.unshift(parseInt(pid))

                  if (this.received) {
                    product_info['quantity_received'] = 1;
                    product_info['quantity_shipped'] = 0;
                  } else {
                    product_info['quantity_shipped'] = 1;
                    product_info['quantity_received'] = 0;
                  }
                  
                  product_data[pid] = product_info;
                }
                send_data[pid] = product_data[pid];
            }
          }
      } 

      this.product_data_list = [...new Set(this.product_data_list)];

      if (Object.keys(send_data).length > 0) {
        Actions.add({
          "step": 1,
          "sid": this.state.id,
          "products": send_data,
        });
      } else {
        this.setState({
          'startAddProduct': false
       });
      }

      // this.listenAdd = Actions.add.completed.listen(() => {
      //     this.setState({"step": 2});
      // });

      this.updateData(product_data);
    }


    onChangeSelect(selectedOption, action) {
      let data = {
          [action.name]: selectedOption,
          'currency_rate': this.state.currency_rate
      }

      if (action.name === "currency") {
          data['currency_rate'] = User.getCurrencyRates(selectedOption.value);
          data['shipment_account'] = {'value': 0};
      }

      this.setState(data)
    }


    onChangeDate(value, name) {
        this.setState({
            [name]: value
        });
    }

    onChange(evt) {
        let value = evt.target.value,
            name = evt.target.name;

        if (name === "shipment_cost") {
          if (value.length > 0) {
            value = value.replace(",", ".");
            if (value[0] === "0") {
              value = value.slice(1, value.length)
            } 

            if (isNaN(value)) {
              return
            }
          }
        }

        this.setState({
            [name]: value
        });
    }

    onChangeNumber(evt) {
        let value = evt.target.value,
            name = evt.target.name;

          this.setState({
              [name]: value
          });
    }

    onChangeProductData(evt, name) {
      let  value = evt.target.value,
            pid = evt.target.name.split("-")[1];

        let product_data = this.state.product_data;

        if (value.length > 0) {
            value = value.replace(",", ".");

            if (value.length >= 2) {
              if (value[0] === "0" && value[1] !== ".") {
                value = value.slice(1, value.length);
              } 
            }
        }

        if (!isNaN(value)) {
          product_data[pid][name] = value;
          this.updateData(product_data);
        }
    }


    onChangeRetailPrice(evt) {
        let value = evt.target.value,
            pid = evt.target.name.split("-")[1];

        let product_data = this.state.product_data,
            wid =  product_data[pid]['wid'];
        value = this.checkVal(value);

            if (value !== product_data[pid]['retail_price']) {
                ProductsActions.setPrice("price", wid, value || 0, pid, this.getMid());
            }

            product_data[pid]['retail_price'] = value;

            this.setState({
                'product_data': product_data
            });

    }

    onChangeDeliveryPrice({target: {value, name}}) {
      const pid = name.split("-")[1]
      const product_data = this.state.product_data

      product_data[pid]['fee_per_unit'] = value;

      this.setState({
        'product_data': product_data
      });
    }

    onChangeCount(evt) {
        let value = evt.target.value,
            pid = evt.target.name.split("-")[2],
            count_name = this.getCountName();

        let product_data = this.state.product_data;

        value = this.checkVal(value);

        if (!isNaN(value)) {
          product_data[pid][count_name] = value;
          this.updateData(product_data)
        }
    }

    getProductName(prod) {
      const pid = prod.id;
      let stock_text = prod.instock;

      if (prod.instock !== prod.quantity) {
          stock_text += "/"+prod.quantity;
      }

      let name = "",
          instock = T('product-info-warehouse-one')+" "+stock_text+" "+ProductsStore.getUnits(prod.units)+".";

      if (User.getPermissionEnable("incomeinformation")) {
        if (prod['netprice']) {
          instock += " "+T('netcost')+": "+numberFormat(prod['netprice'])+" "+User.getCurrency()
        } else if (this.state.step === 1 && prod['raw_net_price']) {
          instock += " " + T('netcost')+": "+numberFormat(prod['raw_net_price'])+" "+this.getCurrency()
        }
      }

      if (prod.sku  && prod.sku !== "") {
          name += prod.sku+". ";
      }

      if (prod.brand && prod.brand !== "") {
          name += prod.brand+". ";
      }

      name += prod.name;

      if (prod.asin && prod.asin !== "") {
          name += " | "+prod.asin;
      }

      return  ( 
          <span>ID: {pid}. {name} <br /> <b>{instock}</b></span>
      );
      
    }

    getProductNameMobile(prod) {
      const pid = prod.id;

      let name = ["ID: "+pid];

      if (prod.sku  && prod.sku !== "") {
          name.push(prod.sku);
      }

      if (prod.brand && prod.brand !== "") {
          name.push(prod.brand);
      }

      name.push(prod.name);

      return  ( 
          <span>{name.join(". ")} </span>
      );
      
    }

    getCountName() {
      return this.received ? 'quantity_received' : 'quantity_shipped';
    }

    moveQuantity(pid) {
       let product_data = this.state.product_data;
       product_data[pid]['quantity_received'] = product_data[pid]['quantity_shipped'];
       this.updateData(product_data);
    }

    moveQuantityAll() {
       let product_data = this.state.product_data;

       for (let pid in product_data) {
          product_data[pid]['quantity_received'] = product_data[pid]['quantity_shipped'];
       }

       this.updateData(product_data);
    }

    productLine(prod) { 
      return this.props.isMobileSize ? this.productLineMobile(prod) : this.productLineDesktop(prod);
    }

  getInstockMobileText(prod) {
      let stock_text = prod.instock;

      if (prod.instock !== prod.quantity) {
          stock_text += "/"+prod.quantity;
      }

      let instock = stock_text+" "+ProductsStore.getUnits(prod.units);

      let net_price = "";
      if (prod['netprice']) {
        net_price = (<span> {T('netcost-short')}: <span className="text-success">{numberFormat(prod['netprice'])} {User.getCurrency()} </span></span>)
      } else if (this.state.step === 1 && prod['raw_net_price']) {
        net_price = (<span> {T('netcost-short')}: <span className="text-success">{numberFormat(prod['raw_net_price'])} {User.getCurrency()} </span></span>)
      }
        
      return (
        <>
          
          <span className="text-success">{instock}</span> <br />
          {!User.getPermissionEnable("incomeinformation") && this.state.step === 3 ? "" : net_price}
        </>
        );
    }
    
    productLineMobile(prod) { 
        const pid = prod.id,
              name = this.getProductNameMobile(prod),
              instock = this.getInstockMobileText(prod),
              count_name = this.getCountName(),
              img = "/bimages/get/64-"+User.get("id")+"-"+pid+"-0.jpg",
              quantity = this.received ? "quantity-res-mob" : "quantity-ord-mob";
        const getProduct = ProductsStore.getProductByMid(pid, this.state.mid.value)


        const shCurr = this.getCurrency();
        const retailCurr = User.getCurrency();

        let sumCostAmount = this.state.product_data[pid][count_name] * (this.state.product_data[pid]['raw_net_price'] || 0)
        const currRate = this.state.currency_rate;

        if(shCurr !== retailCurr) {
          sumCostAmount = this.state.product_data[pid][count_name] * ((this.state.product_data[pid]['raw_net_price'] || 0) * currRate)
        }

        return (
          <List.Item key={pid} align="left" >
                <b>
                <Image src={img} size={32} className="align-left" key={this.state.refresh}/>
                {User.getPermissionEnable("editproducts") ? <OpenInsideModal size="small" text={name} renderAs="b">
                  <EditProductsModal pid={getProduct.parent_id || getProduct.id} step={0}/>
                </OpenInsideModal> : name}
                </b>
            <hr />
            <Columns className="is-mobile">
              <Columns.Column className="is-half">
                <Label>{T('product-info-warehouse-one')}:
                  <span> {instock}</span><br />
                {this.received ?
                    <span>{T('expect')}: <span className="like-link" onClick={() => this.moveQuantity(pid)}> {ProductsStore.getStockUnits(this.state.product_data[pid]['quantity_shipped'], this.state.product_data[pid]['units'])}</span></span> : <span className="opacity0">. &nbsp;</span>}
                </Label>
              </Columns.Column>
              <Columns.Column className="is-half">
                <Field horizontal>
                  <Label>{T(quantity)}:</Label>
                  <Control iconRight>
                    <NumberInput
                      key={"count-pid-"+pid}
                      name={"count-pid-"+pid}
                      type="text"
                      className="for-small-icon"
                      onChange={this.onChangeCount}
                      value={this.state.product_data[pid][count_name]}
                    />
                    <Icon align="right">
                        <span className="small-text">{ProductsStore.getUnits(this.state.product_data[pid]['units'])}</span>
                    </Icon>
                  </Control>
                </Field>
              </Columns.Column>
              <Columns.Column className="is-half">
                <Field horizontal>
                  <Label>{T('shipments-price-item')}:</Label>
                  <Control iconRight>
                    <NumberInput
                      key={"pid-"+pid}
                      name={"pid-"+pid}
                      className="for-small-icon"
                      type="text"
                      onChange={(evt) => this.onChangeProductData(evt, 'raw_net_price')}
                      value={this.state.product_data[pid]['raw_net_price']}
                    />
                    <Icon align="right">
                      <span className="small-text">{this.getCurrency()}</span>
                    </Icon>
                  </Control>
                </Field>
              </Columns.Column>
              <Columns.Column className="is-half">
                  <Field horizontal>
                    <Label>{T('products-price')}:</Label>
                    <Control iconRight>
                      <NumberInput
                        key={"retail_price-"+pid}
                        name={"retail_price-"+pid}
                        className="for-small-icon"
                        onChange={this.onChangeRetailPrice}
                        value={this.state.product_data[pid]['retail_price']}
                      />
                      <Icon align="right">
                        <span className="small-text">{User.getCurrency()}</span>
                      </Icon>
                    </Control>
                  </Field>
                <div align='right' style={{ marginTop: '-5px' }}>
                  <b className='display-inline'>{T("cost-of-sales")}: </b>
                  <Money
                    wrapper={DOM.span}
                    amount={sumCostAmount}/>
                </div>
              </Columns.Column>
            </Columns>
                <div align="right">
                      <Link to="#" onClick={() => this.deleteShipmentItem(pid)} title={T('delete')}>
                        <FontAwesomeIcon icon="trash-alt" size="1x" /> {T('delete-from-list')}
                      </Link>
                </div>
          </List.Item>
        )

    }
    productLineDesktop(prod) { 
        const pid = prod.id,
              name = this.getProductName(prod),
              count_name = this.getCountName(),
              img = "/bimages/get/64-"+User.get("id")+"-"+pid+"-0.jpg";
        const getProduct = ProductsStore.getProductByMid(pid, this.state.mid.value)

        const shCurr = this.getCurrency();
        const retailCurr = User.getCurrency();

        let sumCostAmount = this.state.product_data[pid][count_name] * (this.state.product_data[pid]['raw_net_price'] || 0)
        const currRate = this.state.currency_rate;

        if(shCurr !== retailCurr) {
          sumCostAmount = this.state.product_data[pid][count_name] * ((this.state.product_data[pid]['raw_net_price'] || 0) * currRate)
        }

        return (
          <List.Item key={pid} className="font-size-80">
              <Columns >
                <Columns.Column size={"half"} align="left">
                    <Image src={img} size={32} className="align-left" key={this.state.refresh}/>
                  {User.getPermissionEnable("editproducts") ? <OpenInsideModal  size="small" text={name} renderAs="b">
                    <EditProductsModal pid={getProduct.parent_id || getProduct.id} step={0}/>
                  </OpenInsideModal> : name}
                </Columns.Column>
                      {this.received ?
                      <Columns.Column className="margin-top-10">
                        <span className="like-link" onClick={() => this.moveQuantity(pid)}>{ProductsStore.getStockUnits(this.state.product_data[pid]['quantity_shipped'], this.state.product_data[pid]['units'])}</span>
                      </Columns.Column>
                      : ""}
                      <Columns.Column>
                        <Control iconRight>
                          <NumberInput
                            key={"count-pid-"+pid}
                            name={"count-pid-"+pid}
                            className="for-small-icon"
                            onChange={this.onChangeCount}
                            value={this.state.product_data[pid][count_name]}
                          />
                          <Icon align="right">
                              <span className="small-text">{ProductsStore.getUnits(this.state.product_data[pid]['units'])}</span>
                          </Icon>
                        </Control>
                    </Columns.Column>
                    <Columns.Column>
                      <Control iconRight>
                        <NumberInput
                          key={"pid-"+pid}
                          name={"pid-"+pid}
                          className="for-small-icon"
                          type="text"
                          onChange={(evt) => this.onChangeProductData(evt, 'raw_net_price')}
                          value={this.state.product_data[pid]['raw_net_price']}
                        />
                        <Icon align="right">
                          <span className="small-text">{this.getCurrency()}</span>
                        </Icon>
                      </Control>
                  </Columns.Column>
                  <Columns.Column>
                      <Control iconRight>
                        <NumberInput
                          key={"retail_price-"+pid}
                          name={"retail_price-"+pid}
                          className="for-small-icon"
                          type="text"
                          onChange={this.onChangeRetailPrice}
                          value={this.state.product_data[pid]['retail_price']}
                        />
                        <Icon align="right">
                          <span className="small-text">{User.getCurrency()}</span>
                        </Icon>
                      </Control>
                    </Columns.Column>
                    <Columns.Column className='display-flex-center' style={{height: 'auto'}}>
                      <Money
                        amount={sumCostAmount}/>
                    </Columns.Column>
                    <Columns.Column size={"one-fifth"} className="margin-top-10 text-align-center"  style={{width: 'fit-content'}}>
                      <Link to="#" onClick={() => this.deleteShipmentItem(pid)} title={T('delete')}>
                        <FontAwesomeIcon icon="trash-alt" size="1x" />
                      </Link>
                    </Columns.Column>
            </Columns>
          </List.Item>
        )
    }

    finishProductLine(prod) { 
      return (this.props.isMobileSize ? this.finishProductLineMobile(prod) : this.finishProductLineDesktop(prod));
    }

    finishProductLineMobile(prod) {
        const pid = prod.id,
              product = ProductsStore.getProductByMid(pid, this.getMid()),
              name = this.getProductNameMobile(product),
              instock = this.getInstockMobileText(product),
              img = "/bimages/get/64-"+User.get("id") + "-"+pid+"-0.jpg";
        let margin = calcMargin(this.state.product_data[pid]['raw_net_price'] + this.state.product_data[pid]['fee_per_unit'], this.state.product_data[pid]['retail_price']);

        const shCurr = this.getCurrency();
        const retailCurr = User.getCurrency();

        if (shCurr !== retailCurr) {
          const currRate = this.state.currency_rate;
          margin = calcMargin((this.state.product_data[pid]['raw_net_price'] * currRate) + this.state.product_data[pid]['fee_per_unit'], this.state.product_data[pid]['retail_price']);
        }

      const quantityReceived = this.state.product_data[pid]['quantity_received']
      const rawNetPrice =  this.state.product_data[pid]['raw_net_price'] || 0
      const constDeliveryPerUnit = this.state.product_data[pid]['fee_per_unit'] || 0
      const currRate = this.state.currency_rate;
      let calcNewConstPrice = parseFloat(quantityReceived) * (parseFloat(rawNetPrice) + parseFloat(constDeliveryPerUnit))

      if(shCurr !== retailCurr) {
        calcNewConstPrice = parseFloat(quantityReceived) * ((parseFloat(rawNetPrice) * parseFloat(currRate)) + parseFloat(constDeliveryPerUnit))
      }
        return (
          <List.Item key={pid} align="left">
                <b>
                <Image src={img} size={32} className="align-left"/> 
                {name}</b>
                <hr />
                <Columns className="is-mobile">
                    <Columns.Column>
                      <Label className="margin-top-10">{T('product-info-warehouse-one')}:
                          <span> {instock}</span>
                        </Label>
                    </Columns.Column>
                     <Columns.Column>
                        <Field>
                          <Label>{T('received')}: </Label>
                          <Control iconRight>
                            <NumberInput
                              key={"quantity_received-"+pid}
                              name={"quantity_received-"+pid}
                              className="for-small-icon"
                              type="number"
                              min="0"
                              onChange={(evt) => this.onChangeProductData(evt, 'quantity_received')}
                              value={this.state.product_data[pid]['quantity_received']}
                            />
                            <Icon align="right">
                              <span className="small-text">{T('items-e')}</span>
                            </Icon>
                          </Control>
                        </Field>
                  </Columns.Column>
                  <Columns.Column>
                    <Field horizontal>
                      <Label>
                        <InfoWrapper info='change-delivery-shipment-tip'>
                            {T('delivery-delivery')} ({T("cost-for").toLocaleLowerCase()})
                        </InfoWrapper>
                        :
                      </Label>
                      <Control iconRight>
                        <NumberInput
                          key={"fee_per_unit-"+pid}
                          name={"fee_per_unit-"+pid}
                          className="for-small-icon"
                          onChange={this.onChangeDeliveryPrice}
                          value={this.state.product_data[pid]['fee_per_unit']}
                        />
                        <Icon align="right">
                          <span className="small-text">{retailCurr}</span>
                        </Icon>
                      </Control>
                    </Field>
                  </Columns.Column>
                </Columns>
                {!User.getPermissionEnable("incomeinformation") ? null :
                <Columns className="is-mobile">
                  <Columns.Column>
                    <Field>
                      <Label>{T('new_netprice')}:</Label>
                      <Control iconRight>
                        <NumberInput
                          key={"pid-" + pid}
                          name={"pid-" + pid}
                          className="for-small-icon"
                          min="0.01"
                          onChange={(evt) => this.onChangeProductData(evt, 'raw_net_price')}
                          value={this.state.product_data[pid]['raw_net_price']}
                        />
                        <Icon align="right">
                          <span className="small-text">{shCurr}</span>
                        </Icon>
                      </Control>
                    </Field>
                    <div align="left"
                         style={{marginTop: '-5px'}}
                         className="has-text-black">{T('margin')}: {margin}%
                    </div>
                  </Columns.Column>
                  <Columns.Column>
                    <Field horizontal>
                      <Label>{T('products-price')}:</Label>
                      <Control iconRight>
                        <NumberInput
                          key={"retail_price-" + pid}
                          name={"retail_price-" + pid}
                          className="for-small-icon"
                          type="number"
                          min="0.01"
                          onChange={this.onChangeRetailPrice}
                          value={this.state.product_data[pid]['retail_price']}
                        />
                        <Icon align="right">
                          <span className="small-text">{retailCurr}</span>
                        </Icon>
                      </Control>
                    </Field>
                    <div align='right' style={{marginTop: '-5px'}}>
                      <b className='display-inline'>{T("cost-of-sales")}: </b>
                      <Money
                        wrapper={DOM.span}
                        amount={calcNewConstPrice}/>
                    </div>
                  </Columns.Column>

                </Columns>
                }
          </List.Item>
        )

    }

  finishProductLineDesktop(prod) {
        const pid = prod.id,
          product = ProductsStore.getProductByMid(pid, this.getMid()),
          name = this.getProductName(product),
          img = "/bimages/get/64-"+User.get("id") + "-" + pid + "-0.jpg";
        let margin = calcMargin(this.state.product_data[pid]['raw_net_price'] + this.state.product_data[pid]['fee_per_unit'], this.state.product_data[pid]['retail_price']),
            marginAmount = this.state.product_data[pid]['retail_price'] - this.state.product_data[pid]['raw_net_price'] - this.state.product_data[pid]['fee_per_unit'];

        const shCurr = this.getCurrency();
        const retailCurr = User.getCurrency();

        if (shCurr !== retailCurr) {
            const currRate = this.state.currency_rate;
            margin = calcMargin((this.state.product_data[pid]['raw_net_price'] * currRate) + this.state.product_data[pid]['fee_per_unit'], this.state.product_data[pid]['retail_price']);
            marginAmount = this.state.product_data[pid]['retail_price'] - (this.state.product_data[pid]['raw_net_price'] * currRate) - this.state.product_data[pid]['fee_per_unit'];
        }

        if (!product) {
            return (
                <List.Item key={pid} className="font-size-80">
                    ID: {pid} - {T('product-remove')}
                </List.Item>
              )
        }

        const quantityReceived = this.state.product_data[pid]['quantity_received']
        const rawNetPrice =  this.state.product_data[pid]['raw_net_price'] || 0
        const constDeliveryPerUnit = this.state.product_data[pid]['fee_per_unit'] || 0
        const currRate = this.state.currency_rate;
        let calcNewConstPrice = parseFloat(quantityReceived) * (parseFloat(rawNetPrice) + parseFloat(constDeliveryPerUnit))

        if(shCurr !== retailCurr) {
          calcNewConstPrice = parseFloat(quantityReceived) * ((parseFloat(rawNetPrice) * parseFloat(currRate)) + parseFloat(constDeliveryPerUnit))
        }

        return (
          <List.Item key={pid} className="font-size-80">
              <Columns>
                <Columns.Column align='left' size={"two-fifths"}>
                  <Image src={img} size={32} className="align-left"/>
                  {name}
                </Columns.Column>
                <Columns.Column >
                    <Control iconRight>
                      <NumberInput
                        key={"quantity_received-"+pid}
                        name={"quantity_received-"+pid}
                        className="for-small-icon"
                        onChange={(evt) => this.onChangeProductData(evt, 'quantity_received')}
                        value={this.state.product_data[pid]['quantity_received']}
                      />
                      <Icon align="right">
                        <span className="small-text">{ProductsStore.getUnits(this.state.product_data[pid]['units'])}</span>
                      </Icon>
                    </Control>
                </Columns.Column>
                {User.getPermissionEnable("incomeinformation") && <Columns.Column >
                  <Control iconRight>
                    <NumberInput
                      key={"pid-"+pid}
                      name={"pid-"+pid}
                      className="for-small-icon"
                      onChange={(evt) => this.onChangeProductData(evt, 'raw_net_price')}
                      value={this.state.product_data[pid]['raw_net_price']}
                    />
                    <Icon align="right">
                      <span className="small-text">{shCurr}</span>
                    </Icon>
                  </Control>
                </Columns.Column>}
                <Columns.Column>
                  <Control iconRight>
                    <NumberInput
                      key={"retail_price-"+pid}
                      name={"retail_price-"+pid}
                      className="for-small-icon"
                      onChange={this.onChangeRetailPrice}
                      value={this.state.product_data[pid]['retail_price']}
                    />
                    <Icon align="right">
                      <span className="small-text">{retailCurr}</span>
                    </Icon>
                  </Control>
                </Columns.Column>
                <Columns.Column>
                  <Control iconRight>
                    <NumberInput
                      key={"fee_per_unit-"+pid}
                      name={"fee_per_unit-"+pid}
                      className="for-small-icon"
                      onChange={this.onChangeDeliveryPrice}
                      value={this.state.product_data[pid]['fee_per_unit']}
                    />
                    <Icon align="right">
                      <span className="small-text">{retailCurr}</span>
                    </Icon>
                  </Control>
                </Columns.Column>
                <Columns.Column className='display-flex-center' style={{height: 'auto'}}>
                  <Money
                    amount={calcNewConstPrice}/>
                </Columns.Column>
                {User.getPermissionEnable("incomeinformation") && <Columns.Column>
                <Money amount={marginAmount}/>
                    {margin}%
                </Columns.Column>}
            </Columns>
          </List.Item>
        )
    }

    getRetailLineTitle() {
      return (
        <List.Item className="font-size-80 bold has-background-light">
              <Columns>
                <Columns.Column size={"two-fifths"}>
                  {T('product-name')}
                </Columns.Column>
                <Columns.Column >
                  {T('received')}
                </Columns.Column>
                {User.getPermissionEnable("incomeinformation") &&
                <Columns.Column >
                  {T('new_netprice')}
                </Columns.Column>}
                <Columns.Column>
                  {T('products-price')}
                </Columns.Column>
                <Columns.Column>
                  <div className='display-flex-column' style={{alignItems: "center"}}>
                    <InfoWrapper info='change-delivery-shipment-tip'>
                      {T('delivery-delivery')}
                    </InfoWrapper>
                    <small className='silver font-size-07'>({T("cost-for").toLocaleLowerCase()})</small>
                  </div>
                </Columns.Column>
                <Columns.Column>
                  {T("cost-of-sales")}
                </Columns.Column>
                {User.getPermissionEnable("incomeinformation") && <Columns.Column>
                   {T('margin')}
                </Columns.Column>}
            </Columns>
          </List.Item>
        );
    }

    getProductLineTitle() {
      const quantity = this.received ? "quantity-res-mob" : "quantity-ord-mob",
            all = (<Button className="button_link smll" onClick={() => this.moveQuantityAll()}>({T('all')})</Button> )
      return (
        <List.Item className="font-size-80 bold has-background-light">
              <Columns>
                <Columns.Column size={"half"}>
                    {T('product-name')}
                </Columns.Column>
                    {this.received ?
                    <Columns.Column>
                       {T('expect')}
                    </Columns.Column>
                      : ""}
                    <Columns.Column>
                        {T(quantity)} 
                        {this.received ? all : null}
                    </Columns.Column>
                    <Columns.Column>
                      {T('shipments-price-item')}
                    </Columns.Column> 
                    <Columns.Column>
                      {T('products-price')}
                    </Columns.Column>
                    <Columns.Column>
                      {T("cost-of-sales")}
                    </Columns.Column>
                    <Columns.Column size={1}  style={{width: 'fit-content'}}>
                    </Columns.Column>
            </Columns>
          </List.Item>
        );
    }

    getShipmentsList() {
      return this.props.isMobileSize ? this.getShipmentsListMobile() : this.getShipmentsListDesktop();
    }

    getShipmentsListMobile() {
        const sp = this.state.product_data;

      let shipments_list = [],
          empty_list = (
              <List.Item key="s-1" className="font-size-80"><Heading size={6} className="has-text-danger ">{T('select-min-one-item')}</Heading></List.Item>
          );

      for (let i in this.product_data_list) {
          const pid = this.product_data_list[i];
          let product = sp[pid];
          if (product) {
            shipments_list.push(this.productLine(product));
          }
      }

      this.item_length = shipments_list.length || 0;

      const all = (<Button className="button_link smll" onClick={() => this.moveQuantityAll()}>({T('all-received')})</Button> )

      return (
        <>
          <center>{this.received ? all : null}</center>
          <List hoverable className="shipments-order-list">
              {shipments_list.length > 0 ? shipments_list : empty_list}
          </List>
        </>

      );
    }

    getShipmentsListDesktop() {
      const sp = this.state.product_data;

      let shipments_list = [],
         empty_list = (
            <List.Item key="s-1" className="font-size-80"><Heading size={5} className="has-text-danger ">{T('select-min-one-item')}</Heading></List.Item>
          );
      for (let i in this.product_data_list) {
          const pid = this.product_data_list[i];
          let product = sp[pid];
          if (product) {
            shipments_list.push(this.productLine(product));
          }
      }

      this.item_length = shipments_list.length || 0;
      return (
          <List hoverable className="shipments-order-list">
              {this.getProductLineTitle()}
              {shipments_list.length > 0 ? shipments_list : empty_list}
          </List>

        );
    }

    getRetailPriceList() {
      const sp = this.state.product_data;

      let retail_list = [];
      for (let i in this.product_data_list) {
          const pid = this.product_data_list[i];
          let product = sp[pid];
          if (product) {
            retail_list.push(this.finishProductLine(product));
          }
      }

      return (
          <List hoverable className="shipments-order-list">
              {this.props.isMobileSize ? "" : this.getRetailLineTitle()}
              {retail_list}
          </List>

        );
    }

    getCurrency(aid) {
      if (aid && aid.value) {
        return ExpensesStore.getAccountCurrency(aid.value);
      }

      return User.getCurrency(this.state.currency.value);
    }

    getAccountList() {
      return ExpensesStore.getAccountsList([1,4], this.state.currency.value, true);
    }


    getTotal() {
      return parseFloat(this.state.amount) + parseFloat(this.state.delivery_cost || 0);
    }
//                          <div>{T('total')}: {this.getTotal()} {this.getCurrency()} </div>

    renderInfoBlock() {
      const title_second_block = (this.received) ? T('received') : T('added');
           // title_first_block = (this.received) ? T('expected') : T('expect');

      const calcDeliveryConst = Object.values(this.state.product_data).reduce((acc, cur) => {
        acc += (parseFloat(cur.fee_per_unit) * parseFloat(cur.quantity_received));

        return acc
      },0);
      return (
          <Box className="margin-top-10">
              <Columns>
                <Columns.Column>
                      <Heading>{T('expected')}:</Heading>
                      {!User.getPermissionEnable("incomeinformation") && this.state.step === 3 ? null :
                      <div><b>{T('shipments-amount')}:</b> {numberFormat(this.state.shipment_cost)} {this.getCurrency()} </div>
                      }
                      <div><b>{T('shipment-count-item')}:</b> {this.state.shipment_count_item} {T('items-e')} </div>
                </Columns.Column>
                <Columns.Column>
                      <Heading>{title_second_block}:</Heading>
                      {!User.getPermissionEnable("incomeinformation") && this.state.step === 3 ? null : <div><b>{T('product-on-amount')}:</b> {numberFormat(this.state.received_amount)} {this.getCurrency()} </div>}
                      {this.state.step === 3 && <div><b>{T('delivery-cost')}:</b> {numberFormat(calcDeliveryConst)} {User.getCurrency()}</div>}
                      <div><b>{T('shipment-count-item-added')}: {this.state.quantity} {T('items-e')} | {this.item_length} {T('items-short')}</b></div>
                      {this.state.step === 2 ? <div><b>{T('delivery-cost')}:</b> {numberFormat(this.state.delivery_cost)} {this.getCurrency()} </div> : "" }
                </Columns.Column>
              </Columns>
          </Box>
        )
    }

    getExchangeRateForm() {
        if (parseInt(this.state.currency.value) === parseInt(User.get("currency_id"))) {
          return null;
        }

        return (
          <Field>
              <Label>{T('exchange-rate')}</Label>
              <Control iconRight>
                <NumberInput
                  size="medium"
                  name="currency_rate"
                  onChange={this.onChange}
                  value={this.state.currency_rate}
                />
                <Icon align="right">
                  <span>{User.getCurrency()}</span>
                </Icon>
              </Control>
          </Field>

          )
    }

    renderStepOneMobile() {
      let step = this.state.step, 
          currency_list = ExpensesStore.getAccountsCurrencyList(),
          accounts_list = this.getAccountList();


      return (
        <Box>
          <InfoBlock title={'shipment-title-step-'+step} text={'shipment-help-step-'+step} />
          <Columns className="is-mobile">
                <Columns.Column>
                    <SelectComponent 
                        name="mid"
                        label="product-marketplace"
                        onChange={this.onChangeSelect} 
                        value={this.state.mid} 
                        list={WarehouseStore.getWarehouseShipmentList}
                        load={WarehouseActions.load}
                        creatable={false}/>
                </Columns.Column>
                <Columns.Column>
                   <Field>
                      <Label>{T('shipment-name')}</Label>
                      <Control>
                        <Input  size="medium" 
                              name="shipment_name" 
                              type="text" 
                              onChange={this.onChange} 
                              value={this.state.shipment_name}
                              />
                      </Control>
                   </Field>
                </Columns.Column> 
          </Columns>
          <Columns className="is-mobile">
                <Columns.Column>
                   <Field>
                      <Label>{T('shipment-count-item')}</Label>
                      <Control iconRight>
                        <NumberInput
                          name="shipment_count_item"
                          size="medium"
                          min="1"
                          onChange={this.onChange}
                          value={this.state.shipment_count_item}
                        />
                        <Icon align="right">
                            <span>{T('items-p')}</span>
                        </Icon>
                      </Control>
                   </Field>
                </Columns.Column>
                <Columns.Column>
                     <SelectComponent 
                          name="suppliers"
                          label="select-supplier"
                          empty="select-supplier"
                          onChange={this.onChangeSelect} 
                          value={this.state.suppliers} 
                          list={ShipmentsStore.getSuppliersList}
                          load={Actions.loadSuppliers}
                          addItem={Actions.addSupplier}
                          className="font-size-80"
                          creatable={true}/>
                </Columns.Column>
            </Columns>

              {currency_list.length > 1 ?
              <Columns className="is-mobile">
                <Columns.Column>
                    <Field>
                        <SelectComponent 
                          name="currency"
                          label="shipment-currency"
                          onChange={this.onChangeSelect} 
                          value={this.state.currency} 
                          list={ExpensesStore.getAccountsCurrencyList}/>
                  </Field>
                </Columns.Column>
                <Columns.Column>  
                     {this.getExchangeRateForm()}
                </Columns.Column>
               </Columns>
                : null}

            {accounts_list.length > 0 ? 
            <Columns className="is-mobile">
                 <Columns.Column>
                    <Field>
                      <Label>{T('shipment-cost')}</Label>
                      <Control iconRight>
                        <NumberInput
                          size="medium"
                          name="shipment_cost"
                          onChange={(e) => this.onChangeNumber(e)}
                          value={this.state.shipment_cost}
                        />
                        <Icon align="right">
                          <span>{this.getCurrency()}</span>
                        </Icon>
                      </Control>
                  </Field>
                </Columns.Column>
                <Columns.Column>
                     <SelectComponent 
                          name="shipment_account"
                          label="debit-the-account"
                          onChange={this.onChangeSelect} 
                          value={this.state.shipment_account} 
                          list={this.getAccountList}
                          empty={T('not-paid')}
                          load={ExpensesActions.loadAccounts}
                          creatable={false}/>
                </Columns.Column>
          </Columns>
          : null }
          <Columns >
                <Columns.Column>
         <Field>
              <Label>{T('comment')}</Label>
              <Control>
                <Textarea  size="small" 
                      name="comment" 
                      type="text" 
                      placeholder={T('shipments-comment-placeholder')}
                      onChange={this.onChange} 
                      color={(this.state.comment.error) ? "danger" : "primary"}
                      value={this.state.comment}
                      />
              </Control>
            </Field>
                </Columns.Column>
                <Columns.Column size="one-fifth">
                <Field>
                  <Label>{T("sale-date")}</Label>
                  <Control>
                     <DatePicker
                        selected={this.state.date}
                        onChange={(value) => {this.onChangeDate(value, "date")}}
                        className="input"
                        dateFormat="dd-MM-yyyy"
                        popperPlacement="top-left"
                        showTimeInput
                        locale={User.getLang()}
                        timeFormat="HH:mm"
                      />
                  </Control>
              </Field>
                </Columns.Column>
          </Columns>
          <Button.Group position="centered">
            {this.state.edit && <Button size="large" rounded color="info"
                                        onClick={() => this.setState({"step": this.state.step + 1})}
            >{T('next-btn')}
            </Button>}
            {this.state.edit ?
              <Button  size="large"  rounded color="primary"
                       onClick={this.nextStep}
              >{T('save-btn-label')}
              </Button>
              :
              <SubmitButton text='create-shipment' fullwidth={false} size="large" waiting={this.state.waiting} rounded color="primary"
                submit={() => this.createShipment()} />
              }
              <Button  size="large"  rounded color="light"
                onClick={() => {this.setState({close: true})}}
                >{T('close')}
              </Button>
            </Button.Group>
        </Box>
        );
    }

    renderStepOne() {
      if (this.props.isMobileSize) return this.renderStepOneMobile();

      let step = this.state.step, 
          currency_list = ExpensesStore.getAccountsCurrencyList(),
          accounts_list = this.getAccountList();

      return (
        <Box>
          <InfoBlock title={'shipment-title-step-'+step} text={'shipment-help-step-'+step} />
          <Columns>
                <Columns.Column>
                    <SelectComponent 
                        name="mid"
                        label="product-marketplace"
                        onChange={this.onChangeSelect} 
                        value={this.state.mid} 
                        list={WarehouseStore.getWarehouseShipmentList}
                        load={WarehouseActions.load}
                        creatable={false}/>
                </Columns.Column>
                <Columns.Column>
                   <Field>
                      <Label>{T('shipment-name')}</Label>
                      <Control>
                        <Input  size="medium" 
                              name="shipment_name" 
                              type="text" 
                              onChange={this.onChange} 
                              value={this.state.shipment_name}
                              />
                      </Control>
                   </Field>
                </Columns.Column> 
                <Columns.Column>
                   <Field>
                      <Label>{T('shipment-count-item')}</Label>
                      <Control iconRight>
                        <NumberInput
                          name="shipment_count_item"
                          size="medium"
                          min="1"
                          onChange={this.onChange}
                          value={this.state.shipment_count_item}
                        />
                        <Icon align="right">
                            <span>{T('items-p')}</span>
                        </Icon>
                      </Control>
                   </Field>
                </Columns.Column>
                <Columns.Column>
                     <SelectComponent 
                          name="suppliers"
                          label="select-supplier"
                          empty="select-supplier"
                          onChange={this.onChangeSelect} 
                          value={this.state.suppliers} 
                          list={ShipmentsStore.getSuppliersList}
                          load={Actions.loadSuppliers}
                          addItem={() => { AppStore.openModal(<AddSupplierModal />) }}
                          addItemModal={() => {AppStore.openModal(<AddSupplierModal/>)}}
                          className="font-size-80"
                          creatable={true}/>
                </Columns.Column>
            </Columns>
            {accounts_list.length > 0 ? 
            <Columns>
                {currency_list.length > 1 ?
                  <>
                <Columns.Column>
                    <Field>
                        <SelectComponent 
                          name="currency"
                          label="shipment-currency"
                          onChange={this.onChangeSelect} 
                          value={this.state.currency} 
                          list={ExpensesStore.getAccountsCurrencyList}/>
                  </Field>
                </Columns.Column>
                <Columns.Column>  
                     {this.getExchangeRateForm()}
                </Columns.Column>
                </>
                : null}

                 <Columns.Column>
                    <Field>
                      <Label>{T('shipment-cost')}</Label>
                      <Control iconRight>
                        <NumberInput
                          size="medium"
                          name="shipment_cost"
                          onChange={(e) => this.onChangeNumber(e)}
                          value={this.state.shipment_cost}
                        />
                        <Icon align="right">
                          <span>{this.getCurrency()}</span>
                        </Icon>
                      </Control>
                  </Field>
                </Columns.Column>
                <Columns.Column>
                     <SelectComponent 
                          name="shipment_account"
                          label="debit-the-account"
                          onChange={this.onChangeSelect} 
                          value={this.state.shipment_account} 
                          list={this.getAccountList}
                          empty={T('not-paid')}
                          load={ExpensesActions.loadAccounts}
                          creatable={false}/>
                </Columns.Column>
          </Columns>
          : null }
          <Columns>
           <Columns.Column>
              <Field>
                  <Label>{T('comment')}</Label>
                  <Control>
                    <Textarea  size="small" 
                          name="comment" 
                          type="text" 
                          placeholder={T('shipments-comment-placeholder')}
                          onChange={this.onChange} 
                          color={(this.state.comment.error) ? "danger" : "primary"}
                          value={this.state.comment}
                          />
                  </Control>
              </Field>
                </Columns.Column>
                <Columns.Column size="one-fifth">
                <Field>
                  <Label>{T("sale-date")}</Label>
                  <Control>
                     <DatePicker
                        selected={this.state.date}
                        onChange={(value) => {this.onChangeDate(value, "date")}}
                        className="input"
                        dateFormat="dd-MM-yyyy"
                        popperPlacement="top-left"
                        showTimeInput
                        locale={User.getLang()}
                        timeFormat="HH:mm"
                      />
                  </Control>
              </Field>
                </Columns.Column>
          </Columns>
          <Button.Group position="centered">
              {this.state.edit && <Button size="large" rounded color="info"
                       onClick={() => this.setState({"step": this.state.step + 1})}
              >{T('next-btn')}
              </Button>}
              {this.state.edit ? 
                <Button  size="large"  rounded color="primary"
                  onClick={this.nextStep}
                  >{T('save-btn-label')}
                </Button>
              :
              <SubmitButton text='create-shipment' fullwidth={false} size="large" waiting={this.state.waiting} rounded color="primary"
                submit={() => this.createShipment()} />
              }
              <Button  size="large"  rounded color="light"
                onClick={() => {this.setState({close: true})}}
                >{T('close')}
              </Button>
            </Button.Group>
        </Box>
        );
    }

    renderStepSecond() {
      let step = this.state.step;
      if (this.received) {
        step += "-received";
      }
      return (
          <Box className="left">
            <InfoBlock title={'shipment-title-step-'+step} text={'shipment-help-step-'+step} />
            <div className="button-block shipments-excel-btn"> 
            <OpenInsideModal color="primary" size="small" text={T('load-from-excel')} icon="plus-circle">
              <LoadExcelShipmentsModal sid={this.state.id} mid={this.getMid()} checkShipment={this.checkShipment} />
            </OpenInsideModal>
            </div>
            <SelectProducts 
                onChange={this.onChangeSelectProduct} 
                value={this.state.products_select} 
                pids={[]}
                selected={() => {return this.product_data_list}}
                quantity={0}
                // disabled={this.state.startAddProduct}
                source="shipment" 
                only_names={true}
                create_mid={this.state.mid.value || this.state.mid}
                 />
            {this.getShipmentsList()}
          
            {this.renderInfoBlock()}
            <Button.Group position="centered">
               {(!this.received || !this.state.edit) && false?
              <Button size="large" rounded color="light" onClick={this.backStep}>
              {T('back-btn')}</Button>
              : ""}
              <Button  size="large"  rounded color="primary"
                onClick={this.nextStep}
                >{T('next-btn')}
              </Button>
              <Button  size="large" rounded color="light"
                onClick={() => {Actions.load(); ExpensesActions.loadAccounts(); this.setState({close: true})}}
                >{T('close')}</Button>
            </Button.Group>
        </Box>
      );
    }

    renderStepThird() {
      let step = this.state.step,
          title = T('shipment-title-step-'+step),
          help = T('shipment-help-step-'+step);

      if (!this.state.expenses_id) {
          title += T('shipment-title-step-'+step+'-part-2');
          help += T('shipment-help-step-'+step+'-part-2');
      }

      return (
        <Box className="finish-shipment">
            <InfoBlock title={title} text={help} />
            <Columns>
                <Columns.Column>
                   <SelectComponent 
                          name="delivery_service"
                          label="delivery-service"
                          onChange={this.onChangeSelect} 
                          addItem={Actions.addDeliveryService}
                          value={this.state.delivery_service} 
                          list={ShipmentsStore.getDeliveryList}
                          load={Actions.loadDelivery}
                          creatable={true}/>
                </Columns.Column>
                <Columns.Column>
                   <Field>
                    <Label>{T('delivery-cost')}</Label>
                    <Control iconRight>
                      <NumberInput
                        size="medium"
                        name="delivery_cost"
                        type="number"
                        onChange={this.onChange}
                        value={this.state.delivery_cost}
                      />
                      <Icon align="right">
                        <span>{this.getCurrency(this.state.account)}</span>
                      </Icon>
                    </Control>
                  </Field>
                </Columns.Column>
                <Columns.Column>
                   <SelectComponent 
                          name="account"
                          label="debit-the-account-for-delivery"
                          onChange={this.onChangeSelect} 
                          value={this.state.account} 
                          list={this.getAccountList}
                          empty={T('not-need-pay')}
                          load={ExpensesActions.loadAccounts}
                          creatable={false}/>
                </Columns.Column>
                <Columns.Column>
                  <Field>
                  <Label>{T("shipments-delivered_date")}</Label>
                  <Control>
                     <DatePicker
                        selected={this.state.delivery_date}
                        onChange={(value) => {this.onChangeDate(value, "delivery_date")}}
                        className="input"
                        dateFormat="dd-MM-yyyy"
                        popperPlacement="top-left"
                        showTimeInput
                        locale={User.getLang()}
                        timeFormat="HH:mm"
                      />
                  </Control>
              </Field>
                </Columns.Column>
            </Columns>
            {!this.state.expenses_id ? 
            <div>
                <Heading size={5} className="has-text-danger">
                   {T('shipment-not-paid')}
                </Heading>
                <Columns>
                      <Columns.Column>
                        <Field>
                          <Label>{T('shipment-cost')}</Label>
                          <Control iconRight>
                            <NumberInput
                              size="medium"
                              name="shipment_cost"
                              onChange={(e) => this.onChangeNumber(e)}
                              value={this.state.shipment_cost}
                            />
                            <Icon align="right">
                              <span>{this.getCurrency()}</span>
                            </Icon>
                          </Control>
                      </Field>
                      </Columns.Column>
                      <Columns.Column>
                         <SelectComponent 
                              name="shipment_account"
                              label="debit-the-account-for-shipment"
                              onChange={this.onChangeSelect} 
                              value={this.state.shipment_account} 
                              empty={T('not-need-pay')}
                              list={this.getAccountList}
                              load={ExpensesActions.loadAccounts}
                              creatable={false}/>
                    </Columns.Column>
                </Columns>
            </div>
            : ""}

            {this.renderInfoBlock()}
            <Button.Group  position="centered">
              <Button size="large" rounded color="light" onClick={this.backStep}>
              {T('back-btn')}</Button>
              <SubmitButton fullwidth={false} text='finish-shipment-btn' size="large"  color="primary" waiting={this.state.waiting} submit={() => this.confirmFinishShipment()}/>
              <Button  size="large" rounded color="light"
                onClick={() => {Actions.load(); this.setState({close: true})}}
                >{T('close')}</Button>
            </Button.Group>
          </Box>
        );
    }

    confirmFinishShipment() {
      let mid = this.state.mid.value || this.state.mid;
      let warehouse = WarehouseStore.getWarehouseName(parseInt(mid));

      this.setState({'waiting': true});
        confirmAlert({
          title: T('finish-shipment-btn'),
          message: T('finish-shipment-btn-desc', {'warehouse': warehouse, 'count': this.state.quantity}),
          buttons: [
            {
              label: T('finish-shipment-btn'),
              onClick: () => this.submit()
            },
            {
              label: T('close'),
              onClick: () => { this.setState({'waiting': false}); }
            }
          ]
        });
    }

    renderStepFourth() {
        const step = this.state.step;
        return (
        <Box>
          <InfoBlock title={'shipment-title-step-'+step} text={'shipment-help-step-'+step} />
          {this.getExchangeRateForm()}
          {this.getRetailPriceList()}
          {this.renderInfoBlock()}
          <Button.Group  position="centered">
              <Button size="large"  rounded color="light"
                onClick={() => {Actions.load();this.setState({close: true})}}
                >{T('close')}</Button>
                <SubmitButton
                  fullwidth={false}
                  text='btn-save'
                  waiting={this.state.waiting}
                  size="large"
                  rounded
                  color="primary"
                  submit={this.sendRetailPrice}
                />
                <Button
                  size='large'
                  rounded
                  color='info'
                  onClick={() => AppStore.openModal(<PriceTagCountModal sid={this.state.id} />)}
                >
                  {T('print-tags')}
                </Button>
            </Button.Group>
        </Box>
      );
    }

  onFinishBtnClick() {
    this.setState({ close: true });
    this.props.history.push('/shipments');
  }

  renderStepFinish() {
      return (
        <Box>
          <h1 className="title">{T('shipments-created')}</h1>
          <div className='display-flex-row' style={{justifyContent: 'center'}}>
              <Button
                size='large'
                rounded
                color='info'
                onClick={() => AppStore.openModal(<PriceTagCountModal sid={this.state.id} />)}
              >
                {T('print-tags')}
              </Button>
              <Button
                size="large"
                rounded
                color="primary"
                onClick={() => {this.onFinishBtnClick()}}
                >
                {T('close')}
              </Button>
            </div>
        </Box>
      );
    }

    render() { 
        const stepNaviganor = [this.renderStepOne, this.renderStepSecond, this.renderStepThird, 
        this.renderStepFourth, this.renderStepFinish],
              currentStep = stepNaviganor[this.state.step];
        return (
              <div>
                {currentStep()}
                {this.state.message ? this.getMessage() : ""}
                {this.state.close ? redirectTo("/shipments") : ""}
                {this.state.addSupplierModal ? redirectTo("/shipments/add-supplier") : ""}
              </div>
        );
    }

}

export default connectToStores(withIsMobileSize(withRouter(AddShipmentBase)), {
    products: ProductsStore,
    shipments: ShipmentsStore 
  });
