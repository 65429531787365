import React from 'react';
import T from "components/i18n";
// import { redirectTo } from 'tools/redirect-to'
import { filterClient } from 'tools/filters-helper'
import Heading from 'react-bulma-components/lib/components/heading';

import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';

import Actions from './chats-actions'
// import Store from './orders-store'
import ClientsStore from '../clients/clients-store'
import ClientsActions from '../clients/clients-actions'
// import UserStore from 'user/user-store';
import SelectComponent from 'tools/select-component';
import BaseForm from "components/modules/base-form";

import SubmitButton from 'components/submit-button';
import Messages from 'components/messages';
import AppStore from "../../app-store";
import AddClientModal from "../clients/add-client-modal";

class BindingClientModal extends BaseForm {
    constructor(props) {
        super(props);
        console.log("props make binding", props)
        this.state = {
          message: null,
          waiting: null,
          client: {'value': props.data.client_id || 0},
          close: false,
          step: 0
        };


        this.submit = this.submit.bind(this);
        this.renderStepOne = this.renderStepOne.bind(this);
        this.onChangeSelectClient = this.onChangeSelectClient.bind(this);

    }

    componentWillUnmount() {
      if (typeof(this.listen) == "function") {
          this.listen();
      }

      if (typeof(this.listenAddFaild) == "function") {
          this.listenAddFaild();
      }
    }

    componentDidMount() {
      const {onClose} = this.props;

      this.listen = Actions.bindingClient.completed.listen(() => {
          setTimeout(onClose, 100);
      });

       this.listenAddFaild = Actions.bindingClient.failed.listen((res) => {
            let data = {};

            if (res && typeof(res['response']) != "undefined") {
              data = JSON.parse(res['response']);
            } else {
              data = {
                'error': "unhandled_error"
              }
            }

            this.setState({
              'waiting': false,
              'message': {
                'msg': T(data['error'])
              }
            });
        });
    }


    submit() {
        const {data} = this.props;
        if (!this.state.client_data) {
          this.setState({'message': T('select-client')}) 
          return;
        }
        this.setState({"waiting": true});
        Actions.bindingClient({'client_id': this.state.client_data.id, 'chat_id': data['id']});

    }

    onChangeSelectClient(selectedOption, action) {

        const client = ClientsStore.getClientById(selectedOption.value);

        this.setState({
          [action.name]: selectedOption,
          "client_data": client,
        });
    }    

    renderStepOne() {
       const {data} = this.props;
      return (
          <Box>
              <Heading>
                  {T('binding-clients')}
              </Heading>

              <Box>
                ID: {data.remote_chat_id}. {data.name}
              </Box>
              <center><Heading>{T('with')}</Heading></center>
               <SelectComponent 
                      name="client"
                      label="client"
                      onChange={this.onChangeSelectClient} 
                      value={this.state.client} 
                      filterOption={filterClient}
                      list={ClientsStore.getClientsList}
                      load={ClientsActions.load}
                      addItem={({name}) => AppStore.openModal(<AddClientModal phone={name}/>)}
                      addItemModal={() => AppStore.openModal(<AddClientModal/>)}
                      empty={'enter-client-phone'}
                      creatable={true}/>

              <Button.Group>
                    <SubmitButton text='binding-client-btn' disabled={!this.state.client_data ? true : false} waiting={this.state.waiting} submit={this.submit}/>
              </Button.Group>
          </Box>
      );
    }

    render() {
        const stepNaviganor = [this.renderStepOne],
              currentStep = stepNaviganor[this.state.step];


        return (
              <div>
                <Messages message={this.state.message} close={() => this.setState({message: null})} />
                {currentStep()}
              </div>
        );

    }  
}

export default BindingClientModal;