import React, { Component } from 'react';

// import Box from 'react-bulma-components/lib/components/box';
import T from "components/i18n";
// import Section from 'react-bulma-components/lib/components/section';
// import Columns from 'react-bulma-components/lib/components/columns';
import Button from 'react-bulma-components/lib/components/button';

import { 
    Field, 
    Control,
    Label,
    Input
} from 'react-bulma-components/lib/components/form';
// import Columns from 'react-bulma-components/lib/components/columns';
// import List from 'react-bulma-components/lib/components/list';

import ProductsStore from "modules/products/products-store";
import ProductsActions from 'modules/products/products-actions';

import {getDateRange} from 'tools/date-range';
import DatePicker from "react-datepicker";

import {
  isMobile
} from "react-device-detect";

import SelectComponent from 'tools/select-component'

import WarehouseStore from 'modules/warehouse/warehouse-store'
import WarehouseActions from 'modules/warehouse/warehouse-actions'

import ExpensesStore from 'modules/expenses/expenses-store'
import ExpensesActions from 'modules/expenses/expenses-actions'

import ShipmentsStore from 'modules/shipments/shipments-store'
// import ShipmentsActions from 'modules/shipments/shipments-actions'

import ClientsStore from 'modules/clients/clients-store'
import ClientsActions from 'modules/clients/clients-actions'

import User from 'user/user-store';

class BaseFilter extends Component {
    constructor(props) {
        super(props);

        this.period_days = 7;
        this.state = {
          showFilter: isMobile ? false : true
        };

        this.changeShowFilter = this.changeShowFilter.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        this.delayedOnChange = this.delayedOnChange.bind(this)
    }

    onChangeCheckbox(evt) { 
        const value = evt.target.checked,
              name = evt.target.name

         this.actions.filter(name, value);
    }

    onChange(evt) { 
        const value = evt.target.value,
              name = evt.target.name

        this.actions.filter(name, value);
    }

    onChangeSelect(selectedOption, actionMeta) {
      this.actions.filter(actionMeta.name, selectedOption);
    }

    componentDidMount() {
      const { mid } = this.props;
      if (mid) {
          const name = WarehouseStore.getWarehouseName(mid);
          setTimeout(()=>{this.actions.filter('mid', {value: mid, label: name})}, 500);
          // this.actions.filter('mid', {value: mid, label: name})
      } else {
        setTimeout(()=>{this.actions.filter('mid', {value: 0})}, 500);
      }

      // let date_start = new Date();
      // let date_end = new Date();
      // date_start.setDate(date_start.getDate() - this.period_days);
      // date_end.setHours(23,59,59,999);
      // this.actions.filter("date_start", date_start);
      // this.actions.filter("date_end", date_end);
    }

    componentDidUpdate(prevProps){
      const { mid } = this.props;
      if (prevProps.mid !== mid ){
          if (mid) {
              const name = WarehouseStore.getWarehouseName(mid);
              this.actions.filter('mid', {value: mid, label: name});
          }
      }
    }

    changeShowFilter() {
      this.setState({
        showFilter: !this.state.showFilter
      })
    }

    selectAccount(label) {
        return (
                <SelectComponent 
                          name="account"
                          label={label ? label : 'sale-account'}
                          empty="all-account"
                          onChange={this.onChangeSelect} 
                          value={this.store.getFilter("account")} 
                          list={ExpensesStore.getAccountsList}
                          load={ExpensesActions.loadAccounts}
                          creatable={false}/>
        );
    } 

    renderButton () {
        return (
            <div align="right" className="margin-bottom-10">
             <Button key="show-filter" size="small" color="light" onClick={() => this.changeShowFilter()}>{T(this.state.showFilter ? 'hide-filter' : 'show-filter')}</Button>
            </div>
          );
    }

    delayedOnChange (evt) {
        evt.persist()
        clearTimeout(this.searchTimeout);
        const value = evt.target.value;

        this.setState({
          search_string: value
        })

        this.searchTimeout = setTimeout(() => {
          this.onChange(evt);
        }, 500);
      };


    searchString() {

      return (
            <Field horizontal>
              <Control>
                <Input  size={isMobile ? "small" : "medium"}
                      name="search"
                      type="text"
                      placeholder={T('search-string')}
                      autoComplete="nope"
                      onChange={this.delayedOnChange}
                      value={this.state.search_string}
                      />
              </Control>
            </Field>
        );
    }

  // searchString() {
  //
  //   return (
  //     <Field horizontal>
  //       <Control>
  //         <Input  size={isMobile ? "small" : "medium"}
  //                 name="search"
  //                 type="text"
  //                 placeholder={T('search-string')}
  //                 autoComplete="nope"
  //                 onChange={this.onChange}
  //                 value={this.store.getFilter("search")}
  //         />
  //       </Control>
  //     </Field>
  //   );
  // }

    getWarehouseFilter(label) {
      let list = WarehouseStore.getWarehouseList();
      if (
        // !User.getModuleEnable("warehouse") ||
        list.length <= 1) return null;


        return (
           <SelectComponent 
                              name="mid"
                              empty="all-warehouse"
                              label={label || false}
                              autoSelect={false}
                              onChange={this.onChangeSelect} 
                              value={this.store.getFilter("mid")} 
                              list={WarehouseStore.getWarehouseList}
                              load={WarehouseActions.load}
                              creatable={false}/>
          )
    }    

    getShipmentFilter(label) {
      let list = ShipmentsStore.getList();
      if (!User.getModuleEnable("shipments") || list.length <= 1) return null;

        return (
           <SelectComponent 
                          name="shipment"
                          label={label}
                          empty="all-shipment"
                          onChange={this.onChangeSelect}  
                          autoSelect={false}
                          value={this.store.getFilter("shipment")} 
                          list={ShipmentsStore.getList} 
                          creatable={false}/>
          )
    }

    getCategoryFilter(label) {
       return (<SelectComponent 
                              name="category"
                              label={label}
                              empty={'all-categories'}
                              onChange={this.onChangeSelect} 
                              autoSelect={false}
                              value={this.store.getFilter("category")} 
                              list={ProductsStore.getFormattingCategoryList}
                              load={ProductsActions.loadCategory}
                              creatable={false}/>);
    }

    selectClients() {
        let list = ClientsStore.getClientsList();
        if (list.length <= 1) return null;

        return (
                <SelectComponent 
                          name="clients"
                          label="clients"
                          empty="all"
                          onChange={this.onChangeSelect} 
                          value={this.store.getFilter("clients")} 
                          list={ClientsStore.getClientsList}
                          load={ClientsActions.load}
                          creatable={false}/>
        );
    } 

    selectChannel() {
        return (
                <SelectComponent 
                          name="channel"
                          label="sale-channel"
                          empty="all"
                          onChange={this.onChangeSelect} 
                          value={this.store.getFilter("channel")} 
                          // list={OrdersStore.getChannelsList}
                          // load={OrdersActions.loadChannels}
                          creatable={false}/>
        );
    }  

    getBrandFilter(label) {
        let list = ProductsStore.getBrandsList();
        if (list.length <= 1) return null;

        return (
          <div className="margin-bottom-10">
                <SelectComponent 
                          name="brand"
                          label={label}
                          empty="all-brands"
                          autoSelect={false}
                          onChange={this.onChangeSelect} 
                          list={ProductsStore.getBrandsList}
                          value={this.store.getFilter("brand")} 
                          creatable={false}/>
          </div>
        );
    } 

    selectStatus(label) {
        return (
                <SelectComponent 
                          name="status"
                          label={label || null}
                          empty="all"
                          onChange={this.onChangeSelect} 
                          value={this.store.getFilter("status")} 
                          list={this.store.getStatusList}
                          creatable={false}/>
        );
    } 

    getDateRange(label) {
        return (
            <>
                 <Field kind="addons" >
                          <Control>
                             {label ? <Label>{T("sale-date-from")}</Label> : null}
                             <DatePicker
                                selected={this.store.getFilter("date_start")}
                                onChange={date => this.actions.filter("date_start", date)}
                                name="date_start"
                                className="input"
                                selectsStart
                                dateFormat="dd-MM-yyyy"
                                startDate={this.store.getFilter("date_start")}
                                endDate={this.store.getFilter("date_end")}
                                locale={User.getLang()}
                              />
                          </Control>
                          <Control>
                            {label ? <Label>{T('sale-date-to')}</Label> : null}
                              <DatePicker
                                selected={this.store.getFilter("date_end")}
                                onChange={date => this.actions.filter("date_end", date)}
                                className="input"
                                name="date_end"
                                selectsEnd  
                                dateFormat="dd-MM-yyyy"
                                locale={User.getLang()}
                                startDate={this.store.getFilter("date_start")}
                                endDate={this.store.getFilter("date_end")}
                                minDate={this.store.getFilter("date_start")}
                              />
                          </Control>
                </Field>
                {getDateRange(this.actions.filter, ['today', 'yesterday', 'week', '30days-short', 'this-month', 'last-month', '3-month'])}
          </>
        );
    }

}

export default BaseFilter;
