import React, { Component } from "react";
import T from "components/i18n";
import Money from "components/money";
import WarehouseStore from "modules/warehouse/warehouse-store";
import ShipmentsStore from "modules/shipments/shipments-store";
import ExpensesStore from "modules/expenses/expenses-store";
import Level from "react-bulma-components/lib/components/level";
import OpenModalButton from "components/modal/open-modal-button";
import Dropdown from "react-bulma-components/lib/components/dropdown";
import NumberBlock from "components/number-block";
import User from "user/user-store";
import formatDate from "tools/format-date";
import DOM from "react-dom-factories";
import { confirmAlert } from "react-confirm-alert";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Actions from "../shipments-actions";
import List from "react-bulma-components/lib/components/list";
import Columns from "react-bulma-components/lib/components/columns";
import Heading from "react-bulma-components/lib/components/heading";
import OpenInsideModal from "components/modal/open-inside-modal";
import AddExpensesModal from "modules/expenses/add-expenses-modal";
import Button from "react-bulma-components/lib/components/button";
import AppStore from "../../../app-store";
import AddSupplierModal from "../add-supplier-modal";
import UserStore from "user/user-store";
import { createMobileAppLink } from "../../../tools/create-mobile-app-link";
import PriceTagCountModal from "../../../components/price-tag-count-modal/price-tag-count-modal";
import { withIsMobileSize } from "../../../components/is-mobile-context/is-mobile-context";
import {trimNumber} from "../../../tools/trim-number";

class ShipmentsRow extends Component {
  getRegistrationButton() {
    const { data } = this.props;

    return (
      <Level.Item key="regBut">
        <OpenModalButton
          size="small"
          color="success"
          link={{
            pathname: "/shipments/edit-shipment",
            state: { step: 1, received: true, edit: true, data: data },
          }}
          text={T("finish-shipment-btn")}
        />
      </Level.Item>
    );
  }

  getEditButton() {
    const { data } = this.props;

    const handleClick = () => {
      this.props.history.push({
        pathname: "/shipments/edit-shipment",
        state: { step: 0, received: false, edit: true, data: data },
      });
    };
    return (
      <Level.Item key="editBut" title={T("btn-edit")}>
        <Button
          onClick={handleClick}
          className="button-link-icon"
          style={{ marginTop: 0 }}
        >
          <FontAwesomeIcon
            icon="edit"
            size={this.props.isMobileSize ? "2x" : "1x"}
          />
        </Button>
      </Level.Item>
    );
  }

  getDeleteButton() {
    if (!User.getPermissionEnable("deleteshipments")) {
      return "";
    }

    const { data } = this.props;

    return (
      <Level.Item key="delBut">
        <Button
          className="button-link-icon"
          onClick={() => this.deleteShipment(data["id"], "shipment")}
          title={T("delete")}
        >
          <FontAwesomeIcon
            icon="trash-alt"
            size={this.props.isMobileSize ? "2x" : "1x"}
          />
        </Button>
      </Level.Item>
    );
  }

  getUndoButton() {
    if (!User.getPermissionEnable("deleteshipments")) {
      return "";
    }

    const { data } = this.props;

    return (
      <Level.Item key="undoBut" className="undo-button">
        <Link
          to="#"
          onClick={() => this.deleteShipment(data["id"], "undo")}
          title={T("undo")}
        >
          <FontAwesomeIcon
            icon="undo"
            size={this.props.isMobileSize ? "2x" : "1x"}
          />
        </Link>
      </Level.Item>
    );
  }

  getPendingButton() {
    let response = [
      this.getRegistrationButton(),
      this.getEditButton(),
      this.getDeleteButton(),
    ];

    return response;
  }

  getCompeteButton() {
    return [
      this.getPriceButton(),
      this.getUndoButton(),
      this.getDeleteButton(),
    ];
  }

  getPriceButton() {
    const { data } = this.props;

    return (
      <Level.Item key={"edit-b-" + data.id}>
        <OpenModalButton
          size="small"
          color="info"
          link={{
            pathname: "/shipments/edit-shipment",
            state: { step: 3, received: true, edit: true, data: data },
          }}
          text={T("price-shipment-btn")}
        />
      </Level.Item>
    );
  }

  deleteShipment(sid, _type) {
    confirmAlert({
      title: T("confirm-delete-" + _type),
      message: T("are-you-sure-to-" + _type + "-this-shipment"),
      buttons: [
        {
          label: T(_type === "undo" ? "undo" : "delete"),
          onClick: () =>
            Actions.delete({
              sid,
              type: _type,
            }),
        },
        {
          label: T(_type === "undo" ? "no-undo" : "no-delete"),
          onClick: () => {},
        },
      ],
    });
  }

  getDeliveryInfo(data) {
    const is_delivery_pay = !!data["delivery_expenses_id"];

    if (is_delivery_pay) {
      const daid = data["delivery_expenses"]["account_id"];


      const permissionAccount = UserStore.getPermissionAccount();
      let isAccount = true;

      if (permissionAccount?.length && !permissionAccount.includes(daid)) {
        isAccount = false;
      }

      const isExpenses = UserStore.getPermissionEnable("show-all-expenses");
      const userId = UserStore.get("realId");

      let isAllowExpenses = true;

      if (!isExpenses) {
        isAllowExpenses =
          parseInt(userId) === data["delivery_expenses"]["responsible_user_id"];
      }

      return (
        <div key={"k2"}>
          {isAllowExpenses && isAccount ? <Link
              className="link-with-hover"
              to={{
                pathname: "/transactions",
                state: {search: data["delivery_expenses_id"]},
              }}
            >
              <b className="has-text-success">
                {" "}
                {T("delivery_paid")}:{" "}
                <Money
                  aid={daid}
                  amount={data["delivery_expenses"]["amount"]}
                  className="has-text-success"
                  wrapper={DOM.span}
                />{" "}
              </b>
              <>
                {" " + T("from_account")}{" "}
                <b>{T(ExpensesStore.getAccountName(daid))} </b>
                <br/>
              </>
            </Link>
            :
            <b>
              {" "}
              {T("delivery_paid")}:{" "}
              <Money
                aid={daid}
                amount={data["delivery_expenses"]["amount"]}
                wrapper={DOM.span}
              />{" "}
            </b>
          }
        </div>
      );
    }

    return "";
  }

  getPaymentButton(data, total_pay) {
    return UserStore.getModuleEnable("payments") && (
      <Columns
        key="payment-button"
        className={this.props.isMobileSize ? "is-mobile" : null}
      >
        <Columns.Column size="one-third">
          <OpenInsideModal
            size="small"
            className={this.props.isMobileSize ? "" : "margin-top-10"}
            text={T(
              this.props.isMobileSize
                ? "pay-shipment-mobile-btn"
                : "pay-shipment-btn"
            )}
          >
            <AddExpensesModal
              category_id={11}
              shipment_id={data["id"]}
              amount={total_pay ? total_pay : data.amount}
            />
          </OpenInsideModal>
        </Columns.Column>
        <Columns.Column
          className={this.props.isMobileSize ? null : "margin-top-20"}
          align="right"
        >
          {total_pay > 0 && !this.props.isMobileSize ? (
            <b className="has-text-danger">
              {T("not-paid")}:{" "}
              <Money
                currency={data["currency"]}
                amount={total_pay}
                className="has-text-danger"
                wrapper={DOM.span}
              />
            </b>
          ) : null}
        </Columns.Column>
      </Columns>
    );
  }

  getPaymentInfo(data) {
    const is_pay = !!data["expenses_id"];

    let response = [];

    if (is_pay) {
      if (!this.props.isMobileSize) {
        if(data["shipment_status"] === "complete") {
          response.push(
            <div key={"kp2"}>
              <b> {T("received_goods_worth")}:</b>{" "}
              <Money
                currency={data["currency"]}
                amount={data["received_amount"]}
                wrapper={DOM.span}
              />{" "}
            </div>
          );
        } else {
          response.push(
            <div key="k2">
              <b> {T("expected_goods_worth")}:</b>{" "}
              <Money
                amount={data["amount"]}
                currency={data["currency"]}
                wrapper={DOM.span}
              />
            </div>
          );
        }
      }

      let total_pay = 0;
      for (let i in data["expenses"]) {
        const expenses = data["expenses"][i];
        const aid = expenses["account_id"];
        const acc_info = ExpensesStore.getAccountById(aid);

        const permissionAccount = UserStore.getPermissionAccount();
        let isAccount = true;

        if (permissionAccount?.length && !permissionAccount.includes(aid)) {
          isAccount = false;
        }

        const isExpenses = UserStore.getPermissionEnable("show-all-expenses");
        const userId = UserStore.get("realId");

        let isAllowExpenses = true;

        if (!isExpenses) {
          isAllowExpenses =
            parseInt(userId) === expenses["responsible_user_id"];
        }

        if (!this.props.isMobileSize) {
          response.push(
            <div key={"k1-" + expenses["id"]}>
              {isAllowExpenses && isAccount ? (
                <Link
                  to={{
                    pathname: "/transactions",
                    state: { search: expenses["id"] },
                  }}
                  className="link-with-hover"
                >
                  <b className="has-text-success">{T("paid")}</b>
                  <b className="has-text-success">
                    {" "}
                    <Money
                      aid={aid}
                      amount={expenses["amount"]}
                      className="has-text-success font-size-120"
                      wrapper={DOM.span}
                    />
                  </b>
                  {isAccount && isAllowExpenses && (
                    <>
                      {" " + T("from_account") + " "}
                      <b>{T(ExpensesStore.getAccountName(aid))}</b>
                      <br />
                    </>
                  )}
                </Link>
              ) : (
                <>
                  <b>{T("paid")}</b>
                  <b>
                    {" "}
                    <Money
                      aid={aid}
                      amount={expenses["amount"]}
                      className="font-size-120"
                      wrapper={DOM.span}
                    />
                  </b>
                </>
              )}
            </div>
          );
        }

        let amount;
        if (acc_info.currency_id !== parseInt(data.currency)) {
          amount = expenses.amount / data.currency_rate;
        } else {
          amount = expenses.amount;
        }

        total_pay += amount;
      }

      if (data["expenses"].length > 1 && !this.props.isMobileSize) {
        response.push(
          <div key={"total-" + data["expenses_id"]}>
            <b className="has-text-success">
              {" "}
              {T("total")}:{" "}
              <Money
                currency={data.currency}
                amount={total_pay}
                className="has-text-success font-size-120"
                wrapper={DOM.span}
              />
            </b>
          </div>
        );
      }

      if (!this.props.isMobileSize) {
        response.push(this.getDeliveryInfo(data));
      }

      if (total_pay < data["received_amount"]) {
        response.push(
          this.getPaymentButton(data, data["received_amount"] - total_pay)
        );
      }
    } else if (data["shipment_status"] === "complete") {
      if (!this.props.isMobileSize) {
        response.push(
          <div key={"k1"}>
            <b>{T("received_goods_worth")}:</b>{" "}
            <Money amount={data["received_amount"]} wrapper={DOM.span} /> <br />
            <b>{T("shipment-not-paid-status")}</b>
            <br />
          </div>
        );
      }
      if(!this.props.isMobileSize) {
        response.push(this.getDeliveryInfo(data));
      }
      response.push(this.getPaymentButton(data, 0));
    } else {
      if (!this.props.isMobileSize) {
        response.push(
          <div key="k2">
            <b> {T("expected_goods_worth")}:</b>{" "}
            <Money
              amount={data["amount"]}
              currency={data["currency"]}
              wrapper={DOM.span}
            />
          </div>
        );
      }
      if(!this.props.isMobileSize) {
        response.push(this.getDeliveryInfo(data));
      }
      response.push(this.getPaymentButton(data, 0));
    }

    if (this.props.isMobileSize) {
      return response?.length ? response : false;
    }

    return <div>{response}</div>;
  }

  getSupplierButton(supplierID) {
    return (
      <Button
        style={{ height: "fit-content" }}
        className="button_link"
        onClick={() => AppStore.openModal(<AddSupplierModal id={supplierID} />)}
      >
        ({T("from")} {T(ShipmentsStore.getSupplierName(supplierID))})
      </Button>
    );
  }

  mobileRender() {
    const { data } = this.props,
      is_pay = !!data["expenses_id"],
      is_complete = data["shipment_status"] === "complete",
      is_delivery_pay = !!data["delivery_expenses_id"],
      daid = is_delivery_pay ? data["delivery_expenses"]["account_id"] : false;

    let pay_data = [],
      total_pay = 0;

    if (is_pay) {
      for (let i in data["expenses"]) {
        const expenses = data["expenses"][i];
        const aid = expenses["account_id"];
        const acc_info = ExpensesStore.getAccountById(aid);


        const isExpenses = UserStore.getPermissionEnable("show-all-expenses");
        const userId = UserStore.get("realId");

        let isAllowExpenses = true;

        if (!isExpenses) {
          isAllowExpenses =
            parseInt(userId) === expenses["responsible_user_id"];
        }

        const permissionAccount = UserStore.getPermissionAccount();
        let isAccount = true;

        if (permissionAccount?.length && !permissionAccount.includes(aid)) {
          isAccount = false;
        }

        pay_data.push(
          <div key={"k1-" + expenses["id"]}>
            {isAllowExpenses && isAccount ? <Link
                className="link-with-hover"
                to={{
                  pathname: "/transactions",
                  state: {search: aid},
                }}
              >
                <b className="has-text-success">
                  {" "}
                  {T("paid")}:{" "}
                  <Money
                    amount={expenses["amount"]}
                    aid={aid}
                    className="has-text-success font-size-120"
                    wrapper={DOM.span}
                  />
                </b>
                {" " + T("from_account")}{" "}
                <b>{T(ExpensesStore.getAccountName(aid))}</b>
                <br/>
              </Link>
              :
              <b>
                {" "}
                {T("paid")}:{" "}
                <Money
                  amount={expenses["amount"]}
                  aid={aid}
                  className="font-size-120"
                  wrapper={DOM.span}
                />
              </b>
            }
          </div>
        );

        let amount;
        if (acc_info.currency_id !== parseInt(data.currency)) {
          amount = expenses.amount / data.currency_rate;
        } else {
          amount = expenses.amount;
        }

        total_pay += amount;
      }

      if (data["expenses"].length > 1) {
        pay_data.push(
          <div key={"total-" + data["expenses_id"]}>
            <b>
              {" "}
              {T("total")}:{" "}
              <Money
                currency={data.currency}
                amount={total_pay}
                className="has-text-success font-size-120"
                wrapper={DOM.span}
              />
            </b>
          </div>
        );
      }
    }

    let classname = "";

    if (data["shipment_status"] === "saled") {
      classname += " opacity05";
    }


    const isExpenses = UserStore.getPermissionEnable("show-all-expenses");
    const userId = UserStore.get("realId");

    let isAllowExpenses = true;

    if (!isExpenses && is_delivery_pay) {
      isAllowExpenses =
        parseInt(userId) === data["delivery_expenses"]['responsible_user_id'];
    }

    const permissionAccount = UserStore.getPermissionAccount();
    let isAccount = true;

    if (permissionAccount?.length && !permissionAccount.includes(daid)) {
      isAccount = false;
    }

    const is_delivery_pay_data = is_delivery_pay && (
      <List.Item>
        {isAllowExpenses && isAccount ? <Link
          className="link-with-hover"
          to={{
            pathname: "/transactions",
            state: { search: data["delivery_expenses_id"] },
          }}
        >
          <b>{T("delivery_paid")}:</b>{" "}
          <Money
            amount={data["delivery_expenses"]["amount"]}
            aid={daid}
            className="has-text-success"
            wrapper={DOM.span}
          />
          <b> {T("from_account")} </b>{" "}
          {T(ExpensesStore.getAccountName(daid))}
        </Link> :
          <>
            <b>{T("delivery_paid")}:</b>{" "}
            <Money
              amount={data["delivery_expenses"]["amount"]}
              aid={daid}
              wrapper={DOM.span}
            />
          </>
        }
      </List.Item>)

    return (
      <tr className={classname}>
        <td colSpan="5">
          <Heading
            size={6}
            className="margin-top-10 display-flex-center flex-wrap"
          >
            <span>
              ID: {data["id"]}. {data["name"]}&nbsp;
            </span>
            {!!data["supplier_id"] &&
              this.getSupplierButton(data["supplier_id"])}
            <b className="has-text-success">({T(data["shipment_status"])})</b>
          </Heading>

          <List>
            {!is_complete ? (
              <List.Item>
                <b>{T("shipments-created_at")}:</b>{" "}
                {formatDate(data["created_at"])}
              </List.Item>
            ) : (
              ""
            )}
            <List.Item>
              <b>{T("delivery-delivery")}:</b>{" "}
              {data["delivered_date"]
                ? formatDate(data["delivered_date"])
                : T("supply")}{" "}
              <br />
            </List.Item>
            <List.Item>
              <b>{T("on-warehouse")}:</b>{" "}
              {T(WarehouseStore.getWarehouseName(data["marketplace_id"]))}
            </List.Item>
            <List.Item>
              <b>{T("shipment-currency")}:</b>{" "}
              {User.getCurrency(data["currency"])}
            </List.Item>
            <List.Item>
              <b>{T("exchange-rate")}:</b>{" "}
              {trimNumber(data['currency_rate'], 4, User.getCurrency(data["currency"]))}
            </List.Item>
            {is_pay && <List.Item>{pay_data}</List.Item>}

            {is_delivery_pay && is_delivery_pay_data}

            {is_complete ? (
              <List.Item className="has-text-success">
                <b>{T("involved")}:</b> {data["sold"]} (
                {data["sold"] > 0
                  ? Math.round((100 / data["quantity"]) * data["sold"])
                  : 0}
                %)
              </List.Item>
            ) : (
              ""
            )}
          </List>
          {is_complete ? (
            <Columns className="is-mobile product-mobile-block">
              <Columns.Column size="half">
                <NumberBlock
                  top="received"
                  number={data["quantity"]}
                  bottom="units"
                  className="small-number-box"
                />
              </Columns.Column>
              <Columns.Column>
                <NumberBlock
                  top="on-amount"
                  number={data["received_amount"] || 0}
                  bottom={User.getCurrency(data["currency"])}
                  money={true}
                  className="small-number-box"
                />
              </Columns.Column>
            </Columns>
          ) : (
            <Columns className="is-mobile product-mobile-block">
              <Columns.Column size="half">
                <NumberBlock
                  top={"supply"}
                  number={data["expected_quantity"] || 0}
                  bottom="units"
                  className="small-number-box"
                />
              </Columns.Column>
              <Columns.Column>
                <NumberBlock
                  top="expected_goods_worth_short"
                  number={data["received_amount"] || 0}
                  bottom={User.getCurrency(data["currency"])}
                  money={true}
                  className="small-number-box"
                />
              </Columns.Column>
            </Columns>
          )}

          <div>{data["comment"]}</div>
          <Level
            renderAs="nav"
            className="is-mobile mobile-button"
            breakpoint="mobile"
          >
            {is_complete && this.getPriceButton()}
            {data["shipment_status"] === "pending" &&
              this.getRegistrationButton()}
            {UserStore.getModuleEnable("payments") &&
              !!this.getPaymentInfo(data) && (
                <Level.Item>{this.getPaymentInfo(data)}</Level.Item>
              )}
          </Level>
          <Level
            renderAs="nav"
            className="is-mobile mobile-button margin-bottom-10"
            breakpoint="mobile"
          >
            {data["shipment_status"] === "pending" && (
              <>
                {this.getEditButton()}
                {this.getDeleteButton()}
              </>
            )}
            {is_complete && (
              <>
                {this.getUndoButton()}
                {this.getDeleteButton()}
              </>
            )}
            <Level.Item>{this.getPrintButton(data.id)}</Level.Item>
            <Level.Item>
              <Button
                className="button_link"
                onClick={() =>
                  AppStore.openModal(<PriceTagCountModal sid={data.id} />)
                }
              >
                <FontAwesomeIcon
                  icon="tags"
                  size="2x"
                  title={T("print-tags")}
                />
              </Button>
            </Level.Item>
          </Level>
        </td>
      </tr>
    );
  }

  getPrintUrl(sid, types) {
    return (
      "/bprint/shipment?sid=" +
      sid +
      "&types=" +
      types +
      "&uid=" +
      User.get("id") +
      "&token=" +
      encodeURIComponent(User.getToken())
    );
  }

  getPrintButtonOne(sid) {
    return (
      <Dropdown
        up={this.props.isMobileSize}
        label={<FontAwesomeIcon icon="print" size={"1x"} title={T("print")} />}
      >
        <Dropdown.Item
          renderAs={Link}
          onClick={() => createMobileAppLink(this.getPrintUrl(sid, "sale"))}
          to={this.getPrintUrl(sid, "sale")}
          target="_blank"
          title={T("with-sale-price")}
          value="item"
        >
          {T("with-sale-price")}
        </Dropdown.Item>
      </Dropdown>
    );
  }
  getPrintButton(sid) {
    if (!User.getPermissionEnable("incomeinformation")) {
      return this.getPrintButtonOne(sid);
    }

    return (
      <Dropdown
        up={this.props.isMobileSize}
        label={
          <FontAwesomeIcon
            icon="print"
            size={this.props.isMobileSize ? "2x" : "1x"}
            title={T("print")}
          />
        }
      >
        <Dropdown.Item value="item" style={{ padding: 0 }}>
          <Link
            style={{ padding: "7px 15px", display: "block" }}
            onClick={(evt) =>
              createMobileAppLink(this.getPrintUrl(sid, "net"), evt)
            }
            to={this.getPrintUrl(sid, "net")}
            target="_blank"
          >
            {T("with-netprice")}
          </Link>
        </Dropdown.Item>
        <Dropdown.Item value="item" style={{ padding: 0 }}>
          <Link
            style={{ padding: "7px 15px", display: "block" }}
            onClick={(evt) =>
              createMobileAppLink(this.getPrintUrl(sid, "sale"), evt)
            }
            to={this.getPrintUrl(sid, "sale")}
            target="_blank"
          >
            {T("with-sale-price")}
          </Link>
        </Dropdown.Item>
      </Dropdown>
    );
  }

  desktopRender() {
    const { data } = this.props;
    let comment = "";
    if (data["comment"] && data["comment"] !== "") {
      comment = (
        <>
          {" "}
          <b>{T("shipments-comment")}:</b> {data["comment"]}{" "}
        </>
      );
    }
    let classname = "is-size-7";

    if (data["shipment_status"] === "saled") {
      classname += " opacity05";
    }

    return (
      <tr className={classname}>
        <td align="left" width="30%">
          ID: {data["id"]}. {data["name"]}&nbsp;
          {!!data["supplier_id"] && this.getSupplierButton(data["supplier_id"])}
          <br />
          <b>{T("shipments-created_at")}:</b> {formatDate(data["created_at"])}{" "}
          <br />
          <b>{T("delivery-delivery")}:</b>{" "}
          {data["delivered_date"]
            ? formatDate(data["delivered_date"])
            : T("supply")}{" "}
          <br />
          <b>{T("on-warehouse")}:</b>{" "}
          {T(WarehouseStore.getWarehouseName(data["marketplace_id"]))} <br />
          {ExpensesStore.hasManyCurrencies() &&
          parseInt(data["currency"]) !== parseInt(User.get("currency_id")) ? (
            <span>
              <b>{T("shipment-currency")}:</b>{" "}
              {User.getCurrency(data["currency"])} <br />
              <b>{T("exchange-rate")}:</b>{" "}
              {trimNumber(data['currency_rate'], 4, User.getCurrency(data["currency"]))}
              <br />
            </span>
          ) : null}
          {comment}
          <Level className="margin-top-5">
            <Level.Side align="left">
              {/*<Level.Item>
                            <OpenModalButton size="small" link={{
                              pathname: '/shipments/edit-shipment',
                              state: {'edit': true, 'step': 0, 'data': data }
                            }} text={T('btn-edit')} />
                          </Level.Item> */}
              {data["shipment_status"] === "pending"
                ? this.getPendingButton()
                : ""}
              {data["shipment_status"] === "complete"
                ? this.getCompeteButton()
                : ""}
              <Level.Item>{this.getPrintButton(data.id)}</Level.Item>
              <Level.Item>
                <Button
                  className="button-link-icon"
                  onClick={() =>
                    AppStore.openModal(<PriceTagCountModal sid={data.id} />)
                  }
                >
                  <FontAwesomeIcon
                    icon="tags"
                    size="1x"
                    title={T("print-tags")}
                  />
                </Button>
              </Level.Item>
            </Level.Side>
          </Level>
        </td>
        <td> {T(data["shipment_status"])}</td>
        <td className="left">
          <b>
            {data["shipment_status"] === "complete"
              ? T("expected")
              : T("supply")}
            :
          </b>{" "}
          {data["expected_quantity"]} <br />
          <b>{T("received")}:</b> {data["quantity"]} <br />
          <span className="has-text-success">
            <b>{T("involved")}:</b> {data["sold"]} (
            {data["sold"] > 0
              ? Math.round((100 / data["quantity"]) * data["sold"])
              : 0}
            %) <br />
          </span>
        </td>
        <td className="left">{this.getPaymentInfo(data)}</td>
      </tr>
    );
  }

  render() {
    return this.props.isMobileSize ? this.mobileRender() : this.desktopRender();
  }
}

export default withIsMobileSize(withRouter(ShipmentsRow));
