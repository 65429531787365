import React from 'react'
import T from "../../../../components/i18n";
import Columns from "react-bulma-components/lib/components/columns";
// import Heading from "react-bulma-components/lib/components/heading";
import Tag from "../../../../components/tag";
import Button from "react-bulma-components/lib/components/button";
import {Link} from "react-router-dom";
import AppStore from "../../../../app-store";
import ChannelBlock from "../../../../components/channel-block/channel-block";
import SalesStore from "../../../sales/sales-store";
import {parseJSON} from "../../../../tools/error-handler";
import UserStore from "../../../../user/user-store";

const FormCommon = ({data, wooID, id, name, link, active, additionSetting, additionalButton,submitButton, onClickButtonActions}) => {
	const isHaveChannel = SalesStore.foundChannelsByIntegrationId(id)

	const parseIdent = data?.ident && (parseJSON(data?.ident) || {})

	return (
		<>
			<Columns style={{position: 'relative'}}>
				<Columns.Column className='display-flex-row-gap'>
					<div align='left' style={{flex: 1}}>
						<div className='display-flex-row-gap' style={{alignItems: 'center'}}>
							<img alt={name}
									 src={"/img/integrations/" + wooID + (wooID === 13 ? ".png" : wooID === 16 ? ".webp" : ".svg")}
									 border="0" className="logo-int" style={{minWidth: "100px"}}/>
							{wooID === UserStore.CH_ID && <b>{data.api_key}</b>}
						</div>


						<br/>
						{T('desc-' + wooID)}
						<div style={{position: 'absolute', right: 10}}>
							{[UserStore.WOO_ID, UserStore.OC_ID, UserStore.HS_ID].indexOf(wooID) !== -1
								&& parseIdent?.url
								&& <a href={parseIdent.url} target='_blank'
											rel="noreferrer noopener"
											className='nowrap bold'>{parseIdent.url}</a>}
						</div>
					</div>
					<div className='display-flex-row' style={{alignItems: 'flex-start'}}>
						{isHaveChannel && <ChannelBlock channel_id={isHaveChannel}/>}
						<Tag
							name={active ? T('active') : active === 0 ? T('disabled') : T('unconnected')}
							className={`integration-tag ${active ? 'active' : active === 0 ? 'inactive' : ''}`}
						/>
					</div>
				</Columns.Column>
			</Columns>
			{additionSetting && additionSetting}
			<div className='display-flex-row-gap flex-wrap without-border margin-bottom-15'>
				<div>
					<Button renderAs={Link}
									size="large"
									rounded
									color="light"
									target="_blank" to={{pathname: AppStore.getDocsLink(link)}}
					>
						{T('how-connect')}
					</Button>
				</div>
					<div className='display-flex-row-gap'>
						{additionalButton && additionalButton}
						{submitButton && !id ? submitButton : (
							<Button
								size='large'
								color="info"
								onClick={onClickButtonActions}
							>
								{id ? T('settings') : T('connect')}
							</Button>
						)}
					</div>
			</div>
		</>
	);
};

export default FormCommon;