import React, {useState} from 'react'
import ErrorMessageBlock
	from "../../../../../components/error-message-block/error-message-block";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../../../components/i18n";
import IntegrationControlHook from "../common/integration-control.hook";
import Box from "react-bulma-components/lib/components/box";
import InputsGroupField from "../common/inputs-group-field";
import ActionsModal from "../common/actions-modal";
import SelectComponent from "../../../../../tools/select-component";
import WarehouseStore from "../../../../warehouse/warehouse-store";
import WarehouseActions from "../../../../warehouse/warehouse-actions";
import ModalItsOK from "../../../../../components/modal-its-ok";
import UserActions from "../../../../../user/user-actions";
import {
	handleDeleteIntegration,
	isValidKeys,
	isValidURL,
	parseObj
} from "../utils";
import {confirmDelete} from "../../../../../tools/confirm-delete";
import IntegrationListenerHook from "../common/integration-listener.hook";
import IntegrationCheckboxGroup from "../common/integration-checkbox-group";
import SalesStore from "../../../../sales/sales-store";
import AppStore from "../../../../../app-store";
import AddChannelModal from "../../../add-channel-modal";
import ChannelBlock
	from "../../../../../components/channel-block/channel-block";
import InfoWrapper from "../../../../../components/info-wrapper";
import AdditionalButtonDownload
	from "../../components/additional-button-download";
import VirtualWarehouse from "../../components/virtual-warehouse";

const DATA_INPUT = [
	{
		name: "api_key",
		placeholder: "consumer-key"
	},
	{
		name: "api_pass",
		placeholder: "consumer-secret"
	},
	{
		name: "url",
		placeholder: T('site-url', ['WooCommerce'])
	}
]

const WooCommerce = ({data, onClose}) => {
	const {api_key, api_pass, ident, id, is_active} = data

	let identParse = typeof ident === 'string' ? parseObj(ident) : ident

	const channel = SalesStore.foundChannelsByIntegrationId(id)


	const {
		handleAutoRemoveError,
		setErrorMessage,
		errorMessage,
		inputValues,
		handleChangeInputValues,
		checkboxValue,
		handleChangeCheckbox,
		selectValue,
		handleChangeSelect
	} = IntegrationControlHook({
		inputDefaultValue: {
			api_key,
			api_pass,
			url: identParse?.url || ''
		},
		checkboxDefaultValue: {
			update_stock: identParse?.update_stock,
			update_price: identParse?.update_price,
			update_orders: identParse?.update_orders,
			is_description: identParse?.is_description || false,
			is_price: identParse?.is_price || false,
			dont_orders: identParse?.dont_orders || false,
			vmid: identParse?.vmid || false
		},
		selectDefaultValue: {
			mid: {value: identParse?.mid},
			channel_id: channel ? {value: channel || 0} : false,
			vmids: identParse?.vmids?.length ? identParse.vmids.map(item => ({value: item, label: WarehouseStore.getWarehouseName(item)})) : null,
		}
	})
	const [waiting, setWaiting] = useState(false)
	const {submitOK, additionalMessage} = IntegrationListenerHook({handleError: handleAutoRemoveError, setWaiting})

	const handleError = () => {
		let errorMessage;

		if(!isValidKeys(inputValues, ['api_key', 'api_pass'], 43)){
			errorMessage = 'wrong-key'
		}
		if(!isValidURL(inputValues?.url)) {
			errorMessage = 'wrong-url'
		}

		if(errorMessage) {
			handleAutoRemoveError(errorMessage)
			return false
		}

		return  true
	}

	const handleSubmit = ({deactivate}) => {
		const identData = {
			url: inputValues.url,
			mid: selectValue.mid.value || WarehouseStore.getDefaultWarehouse(),
			is_load: 0,
			update_stock: !!checkboxValue.update_stock,
			update_price: !!checkboxValue.update_price,
			update_orders: !!checkboxValue.update_orders,
			is_description: !!checkboxValue.is_description,
			is_price: !!checkboxValue.is_price,
			dont_orders: !!checkboxValue.dont_orders,
			channel_id: selectValue.channel_id?.value ? selectValue.channel_id.value : false,
			vmid: checkboxValue?.vmid || false
		}

		if(checkboxValue.vmid) {
			identData.vmid = true;
			if(selectValue?.vmids?.length) {
				identData.vmids = selectValue.vmids.map(item => item.value)
			} else {
				handleAutoRemoveError('fill-in-the-virtual-lineups')
				return
			}
		} else {
			identData.vmids = null
		}

		const data = {
			"integration_id": 2,
			"api_key": inputValues.api_key,
			"api_pass": inputValues.api_pass,
			"ident": JSON.stringify(identData),
			is_active: 1
		}

		if(id) data.id = id
		if(deactivate) data.is_active = 0

		if(handleError()) {
			setWaiting(true)
			UserActions.updateIntegrations(data)
		}
	}

	const isHaveChannel = SalesStore.foundChannelsByIntegrationId(data?.id)

	const handleChangeChannelModal = () => {
		const getChannelById = SalesStore.getChannelById(isHaveChannel)
		AppStore.openModal(<AddChannelModal {...getChannelById}/>)
	}

	const handleChangeSelectMid = (value, meta) => {
		if(checkboxValue.vmid) {
			const vmidsSelect = selectValue?.vmids?.filter(item => item.value !== value.value)
			if(vmidsSelect?.length) {
				handleChangeSelect(vmidsSelect, {name: 'vmids'})
			} else {
				handleChangeSelect(null, {name: 'vmids'})
			}
		}

		handleChangeSelect(value, meta)
	}

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			{!submitOK && <Box>
					<div className='display-flex-row-gap align-start'>
						<Heading
							size={3}>
							{T('setting-integration', [T('integration-2')])}
						</Heading>
						{isHaveChannel && <ChannelBlock channel_id={isHaveChannel}
																						onClick={handleChangeChannelModal}/>}
					</div>
					<InputsGroupField inputValues={inputValues} dataInput={DATA_INPUT}
														onChange={handleChangeInputValues}/>
					<SelectComponent
						name="mid"
						onChange={handleChangeSelectMid}
						value={selectValue.mid}
						list={WarehouseStore.getWarehouseList}
						load={WarehouseActions.load}
						readOnly={!!id}
						creatable={false}
					/>
					<VirtualWarehouse withoutMidId={selectValue.mid.value} value={selectValue.vmids} onChange={handleChangeSelect} vmidValue={checkboxValue.vmid} onChangeCheckbox={handleChangeCheckbox}/>
					<SelectComponent
						name="channel_id"
						label={<InfoWrapper info='not-select-channel-integration-tip'>{T('sale-channel')}</InfoWrapper>}
						placeholder='channel'
						onChange={handleChangeSelect}
						value={selectValue.channel_id}
						list={() => SalesStore.getChannelListWithoutIntegration(channel)}
						addItem={({name}) => AppStore.openModal(<AddChannelModal name={name} />)}
						addItemModal={() => AppStore.openModal(<AddChannelModal/>)}
						creatable
					/>
					<IntegrationCheckboxGroup onChange={handleChangeCheckbox}
																		values={checkboxValue}/>
					<ActionsModal
						onSubmit={handleSubmit}
						onDelete={() => confirmDelete(() => handleDeleteIntegration(id))}
						onClose={onClose}
						isEdit={!!id}
						isActive={is_active}
						waiting={waiting}/>
			</Box>}
			{submitOK && <ModalItsOK onClose={onClose} title={submitOK} additionalMessage={additionalMessage} children={!data.id && <AdditionalButtonDownload data={data}/>}/>}
		</>
	);
};

export default WooCommerce;
