import React from "react";
import OpenModalButton from "../../../components/modal/open-modal-button";
import T from "../../../components/i18n";
import ExportDocumentDropdown from "../common/export-document-dropdown";

const ActionsButton = () => {
  return (
    <div
      className="display-flex-row flex-wrap margin-bottom-10"
      style={{ justifyContent: "flex-end" }}
    >
      <OpenModalButton
        link="/shipments/add-shipment-base"
        text={T("create-product-delivery")}
        icon="plus-circle"
      />
      <ExportDocumentDropdown />
    </div>
  );
};

export default ActionsButton;
