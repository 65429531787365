import React, { useEffect, useState } from "react";
import { Box, Heading } from "react-bulma-components";
import ProductsStore from "../../products-store";
import DescriptionBlock from "./description/description-block";
import DynamicTabList from "../../../../components/dynamic-tab-list/dynamic-tab-list";
import SalesStore from "../../../sales/sales-store";
import SalesProductBlock from "./sales-product/sales-product-block";
import { connectToStores } from "../../../../tools/reflux-tools";
import AddProductBlock from "./add-product/add-product-block";
import ShipmentProductBlock from "./shipment-product/shipment-product-block";
import MovingProductBlock from "./moving-product/moving-product-block";
import ProductsActions from "../../products-actions";
import WriteOffProductBlock from "./write-off-product/write-off-product-block";
import T from '../../../../components/i18n'
import ShipmentsStore from "../../../shipments/shipments-store";
import ShipmentsActions from "../../../shipments/shipments-actions";
import ActionButtons from "./common/action-buttons";
import HistoryProductBlock from "./history-product/history-product-block";

const tabList = [
  { label: "description", value: "description" },
  { label: "sales", value: "sales" },
  { label: "Shipments", value: "Shipments" },
  { label: "added", value: "added" },
  { label: "moving", value: "moving" },
  { label: "write-off-label", value: "write-off-label" },
  { label: "history", value: "history" },
];

const ViewProductModal = ({ pid,mid, onClose }) => {
  const [isActiveTab, setIsActiveTab] = useState("description");
  const data = ProductsStore.getProduct(pid);
  const handleChangeTab = (value) => {
    setIsActiveTab(value);
  };

  useEffect(() => {
    if(pid) {
      ProductsActions.loadHistory(pid);
      ShipmentsActions.load()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(!data) {
    onClose && onClose()
    return null
  }

 if(!pid) {
   return <Box>{T('error-payment')}</Box>
 }
  const firstMid = Object.keys(data.stock)[0];
  return (
    <Box>
      <Heading>
        ID {pid}: {data.name}
      </Heading>
      <DynamicTabList
        defaultTab={isActiveTab}
        list={tabList}
        onChangeTab={handleChangeTab}
      />
      {isActiveTab === "description" && <DescriptionBlock pid={pid} />}
      {isActiveTab === "sales" && (
        <SalesProductBlock pid={pid} units={data.units} />
      )}
      {isActiveTab === "Shipments" && (
        <ShipmentProductBlock pid={pid} units={data.units} />
      )}
      {isActiveTab === "moving" && (
        <MovingProductBlock pid={pid} units={data.units} />
      )}
      {isActiveTab === "added" && (
        <AddProductBlock pid={pid} units={data.units} />
      )}
      {isActiveTab === "write-off-label" && <WriteOffProductBlock units={data.units} />}
      {isActiveTab === "history" && <HistoryProductBlock pid={pid}/>}
      <ActionButtons isActiveTab={isActiveTab} onClose={onClose} pid={pid} parentId={data?.parent_id} mid={mid || firstMid}/>
    </Box>
  );
};

export default connectToStores(ViewProductModal, {
  sales: SalesStore,
  products: ProductsStore,
  shipments: ShipmentsStore,
});
