import React, { Component } from 'react';
import T from "components/i18n";
import { connectToStores } from "tools/reflux-tools";
// import { redirectTo } from 'tools/redirect-to'
import { 
    Field, 
    Control, 
    Input,
    Label, 
    Textarea
} from 'react-bulma-components/lib/components/form';

import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import Heading from 'react-bulma-components/lib/components/heading';
import List from 'react-bulma-components/lib/components/list';
// import Level from 'react-bulma-components/lib/components/level';
import ClientsStore from './clients-store'
import Actions from './clients-actions'
import SalesStore from '../sales/sales-store'

// import SelectComponent from 'tools/select-component';
import Columns from 'react-bulma-components/lib/components/columns';

import DatePicker from "react-datepicker";

import SelectComponent from 'tools/select-component';

import UserStore from 'user/user-store';
// import UserActions from 'user/user-actions';

import isValidEmail from 'tools/valid-email';
import OpenModalButton from "components/modal/open-modal-button";

// import ScrollToTopOnMount from 'tools/scroll-top'

// import Notification from 'react-bulma-components/lib/components/notification';

import PreOrder from 'modules/sales/preorder'

import Messages from 'components/messages';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tag from "../../components/tag";
import AppStore from "../../app-store";
import AddToClientTagModal from "./clients-tags/add-to-client-tag.modal";
import ClientViewModal from "./modal/client-view/client-view-modal";
import InfoWrapper from "../../components/info-wrapper";
import {parsePhoneNumber} from "../../tools/parse-phone-number";
import SubmitButton from "../../components/submit-button";
import NumberInput from "../../components/number-input/number-input";
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";
import {parseJSON} from "../../tools/error-handler";
import AdditionalInfoClient from "./common/additional-info-client/additional-info-client";

const generateSocialInfo = (social) => {
  const data = {
    telegram: {
      label: 'Telegram',
      icon: 'img/Telegram_logo.svg',
      is_global: 1,
      info: 'add-nickname-link',
      value: ['']
    },
    facebook: {
      label: 'Facebook',
      icon: '/img/Facebook_f_logo.png',
      info: 'add-facebook-link',
      is_global: 1,
      value: ['']
    },
    instagram: {
      label: 'Instagram',
      icon: '/img/Instagram_logo.webp',
      is_global: 1,
      info: 'add-nickname-link',
      value: ['']
    },
    linkedin: {
      label: 'Linkedin',
      icon: '/img/linkedin-logo.svg',
      is_global: 1,
      info: 'add-linkedin-link',
      value: ['']
    },
    addPhone: {
      label: "phone",
      is_global: 1,
      info: 'add-phone-link',
      value: [''],
      type: 'number'
    },
    addEmail: {
      label: 'E-mail',
      is_global: 1,
      value: ['']
    }
  }

  Object.entries(social || {}).forEach(([key, value]) => {
    if(data[key]) {
      data[key].value = value;
    } else {
      data[key] = {}
      data[key].name = key
      data[key].value = value
    }
  })

  return data;
}
class AddClientModal extends Component {
    constructor(props) {
        super(props);
        const data = props.data || {};
        let phone = props.phone || data.phone;
        if (phone) {
          if (UserStore.isRussia()) {
            if (["+", "7"].includes(phone[0]) === false) {
                phone = UserStore.getPhoneCode()+phone;
            }
          } else {
            phone = parsePhoneNumber(phone)
          }

          if (typeof(phone.replaceAll) === "function") {
            phone = phone.replaceAll("(","").replaceAll(")","").replaceAll(" ", "").replaceAll("-","");
          }
        }

        let name = "";
        if (data.name) {
          name = data.name.replace("(+"+data.phone+")", "")
        }

        const parseMoreInfo = parseJSON(data.more_info || {});

        this.state = {
          preorder: props.preorder || null,
          client_id: props.client_id || null,
          edit: props.edit || false,
          name: {
                error: "",
                value: name
          },
          company: {
                error: "",
                value: data.company || ""
          },
          firstname: {
                error: "",
                value: data.first_name || (name.split(" ")[0] || "")  
          },
          lastname: {
                error: "",
                value: data.last_name || (name.split(" ")[1] || "")
          },
          middlename: {
                error: "",
                value: data.middle_name || ""
          },
          nickname: {
                error: "",
                value: data.nickname || ""
          }, 
          balance: {
                error: "",
                value: data.balance || 0
          },
          phone: {
                error: "",
                value: phone || data.phone || UserStore.getPhoneCode()
          },
          email: {
                error: "",
                value: data.email || ""
          },
          birth_day: data.birth_day ? new Date(data.birth_day) : null,
          birth_day_child_1: data.birth_day_child_1 ? new Date(data.birth_day_child_1) : null,
          birth_day_child_2: data.birth_day_child_2 ? new Date(data.birth_day_child_2) : null,
          birth_day_child_3: data.birth_day_child_3 ? new Date(data.birth_day_child_3) : null,
          discount: {
                error: "",
                value: data.discount || "0"
          },
          address: {
                error: "",
                value: data.address || ""
          },
          city: {
                error: "",
                value: data.city || ""
          },
          comment: {value: data.comment || ""},
          invoice: {value: data.invoice || ""},
          city_ref: data.city || 0,
          postoffice: data.postoffice || null,
          add_delivery_service: {value: 1},
          message: null,
          updates: 0,
          close: false,
          waiting: false,
          more_info: parseMoreInfo,
          social: generateSocialInfo(parseMoreInfo?.social || {})
        };

        this.onChange = this.onChange.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeDateInput = this.onChangeDateInput.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.submit = this.submit.bind(this);
        this.onClickBack = this.onClickBack.bind(this);
    }

    componentWillUnmount() {
      if (typeof(this.listen) == "function") {
          this.listen();
      }

      if (typeof(this.listenAddFaild) == "function") {
          this.listenAddFaild();
      }

    }

    componentDidMount() {
        const {onClose} = this.props;

        this.listen = Actions.add.completed.listen(() => {
            setTimeout(() => {this.setState({"close": true}); onClose();}, 100);
        });        

        this.listenAddFaild = Actions.add.failed.listen((res) => {
            this.setState({
              waiting: false
            })
            let data = {};

            if (res && typeof(res['response']) != "undefined") {
              data = JSON.parse(res['response']);
            } else {
              data = {
                'error': "unhandled_error"
              }
            }

            this.setState({
              'message': {
                'msg': T(data['error'])
              }
            });
        });

    }

    submit() {
        let phone = this.state.phone.value,
            email = String(this.state.email.value),
            bday = this.state.birth_day,
            bday1 = this.state.birth_day_child_1,
            bday2 = this.state.birth_day_child_2,
            bday3 = this.state.birth_day_child_3,
            firstname = this.state.firstname.value || "",
            company = this.state.company.value || "",
            lastname = this.state.lastname.value || "",
            middlename = this.state.middlename.value || "",
            nickname = this.state.nickname.value || "",
            balance = this.state.balance.value || 0;

        if (phone !== "" && !UserStore.checkPhone(phone, true) ) {
            this.setState({
              "phone": {
                'error': 'bad-phone-number',
                'value': phone
              },
              'message': T('wrong-phone')
            });

            return false;
        }

        // if (UserStore.isDeliveryEnable()) {
        //   if (firstname.length < 2) {
        //       this.setState({
        //           'message': T('wrong-firstname')
        //         });

        //         return false;
        //       }
        // }  

        if (firstname.length < 2 && company.length < 2 && phone.length < 2) {
              this.setState({
                  'message': T('wrong-firstname')
                });

                return false;
        }


        const moreInfo = this.state.more_info;

        moreInfo.social = Object.entries(this.state.social).reduce((acc,[key, social]) => {
          let filterClearValue = social.value.filter(item => !!item)
          if(key === 'addPhone' && !!filterClearValue.length) {
            filterClearValue = filterClearValue.map(item => parsePhoneNumber(item, true))
          }
          if(!!filterClearValue.length) {
            acc[key] = filterClearValue
          }

          return acc
        },{})

        if (email !== "" && !isValidEmail(email)) {
            this.setState({
              "email": {
                'error': 'bad-email',
                'value': email
            }
            });

          return false;
        }

        const data = {
              "firstname": firstname || "",
              "lastname": lastname || "",
              "middlename": middlename || "",
              "nickname": nickname || "",
              "balance": balance || 0,
              "company": company || "",
              "phone": phone === "380" || phone === '+380' ? "" : parsePhoneNumber(phone, true),
              "email": email || "",
              "birth_day": (bday ? bday.toUTCString() : ""),
              "birth_day_child_1": (bday1 ? bday1.toUTCString() : ""),
              "birth_day_child_2": (bday2 ? bday2.toUTCString() : ""),
              "birth_day_child_3": (bday3 ? bday3.toUTCString() : ""),
              "discount": parseInt(this.state.discount.value) || 0,
              "city": this.state.city.value || '',
              "comment": this.state.comment.value || '',
              "invoice": this.state.invoice.value || '',
              "address": this.state.address.value || '',
              "more_info": JSON.stringify(moreInfo)
        }

        if (UserStore.getEnableNovaPoshta() && !this.state.edit) {
            data['city_ref'] = this.state.city_ref.value;
            data['postoffice'] = this.state.postoffice.value;

            if (data['postoffice'] && data['postoffice'] !== 0) {
                if (firstname.length < 2 || lastname.length < 2) {
                  this.setState({
                      'message': T('wrong-firstname')
                    });

                    return false;
                }
            }
        }

        if (this.state.edit) {
          data['cid'] = this.state.client_id;
        }
        Actions.add(data);
        this.setState({
          waiting: true
        })

    }

    deleteDelivery(delivery_id) {
        Actions.deleteDelivery(delivery_id);
        return false;
    } 

    onChange(evt) {
        let value = evt.target.value,
            name = evt.target.name;

        if (['firstname', 'lastname', 'middlename'].indexOf(name) > -1) {
            value = value.replace(/[&/\\#,+()$~%;|@.":*?<>{}]/g, '');
        }

        this.setState({
        [name]: {
          'value': value
        }
        });
    }

    onChangeSelect(selectedOption, actions) {
      if (actions.name === "city_ref" && ClientsStore.getLastCity() !== selectedOption.value) {
        ClientsStore.cleanPostoffice()
      }

      this.setState({
          [actions.name]:  selectedOption,
      });
    }

    onChangeDate(date) {
        this.setState({
            "birth_day": date
        });
    }

    onChangeDateInput(date, name) {
      this.setState({
        [name]: date
      })
    }
    getChannels(channels) {
      let response = [];

      for (let i in channels) {
          let channel_id = channels[i];
          response.push( <span className={SalesStore.getChannelsClass(channel_id)} key={i+'channels'}> {T(SalesStore.getChannelsName(channel_id))} </span>  )
      }

      return response;
    }

    getTagsGroup() {
      const tagsGroup = ClientsStore.getGroupClientTagByClientId(this.state.client_id)

      const tag_list = []
      for (let i in tagsGroup) {
        tag_list.push(<Tag key={`tag-${tagsGroup[i].id}`} color={tagsGroup[i].color} name={tagsGroup[i].name}/>)
      }



      return (
        <Box>
          <div className='display-flex-row-gap'>
            <Heading renderAs="h2" size={4}>{T('clients-tags')}</Heading>
            <Button className='button-link-icon' onClick={()=> AppStore.openModal(<AddToClientTagModal modal='add-to-client-tags' id={this.state.client_id}/>)}>
              <FontAwesomeIcon icon='plus-circle' size='1x'/>
            </Button>
          </div>
          <div className='display-flex-row'>
          {tag_list}
          </div>
        </Box>)
    }

    onClickBack() {
     this.props.onClose()

     AppStore.openModal(<ClientViewModal clientID={this.props.client_id}/>)
    }

    render() {
      const classIsMobile = this.props.isMobileSize ? "is-mobile": "";
        const {onClose} = this.props;
        const client_delivery = ClientsStore.getClientDeliveryList(this.state.client_id);

        let list_delivery = [];

        for (let i in client_delivery) {
          let d = client_delivery[i];

          list_delivery.push(
              <List.Item key={i}>
                  <Columns className={classIsMobile}>
                    <Columns.Column>
                        <b>{T('delivery-'+d['delivery_id'])}:</b> {ClientsStore.getClientDeliveryNane(d, UserStore.getLang())}
                    </Columns.Column>
                    <Columns.Column size="one-fifth" align='right'>
                      <Button className="without-border" onClick={() => {this.deleteDelivery(d['id']);}} title={T('delete')}>
                        <FontAwesomeIcon icon="trash-alt" size="2x" />
                      </Button>
                    </Columns.Column>
                  </Columns>
              </List.Item>
            )
        }

      const filterData = (inputValue, list) => {
          if (inputValue.length < 2) return [];

          let result = list.filter(i =>
            i.label.toLowerCase().split("(")[0].includes(inputValue.toLowerCase())
          );
          return result.slice(0, 30);
        };

        return (
          <div>
              {this.state.preorder ? <PreOrder data={this.state.preorder} /> : null}
              <Messages message={this.state.message} close={() => this.setState({message: null})} />
              <Box>
                <Columns className={classIsMobile}> 
                  <Columns.Column>
                      <Field>
                        <Control>
                          <Label>{T('last-name')}</Label>
                          <Input  size="large" 
                                name="lastname" 
                                type="text" 
                                placeholder={T('lastname-placeholder')}
                                onChange={this.onChange} 
                                color={this.state.lastname.error ? "danger" : null}
                                value={this.state.lastname.value}
                                />
                        </Control>
                      </Field>
                  </Columns.Column> 
                  <Columns.Column>
                      <Field>
                        <Control>
                          <Label>{T('first-name')}</Label>
                          <Input  size="large" 
                                name="firstname" 
                                type="text" 
                                placeholder={T('firstname-placeholder')}
                                onChange={this.onChange} 
                                color={(this.state.firstname.error) ? "danger" : "light"}
                                value={this.state.firstname.value}
                                />
                        </Control>
                      </Field>
                  </Columns.Column>
                  <Columns.Column>
                      <Field>
                        <Control>
                          <Label>{T('middle-name')}</Label>
                          <Input  size="large" 
                                name="middlename" 
                                type="text" 
                                placeholder={T('middlename-placeholder')}
                                onChange={this.onChange} 
                                color={(this.state.middlename.error) ? "danger" : "light"}
                                value={this.state.middlename.value}
                                />
                        </Control>
                      </Field>
                  </Columns.Column>
                </Columns>
                <Columns  className={classIsMobile}>
                  <Columns.Column>
                      <Field>
                      <Control>
                        <Label>{T('client-phone')}</Label>
                        <Input  size="large" 
                              name="phone" 
                              type="text" 
                              placeholder={T('client-phone-placeholder')}
                              onChange={this.onChange} 
                              color={(this.state.phone.error) ? "danger" : "light"}
                              value={this.state.phone.value}
                              />
                      </Control>
                    </Field>
                  </Columns.Column>
                  <Columns.Column>
                      <Field>
                      <Control>
                        <Label>{T('client-email')}</Label>
                        <Input  size="large" 
                              name="email" 
                              type="text"
                              placeholder={T('client-email-placeholder')}
                              onChange={this.onChange} 
                              color={(this.state.email.error) ? "danger" : "light"}
                              value={this.state.email.value}
                              />
                      </Control>
                    </Field>
                  </Columns.Column> 
                  <Columns.Column>
                      <Field>
                        <Control>
                          <Label>{T('balance')}</Label>
                          <NumberInput
                            size="large"
                            name="balance"
                            placeholder={T('balance-placeholder')}
                            onChange={this.onChange}
                            className='disabled-arrow-input'
                            color={(this.state.balance.error) ? "danger" : "light"}
                            value={this.state.balance.value}
                          />
                        </Control>
                      </Field>
                  </Columns.Column> 
                </Columns>
                <Columns  className={classIsMobile}>
                    <Columns.Column>
                        <Field>
                        <Control>
                          <Label>{T('client-discount')}</Label>
                          <NumberInput
                            size="large"
                            name="discount"
                            min="0"
                            placeholder={T('client-discount-placeholder')}
                            onChange={this.onChange}
                            color={(this.state.discount.error) ? "danger" : "light"}
                            value={this.state.discount.value}
                          />
                        </Control>
                      </Field>
                    </Columns.Column>
                    <Columns.Column>
                      <Field>
                      <Control>
                        <Label>{T('company')}</Label>
                        <Input  size="large" 
                              name="company" 
                              type="text" 
                              placeholder={T('client-company-placeholder')}
                              onChange={this.onChange} 
                              color={(this.state.company.error) ? "danger" : "light"}
                              value={this.state.company.value}
                              />
                      </Control>
                    </Field>
                  </Columns.Column>
                    <Columns.Column>
                      <Field>
                        <Control>
                          <Label><InfoWrapper info={T('client-nickname-tip')}>{T('nickname')}</InfoWrapper></Label>
                          <Input  size="large" 
                                name="nickname" 
                                type="text" 
                                placeholder={T('nickname-placeholder')}
                                onChange={this.onChange} 
                                color={(this.state.nickname.error) ? "danger" : "light"}
                                value={this.state.nickname.value}
                                />
                        </Control>
                      </Field>
                  </Columns.Column> 
                </Columns>
                <Columns  className={classIsMobile}>
                  <Columns.Column>
                    <Field>
                      <Control>
                        <Label>{T('city')}</Label>
                        <Input  size="large" 
                              name="city" 
                              type="text" 
                              min="0" 
                              placeholder={T('city')}
                              onChange={this.onChange} 
                              color={(this.state.city.error) ? "danger" : null}
                              value={this.state.city.value}
                              />
                      </Control>
                    </Field>
                  </Columns.Column>
                  <Columns.Column>
                    <Field>
                      <Control>
                        <Label>{T('client-address')}</Label>
                        <Input  size="large" 
                              name="address" 
                              type="text" 
                              placeholder={T('client-address-placeholder')}
                              onChange={this.onChange} 
                              color={(this.state.address.error) ? "danger" : null}
                              value={this.state.address.value}
                              />
                      </Control>
                    </Field>
                  </Columns.Column>
                </Columns>
                <Columns  className={classIsMobile}>
                  <Columns.Column>
                    <Field>
                      <Label>{T('client-birhday')}</Label>
                      <Control>
                        <DatePicker
                          name="birth_day"
                          selected={this.state.birth_day}
                          maxDate={new Date()}
                          onChange={this.onChangeDate}
                          className="input"
                          dateFormat="dd-MM-yyyy"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          locale={UserStore.getLang()}
                        />
                      </Control>
                    </Field>
                  </Columns.Column>
                </Columns>
                <AdditionalInfoClient
                  socials={this.state.social}
                  dateValue={{
                    birth_day_child_1: this.state.birth_day_child_1,
                    birth_day_child_2: this.state.birth_day_child_2
                  }}
                  socialChange={(social) => this.setState({social})}
                  onChangeDate={this.onChangeDateInput}
                />
                {UserStore.getEnableNovaPoshta() && !this.state.client_id &&
                  <Box>
                    <Label size='large'>{T('delivery-service')}</Label>
                    <Columns className={classIsMobile}>
                      <Columns.Column>
                        <Field>
                          <SelectComponent
                            name="city_ref"
                            label="city"
                            onChange={this.onChangeSelect}
                            value={this.state.city_ref}
                            empty={T('select-city')}
                            autoComplete="nope"
                            list={ClientsStore.getCityList}
                            _filters={filterData}
                            load={Actions.loadCity}
                            async={true}
                          />
                        </Field>
                      </Columns.Column>
                      <Columns.Column>
                        <Field>
                          <SelectComponent
                            name="postoffice"
                            label="post-office-number"
                            onChange={this.onChangeSelect}
                            value={this.state.postoffice}
                            autoComplete="nope"
                            empty={'select-post-office-number'}
                            list={() => {return ClientsStore.getPostOfficeList(this.state.city_ref)}}
                            async={true}
                            // load={() => {return Actions.loadPostoffice(this.state.city)}}
                            // promice={() => {return Actions.loadPostoffice(this.state.city.value)}}
                          />
                        </Field>
                      </Columns.Column>
                    </Columns>
                  </Box>}
                <Field>
                  <Control>
                    <Label>{T('comment')}</Label>
                    <Textarea  size="medium" 
                          name="comment" 
                          type="text" 
                          placeholder={T('client-comment-placeholder')}
                          onChange={this.onChange} 
                          value={this.state.comment.value}
                          />
                  </Control>
                </Field>
                <Field>
                  <Control>
                    <Label>{T('data-for-invoice')}</Label>
                    <Textarea  size="medium" 
                          name="invoice" 
                          type="text" 
                          placeholder={T('client-invoice-placeholder')}
                          onChange={this.onChange} 
                          value={this.state.invoice.value}
                          />
                  </Control>
                </Field>
                {this.state.client_id && this.getTagsGroup()}
                {UserStore.getDeliveryService() && this.state.client_id && UserStore.isUkraine() ?
                <Box>
                  <Heading renderAs="h2" size={4}>{T('delivery-services')}</Heading>

                  <List>
                  {list_delivery.length > 0 ?
                    <>{list_delivery}</>
                  : <List.Item className="text-align-center"><Heading size={6}>{T('not-have-history')}</Heading></List.Item>}
                  </List>
                  <Columns className={this.props.isMobileSize ? "is-mobile is-vcentered " : "is-mobile"}>
                      <Columns.Column>
                          <SelectComponent 
                            name="add_delivery_service"
                            label=""
                            onChange={this.onChangeSelect} 
                            value={this.state.add_delivery_service} 
                            list={UserStore.getCreatableDeliveryService}
                            creatable={false}/>
                      </Columns.Column> 
                      <Columns.Column className="alignright">
                           <OpenModalButton size="medium" link={{
                                          pathname: '/clients/add-delivery',
                                          state: {
                                            delivery: this.state.add_delivery_service.value,
                                            phone: this.state.phone,
                                            client_id: this.state.client_id,
                                            name: this.state.name
                                          }
                                        }} text={T('add-delivery-btn')} />
                      </Columns.Column>
                </Columns>
                </Box>
                : null}

                <div  className='display-flex-row-gap flex-wrap' style={this.props.isMobileSize ? {justifyContent: 'center', gap: '10px'} : {}}>
                  {this.state.client_id ? <Button size="medium" rounded color="light"
                                                  onClick={this.onClickBack}
                  >
                    {T('client-info')}
                  </Button> : <div style={{width: '100px'}}/>}
                  <SubmitButton
                    text={T(this.state.edit ? 'edit-client-btn' : 'add-client-btn')}
                    color='success'
                    fullwidth={false}
                    waiting={this.state.waiting}
                    submit={this.submit}
                  />
                  <Button size="medium" rounded color="light"
                          onClick={() => {onClose()}}>{T('close')}</Button>
                </div>
                {/*{this.state.close ? redirectTo("/clients") : ""}*/}

              </Box>
            </div>
        );
    }

}

export default connectToStores(withIsMobileSize(AddClientModal), {
    client: ClientsStore
});
