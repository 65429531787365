import React, {useEffect} from "react";
import Box from "react-bulma-components/lib/components/box";
import HistoryStore from "../history-store";
import Reports from "../../../components/reports";
import T from "../../../components/i18n";
import RowDesktop from "./row-desktop";
import usePaginationHook from "../../../tools/pagination-hook";
import RowMobile from "./row-mobile";
import FilterPanel from "./filter-panel";
import { filterSearch } from "../../../tools/filters-helper";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
import HistoryActions from "../history-actions";

const EmptyMessage = () => {
  return (
    <Box>
      <h2>{T("not-have-history")}</h2>
    </Box>
  );
};

const COLUMNS = ["name", "date-of-deletion",'history-data-marketplace_id', "reports-actions"];

const ProductsDeleted = () => {
  const {isMobileSize} = useIsMobile()
  const getList = HistoryStore.getProductsDeletedList();

  const listWithFilter = getList.filter((item) => {
    const filter = HistoryStore.get('_filterDeleted')

    let result = true

    const date = new Date(item.updated_at)

    filter['date_start'].setHours(0,0,0,0);
    filter['date_end'].setHours(23,59,59,999);

    if (date < filter['date_start'] || date > filter['date_end']) {
      return false;
    }

    if(filter.mid && filter.mid.value) {
      result = result && !!item.stock[filter.mid.value]
    }

    if(filter.search) {
      return filterSearch(
        filter.search,
        ["name", "cat_name", "brand_id", "category_id", "brand", "id", "asin"],
        item
      );
    }
    return result
  });

  const { start, end, page, total, setPage, PaginationBlock } =
    usePaginationHook({ listLength: listWithFilter?.length });



  useEffect(() => {
    HistoryActions.loadProductsDeleted()
  }, []);

  return (
    <>
      {!isMobileSize && <FilterPanel/>}
      <Box>
        {isMobileSize && <FilterPanel />}
        <PaginationBlock
          totalPage={total}
          setPage={setPage}
          currentPage={page}
          showPrevNext={false}
        />
        <Reports
          emptyMessage={EmptyMessage}
          columns={COLUMNS}
          hideFooterOnEmpty
          isLoading={HistoryStore.loadingProductsDeleted}
          rows={listWithFilter.slice(start, end)}
        >
          {isMobileSize ? <RowMobile role="row" /> : <RowDesktop role="row" />}
        </Reports>
        <PaginationBlock
          totalPage={total}
          setPage={setPage}
          currentPage={page}
          showPrevNext={false}
        />
      </Box>
    </>
  );
};

export default ProductsDeleted;
