import React, {useEffect} from 'react'
import OpenInsideModal from "../../../../components/modal/open-inside-modal";
import BindingClientModal from "../../binding-client";
import AddClientModal from "../../../clients/add-client-modal";
import ClientsActions from "../../../clients/clients-actions";
import ChatsActions from "../../chats-actions";

const ButtonsCreate = ({activeChat}) => {

	useEffect(() => {
		const addClient = ClientsActions.add.completed.listen(res => {
			const clientID = res.id

			ChatsActions.bindingClient({'client_id': clientID, 'chat_id': activeChat['id']})
		})

		return () => {
			addClient()
		}
	}, [activeChat]);
	return (
		<div className='display-flex-column-gap'>
			<OpenInsideModal size="large" text="binding-client-btn">
				<BindingClientModal modal="binding-client" data={activeChat}/>
			</OpenInsideModal>
			<OpenInsideModal size="large" text="add-client-btn" color='success'>
				<AddClientModal data={activeChat}/>
			</OpenInsideModal>
		</div>
	);
};

export default ButtonsCreate;