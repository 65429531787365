import React, {useEffect} from "react";

import {connectToStores} from "tools/reflux-tools";

import {Box, Button, Dropdown, Heading, Level} from "react-bulma-components";
import Reports from "components/reports";
import T from "components/i18n";

import OpenModalButton from "components/modal/open-modal-button";
import OpenModalFactory from "components/modal/open-modal-factory";
import OpenInsideModal from "components/modal/open-inside-modal";
// import OpenModalIcon from "components/modal/open-modal-icon";
import ProductsFilter from "./products-filters";
import ProductsStore from "./products-store";
import Actions from "./products-actions";
import ProductsActions from "./products-actions";
import {LocalIcon} from "whitelables/wl-name/icons";

import {Link, useLocation} from "react-router-dom";

import AddProductsModal from "./modal/add-product/add-products-modal";
import ImportItemsModal from "./import-from-remote";
import PriceModal from "./modal/price-modal/price-modal";
import WarehouseStore from "modules/warehouse/warehouse-store";
import SalesActions from "modules/sales/sales-actions";
import User from "user/user-store";
import UserStore from "user/user-store";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ButtonsDropdownList from "./desktop/buttons-dropdown";
import MassButton from "../../components/mass-button";
import GroupButtonList from "./common/group-button-list";
import LightStore from "./light-store";
import BaseFilterLayout from "../../components/base-filter-layout";
import LightActions from "./light-actions";
import DynamicTabList from "../../components/dynamic-tab-list/dynamic-tab-list";
import AddWarehouseModal from "../warehouse/add-warehouse-modal";
import AppStore from "../../app-store";
import {
  useIsMobile
} from "../../components/is-mobile-context/is-mobile-context";
import NewWrapperProductRow from "./new-render/new-wrapper-product-row";
import usePaginationHook from "../../tools/pagination-hook";
import calcMargin from "../../tools/calc-margin";
import SalesStore from "../sales/sales-store";
import ProductsTotal from "./products-total";

let COLUMNS = [
  "products-name-date",
  "available",
  "sales",
  "products-netcost",
  "product-info-price",
  "margin",
];

const DATA_SORT = [
  { label: "date", value: "products-name-date" },
  { label: "available", value: "available" },
  { label: "sales", value: "sales" },
  { label: "products-netcost", value: "products-netcost" },
  { label: "products-price", value: "products-price" },
  { label: "margin", value: "margin" },
];

const EmptyMessage = () => {
  return (
    <Box>
      <Heading>{T("enter-search-term")}</Heading>
    </Box>
  );
}

const RenderFilter = () => {
  const view = [
    "brand",
    "category",
    "shipment",
    "attr",
    "price_from",
    "price_to",
  ];

  const handleChangeFilter = ({ name, value }) => {
      if (name) {
        ProductsActions.filter(name, value);
      }
  }

  const handleSelectAll = (evt) => {
    const checked = evt.target.checked;
    LightActions.setProductGroup("all", checked);
  }

  const handleRefreshFilter = () => {
    ProductsStore.clearFilter(true);
    SalesActions.loadReports();
    Actions.load(true);
  }

  return (
    <BaseFilterLayout
      selectAllSetting={{
        onChange: handleSelectAll,
        isChecked: LightStore.getIsChecked(),
      }}
      searchStringSetting={{
        onChange: handleChangeFilter,
        isStockFilter: true,
        defaultValue: ProductsStore.getFilter("search"),
      }}
      refresh={{
        onChange: handleRefreshFilter,
      }}
      sortSetting={{
        data: DATA_SORT,
        defaultActive: ProductsStore.getFilter("sort"),
        onChange: (value) => ProductsStore.onFilter("sort", value),
      }}
      filterView={{
        list: ProductsStore.get("_filter"),
        view,
        onDelete: ProductsStore.filterClearField,
      }}
      hiddenFilter={<ProductsFilter />}
    />
  );
}

const TopButtons = ({mid}) => {
  const {isMobileSize} = useIsMobile()
  const isNeedShipment =
      WarehouseStore.getWarehouseShipmentList().length > 0 &&
      User.getModuleEnable("shipments") &&
      User.getPermissionEnable("editinstock"),
    size_button = isMobileSize ? "small" : "medium";

  const importDropdownItems = [
    {
      link: { pathname: "/products/upload-excel", state: { mid: mid } },
      title: "load-from-excel",
    },
    {
      link: { pathname: "/products/upload-xml", state: { mid: mid } },
      title: "load-from-xml",
    },
  ];
  let exportDropdownItems = [
    {
      modal: <PriceModal />,
      title: "price-list",
    },
    {
      link: ProductsStore.getExportCsv(),
      title: "export-excel",
      target: "_blank",
    },
  ];
  if (mid !== "all") {
    exportDropdownItems.push({
      link:
        `${ProductsStore._prefix_remote_csv}get_xml_products?uid=` + User.getUserId() + "&mid=" + mid,
      title: "export-xml",
      target: "_blank",
    });
  }

  return (
    <Level className={isMobileSize ? "is-mobile text-align-center" : null}>
      {isMobileSize ? null : <Level.Side align="left"></Level.Side>}
      <Level.Side align="right">
        {isNeedShipment ? (
          <Level.Item>
            <OpenModalButton
              size={size_button}
              link="/shipments/add-shipment-base"
              text={T("purchase-of-items")}
              icon="plus-circle"
            />
          </Level.Item>
        ) : (
          ""
        )}
        {!User.getPermissionEnable("editinstock") ? null : (
          <Level.Item>
            <Button
              onClick={() =>
                AppStore.openModal(<AddProductsModal mid={mid} />)
              }
              size={size_button}
              rounded
              color="primary"
              className={
                User.getOnBoaringStep() === 2 ? "button_blink" : null
              }
            >
              <FontAwesomeIcon icon="plus-circle" />
              &nbsp;
              {T("add-new-product-btn")}
            </Button>
          </Level.Item>
        )}
        {isMobileSize || !User.getPermissionEnable("editinstock") ? null : (
          <Level.Item>
            <ButtonsDropdownList
              icon="plus-circle"
              className="import-products"
              text="import"
              items={importDropdownItems}
            />
          </Level.Item>
        )}
        {User.isEnableWoocommerce() && false ? (
          <Level.Item>
            <OpenInsideModal
              size={size_button}
              color="info"
              text={T("import-items")}
              icon="plus-circle"
            >
              <ImportItemsModal />
            </OpenInsideModal>
          </Level.Item>
        ) : null}
        <Level.Item>
          <ButtonsDropdownList
            className="export-products"
            local_icon="export-products"
            text="export"
            items={exportDropdownItems}
          />
        </Level.Item>
        {UserStore.getModuleEnable("settings") && (
          <Level.Item>
            <Link to="/settings/products">
              <LocalIcon icon="gear" size="large" />
            </Link>
          </Level.Item>
        )}
      </Level.Side>
    </Level>
  );
}

const TopButtonsMobile = ({mid}) => {
  const isNeedShipment =
      WarehouseStore.getWarehouseShipmentList().length > 0 &&
      User.getModuleEnable("shipments") &&
      User.getPermissionEnable("editinstock"),
    size_button = "small";

  let items = [];

  if (User.getPermissionEnable("editinstock")) {
    items.push(
      <Dropdown.Item key="load-from-excel" value="item">
        <OpenModalButton
          renderAs="a"
          size={size_button}
          link={{ pathname: "/products/upload-excel", state: { mid: mid } }}
          text={T("load-from-excel")}
          icon="plus-circle"
        />
      </Dropdown.Item>
    );
    items.push(
      <Dropdown.Item key="load-from-xml" value="item">
        <OpenModalButton
          renderAs="a"
          size={size_button}
          link={{ pathname: "/products/upload-xml", state: { mid: mid } }}
          text={T("load-from-xml")}
          icon="plus-circle"
        />
      </Dropdown.Item>
    );
  }

  if (isNeedShipment) {
    items.push(
      <Dropdown.Item key="purchase-of-items" value="item">
        <OpenModalButton
          renderAs="a"
          size={size_button}
          link="/shipments/add-shipment-base"
          text={T("purchase-of-items")}
          icon="plus-circle"
        />
      </Dropdown.Item>
    );
  }

  items.push(
    <Dropdown.Item key="btn-edit" value="item">
      <OpenInsideModal
        size={size_button}
        renderAs="a"
        color="info"
        text={T("price-list")}
        icon="tags"
      >
        <PriceModal />
      </OpenInsideModal>
    </Dropdown.Item>
  );

  items.push(
    <Dropdown.Item key="export-excel" value="item">
      {User.isMobileApp() ? (
        <Button
          className="button_link"
          onClick={() =>
            User.postMessage("open-url::" + ProductsStore.getExportCsv())
          }
        >
            <span className="margin-right-5">
              <FontAwesomeIcon icon="file-excel" size="1x" />
            </span>{" "}
          {T("export-excel")}{" "}
        </Button>
      ) : (
        <Link
          className="button_link is-info is-small is-rounded button"
          to={ProductsStore.getExportCsv()}
          target="_blank"
          title={T("export-excel")}
        >
            <span className="margin-right-5">
              <FontAwesomeIcon icon="file-excel" size="1x" />
            </span>{" "}
          {T("export-excel")}
        </Link>
      )}
    </Dropdown.Item>
  );

  if (mid !== "all") {
    items.push(
      <Dropdown.Item key="export-xml" value="item">
        {User.isMobileApp() ? (
          <Button
            className="button_link"
            onClick={() =>
              User.postMessage(
                `open-url::${ProductsStore._prefix_remote_csv}get_xml_products?uid=` +
                User.getUserId() +
                "&mid=" +
                mid
              )
            }
          >
              <span className="margin-right-5">
                <FontAwesomeIcon icon="file-excel" size="1x" />
              </span>{" "}
            {T("export-xml")}{" "}
          </Button>
        ) : (
          <Link
            className="button_link is-info is-small is-rounded button"
            to={
              `${ProductsStore._prefix_remote_csv}get_xml_products?uid=` +
              User.getUserId() +
              "&mid=" +
              mid
            }
            target="_blank"
            title={T("export-xml")}
          >
              <span className="margin-right-5">
                <FontAwesomeIcon icon="file-excel" size="1x" />
              </span>{" "}
            {T("export-xml")}
          </Link>
        )}
      </Dropdown.Item>
    );
  }

  return (
    <Level className="is-mobile">
      <Level.Side align="left">
        {UserStore.getModuleEnable("settings") && (
          <Level.Item>
            <Link to="/settings/products">
              <LocalIcon icon="gear" size="large" />
            </Link>
          </Level.Item>
        )}
        {!User.getPermissionEnable("editinstock") ? null : (
          <Level.Item>
            <Button
              size={size_button}
              rounded
              color="primary"
              className={
                User.getOnBoaringStep() === 2 ? "button_blink" : null
              }
              onClick={() =>
                AppStore.openModal(<AddProductsModal mid={mid} />)
              }
            >
              <FontAwesomeIcon icon="plus-circle" />
              &nbsp;
              {T("add-new-product-btn")}
            </Button>
          </Level.Item>
        )}
      </Level.Side>
      <Level.Side align="right">
        <Level.Item>
          <Dropdown
            className="dot"
            right
            label={<LocalIcon icon="ellipsis" />}
          >
            {items}
          </Dropdown>
        </Level.Item>
      </Level.Side>
    </Level>
  );
}

const RenderTabs = () => {
  const location = useLocation()
  const pathname = location.pathname;
  const splitPathname = pathname.split("/");

  const handleChangeTab = (tab) => {
    ProductsActions.filter("mid", { value: tab });
  }

  return (
    <DynamicTabList
      settingModal={
        (UserStore.isMainUser() || UserStore.isAdmin()) && AddWarehouseModal
      }
      propsSettingModal={{ edit: true }}
      addModal={
        (UserStore.isMainUser() || UserStore.isAdmin()) && AddWarehouseModal
      }
      list={WarehouseStore.getTabList()}
      onChangeTab={handleChangeTab}
      defaultTab={splitPathname[2]}
    />
  );
}

const RenderTopDesktop = ({statistics}) => {
  return (
    <>
      <RenderTabs/>
      <RenderFilter/>
      <ProductsTotal statistics={statistics} />
    </>
  );
}

const RenderTopMobile = ({statistics}) => {
  return (
    <>
      <ProductsTotal statistics={statistics} />
      <RenderTabs/>
    </>
  );
}


const Products = (props) => {
  const { isMobileSize } = useIsMobile()

  let className = "inventory-report";

  if (!User.getPermissionEnable("incomeinformation")) {
    COLUMNS = COLUMNS.filter(function (value, index, arr) {
      return (
        value !== "products-netcost" &&
        value !== "margin" &&
        value !== "sales"
      );
    });
  }


  if (!User.getPermissionEnable("salesprice")) {
    COLUMNS = COLUMNS.filter(function (value, index, arr) {
      return value !== "products-price" && value !== "margin";
    });
  }

  const filterMidValue = ProductsStore.getFilter("mid").value;
  const mid = filterMidValue === 0 ? "all" : filterMidValue;

  const productInfo = ProductsStore.getProductNew(true)
  const productState = productInfo.rows
  const statistics = productInfo.statistics


  productState.sort((a,b) => {
    const mapKey = {
      'products-name-date': 'id',
      'available': 'quantity',
      'sales': 'stock',
      'product-info-price': 'price',
      'products-netcost': 'net_price'
    }

    const sort = ProductsStore.getFilter('sort')
    for (const sortKey in sort) {
      const direction = sort[sortKey];

      let sumA
      let sumB

      const calcWarehouse = (stock, key) => {
        const result = stock.reduce((acc, cur) => {
          let value = parseFloat(cur[mapKey[key]])


          if(key === 'margin') {
            value = calcMargin(cur.net_price, cur.price, cur.sale_price)
            if(value === '-') {
              value = 0
            }
          } else if(key === 'sales') {
            const sales =  SalesStore.getSaleReportsByProduct(cur.product_id, cur.marketplace_id)
            value = sales.items_count
          }

          if(value) {
            acc.notNullCount += 1
          }


          acc.value += value || 0

          return acc
        }, {notNullCount: 0, value: 0})

        if(key === "available" && key === "sales") {
          return result.value
        }

        return (result.value / result.notNullCount) || 0
      }

      const calcSum = (values, key) => {
          const result = values.reduce((acc,cur) => {
            const value = calcWarehouse(Object.values(cur.stock), key)
            if(value) {
              acc.notNullCount += 1
            }
            acc.value += value || 0
            return acc
          } , {notNullCount: 0, value: 0})

        if(key === "available" && key === "sales") {
          return result.value
        }

        return (result.value / result.notNullCount) || 0
      }

      if(sortKey !== 'products-name-date') {
        if(Array.isArray(a)) {
          sumA = calcSum(a, sortKey)
        } else {
          sumA = calcWarehouse(Object.values(a.stock), sortKey)
        }

        if(Array.isArray(b)) {
          sumB = calcSum(b, sortKey)
        } else {
          sumB = calcWarehouse(Object.values(b.stock), sortKey)
        }

        if (direction === 1 ) {
          if (sumA < sumB) return 1;
          if (sumA > sumB) return -1;
        } else if (direction === 0) {
          if (sumA > sumB) return 1;
          if (sumA < sumB) return -1;
        }
      } else {
        if(Array.isArray(a)) {
          sumA = a[0].name
        } else {
          sumA = a.name
        }

        if(Array.isArray(b)) {
          sumB = b[0].name
        } else {
          sumB = b.name
        }

        if (direction === 1 ) {
          if (sumA > sumB) return 1;
          if (sumA < sumB) return -1;
        } else if (direction === 0) {
          if (sumA < sumB) return 1;
          if (sumA > sumB) return -1;
        }
      }
    }
    return 0
  })

  const location = useLocation()

  useEffect(() => {
    SalesActions.loadReports();
    ProductsStore.setCountPerPage(20);

    const pathname = location.pathname;
    const splitPathname = pathname.split("/");
    const defaultTab = splitPathname[2];
    if (
      WarehouseStore.getTabList()?.some(
        (item) => `${item.value}` === defaultTab
      )
    ) {
      if (defaultTab) {
        Actions.filter("mid", { value: Number(defaultTab) });
      } else {
        Actions.filter("mid", { value: 0 });
      }
    }

    return () => {
      const currentPath = window.location.pathname;
      if (currentPath !== "/products" && !currentPath.startsWith("/products/")) {
        ProductsStore.clearFilter();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {end,page,setPage,PaginationBlock,start,total} = usePaginationHook({listLength: productState.length})


  const handleCheckAll = (evt) => {
      const checked = evt.target.checked
      LightActions.setProductGroup("all", checked);
  }

  return (
    <div className={`products-page ${isMobileSize ? '' : 'fix-box-product'}`}>
      {isMobileSize
        ? <RenderTopMobile statistics={statistics}/>
        : <RenderTopDesktop statistics={statistics} />}
      <Box className={className}>
        {isMobileSize
          ? <TopButtonsMobile mid={mid} />
          : !LightStore.isGroupSettings() && <TopButtons mid={mid}/>}
        <MassButton
          view={LightStore.isGroupSettings()}
          count={LightStore.get("count")}
          onDeSelect={LightStore.clearSelectGroup}
        >
          <GroupButtonList />
        </MassButton>
        <div style={isMobileSize ? {} : {overflow: 'auto'}}>
          {isMobileSize && <RenderFilter/>}
          <PaginationBlock
            totalPage={total}
            setPage={setPage}
            currentPage={page}
            showPrevNext={false}
          />

          <Reports
            {...props}
            emptyMessage={EmptyMessage}
            columns={COLUMNS}
            isSelectAll={!!productState.length}
            isSortingColumns={[true, true, true, true, true, true]}
            selectAllValue={LightStore.getIsChecked()}
            selectAllChange={handleCheckAll}
            isLoading={ProductsStore.loading}
            sortActions={(value) => ProductsStore.onFilter("sort", value)}
            hideFooterOnEmpty
            minWidthList={COLUMNS.length * 213}
            rows={productState.slice(start, end)}
          >
            <NewWrapperProductRow role='row'/>
          </Reports>

          <PaginationBlock
            totalPage={total}
            setPage={setPage}
            currentPage={page}
            showPrevNext={false}
          />
        </div>

        {props["openModal"] === "add"
          ? OpenModalFactory(
            <AddProductsModal
              modal="product-modal"
              barcode={props["barcode"]}
            />,
            ProductsStore.closeModal
          )
          : ""}
      </Box>
    </div>
  );
}

export default connectToStores(Products, { light: LightStore });
