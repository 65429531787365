import React from "react";
import ProductsStore from "../../../products/products-store";
import calcMargin from "../../../../tools/calc-margin";
import AppStore from "../../../../app-store";
import { Button, Columns } from "react-bulma-components";
import T from "../../../../components/i18n";
import WarehouseStore from "../../../warehouse/warehouse-store";
import Money from "../../../../components/money";
import DOM from "react-dom-factories";
import User from "../../../../user/user-store";
import ViewProductModal
  from "../../../products/modal/view-product/view-product-modal";

const SaleProductRow = ({ data, accountID }) => {
  const productInfo = ProductsStore.getProductByMid(
    data["product_id"],
    data["mid"]
  );

  const productName = productInfo?.name || "";
  const margin = calcMargin(data["net_price"], data["amount"]);

  const deleteComponent = (
    <span className="has-text-danger"> {T("product_deleted")} </span>
  );
  const textDeleted =
    data?.product_deleted === 1 || !productInfo ? deleteComponent : "";

  return (
    <div className="display-flex-column">
      <div className="display-flex-row">
        <img
          alt={productInfo.name}
          src={ProductsStore.makeImageUrl(data["product_id"], 0, 32)}
          style={{ height: 32 }}
        />
        <div className="display-flex-column" style={{ flex: 1 }}>
          <Button
            onClick={() =>
              AppStore.openModal(<ViewProductModal pid={data["product_id"]} />)
            }
            className="button-link-without-style"
            style={{ justifyContent: "flex-start" }}
          >
            {productName} {textDeleted}
          </Button>
          {WarehouseStore.getWarehouseName(data["mid"])} |{" "}
          {ProductsStore.getCategoryName(productInfo["category_id"])}{" "}
          {productInfo["sku"] ? "| " + productInfo["sku"] : null}
        </div>
      </div>

      <Columns>
        {User.getPermissionEnable("incomeinformation") && (
          <Columns.Column>
            <div>
              <span className="silver">{T("netcost")}: </span>
              <Money
                title={T("commission") + ": " + (data["tax"] || 0)}
                amount={(data["net_price"] || 0) + (data["tax"] || 0)}
                wrapper={DOM.span}
              />
            </div>
            {User.getPermissionEnable("salesprice") && (
              <div>
                <span className="silver">{T("netprice-price")}: </span>
                <Money
                  aid={accountID}
                  amount={data["amount"] || 0}
                  wrapper={DOM.span}
                />
              </div>
            )}

            <div>
              <span className="silver">{T("sales-profit")}: </span>
              <b>
                <Money
                  signClassname={true}
                  aid={accountID}
                  amount={data["profit"] || 0}
                  wrapper={DOM.span}
                />
              </b>
              ({margin}%)
            </div>
          </Columns.Column>
        )}
        <Columns.Column>
          <div className="nowrap">
            <span className="silver">{T("quantity")}: </span>{" "}
            {ProductsStore.getStockUnits(
              data["quantity"],
              productInfo["units"]
            )}
          </div>
          {!!data?.discount && (
            <div>
              <span className="silver">{T("sales-discount")}: </span>
              {data?.discount}% <br />
            </div>
          )}
          {!!data["tax"] && (
            <div>
              <span className="silver">{T("bank_comission")}: </span>
              <Money amount={data["tax"]} wrapper={DOM.span} /> <br />
            </div>
          )}
          {!!data["channel_tax"] && (
            <div>
              <span className="silver">{T("commission-sales-chanel")}: </span>
              <Money amount={data["channel_tax"]} wrapper={DOM.span} />
            </div>
          )}
        </Columns.Column>
      </Columns>
    </div>
  );
};

export default SaleProductRow;
