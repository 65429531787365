import React from 'react'
import FacebookLogin from "react-facebook-login";
import AppStore from "../../../../../app-store";
import UserActions from "../../../../../user/user-actions";

const FacebookButton = ({wooID}) => {
	const responseFacebook = (response) => {
		if (response.accessToken) {
			let data = {
				integration_id: wooID,
				api_key: response.accessToken,
				userID: response.userID,
				ident: JSON.stringify({
					userID: response.userID,
				}),
			};

			UserActions.updateIntegrations(data);
		}
	};

	return (
		<FacebookLogin
			appId={AppStore.isEnableForWhiteLable("facebook-app")}
			autoLoad={false}
			fields="name,email,picture"
			cssClass="my-facebook-button-class"
			scope="business_management,instagram_basic,pages_read_engagement,pages_show_list,pages_messaging,pages_manage_metadata,instagram_manage_messages"
			callback={responseFacebook}
			icon="fa-facebook"
		/>
	);
};

export default FacebookButton;