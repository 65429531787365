import React from 'react'
import UserStore from "../../user/user-store";
import yajax from "yajax";
import trackEvent from "../../tools/track-events";
import UserActions from "../../user/user-actions";
import {GOOGLE_API} from "../../configs/wl-name/api";
import {GoogleLogin, GoogleOAuthProvider} from "@react-oauth/google";
import jwt_decode from "jwt-decode";

const GoogleButton = () => {
	const submit = (response) => {
		try {
			const decode = jwt_decode(response.credential)

			const data = {
				'email': decode['email'],
				'name': decode['name'],
				'userID': decode['nbf'],
				'accessToken': decode['aud'],
				'graphDomain': 'google.com'
			}

			const params = {
				method: "POST",
				url: "/userprofile/social",
				headers: {
					"Content-type": "application/x-www-form-urlencoded"
				},
	 			data: {"lang": UserStore.getLang(), "currency_id": UserStore.get("currency_id"), "social_network": JSON.stringify(data)}
			};

			const partner_id = window.localStorage.getItem('partner_id');
			if (partner_id) {
				params.data['partner_id'] = partner_id;
			}

			return yajax(params).then((data) => {
				if (data.success) {
					if (data.first) {
						trackEvent('users', 'registration', 'google', '1');
						UserStore.setFirstReg(true);
					} else {
						UserStore.setFirstReg(false);
					}
					UserActions.changeToken(data.token, true);
					window.localStorage.removeItem('partner_id');
				}
			});
		} catch (err) {
			console.log(err)
		}
	}


	return (
		<div align='center'>
			<GoogleOAuthProvider clientId={GOOGLE_API} >
				<GoogleLogin
					onSuccess={submit}
					onError={() => {
						console.log('Login Failed');
					}}
					/>
			</GoogleOAuthProvider>
		</div>

	);
};

export default GoogleButton;