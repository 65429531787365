import {useEffect, useState} from "react";
import UserActions from "../../../../../user/user-actions";
import {errorHandler} from "../../../../../tools/error-handler";
import UserStore from "../../../../../user/user-store";

const IntegrationListenerHook = ({handleError, setWaiting}) => {
	const [submitOK, setSubmitOK] = useState('')
	const [additionalMessage, setAdditionalMessage] = useState('')

	useEffect(() => {
		const listenAdd = UserActions.updateIntegrations.completed.listen((data) => {
			setWaiting(false)

			if(data?.message && typeof data.message === 'object') {
				const getLang = UserStore.getLang()
				const localesMessage = data.message[getLang || 'ua']
				if(localesMessage) {
					setAdditionalMessage(localesMessage)
				}
			}

			if(data?.error) {
				handleError(data.error)
			} else {
				if(data?.is_active === 0) {
					setSubmitOK('deactivate-integration-completed-title')
				} else {
					setSubmitOK('add-integration-completed-title')
				}
			}
		})

		const listenAddFailed = UserActions.updateIntegrations.failed.listen((res) => {
			setWaiting(false)
			const message = errorHandler(res)
			handleError(message)
		})

		const listenDelete = UserActions.deleteIntegration.completed.listen(() => {
			setWaiting(false)
			setSubmitOK('delete-integration-completed-title')
		})

		const listenDeleteFailed = UserActions.deleteIntegration.failed.listen((res) => {
			setWaiting(false)
			const message = errorHandler(res)
			handleError(message)
		})

		return () => {
			listenAdd()
			listenAddFailed()
			listenDelete()
			listenDeleteFailed()
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {submitOK, additionalMessage}
}

export default IntegrationListenerHook