// import React from "react";
// import axios from 'axios';
import yajax from "yajax";
import T from "components/i18n";

// import moment from "moment";
import { createStore } from "../../tools/reflux-tools";
import Actions from './orders-actions';
import ClientsActions from '../clients/clients-actions';
import ProductsActions from '../products/products-actions';
import SalesStore from '../sales/sales-store';

import User from 'user/user-store';
import {filterSearch} from "../../tools/filters-helper";
import {getDatePeriod} from "../../tools/date-range";
// import { filterSearch } from 'tools/filters-helper';
import UserStore from "user/user-store";
import {parseJSON} from "../../tools/error-handler";

const CLEAR_FILTER = {
    status: "saled",
    clients: {value: 0},
    integration: {value: 0},
    payment_type: {value: 0},
    date_start: getDatePeriod(14),
    date_end: new Date(),
}

const OrdersStore = createStore({
	listenables: Actions,
    _prefix: "/bremote/",
    default_status: ['completed', 'cancelled'],
    tabs: {'pending': "processing"},
    state: {
        rows: [],
        refundRows: [],
        _filter: CLEAR_FILTER,
        row_reserve_orders: null,
        row_reserve_sales: null,
        row_waiting_shipment: null,
    status: null,
    waiting: null,
    statuses: {},
		count: 0,
    dateInit: new Date(),
	  },
    complete_status: {
        [User?.WOO_ID]: 'completed',
        [User?.PROM_ID]: 'received',
        [User?.RZ_ID]: 'completed',
    },
    cancel_status: {
        [User?.WOO_ID]: 'cancelled',
        [User?.PROM_ID]: 'canceled',
        [User?.RZ_ID]: 'cancelled',
    },
    isLoadedStatus: false,
    isLoaded: false,
    failedLoad: 0,
    loadTimeout: null,


    getInitialState() {
        return this.state;
    },

    get(field) {
        return this.state[field];
    },

    clearFilter () {
        this.setState({
            _filter: {
                status: "saled",
                clients: {value: 0},
                integration: {value: 0},
                date_start: getDatePeriod(14),
                date_end: new Date(),
                payment_type: {value: 0},
            },
        })
    },

    onFilter(name, value) {
        let filters = {};

        for (let i in this.state['_filter']) {
            filters[i] = this.state['_filter'][i];
        }

        let date_start = filters['date_start'];

        filters[name] = value;

        this.setState({
            _filter: filters
        });

        if (name === "date_start" && value < date_start) {
            Actions.load();
            Actions.loadRefund()
        }
    },
    getCountDelivery() {
        return this.get("count");
    },

    getOrders() {
        const orders = this.get("rows");
        if (orders === null) {
            Actions.load();
            return [];
        }
        return orders.filter(this.applyFilter);
    },

    getOrderById(id) {
        const orders = this.get("rows");
        if (orders === null) {
            Actions.load();
            return [];
        }

        let order =  orders.filter((order) => parseInt(order['id']) === parseInt(id));
        return order.length > 0 ? order[0] : {}
    },

    getStatusesValueLabelByIds(ids, getField, withReserv) {
        const idsString = ids &&  typeof ids === "string" ? ids : JSON.stringify(ids || {})
        if(idsString ===  "{}" || idsString === '[0]') {
            const data = {value: 0, label: T('all')}
            if(getField) {
                return [data[getField]]
            } else {
                return data
            }
        }


        const getStatuses = SalesStore.getAllStatusesList([], withReserv)
        const filterStatus = getStatuses.filter(status => ids.some(id => status.id === id))

        return filterStatus.map(item => {
            const data = {value: item.id, label: T(item.name)}
            if(getField) {
                return data[getField]
            } else {
                return data
            }
        })
     },

    applyFilter(order, ignoreStatus) {
        const filter = this.state._filter,
              date = new Date(order['date_created']);
        let result = true;

        const dayNow = new Date()
        const checkDayNow = dayNow.getDate()
        const checkDayNowFilter = this.get('dateInit').getDate()

        if(checkDayNow !== checkDayNowFilter) {
            Actions.filter('date_end', dayNow)
            this.setState({dateInit: dayNow})
            return  false
        }

        filter['date_start'].setHours(0,0,0,0);
        filter['date_end'].setHours(23,59,59,999);

        if (date < filter['date_start'] || date > filter['date_end']) {
            return false;
        }

        if (filter['search'] !== "" && typeof(filter['search']) !== "undefined") {
            let search_data = {
                "first_name": order['first_name'],
                "last_name": order['last_name'],
                "order_id": order['order_id'],
                "phone": order['phone'],
                "id": order['id'],
                pids: ''
            };
            order.order_data.forEach(item => {
                if(item.local_product_id) {
                    search_data.pids += ` ${item.local_product_id}`;
                }
            })

            result = filterSearch(filter['search'], ['first_name', 'last_name','phone', 'id', 'order_id', 'pids'], search_data);
        }


        if(ignoreStatus !== 'refund') {
            if (filter['status'] !== "all") {
                const status = order['local_status'] ? order['local_status'] : order['status'];

                if (filter['status'] !== status){
                    return false;
                }
            }
        }

        // if(filter['payment_type'] && filter['payment_type']?.value !== 0) {
        //     result = result && order.order_data.some(item => item.payment_type === filter['payment_type']?.value)
        // }

        if (filter['clients'] && filter['clients']['value'] !== 0) {
            if (parseInt(filter['clients']['value']) !== parseInt(order['client_id'])) {
                result = result && false;
            }
        }

        if (filter['integration'] && filter['integration']['value'] !== 0) {
            if (parseInt(filter['integration']['value']) !== parseInt(order['integration_id'])) {
                result = result && false;
            }
        }



        return result;
    },

    onLoad(force) {
        clearTimeout(this.loadTimeout);
        // console.log('before', this.isLoaded,this.loadTimeout, '-------=========----------')
        if (this.isLoaded) {
            console.log("already start loading orders")
            return this.isLoaded;
        }
        // console.log('after', this.isLoaded,this.loadTimeout, '-------=========----------')


        let url = this._prefix+"remote_orders";

        console.log("start load orders...")

        const filter = this.get('_filter')
        const date = {}

        if (filter['date_start']) {
            const date_start = filter['date_start'].setHours(0, 0, 0, 0);
            date.start = parseInt(`${date_start / 1000}`)
        }

        if (filter['date_end']) {
            const date_end = filter['date_end'].setHours(23, 59, 59, 999);
            date.end = parseInt(`${date_end / 1000}`);
        }

        this.isLoaded = yajax.get(url, date).then(Actions.load.completed, Actions.load.failed);

        return this.isLoaded;

    },

    getPaymentTypeForSelect() {
        const paymentTypeRow = this.get('payment_row')

        return paymentTypeRow?.length > 1 ? paymentTypeRow.map(item => ({name: item, id: item})) : null
    },

    onLoadCompleted(data) {
        clearTimeout(this.loadTimeout);
        this.isLoaded = false;

        let statuses = {
            'all': {count: 0, tab: 'all', label: 'all'},
            'processing': {'count': 0, 'tab': 'processing', 'label': 'processing'}
        };

        const payment_type = new Set();

        for (let i in data) {
            let order = data[i],
            status = order['local_status'] ? order['local_status'] : order['status'];

            if(order.info && typeof order.info === 'string') {
                data[i]['info'] = JSON.parse(order['info']);
            }

            if (order['order_data']) {
                data[i]['order_data'] = JSON.parse(order['order_data']);

                payment_type.add(data[i]['order_data'][0]?.payment_type)
            } else {
                data[i]['order_data'] = {};
            }

            let tab = typeof(this.tabs[status]) !== "undefined" ? this.tabs[status] : status;

            order['status'] = tab;

            const statusInfo = this.getStatusById(tab)

            if (typeof(statuses[tab]) === "undefined") {
                statuses[tab] = {
                    'count': 1,
                    'tab': tab,
                    'label': statusInfo?.name || tab
                }
            } else {
                statuses[tab]['count']++;
            }

            statuses.all["count"]++
        }
        if (!!this.state.rows.length && this.state.rows.length !== data.length) {
            ClientsActions.loadDelivery(true);
            ProductsActions.load();
            ClientsActions.load(true)
            Actions.loadReserveWaiting()
        }
        this.failedLoad = 0
        this.loadTimeout = setTimeout(Actions.load, 1000*60*5);

        this.setState({"rows": data, 'statuses': statuses, "waiting": false, payment_row: Array.from(payment_type)})


        console.log("complete loaded");
    },

    onLoadFailed(data) {
        clearTimeout(this.loadTimeout);
        this.isLoaded = false;

        if(this.failedLoad <= 3) {
            this.loadTimeout = setTimeout(Actions.load, 3000);
        } else if(this.failedLoad > 3 && this.failedLoad <= 7) {
            this.loadTimeout = setTimeout(Actions.load, 5000);
        } else if(this.failedLoad > 7) {
            this.loadTimeout = setTimeout(Actions.load, 1000 * 60)
        }

        this.failedLoad = this.failedLoad + 1
    },

    getTabs() {
        const statuses = this.get("statuses")
        const statusesList = Object.keys(statuses)

        statusesList.sort((a, b) => {
            if (a === "all") {
                return -1;
            } else if (b === "all") {
                return 1;
            } else if (a === "processing") {
                return -1;
            } else if (b === "processing") {
                return 1;
            } else {
                return 0;
            }
        });

        const data = statusesList.map(item => ({
            label: statuses[item].label, value: statuses[item].tab, count: statuses[item].count
        }))

        data.splice(2, 0, {
            label: 'status-cancelled', value: 'refund', count: this.get('refundRows').length
        });

        return data
    },

    onUpdateStatusOrder(data) {
        let integ = User.getIntegrationNameById(data['iid']);
        let status = data['status'];

        if (status === "completed") {
            data['status'] = this.getCompetedStatus(integ['integration_id']);
        } else if (status === "cancelled") {
            data['status'] = this.getCancelStatus(integ['integration_id']);
        }

        return yajax.post(this._prefix+"remote_orders", data).then(
            Actions.updateStatusOrder.completed, Actions.updateStatusOrder.failed
        );
    },

    onUpdateStatusOrderCompleted() {
        console.log("completed update status");
        Actions.loadRefund()
    },

    onUpdateStatusOrderFailed() {

    },

    getCompetedStatus(iid) {
        console.log("get complete_status", iid, this.complete_status[parseInt(iid)]);
        return this.complete_status[iid] || 'completed'
    },

    getCancelStatus(iid) {
        console.log("get cancel status", iid, this.cancel_status[parseInt(iid)]);
        return this.cancel_status[iid] || 'cancelled'
    },

    onDelete(data) {
        const params = {
          method: "DELETE",
          url: this._prefix+"remote_orders",
          data: data
        };

        return yajax(params).then(Actions.delete.completed, Actions.delete.failed);
    },

    onDeleteCompleted() {
        console.log("complete delete order");
        ProductsActions.load();

    },

    onDeleteFailed() {

    },

    prepareStatus(data) {
        let result = [];
        const permissionStatus = UserStore.getMoreSetting('statuses')
        for (let i in data) {
            let d = data[i];
            if(d?.types === 'sales' || typeof d === 'string') {
                const id = typeof d === 'string' ? d : d.id
                if(!!permissionStatus && permissionStatus[0] !== 0 && !permissionStatus?.includes(id)) {
                    continue
                }
            }

            let name = typeof(d['name']) !== "undefined" ? d['name'] : "status-"+d;


            let val = typeof (d['id']) !== "undefined" ? d['id'] : d;

            const status = {
                'id': val,
                'name': name
            }
            if (d['types']) {
                status['type'] = d['types'];
            };
            if (d['remote_id']) {
                status['remote_id'] = d['remote_id'];
                let add_name = T('remote');
                if (d['integration_id']) {
                    let channel_id = SalesStore.foundChannelsByIntegrationId(d['integration_id']);
                    if (channel_id) {
                        add_name = SalesStore.getChannelById(channel_id)?.name;
                    }
                }

                status['name'] += " (" +add_name+")"
            };
            result.push(status)
            // let val = typeof(d['name']) !== "undefined" ? d['name'] : d;
            // result.push({'id': val, 'name': name})
        }

        return result;
    },

    getStatusList(statuses) {
        const status = this.get("status");
        let default_status = this.prepareStatus(statuses || this.default_status);

        if (status === null) {
            Actions.loadStatus();
            return default_status;
        }

        let result = default_status.concat(status);
        return result;
    },

    getStatusById(id) {
        const status = this.get("status");
        return status?.find(item => item.id === +id)

    },

    getLocalStatusesOnly() {
        let result = [];
        const allItems = this.getStatusList();
        for (let i in allItems) {
                if (!this.default_status.includes(allItems[i].id)) {
                    result.push(allItems[i]);
                }
        }
        return result
    },

    getSalesCustomStatuses() {
        let result = [];
        const allItems = this.getLocalStatusesOnly();
        for (let i in allItems) {
            if (!allItems[i].remote_id && allItems[i].type !== "orders") {
                result.push(allItems[i]);
            }
            // if (allItems[i].type === "sales") {
            //     result.push(allItems[i]);
            // }
        }
        return result
    },

    getOrdersStatuses() {
        let result = [];
        const allItems = this.getStatusList();
        for (let i in allItems) {
            if (allItems[i].type !== "sales") {
                result.push(allItems[i]);
            }
            // if (allItems[i].type === "orders") {
            //     result.push(allItems[i]);
            // }
        }
        return result
    },

    getStatusesListsForCatalog() {
        let commonStatuses = [];
        let salesStatuses = [];
        let ordersStatuses = [];

        const allItems = this.getLocalStatusesOnly();
        for (let i in allItems) {
            let status = allItems[i];
            if (status.type === "orders") {
                !status.remote_id && ordersStatuses.push(status);
            } else if (status.type === "sales") {
                salesStatuses.push(status);
            } else {
                commonStatuses.push(status);
            }
        }

        const result = [
            {
                label: "sales-statuses",
                list: salesStatuses.map(item => ({name: item.name, id: item.id})),
                type: "sales"
            },
            {
                label: "orders-statuses",
                list: ordersStatuses.map(item => ({name: item.name, id: item.id})),
                type: 'orders'
            }
        ]

        if(!!commonStatuses.length) {
            result.unshift(            {
                label: 'common-statuses',
                list: commonStatuses.map(item => ({name: item.name, id: item.id})),
                type: 'common'
            })
        }

        return result

    },

    getStatusNameById(id) {
        let statuses = this.getLocalStatusesOnly();
        const statusFound = statuses.find(status => parseInt(status.id) === parseInt(id));
        if (statusFound) {
            return statusFound.name
        } else {
            return false
        }
    },

    onLoadStatus() {
        if (this.isLoadedStatus) return;

        this.isLoadedStatus = true;
        console.log("start load status products")
        return yajax.get(this._prefix+"status").then(Actions.loadStatus.completed, Actions.loadStatus.failed);
    },

    onLoadStatusCompleted(data) {
        // console.log("completed loading status ", data['data']);

        this.setState({
            status: this.prepareStatus(data['data']),
        });
        this.isLoadedStatus = false;
    },

    onLoadStatusFailed() {
        this.isLoadedStatus = false;
        console.log("failed load status");
    },

    onAddStatus(data) {
        console.log("start add product status", data)
        return yajax.post(this._prefix+"status", data).then(
            Actions.addStatus.completed, Actions.addStatus.failed
        );
    },
    onAddStatusCompleted(data) {
        this.setState({
            status: this.prepareStatus(data['data']),
        })
    },
    onAddStatusFailed(){
        console.log("failed add status")
    },

    onDeleteStatus(id) {
        console.log("start delete status", id)
        const params = {
          method: "DELETE",
          url: this._prefix+"status",
          data: {'id': id}
        };

        return yajax(params).then(
            Actions.deleteStatus.completed, Actions.deleteStatus.failed
        );
    },
    onDeleteStatusCompleted(data) {
        this.setState({
            status: this.prepareStatus(data['data']),
        })
        // Actions.loadStatus();
    },
    onDeleteStatusFailed(){
        console.log("failed delete status")
    },

    onBinding(data) {
        console.log("start Binding", data)
        return yajax.post(this._prefix+"binding", data).then(
            Actions.binding.completed, Actions.binding.failed
        );
    },
    onBindingCompleted(data) {
        console.log("result Binding: ", data)
       Actions.refreshOrders();
    },
    onBindingFailed(){
        console.log("failed Binding")
    },

    onManualPutProduct(data) {
        yajax.post(this._prefix+"manual_upload_to_marketplace", data).then(() => Actions.manualPutProduct.completed(data.pid), Actions.manualPutProduct.failed)
    },
    onManualPutProductCompleted(pid) {
        ProductsActions.loadByPid([pid])
        Actions.refreshOrders();
    },
    onManualPutProductFailed() {},


    onDeleteBinding(data) {
        console.log("start delete Binding", data)
        const params = {
            method: "DELETE",
            url: this._prefix+"binding",
            data
        };

        return yajax(params).then(
          Actions.deleteBinding.completed, Actions.deleteBinding.failed
        );
    },
    onDeleteBindingCompleted(data) {
      console.log("remove binding completed")
      Actions.refreshOrders();
    },
    onDeleteBindingFailed() {
      console.log("remove binding filed")
    },

    onRefreshOrders() {
        this.setState({"waiting": true});
        console.log("start refresh orders")


        return yajax.post(this._prefix+"refresh-orders").then(
            Actions.refreshOrders.completed, Actions.refreshOrders.failed
        );
    },
    onRefreshOrdersCompleted(data) {
        setTimeout(() => {Actions.load(); ProductsActions.load();}, 3000);
        console.log("result refresh orders status: ", data)
    },
    onRefreshOrdersFailed(){
        console.log("failed refresh orders")
        this.setState({"waiting": false});
    },

    onLoadReserveWaiting() {
      yajax.get(this._prefix + 'get-reserve').then(
        Actions.loadReserveWaiting.completed, Actions.loadReserveWaiting.failed
      )
    },

    onLoadReserveWaitingCompleted(data) {
      if(data?.success) {
          this.setState({
              row_reserve_orders: data.orders,
              row_reserve_sales: data.sales,
              row_waiting_shipment: data.shipment
          })
      }
    },
    onLoadReserveWaitingFailed() {
        console.log('load filed reserve and waiting')
    },

    getReserveWaitingByPid(dict,list, pid) {
      if(list && dict && pid) {
          const rowList = this.get(`row_${dict}_${list}`)

          if(rowList && rowList[pid]) {
              return rowList[pid]
          }
      }
        return []
    },

    getIsReserveOrdersByOidPid(oid, pid) {
      const getList = this.getReserveWaitingByPid('reserve', 'orders',pid)

      if(Array.isArray(getList)) {
          return getList.find(item => item.order_id === oid)
      }

      return false
    },

    onDeleteSuperfluousReserv({pid, mid, instock}) {
        const data = {
            pid,
            mid,
            instock
        }


        yajax.post(this._prefix + 'get-reserve', data).then(
          Actions.deleteSuperfluousReserv.completed, Actions.deleteSuperfluousReserv.failed
        )
    },
    onDeleteSuperfluousReservCompleted() {

    },
    onDeleteSuperfluousReservFailed() {

    },

    onAddOrder(data) {
        yajax.put(this._prefix + 'remote_orders', JSON.stringify(data)).then(
          Actions.addOrder.completed, Actions.addOrder.failed
        )
    },
    onAddOrderCompleted() {
      console.log('add order completed')
        Actions.load()
    },
    onAddOrderFailed() {
      console.log('add order failed')
    },

    onLoadRefund() {
        if (this.loadingRefund) return;

        this.loadingRefund = true

        const filter = this.get('_filter')
        const data = {}

        if (filter['date_start']) {
            const date_start = filter['date_start'].setHours(0, 0, 0, 0);
            data.start = parseInt(`${date_start / 1000}`)
        }

        if (filter['date_end']) {
            const date_end = filter['date_end'].setHours(23, 59, 59, 999);
            data.end = parseInt(`${date_end / 1000}`);
        }

        if(!!filter['clients'].value) {

        }

        if(!!filter['integration'].value) {
            console.log(filter['integration'].value)
        }

        yajax
          .get(this._prefix +'refund', data)
          .then(Actions.loadRefund.completed, Actions.loadRefund.failed);

    },
    onLoadRefundCompleted(data) {
      this.loadingRefund = false

      if(data?.success) {
          this.setState({
              refundRows: data.data.map(item => {
                  item.order_data = parseJSON(item.order_data)
                  return item
              })
          })
      }


    },
    onLoadRefundFailed() {
      this.loadingRefund = false
    },

    onDeleteReserve(data) {
      yajax.post("/bproducts/unreserv_all", JSON.stringify(data)).then(Actions.deleteReserve.completed, Actions.deleteReserve.failed);
    },

  onDeleteReserveCompleted(data) {
    Actions.loadReserveWaiting();
    const getProductId = data.data.map(item => item.product_id)

    if(getProductId && getProductId.length) {
      ProductsActions.loadByPid(getProductId);
    }
  },
    onDeleteReserveFailed() {
    }
});

export default OrdersStore;
