import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Control,
  Field,
  Input, Label,
} from "react-bulma-components/lib/components/form";
import T from "../../../../../components/i18n";
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import FiscalizeTypeCheckbox from "../common/fiscalize-type-checkbox";
import AdditionalField from "../common/additional-field";
import ErrorMessageBlock from "../../../../../components/error-message-block/error-message-block";
import UserActions from "../../../../../user/user-actions";
import { confirmDelete } from "../../../../../tools/confirm-delete";
import ModalItsOK from "../../../../../components/modal-its-ok";
// import {errorHandler} from "../../../../../tools/error-handler";
import FiscalizeAccountType from "../../components/fiscalize-account-type";
import { getActiveType } from "./utils";
import ActionsModal from "../common/actions-modal";
import IntegrationListenerHook from "../common/integration-listener.hook";
import IntegrationControlHook from "../common/integration-control.hook";
import { parseObj } from "../utils";
import RadioButtonsListControl
  from "../../../../../components/radio-buttons-list/radio-button-list-control";
import InfoWrapper from "../../../../../components/info-wrapper";
import NumberInput from "../../../../../components/number-input/number-input";

const DATA_INPUT = [
  {
    name: "login",
    placeholder: "ch_login-placeholder",
  },
  {
    name: "pass",
    placeholder: "ch_pass-placeholder",
  },
  {
    name: "licence",
    placeholder: "cashdesk-licence-placeholder",
  },
  {
    name: 'tax_literal',
    placeholder: 'tax-literal',
    label: 'tax-literal'
  },
];

const DATA_RADIO = [
  {
    title: 'type-account',
    value: 'system'
  },
  {
    title: 'personal',
    value: 'local'
  },
  {
    title: "together",
    value: "all"
  }
]

const CheckboxModal = ({ id, is_active,data, onClose, ident }) => {
  const identParse =
    typeof ident === "string" ? parseObj(ident) : ident ? ident : {};

  const { activeType, defaultValue } = getActiveType(
    identParse?.mid,
    identParse.rid
  );
  const [typeFiscalize, setTypeFiscalize] = useState(activeType || ["all"]);

	const [accountTypeValue, setAccountTypeValue] = useState(identParse?.account_type || 'cash')

  const {
    inputValues,
    handleChangeInputValues,
    checkboxValue,
    setCheckboxValue,
    selectValue,
    handleChangeSelect,
    setSelectValue,
    errorMessage,
    handleAutoRemoveError,
    setErrorMessage,
    radioValue,
    handleChangeRadio
  } = IntegrationControlHook({
    inputDefaultValue: {
      login: identParse?.login || data?.api_key ||  "",
      pass: identParse?.pass || data?.api_pass ||  "",
      licence: identParse?.licence || "",
      tax_literal: identParse?.tax_literal || "",
      position: identParse?.position || ''
    },
    selectDefaultValue: defaultValue || null,
    checkboxDefaultValue: {
      is_auto: !!identParse?.is_auto || false,
      received_cod: !!identParse?.received_cod || false,
      variable_attrs: !!identParse?.variable_attrs || false
    },
    radioDefaultValue: {
      account_name: identParse?.account_name || 'system'
    }
  });

  const [waiting, setWaiting] = useState(false);
  const { submitOK, additionalMessage } = IntegrationListenerHook({
    handleError: handleAutoRemoveError,
    setWaiting,
  });

  const handleChangeCheckbox = (evt) => {
    const name = evt.target.name;
    const checked = evt.target.checked;
    if (name === "received_cod" && checked) {
      setCheckboxValue({
        [name]: checked,
        is_auto: checked,
      });
    } else {
      setCheckboxValue((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    }
  };

  const handleError = () => {
    let error = "";
    const checkedInputValue = !Object.entries(inputValues).every(
      ([key, item]) => key === 'tax_literal' || !!item
    );

    if (checkedInputValue) {
      error = T("not all fields required");
    }

    if (error) {
      handleAutoRemoveError(error);
      return false;
    }

    return true;
  };

  const handleSubmit = ({ deactivate }) => {
    const data = {
      integration_id: 4,
      checkbox: {
        ...inputValues,
        is_auto: Number(checkboxValue.is_auto),
        received_cod: Number(checkboxValue.received_cod),
        variable_attrs: checkboxValue.variable_attrs || false,
        account_type: accountTypeValue,
        mid: [0],
        rid: [0],
        is_active: 1,
      },
    };
    if (selectValue) {
      const keysSelect =
        selectValue &&
        Object.keys(selectValue).filter((item) =>
          typeFiscalize.some((type) => item === type)
        );

      if (keysSelect) {
        keysSelect.forEach(
          (item) => {
            if(selectValue[item] && Array.isArray(selectValue[item])) {
              data.checkbox[item] = selectValue[item].map(
                ({ value }) => value)
            }
          }
        );
      }
    }
    if(radioValue) {
      const keysRadio = Object.keys(radioValue)
      if(!!keysRadio?.length) {
        keysRadio.forEach(key => {
          data.checkbox[key] = radioValue[key]
        })
      }
    }

    if (id) data.checkbox.id = id;
    if (deactivate) data.checkbox.is_active = 0;

    if (handleError()) {
      setWaiting(true);
      UserActions.updateIntegrations(data);
    }
  };

  const handleDelete = () => {
    if (id) {
      setWaiting(true);
      UserActions.deleteIntegration(id);
    }
  };

  useEffect(() => {
    if (typeFiscalize.includes("all")) {
      setSelectValue(null);
    } else {
      setSelectValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeFiscalize]);

  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
      {!submitOK && (
        <Box>
          <Heading size={3}>
            {T("setting-integration", [T("Checkbox")])}
          </Heading>
          {DATA_INPUT?.map((item) => (
            <Field className="margin-top-20" key={item.name}>
              {item.label && <Label>{T(item.label)}</Label>}
              <Control>
                <Input
                  size="large"
                  type="text"
                  name={item.name}
                  value={inputValues[item.name] || ""}
                  placeholder={T(item.placeholder)}
                  onChange={handleChangeInputValues}
                />
              </Control>
            </Field>
          ))}
          <Field>
            <Label>{T('position')}</Label>
            <Control>
              <NumberInput value={inputValues.position} name='position' onChange={handleChangeInputValues} placeholder={T('position')} />
            </Control>
          </Field>
          <FiscalizeTypeCheckbox
            type={typeFiscalize}
            onChange={setTypeFiscalize}
          />

          {typeFiscalize?.map((item) => (
            <AdditionalField
              key={item}
              type={item}
              value={selectValue && selectValue[item]}
              onChange={handleChangeSelect}
            />
          ))}

          <FiscalizeAccountType
            accountType={accountTypeValue}
            onChange={setAccountTypeValue}
          />
          <RadioButtonsListControl
            name='account_name'
            labelComponent={<InfoWrapper info={T('name-accounts-check-tip')}>{T('name-accounts-check')}</InfoWrapper>}
            list={DATA_RADIO}
            onChange={handleChangeRadio}
            valueRadio={radioValue['account_name']}
          />
          <Field className="margin-top-20">
            <Control>
              <Checkbox
                name="variable_attrs"
                onChange={handleChangeCheckbox}
                checked={checkboxValue.variable_attrs}
              >
                {T("prro-variable-attrs")}
              </Checkbox>
            </Control>
          </Field>
          <Field className="margin-top-20">
            <Control>
              <Checkbox
                name="received_cod"
                onChange={handleChangeCheckbox}
                checked={checkboxValue.received_cod}
              >
                {T("fiscalization-on-delivery")}
              </Checkbox>
            </Control>
          </Field>

          <Field className="margin-top-20">
            <Control>
              <Checkbox
                name="is_auto"
                onChange={handleChangeCheckbox}
                checked={checkboxValue.is_auto}
                disabled={checkboxValue.received_cod}
              >
                {T("auto-send-to-checkbox")}
              </Checkbox>
            </Control>
          </Field>

          <ActionsModal
            isEdit={!!id}
            isActive={is_active}
            onSubmit={handleSubmit}
            onClose={onClose}
            onDelete={() => confirmDelete(handleDelete)}
            waiting={waiting}
          />
        </Box>
      )}
      {submitOK && (
        <ModalItsOK
          onClose={onClose}
          title={submitOK}
          additionalMessage={additionalMessage}
        />
      )}
    </>
  );
};

export default CheckboxModal;