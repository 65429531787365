import React from "react";
import List from "react-bulma-components/lib/components/list";
import Columns from "react-bulma-components/lib/components/columns";
import T from "../../../../components/i18n";

const ListTitleDesktop = ({onlyShow,step}) => {
	return (
		<List.Item className="font-size-80 bold has-background-light nowrap" >
			<Columns>
				<Columns.Column size={"two-thirds"}>
					{T('product-name')}
				</Columns.Column>
				<Columns.Column>
					<Columns>
						{!onlyShow && (
							<Columns.Column>
								{T('In-stock')}
							</Columns.Column>)}

						<Columns.Column>
							{T('write-off-label')}
						</Columns.Column>
						{!onlyShow && step !== 2 && <Columns.Column>
							{T('del')}
						</Columns.Column>}
					</Columns>
				</Columns.Column>
			</Columns>
		</List.Item>
	);
};

export default ListTitleDesktop;