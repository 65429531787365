import React, { useEffect, useState } from "react";
import Reports from "../../../components/reports";
import SalesStore from "../sales-store";
import SalesRowMobile from "../mobile/sales-row-mobile";
import DesktopSalesRow from "../desktop/sales-row";
import EmptyMessage from "./empty-message";
import SalesActions from "../sales-actions";
import UserActions from "../../../user/user-actions";
import UserStore from "../../../user/user-store";
import User from "../../../user/user-store";
import CustomizeColumns from "../../../components/customize-columns";
import Box from "react-bulma-components/lib/components/box";
import FilterPanel from "./filter-panel";
import Button from "react-bulma-components/lib/components/button";
import T from "../../../components/i18n";
import usePaginationHook from "../../../tools/pagination-hook";
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";

let COLUMNS = [
	"",
	"purchase",
	"who-add-sale",
	"customer",
	"delivery",
	"account",
	"payment-pay",
	"status",
	"reports-actions",
];

const SaleRefundReportList = ({ onChangeSelectAllCheckbox }) => {
	const {isMobileSize} = useIsMobile()
	const [activeColumn, setActiveColumn] = useState([]);
	const [custom_column, setCustom_column] = useState([]);
	const [isOpenRow, setIsOpenRow] = useState(false);

	useEffect(() => {
		SalesActions.loadRefund();
		const getColumn = Object.values(
			UserStore.getCustomColumns("pr-sales-column")
		);

		const CUSTOMIZE_COLUMNS_DATA = COLUMNS.map((item, index) => ({
			label: item,
			is_global: item === "" || item === "purchase" ? 1 : 0,
			position: index + 1,
			is_active: item !== "who-add-sale",
		})).filter(item => {
			if(!User.getPermissionEnable("salesprice") && item.label === 'payment-pay') {
				return false
			}

			if(!User.getModuleEnable("clients") && item.label === 'customer') {
				return false
			}

			return true
		});
		setActiveColumn(getColumn?.length ? getColumn.filter(item => {
			if(!User.getPermissionEnable("salesprice") && item.label === 'payment-pay') {
				return false
			}

			if(!User.getModuleEnable("clients") && item.label === 'customer') {
				return false
			}

			return true
		}) : CUSTOMIZE_COLUMNS_DATA);
		setCustom_column(CUSTOMIZE_COLUMNS_DATA);

		const listenChangeColumn = UserActions.setCustomColumns.completed.listen(
			() => {
				const getColumn = Object.values(
					UserStore.getCustomColumns("pr-sales-column")
				);

				setActiveColumn(
					!!getColumn?.length ? getColumn.filter(item => {
						if(!User.getPermissionEnable("salesprice") && item.label === 'payment-pay') {
							return false
						}

						if(!User.getModuleEnable("clients") && item.label === 'customer') {
							return false
						}

						return true
					}) : CUSTOMIZE_COLUMNS_DATA
				);
			}
		);
		const listenDeleteColumn = UserActions.deleteCustomColumns.completed.listen(
			() => {
				setActiveColumn(CUSTOMIZE_COLUMNS_DATA);
			}
		);
		return () => {
			listenChangeColumn();
			listenDeleteColumn();
		};
	}, []);

	const ACTIVE_COLUMNS = activeColumn
		.sort((a, b) => a.position - b.position)
		.reduce((acc, cur) => {
			if (cur.is_active) {
				acc.push(cur.label);
			}

			return acc;
		}, []);

	const handleResetColumns = () => {
		UserActions.deleteCustomColumns("pr-sales-column");
	};

	const list = SalesStore.getRefund();
	const {start,end,PaginationBlock,setPage,page,total} = usePaginationHook({listLength: list?.length})

	return (
		<>
			{!isMobileSize && (
				<div
					align="right"
					style={{ marginBottom: "5px", position: "relative" }}
				>
					<CustomizeColumns
						customizeColumns={custom_column}
						customizeColumnsActive={UserStore.getCustomColumns(
							"pr-sales-column"
						)}
						columnsName="pr-sales-column"
						onReset={handleResetColumns}
					/>
				</div>
			)}
			<Box className="sales-report" style={{ overflow: "auto" }}>
				{isMobileSize && (
					<FilterPanel onChangeSelectAllCheckbox={onChangeSelectAllCheckbox} isRefund/>
				)}
				{!!SalesStore.getSales().length && isMobileSize && (
					<div align="right" style={{ marginBottom: "5px" }}>
						<Button
							onClick={() => setIsOpenRow((prev) => !prev)}
							style={{ fontSize: "12px" }}
						>
							{isOpenRow ? T("show-all-sales-btn") : T("hide-all-sales-btn")}
						</Button>
					</div>
				)}
				<PaginationBlock
					totalPage={total}
					setPage={setPage}
					currentPage={page}
					showPrevNext={false}
				/>
				<Reports
					load={SalesActions.load}
					emptyMessage={EmptyMessage}
					columns={ACTIVE_COLUMNS}
					hideFooterOnEmpty
					isLoading={SalesStore.get("loadingRefund")}
					minWidthList={ACTIVE_COLUMNS.length * 110}
					rows={list.slice(start,end)}
				>
					{isMobileSize ? (
						<SalesRowMobile
							key="r0"
							role="row"
							columnLength={activeColumn.length}
							triggerIsOpen={isOpenRow}
							isRefund
						/>
					) : (
						<DesktopSalesRow
							activeColumn={activeColumn}
							key="r0"
							role="row"
							isRefund
						/>
					)}
				</Reports>
				<PaginationBlock
					totalPage={total}
					setPage={setPage}
					currentPage={page}
					showPrevNext={false}
				/>
			</Box>
		</>
	);
};

export default SaleRefundReportList;
