import React, {useState} from 'react'
import List from "react-bulma-components/lib/components/list";
import T from "../../../../components/i18n";
import formatDate from "../../../../tools/format-date";
import Money from "../../../../components/money";
import DOM from "react-dom-factories";
import ExpensesStore from "../../../expenses/expenses-store";
import ChannelList from "../../../sales/common/channel-list";
import SalesReportSubItem from "./sales-report-sub-item";

const SalesReportItem = ({data}) => {
	const [isShow, setIsShow] = useState(false)

	const handleToggleShow = () => {
		setIsShow(prev => !prev)
	}

	return (
		<List.Item>
			<div onClick={handleToggleShow} className='cursor-pointer'>
				<b>
					{T("order")} ID: {data["oid"]}
				</b>
				. {formatDate(data["purchase_date"])}.{" "}
				<b>
					<Money amount={data["amount_sale"]} wrapper={DOM.span} />{" "}
					{T(ExpensesStore.getAccountName(data["account_id"]))}{" "}
				</b>
				<ChannelList channels={data?.channel_id}/>
				<br />
			</div>

			{isShow && data?.items?.map(item => <SalesReportSubItem key={`${item.product_id}_${data.oid}`} data={item}/>)}
		</List.Item>
	);
};

export default SalesReportItem;