import React, { Component } from "react";
import yajax from "yajax";
// import Reflux from 'reflux';
import T from "components/i18n";

import UserStore from "user/user-store";
import WarehouseStore from "modules/warehouse/warehouse-store";
import ExpensesStore from "modules/expenses/expenses-store";
import ExpensesActions from "modules/expenses/expenses-actions";
// import Actions from 'user/user-actions';
// import Icon from 'react-bulma-components/lib/components/icon';
import {
  Field,
  Control,
  Input,
  Help,
  Label,
} from "react-bulma-components/lib/components/form";
import Box from "react-bulma-components/lib/components/box";
import Button from "react-bulma-components/lib/components/button";
import isValidEmail from "tools/valid-email";
import isValidPhone from "tools/valid-phone";
// import { loadReCaptcha } from 'react-recaptcha-v3'
import OpenModal from "components/modal/modal";
// import User from 'user/user-store';
import Messages from "components/messages";

import SelectComponent from "tools/select-component";
import trackEvent from "tools/track-events";
import AddUserCheckboxList from "./checkbox-list";
import OrdersStore from "../../../orders/orders-store";
import SalesStore from "../../../sales/sales-store";
import AddAccrualSales from "../../common/add-accrual-sales/add-accrual-sales";
import AppStore from "../../../../app-store";
import { getActiveIdsItem } from "../../common/add-accrual-sales/utils";
import AccrualList from "../../common/add-accrual-sales/accrual-list";
import SubmitButton from "../../../../components/submit-button";
import NumberInput from "../../../../components/number-input/number-input";

class AddUserModal extends Component {
  constructor(props) {
    super(props);
    const { user_id } = this.props;
    let user_data = {};

    if (user_id) {
      user_data = UserStore.getSubUserData(user_id);
    }

    let accounts = null;
    if (user_data.accounts) {
      const raw_accounts = JSON.parse(user_data.accounts);
      accounts = [];
      for (let i in raw_accounts) {
        if (parseInt(raw_accounts[i]) === 0) {
          continue;
        }
        accounts.push({
          value: raw_accounts[i],
        });
      }
    }

    let warehouses = null;
    if (user_data.warehouse) {
      let raw_warehouse = [];
      if (user_data.warehouse.toString().indexOf("[") > -1) {
        raw_warehouse = JSON.parse(user_data.warehouse);
      } else {
        raw_warehouse = [user_data.warehouse];
      }
      warehouses = [];
      for (let i in raw_warehouse) {
        if (parseInt(raw_warehouse[i]) === 0) {
          continue;
        }
        warehouses.push({
          value: raw_warehouse[i],
        });
      }
    }

    let statuses = { value: 0, label: T("all") };

    if (user_data?.more_setting?.statuses) {
      statuses = OrdersStore.getStatusesValueLabelByIds(
        user_data?.more_setting?.statuses,
        false,
        true
      );
    }

    let custom_prices = { value: 0, label: T("all") };

    if (user_data?.more_setting?.custom_prices) {
      custom_prices = UserStore.getCustomPriceValueLabelByIds(
        user_data?.more_setting?.custom_prices
      );
    }

    this.state = {
      subname: {
        error: "",
        value: user_data["name"] || "",
      },
      email: {
        error: "",
        value: user_data["email"] || "",
      },
      subphone: {
        error: "",
        value: user_data["phone"] || "",
      },
      subpsw: {
        error: "",
        value: "",
      },
      internalNumber: {
        error: "",
        value: user_data?.more_setting?.internalNumber || ''
      },
      statuses: statuses,
      custom_prices: custom_prices,
      settings: {
        modules: user_data["modules"] || {},
        permissions: user_data["permissions"] || {},
        more_setting: {},
      },
      group: { value: user_data["group"] } || null,
      warehouse: warehouses || null,
      accounts: accounts || null,
      // warehouse: {'value': 0},
      // accounts: [{'value': 0}],
      user_id: user_id || null,
      isCheckBot: false,
      accrual: user_data?.more_setting?.accrual
        ? user_data?.more_setting?.accrual
        : {
            general: "",
            general_type: "",
            category: [],
            brand: [],
            accrual_type: "turnover",
          },
    };

    this.default_settings = {
      2: {
        modules: {
          dashboard: 1,
          chats: 1,
          orders: 1,
          sales: 1,
          reserv: 1,
          payments: 1,
          production: 1,
          shipments: 1,
          moving: 1,
          "write-off": 1,
          inventory: 1,
          clients: 1,
          reports: 1,
          transactions: 1,
          history: 1,
          settings: 1,
          slip: 1,
          pricetags: 1,
        },
        permissions: {
          "show-all-sales": 1,
          salesprice: 1,
          showbalance: 1,
          "show-cashflow": 1,
          "show-all-expenses": 1,
          incomeinformation: 1,
          editinstock: 1,
          editproducts: 1,
          rawcost: 1,
          "edit-sales": 1,
          "refund-products": 1,
          deleteproducts: 1,
          deleteshipments: 1,
          deletesales: 1,
          "delete-client": 1,
          'edit-balance': 0
        },
      },
      3: {
        modules: {
          dashboard: 1,
          chats: 1,
          orders: 0,
          sales: 1,
          reserv: 1,
          payments: 1,
          production: 0,
          shipments: 0,
          moving: 0,
          "write-off": 0,
          inventory: 0,
          clients: 1,
          reports: 0,
          transactions: 0,
          history: 0,
          settings: 0,
          slip: 1,
          pricetags: 1,
        },
        permissions: {
          "show-all-sales": 0,
          salesprice: 1,
          showbalance: 1,
          "show-cashflow": 0,
          "show-all-expenses": 0,
          incomeinformation: 0,
          editinstock: 0,
          editproducts: 1,
          rawcost: 0,
          "edit-sales": 0,
          "refund-products": 1,
          deleteproducts: 0,
          deleteshipments: 0,
          deletesales: 0,
          "delete-client": 0,
          'edit-balance': 0
        },
      },
      4: {
        modules: {
          dashboard: 0,
          chats: 0,
          orders: 0,
          sales: 0,
          reserv: 0,
          payments: 0,
          production: 0,
          shipments: 1,
          moving: 1,
          "write-off": 1,
          inventory: 1,
          clients: 1,
          reports: 0,
          transactions: 0,
          history: 0,
          settings: 0,
          slip: 1,
          pricetags: 1,
        },
        permissions: {
          "show-all-sales": 0,
          salesprice: 0,
          showbalance: 1,
          "show-cashflow": 0,
          "show-all-expenses": 0,
          incomeinformation: 0,
          editinstock: 1,
          editproducts: 1,
          rawcost: 1,
          "edit-sales": 0,
          "refund-products": 1,
          deleteproducts: 0,
          deleteshipments: 0,
          deletesales: 0,
          "delete-client": 0,
          'edit-balance': 0
        },
      },
      5: {
        modules: {
          dashboard: 1,
          chats: 1,
          orders: 1,
          sales: 1,
          reserv: 1,
          payments: 1,
          production: 0,
          shipments: 1,
          moving: 1,
          "write-off": 0,
          inventory: 0,
          clients: 1,
          reports: 0,
          transactions: 1,
          history: 0,
          settings: 0,
          slip: 1,
          pricetags: 1,
        },
        permissions: {
          "show-all-sales": 0,
          salesprice: 1,
          showbalance: 0,
          "show-cashflow": 0,
          "show-all-expenses": 0,
          incomeinformation: 0,
          editinstock: 1,
          editproducts: 1,
          rawcost: 1,
          "edit-sales": 0,
          "refund-products": 1,
          deleteproducts: 1,
          deleteshipments: 1,
          deletesales: 1,
          "delete-client": 1,
          "edit-balance": 0
        },
      },
      6: {
        modules: {
          dashboard: 0,
          chats: 0,
          orders: 0,
          sales: 0,
          reserv: 0,
          payments: 0,
          production: 0,
          shipments: 0,
          moving: 0,
          "write-off": 0,
          inventory: 0,
          clients: 0,
          reports: 0,
          transactions: 0,
          history: 0,
          settings: 0,
          slip: 0,
          pricetags: 0,
        },
        permissions: {
          "show-all-sales": 0,
          salesprice: 0,
          showbalance: 0,
          "show-cashflow": 0,
          "show-all-expenses": 0,
          incomeinformation: 0,
          editinstock: 0,
          editproducts: 0,
          rawcost: 0,
          "edit-sales": 0,
          "refund-products": 0,
          deleteproducts: 0,
          deleteshipments: 0,
          deletesales: 0,
          "delete-client": 0,
          'edit-balance': 0
        },
      },
    };

    this.onChangeReg = this.onChangeReg.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.onChangeSelectSimple = this.onChangeSelectSimple.bind(this);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.onChangeCheckboxPerm = this.onChangeCheckboxPerm.bind(this);
    this.onSendRegistrationData = this.onSendRegistrationData.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.submit = this.submit.bind(this);
    this.onChangeSelectMulti = this.onChangeSelectMulti.bind(this);
    this.onChangeAccrual = this.onChangeAccrual.bind(this);
    this.onDeleteAccrual = this.onDeleteAccrual.bind(this);
    this.onEditAccrual = this.onEditAccrual.bind(this);
  }

  componentDidMount() {
    // loadReCaptcha("6LeTocIZAAAAAIRfeIJfDD8aBCHAczTNqGg8Pqm_", () => {this.setState({'isCheckBot': true})});
    // this.listenChangeToken = UserActions.changeToken.listen(() => {
    // });
  }

  componentWillUnmount() {
    // if (typeof(this.listenChangeToken) == "function") {
    //     this.listenChangeToken();
    // }
  }

  _validation(name, val) {
    this._cleanError(name, val);

    let res = this.state,
      error;

    if (name === "email") {
      error = !isValidEmail(val) ? "wrong-email" : "";
    } else if (name === "subphone") {
      error = !isValidPhone(val) ? "wrong-phone" : "";
    } else if (name === "subpsw") {
      error = !this.isValidPassword(this.state.subpsw.value)
        ? "wrong-password"
        : "";
    }

    if (val !== "") {
      res = { [name]: { error: error, value: val } };
      this.setState(res);
    }
  }

  isValidPassword(pass) {
    return pass.length > 3;
  }

  _cleanError(name, val) {
    this.setState({
      [name]: {
        error: "",
        value: val,
      },
    });
  }

  setModuleEnable(name, val, _type) {
    let modules = this.state.settings.modules,
      permissions = this.state.settings.permissions;

    if (_type === "modules") {
      modules[name] = val ? 1 : 0;
    } else if (_type === "permissions") {
      permissions[name] = val ? 1 : 0;
    } else if ("group") {
      permissions[name] = val;
    }

    this.setState({
      settings: {
        modules: modules,
        permissions: permissions,
      },
    });

    // Actions.updateSettings();
  }

  onChangeAccrual(name, value) {
    if (name === "general") {
      this.setState((prev) => ({
        accrual: {
          ...prev.accrual,
          general: value.value,
          general_type: value.type,
        },
      }));
    } else {
      this.setState((prev) => ({
        accrual: {
          ...prev.accrual,
          [name]: [...prev.accrual[name], value],
        },
      }));
    }
  }

  onDeleteAccrual(name, index) {
    const accrualData = JSON.parse(JSON.stringify(this.state.accrual));
    if (name === "general") {
      accrualData.general = "";
      accrualData.general_type = "";
    } else {
      if (accrualData[name][index]) {
        accrualData[name] = [
          ...accrualData[name].slice(0, index),
          ...accrualData[name].slice(index + 1),
        ];
      }
    }

    this.setState({
      accrual: accrualData,
    });
  }

  onEditAccrual(name, index, value) {
    const accrualData = JSON.parse(JSON.stringify(this.state.accrual));
    if (name === "general") {
      accrualData.general = value.value;
      accrualData.general_type = value.type;
    } else if (name === "accrual_type") {
      accrualData.accrual_type = value;
    } else {
      if (accrualData[name][index]) {
        accrualData[name][index] = value;
      }
    }

    this.setState({
      accrual: accrualData,
    });
  }

  onChangeReg(evt) {
    const value = evt.target.value,
      name = evt.target.name;
    this.setState({
      [name]: {
        value: value,
      },
    });
  }

  onChangeSelectSimple(selectedOption, action) {
    this.setState({
      [action.name]: selectedOption,
    });
  }

  onChangeSelect(selectedOption, action) {
    const { user_id } = this.props;
    this.setState({
      [action.name]: selectedOption,
      settings: user_id
        ? this.state.settings
        : this.default_settings[parseInt(selectedOption.value)],
    });
  }

  onChangeSelectMulti(selectedOption, action) {
    if (!selectedOption) {
      this.setState({ [action.name]: [{ value: 0, label: T("all") }] });
    } else if (action.option?.value === 0) {
      this.setState({ [action.name]: [action.option] });
    } else {
      this.setState({
        [action.name]: selectedOption.filter((item) => item?.value !== 0),
      });
    }
  }

  onChangeCheckbox(evt) {
    const value = evt.target.checked,
      name = evt.target.name;

    this.setModuleEnable(name, value, "modules");
  }

  onChangeCheckboxPerm(evt) {
    const value = evt.target.checked,
      name = evt.target.name;

    this.setModuleEnable(name, value, "permissions");
  }

  onBlur(evt) {
    const value = evt.target.value,
      name = evt.target.name;

    this._validation(name, value);
  }

  submit() {
    if (!this.state.isCheckBot) {
      // return false;
    }

    const email = this.state.email.value,
      subphone = this.state.subphone.value,
      pass = this.state.subpsw.value;

    if (this.state.email.error !== "") {
      console.log("problem with email");
      return false;
    }
    if(this.state.subpsw.error !== "" || (!this.state.user_id && !pass)) {
      this.setState({
        message: T("password-must-characters-long"),
      });
      return false
    }


    if (subphone !== "" && this.state.subphone.error !== "") {
      console.log("problem with phone");
      return false;
    }

    if (this.state.group.value !== UserStore.ADMIN) {
      if (this.state.group.value !== UserStore.WAREHOUSEMAN) {
        if (!this.state.accounts) {
          console.log("problem with accounts");
          this.setState({
            message: T("select-available-account"),
          });
          return false;
        }
      }

      if (!this.state.warehouse) {
        console.log("problem with warehouse");
        this.setState({
          message: T("select-available-warehouse"),
        });
        return false;
      }
    }

    const settings = JSON.parse(JSON.stringify(this.state.settings));
    settings.more_setting =
      UserStore.getSubUserData(this.state.user_id)?.more_setting || {};

    if (this.state.statuses && settings.modules.sales) {
      const statuses = this.state.statuses;

      settings.more_setting.statuses = Array.isArray(statuses)
        ? this.state.statuses.map((item) => item.value)
        : [statuses?.value];
    } else if (!settings.modules.sales) {
      settings.more_setting.statuses = null;
    }
    if (this.state.custom_prices && settings.modules.sales) {
      const custom_prices = this.state.custom_prices;

      settings.more_setting.custom_prices = Array.isArray(custom_prices)
        ? this.state.custom_prices.map((item) => item.value)
        : [custom_prices?.value];
    } else if (!settings.modules.sales) {
      settings.more_setting.custom_prices = null;
    }

    if (this.state.accrual) {
      settings.more_setting.accrual = this.state.accrual;
    }

    if(this.state.internalNumber.value) {
      settings.more_setting.internalNumber = this.state.internalNumber.value;
    } else {
      settings.more_setting.internalNumber = ''
    }


    let data = {
      email: email,
      name: this.state.subname.value,
      password: pass,
      phone: subphone,
      lang: UserStore.getLang(),
      settings: JSON.stringify(settings),
    };

    if (this.state.group) {
      data["group"] = this.state.group.value;
    }

    if (this.state.accounts && this.state.group.value !== UserStore.ADMIN) {
      let ac = Array.isArray(this.state.accounts)
        ? this.state.accounts
        : [this.state.accounts];
      data["accounts"] = JSON.stringify(ac);
    }

    if (this.state.warehouse && this.state.group.value !== UserStore.ADMIN) {
      let wh = Array.isArray(this.state.warehouse)
        ? this.state.warehouse
        : [this.state.warehouse];
      data["warehouse"] = JSON.stringify(wh);
    }

    if (this.state.user_id) {
      data["user_id"] = this.state.user_id;
    }

    trackEvent("users", "working", "add-sub-user");
    this.setState({
      waiting: true
    })
    return yajax
      .post("/userprofile/personnel", data)
      .then(this.onSendRegistrationData, (error) => {
        console.log("response error ", error);
        if (error["response"][0] === "{") {
          const response = JSON.parse(error["response"]);

          if ("error" in response) {
            if (response["error"]["code"] === "email-already-exists") {
              this.setState({
                email: {
                  error: T(response["error"]["code"]),
                },
                waiting: false
              });
            }
          }
        } else {
          this.setState({
            email: {
              error: T("unhandled_error"),
              waiting: false
            },
          });
        }
      });
  }

  onSendRegistrationData(data) {
    if (data.success) {
      const { onClose } = this.props;
      onClose();
    }
  }

  _getError(name) {
    return <Help color="danger">{T(this.state[name].error)}</Help>;
  }

  render() {
    const getCustomPrice = UserStore.getCustomPriceForList();
    return (
      <Box className="addUser">
        <Messages
          message={this.state.message}
          color={this.state.color || "danger"}
          close={() => this.setState({ message: null })}
        />
        <Field>
          <Label>{T("user-name")}</Label>
          <Control>
            <Input
              size="large"
              name="subname"
              autoComplete="nope"
              color={this.state.subname.error ? "danger" : "primary"}
              placeholder={T("name-placeholder")}
              onChange={this.onChangeReg}
              onBlur={this.onBlur}
              value={this.state.subname.value}
            />
          </Control>
          {this.state.subname.error && this._getError("subname")}
        </Field>

        <Field>
          <Label>E-mail</Label>
          <Control>
            <Input
              size="large"
              type="email"
              name="email"
              color={this.state.email.error ? "danger" : "primary"}
              placeholder={T("email-placeholder")}
              autoComplete="nope"
              onChange={this.onChangeReg}
              onBlur={this.onBlur}
              value={this.state.email.value}
            />
          </Control>
          {this.state.email.error && this._getError("email")}
        </Field>

        <Field>
          <Label>{T("phone")}</Label>
          <Control>
            <Input
              size="large"
              type="text"
              name="subphone"
              color={this.state.subphone.error ? "danger" : "primary"}
              placeholder={T("phone-placeholder")}
              onChange={this.onChangeReg}
              autoComplete="off"
              onBlur={this.onBlur}
              value={this.state.subphone.value}
            />
          </Control>
          {this.state.subphone.error && this._getError("subphone")}
        </Field>

        <Field>
          <Label>{T("users-pass")}</Label>
          <Control>
            <Input
              size="large"
              name="subpsw"
              type="text"
              placeholder={T("password")}
              color="primary"
              autoComplete="off"
              onChange={this.onChangeReg}
              onBlur={this.onBlur}
              value={this.state.subpsw.value}
            />
          </Control>
        </Field>
        {!!UserStore.getBinotelList().length && <Field>
          <Label>{T('binotel-telephone-line')}</Label>
          <NumberInput name='internalNumber' size='large' color='primary'
                 onChange={this.onChangeReg}
                 value={this.state.internalNumber.value}/>
        </Field>}

        <SelectComponent
          name="group"
          label="user-group"
          onChange={this.onChangeSelect}
          value={this.state.group}
          list={UserStore.getGroupList}
          creatable={false}
        />

        {[
          UserStore.CASHIER,
          UserStore.WAREHOUSEMAN,
          UserStore.MANAGER,
          UserStore.GUEST,
        ].indexOf(this.state.group.value) > -1 ? (
          <>
            <SelectComponent
              name="warehouse"
              label="history-data-marketplace_id"
              className="full-size-select"
              onChange={this.onChangeSelectSimple}
              value={this.state.warehouse}
              isMulti={true}
              list={WarehouseStore.getWarehouseList}
              creatable={false}
            />

            <SelectComponent
              name="accounts"
              label="select-account"
              className="full-size-select"
              onChange={this.onChangeSelectSimple}
              value={this.state.accounts}
              autoSelect={false}
              isMulti={true}
              list={ExpensesStore.getAccountsList}
              load={ExpensesActions.loadAccounts}
              creatable={false}
            />
          </>
        ) : null}

        {!!this.state.settings.modules.sales && (
          <SelectComponent
            name="statuses"
            className="full-size-select"
            empty="all"
            label="sales-statuses"
            onChange={this.onChangeSelectMulti}
            value={this.state.statuses}
            isMulti={true}
            list={() => SalesStore.getAllStatusesList([], true)}
            creatable={false}
          />
        )}

        {!!this.state.settings.modules.sales && !!getCustomPrice.length && (
          <SelectComponent
            name="custom_prices"
            className="full-size-select"
            empty="all"
            label="custom-price"
            onChange={this.onChangeSelectMulti}
            value={this.state.custom_prices}
            isMulti={true}
            list={() => getCustomPrice}
            creatable={false}
          />
        )}
        <Box>
          <div align="right">
            <Button
              color="success"
              onClick={() =>
                AppStore.openModal(
                  <AddAccrualSales
                    modal="accrual"
                    deleteGeneral={!!this.state.accrual.general}
                    onSubmit={this.onChangeAccrual}
                    activeBrand={getActiveIdsItem(this.state.accrual.brand)}
                    activeCategory={getActiveIdsItem(this.state.accrual.category)}
                  />
                )
              }
            >
              {T("add-accrual-of-sales")}
            </Button>
          </div>
          <AccrualList
            data={this.state.accrual}
            onDelete={this.onDeleteAccrual}
            onEdit={this.onEditAccrual}
          />
        </Box>

        <AddUserCheckboxList
          settings={this.state.settings}
          onChangeCheckbox={this.onChangeCheckbox}
          onChangeCheckboxPerm={this.onChangeCheckboxPerm}
        />

        <Button.Group>
          <SubmitButton
            text={T(this.state.user_id ? "edit-user" : "create-new-user")}
            submit={this.submit}
            color='primary'
            waiting={this.state.waiting}
          />
        </Button.Group>
      </Box>
    );
  }
}

class AddUserModalLink extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const props = this.props.location.state;
    return (
      <OpenModal modal={{ closeOnBlur: false }}>
        <AddUserModal {...props} />
      </OpenModal>
    );
  }
}

export default AddUserModalLink;
